import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { Unit } from '../unit.model';
import { Widget } from './widget.model';

@JsonObject('MeasureWidget')
export class MeasureWidget extends Widget {
  @JsonProperty('disableControl', Boolean, true)
  public disableControl: boolean = false;

  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('max', Number, true)
  public max: number | null = null;

  @JsonProperty('min', Number, true)
  public min: number | null = null;

  @JsonProperty('tooltip', String, true)
  public tooltip: string = '';

  @JsonProperty('unit', Unit, true)
  public unit: Unit | null = null;

  public constructor() {
    super();
    this.componentType = ComponentType.MEASURE;
  }
}
