import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { ComponentType, SectionWidget, TemplateFull, Widget } from '@al/model';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import {
  FormGroupModelInterface,
  GenericFormComponent,
} from '@al/generic-components';
import { TemplateFullService, TemplateLightService } from '@al/services';
import { FormControlNames } from '../form-control-names.enum';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@al/navigation';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-template-body',
  templateUrl: './template-body.component.html',
  styleUrls: ['./template-body.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class TemplateBodyComponent
  extends GenericFormComponent<TemplateFull>
  implements
    OnInit,
    OnDestroy,
    AfterViewInit,
    FormGroupModelInterface<TemplateFull>
{
  @ViewChild(CdkDropList)
  public dropList: CdkDropList | undefined;

  public cdkDropLists: CdkDropList[] = [];

  public formControlNames = FormControlNames;

  public formGroup: FormGroup = new FormGroup({});

  public isFormValid = false;

  public isInit: boolean = false;

  public listId = 'amain-list';

  public templateBody: Widget[] = [];

  public constructor(
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected templateLightService: TemplateLightService,
    protected templateFullService: TemplateFullService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected templateModelService: TemplateModelService,
    protected dragAndDropService: DragAndDropService,
    protected formGroupDirective: FormGroupDirective
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      templateFullService,
      translateService,
      router,
      route
    );
    this.formGroup = this.buildFormGroup();
  }

  public buildFormGroup(): FormGroup {
    return this.formBuilder.group({
      // title: this.formBuilder.control(templateFull?.title, [
      //   Validators.required,
      // ]),
    });
  }

  public delete(widget: Widget): void {
    this.templateModelService.delete(widget);
  }

  public drop(event: CdkDragDrop<Widget[]>): void {
    if (event.previousContainer === event.container) {
      this.dragAndDropService.moveItemInArray(event);
    } else {
      let nextId = this.templateModelService.nextId();
      let widgetId = `0_${nextId}`;
      let move: boolean = false;

      if (event.previousContainer.data[event.previousIndex]) {
        move = event.previousContainer.data[event.previousIndex].id !== null;
      } else {
        move = true;
      }

      if (move) {
        this.dragAndDropService.transferArrayItem(event, widgetId);
      } else {
        this.dragAndDropService.copyArrayItem(event, widgetId);
      }
    }
  }

  public duplicate($event: Widget): void {
    let index: number = this.templateBody.indexOf($event);
    let nextId = this.templateModelService.nextId();

    const widgetCopy = Object.assign(
      Object.create(Object.getPrototypeOf($event)),
      $event
    );

    widgetCopy.id = `0_${nextId}`;
    if (widgetCopy instanceof SectionWidget) {
      let section = $event as SectionWidget;
      widgetCopy.children = [];

      section.children.forEach((child: Widget) => {
        let childCopy = Object.assign(
          Object.create(Object.getPrototypeOf(child)),
          child
        );
        childCopy.id = nextId + '_' + this.templateModelService.nextId();
        //TODO ICI
        widgetCopy.children.push(childCopy);
      });
    }

    this.templateBody.splice(index, 0, widgetCopy);
  }

  public get getCdkListId(): CdkDropList[] {
    return this.cdkDropLists;
  }

  public getEntityToSave(): TemplateFull {
    return new TemplateFull();
  }

  public get getListId(): string {
    return this.listId;
  }

  public initFormGroup(): void {
    this.formGroup = this.buildFormGroup();
  }

  public initModel(): TemplateFull {
    return new TemplateFull();
  }

  public isGate(widget: Widget): boolean {
    return widget.componentType === ComponentType.GATE;
  }

  public isMcq(widget: Widget): boolean {
    return widget.componentType === ComponentType.MULTI_CHECKBOX;
  }

  public isMeasure(widget: Widget): boolean {
    return widget.componentType === ComponentType.MEASURE;
  }

  public isNote(widget: Widget): boolean {
    return widget.componentType === ComponentType.NOTE;
  }

  public isSection(widget: Widget): boolean {
    return widget.componentType === ComponentType.SECTION;
  }

  public isText(widget: Widget): boolean {
    return widget.componentType === ComponentType.TEXT;
  }

  public isValidation(widget: Widget): boolean {
    return widget.componentType === ComponentType.CHECKBOX;
  }

  public isWheelpicker(widget: Widget): boolean {
    return widget.componentType === ComponentType.WHEEL_PICKER;
  }

  public ngAfterViewInit() {
    if (this.dropList) {
      this.dragAndDropService.registerForDrop(this.dropList);
    }

    this.dragAndDropService
      .getCdkDropListObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((cdkListId: CdkDropList[]) => {
        this.cdkDropLists = cdkListId;
        this.cdkDropLists.forEach((dropList) => {
          let dropListRef = dropList._dropListRef;
          let siblings = this.cdkDropLists.map((dl) => dl?._dropListRef);
          siblings = siblings.sort((a, b) =>
            b.data.id.localeCompare(a.data.id)
          );
          dropListRef._getSiblingContainerFromPosition = (item, x, y) => {
            let selectedDropList = siblings.find((sibling) =>
              sibling._canReceive(item, x, y)
            );
            return selectedDropList;
          };
        });
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dragAndDropService.unregisterForDrop(this.listId);
  }

  public ngOnInit(): void {
    this.templateModelService
      .getTemplateBodyObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((widgets: Widget[]) => {
        this.templateBody = widgets;
        if (this.formGroupDirective && !this.isInit) {
          this.formGroup = this.buildFormGroup();
          this.formGroupDirective.form.addControl(
            'template-body',
            this.formGroup
          );
          this.isInit = true;
        }
      });
  }

  public get isValid(): boolean {
    return this.isFormValid;
  }

  public save(): void {}
}
