
<div class="container">
  <mat-form-field [matTooltip]="displayProperty('PU', fullObject.locationPU)"
  matTooltipPosition="above" appearance="outline" class="full-width" floatLabel="always">
    <mat-label>{{ "PU_LABEL" | translate}}
    </mat-label>

    <input type="text" [formControl]="pu" matInput>
  </mat-form-field>

  <mat-form-field [matTooltip]="displayProperty('PG', fullObject.locationPG)"
  matTooltipPosition="above" appearance="outline" class="full-width" floatLabel="always">
    <mat-label>{{ "PG_LABEL" | translate}}
    </mat-label>

    <input type="text" [formControl]="pg" matInput>
  </mat-form-field>

  <mat-form-field [matTooltip]="displayProperty('Asset', fullObject.asset)"
  matTooltipPosition="above" appearance="outline" class="full-width" floatLabel="always">
    <mat-label>{{ "ASSET_LABEL" | translate}}
    </mat-label>

    <input type="text" [formControl]="asset" matInput>
  </mat-form-field>

  <button class="display-tree" translate mat-raised-button (click)="displayTree()" color="primary">
    {{ "ASSET_TREE_TOGGLE" | translate}}
  </button>
  <button mat-icon-button (click)="clear()" aria-label="clear">
    <mat-icon>clear</mat-icon>
  </button>

</div>

