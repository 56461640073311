import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Returnable, Unit, WheelPickerWidget } from '@al/model';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { DragAndDropService } from '@al/dnd-service';
import { FormGroupModelInterface } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { Observable } from 'rxjs';
import { UnitFilterService } from '../unit-filter.service';
import { UnitService } from '@al/services';
import { WidgetFormControlNames } from '../widget-form-control-names.enum';
import { decimalPrecisionValidator } from './validators/decimal-precision.validator';

@Component({
  selector: 'al-wheelpicker-widget',
  templateUrl: './wheelpicker-widget.component.html',
  styleUrls: [
    '../template-widget.component.scss',
    './wheelpicker-widget.component.scss',
  ],
})
export class WheelpickerWidgetComponent
  extends TemplateWidgetComponent
  implements FormGroupModelInterface<WheelPickerWidget>, OnInit
{
  public filteredUnits: Observable<Unit[]> = new Observable<Unit[]>();

  public units: Unit[] = [];

  public wheelpickerWidget = new WheelPickerWidget();

  public constructor(
    protected override formBuilder: FormBuilder,
    protected override answerService: InstanceAnswerService,
    protected dragAndDropService: DragAndDropService,
    protected override formGroupDirective: FormGroupDirective,
    protected unitFilterService: UnitFilterService,
    protected unitService: UnitService
  ) {
    super(formBuilder, answerService, formGroupDirective);
  }

  public buildFormGroup(
    wheelpickerWidget: WheelPickerWidget | null
  ): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      decimalIndex: [
        wheelpickerWidget?.decimalIndex,
        [Validators.required, decimalPrecisionValidator],
      ],
      disableControl: [wheelpickerWidget?.disableControl, []],
      isMandatory: [wheelpickerWidget?.isMandatory, []],
      label: [wheelpickerWidget?.label, Validators.required],
      minRange: [
        wheelpickerWidget?.minRange,
        [
          Validators.required,
          (control: AbstractControl) => {
            return this.minRangeControl(control);
          },
        ],
      ],
      maxRange: [
        wheelpickerWidget?.maxRange,
        [
          Validators.required,
          (control: AbstractControl) => {
            return this.maxRangeControl(control);
          },
          // Validators.required,
          // (control: AbstractControl) =>
          //   Validators.min(
          //     this.wheelpickerWidget.minRange || Number.MIN_SAFE_INTEGER
          //   )(control),
        ],
      ],
      min: [
        {
          value: wheelpickerWidget?.min,
          disabled: true,
        },
        [
          Validators.required,
          (control: AbstractControl) => {
            return this.minControl(control);
          },
          // Validators.required,
          // (control: AbstractControl) =>
          //   Validators.max(
          //     this.wheelpickerWidget.maxRange || Number.MAX_SAFE_INTEGER
          //   )(control),
          // (control: AbstractControl) =>
          //   Validators.min(
          //     this.wheelpickerWidget.minRange || Number.MIN_SAFE_INTEGER
          //   )(control),
        ],
      ],
      max: [
        {
          value: wheelpickerWidget?.max,
          disabled: true,
        },
        [
          Validators.required,
          (control: AbstractControl) => {
            return this.maxControl(control);
          },
          // Validators.required,
          // (control: AbstractControl) =>
          //   Validators.max(
          //     this.wheelpickerWidget.maxRange || Number.MAX_SAFE_INTEGER
          //   )(control),
          // (control: AbstractControl) =>
          //   Validators.min(
          //     this.wheelpickerWidget.minRange || Number.MIN_SAFE_INTEGER
          //   )(control),
        ],
      ],
      nominalValue: [
        {
          value: wheelpickerWidget?.nominalValue,
          disabled: true,
        },
        [
          Validators.required,
          (control: AbstractControl) => {
            return this.nominalControl(control);
          },
          // Validators.required,
          // (control: AbstractControl) =>
          //   Validators.max(
          //     this.wheelpickerWidget.maxRange || Number.MAX_SAFE_INTEGER
          //   )(control),
          // (control: AbstractControl) =>
          //   Validators.min(
          //     this.wheelpickerWidget.minRange || Number.MIN_SAFE_INTEGER
          //   )(control),
        ],
      ],
      tooltip: [wheelpickerWidget?.tooltip],
    });
    form.addControl(
      'unit',
      new FormControl(this.wheelpickerWidget.unit, [
        Validators.required,
        (control: AbstractControl): ValidationErrors | null => {
          return this.controlUnit(control);
        },
      ])
    );
    return form;
  }

  public checkRangeValid(): void {
    if (
      this.formGroup.get(WidgetFormControlNames.maxRange)?.invalid ||
      this.formGroup.get(WidgetFormControlNames.minRange)?.invalid
    ) {
      this.formGroup.get(WidgetFormControlNames.min)?.disable();
      this.formGroup.get(WidgetFormControlNames.max)?.disable();
      this.formGroup.get(WidgetFormControlNames.nominalValue)?.disable();
    } else {
      this.formGroup.get(WidgetFormControlNames.min)?.enable();
      this.formGroup.get(WidgetFormControlNames.max)?.enable();
      this.formGroup.get(WidgetFormControlNames.nominalValue)?.enable();
    }
  }

  public displayUnit(unit: Unit): string {
    if (unit.uuid !== null) {
      return `${unit.abbreviation}-${unit.description}`;
    }
    return '';
  }

  public filterUnit(value: string | Unit): Unit[] {
    // unit foud
    if (value instanceof Unit) {
      return this.units;
    } else {
      //unit not found
      const filterValue = value.toLowerCase();
      let result = this.units.filter(
        (unit) =>
          unit.label!.toLowerCase().includes(filterValue) ||
          unit.description!.toLowerCase().includes(filterValue) ||
          unit.abbreviation!.toLowerCase().includes(filterValue) ||
          this.displayUnit(unit).toLowerCase().includes(filterValue)
      );
      return result;
    }
  }

  public getAssets(value: Returnable) {
    this.wheelpickerWidget.equipment = value.asset;
    this.wheelpickerWidget.processGroup = value.locationPG;
    this.wheelpickerWidget.productionUnit = value.locationPU;
  }

  public getEntityToSave(): WheelPickerWidget {
    return new WheelPickerWidget();
  }

  public getUnit(): string {
    if (this.wheelpickerWidget !== null && this.wheelpickerWidget.unit) {
      return this.wheelpickerWidget.unit!.abbreviation!;
    }
    return '';
  }

  public hasAnomaly(): boolean {
    return this.answerService.isAnomaly(this.wheelpickerWidget);
  }

  public initModel(): WheelPickerWidget {
    return new WheelPickerWidget();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.unitFilterService
      .getFilter()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((units: Unit[] | undefined) => {
        if (units) {
          this.units = units.sort((unit1: Unit, unit2: Unit) =>
            unit1!.label!.localeCompare(unit2!.label!)
          );
          this.units = units.filter(
            (unit1: Unit) => unit1.abbreviation !== 'NONE'
          );
          this.units.splice(0, 0, this.unitService.getDefaultUnit());
        }
      });
    this.wheelpickerWidget = this.widget as WheelPickerWidget;
    if (
      this.wheelpickerWidget.unit === null ||
      this.wheelpickerWidget.unit.uuid === null
    ) {
      this.wheelpickerWidget.unit = this.unitService.getDefaultUnit();
    }
    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroup = this.buildFormGroup(this.wheelpickerWidget);
      this.checkRangeValid();
      this.updateAssociatedControls();
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);

      this.filteredUnits = this.formGroup.get('unit')!.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe),
        startWith(''),
        map((value) => this.filterUnit(value || ''))
      );
    }
  }

  public save(): void {}

  //
  // public setUnit(formControlName: WidgetFormControlNames, unit: Unit): void {
  //   if (
  //     this.formGroup !== null &&
  //     this.formGroup.get(formControlName) !== null
  //   ) {
  //     let formControl = this.formGroup.get(formControlName);
  //
  //     if (
  //       formControl !== null &&
  //       formControlName === WidgetFormControlNames.unit &&
  //       formControl.value instanceof Unit
  //     ) {
  //       this.wheelpickerWidget.unit = formControl.value;
  //     }
  //   }
  // }

  public setValue(formControlName: WidgetFormControlNames): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === WidgetFormControlNames.label) {
          this.wheelpickerWidget.label = formControl.value;
        } else if (formControlName === WidgetFormControlNames.tooltip) {
          this.wheelpickerWidget.tooltip = formControl.value;
        } else if (formControlName === WidgetFormControlNames.decimalIndex) {
          this.wheelpickerWidget.decimalIndex = formControl.value;
        } else if (formControlName === WidgetFormControlNames.max) {
          this.wheelpickerWidget.max = formControl.value;
          this.updateAssociatedControls();
        } else if (formControlName === WidgetFormControlNames.disableControl) {
          this.wheelpickerWidget.disableControl = formControl.value;
        } else if (formControlName === WidgetFormControlNames.isMandatory) {
          this.wheelpickerWidget.isMandatory = formControl.value;
        } else if (formControlName === WidgetFormControlNames.maxRange) {
          this.wheelpickerWidget.maxRange = formControl.value;
          this.updateAssociatedControls();
          this.checkRangeValid();
        } else if (formControlName === WidgetFormControlNames.min) {
          this.wheelpickerWidget.min = formControl.value;
          this.updateAssociatedControls();
        } else if (formControlName === WidgetFormControlNames.minRange) {
          this.wheelpickerWidget.minRange = formControl.value;
          this.updateAssociatedControls();
          this.checkRangeValid();
        } else if (formControlName === WidgetFormControlNames.nominalValue) {
          this.wheelpickerWidget.nominalValue = formControl.value;
        } else if (formControlName === WidgetFormControlNames.unit) {
          if (formControl.value instanceof Unit) {
            this.wheelpickerWidget.unit = formControl.value;
          }
        }
        this.formGroup.updateValueAndValidity();
      }
    }
  }

  public updateAssociatedControls(): void {
    this.formGroup
      .get(WidgetFormControlNames.minRange)
      ?.updateValueAndValidity();
    this.formGroup
      .get(WidgetFormControlNames.maxRange)
      ?.updateValueAndValidity();
    this.formGroup.get(WidgetFormControlNames.min)?.updateValueAndValidity();
    this.formGroup.get(WidgetFormControlNames.max)?.updateValueAndValidity();
    this.formGroup
      .get(WidgetFormControlNames.nominalValue)
      ?.updateValueAndValidity();
  }

  private controlUnit(control: AbstractControl): ValidationErrors | null {
    if (!(control.value instanceof Unit)) {
      return { unit: { value: control.value } };
    }
    let selected = this.filterUnit(control.value);
    if (selected.length === 0) {
      return { unit: { value: control.value } };
    }

    return null;
  }

  private maxControl(control: AbstractControl): ValidationErrors | null {
    //if range set
    if (
      this.wheelpickerWidget.minRange !== null &&
      this.wheelpickerWidget.minRange !== undefined &&
      this.wheelpickerWidget.maxRange !== null &&
      this.wheelpickerWidget.maxRange !== undefined
    ) {
      // if max already define
      if (
        this.wheelpickerWidget.min !== null &&
        this.wheelpickerWidget.min !== undefined
      ) {
        if (
          control.value <= this.wheelpickerWidget.min ||
          control.value > this.wheelpickerWidget.maxRange
        ) {
          return { min: { value: control.value } };
        }
      } else if (
        control.value < this.wheelpickerWidget.minRange ||
        control.value > this.wheelpickerWidget.maxRange
      ) {
        return { min: { value: control.value } };
      }
    } else {
      return { min: { value: control.value } };
    }

    return null;
  }

  private maxRangeControl(control: AbstractControl): ValidationErrors | null {
    if (
      this.wheelpickerWidget.minRange !== null &&
      this.wheelpickerWidget.minRange !== undefined
    ) {
      if (control.value <= this.wheelpickerWidget.minRange) {
        return { min: { value: control.value } };
      }
    } else if (
      control.value < Number.MIN_SAFE_INTEGER ||
      control.value > Number.MAX_SAFE_INTEGER
    ) {
      return { min: { value: control.value } };
    }
    return null;
  }

  private minControl(control: AbstractControl): ValidationErrors | null {
    //if range set
    if (
      this.wheelpickerWidget.minRange !== null &&
      this.wheelpickerWidget.minRange !== undefined &&
      this.wheelpickerWidget.maxRange !== null &&
      this.wheelpickerWidget.maxRange !== undefined
    ) {
      // if max already define
      if (
        this.wheelpickerWidget.max !== null &&
        this.wheelpickerWidget.max !== undefined
      ) {
        if (
          control.value >= this.wheelpickerWidget.max ||
          control.value < this.wheelpickerWidget.minRange
        ) {
          return { min: { value: control.value } };
        }
      } else if (
        control.value < this.wheelpickerWidget.minRange ||
        control.value > this.wheelpickerWidget.maxRange
      ) {
        return { min: { value: control.value } };
      }
    } else {
      return { min: { value: control.value } };
    }

    return null;
  }

  private minRangeControl(control: AbstractControl): ValidationErrors | null {
    if (
      this.wheelpickerWidget.maxRange !== null &&
      this.wheelpickerWidget.maxRange !== undefined
    ) {
      if (control.value >= this.wheelpickerWidget.maxRange) {
        return { max: { value: control.value } };
      }
    } else if (
      control.value < Number.MIN_SAFE_INTEGER ||
      control.value > Number.MAX_SAFE_INTEGER
    ) {
      return { max: { value: control.value } };
    }
    return null;
  }

  private nominalControl(control: AbstractControl): ValidationErrors | null {
    //if range set
    if (
      this.wheelpickerWidget.minRange !== null &&
      this.wheelpickerWidget.minRange !== undefined &&
      this.wheelpickerWidget.maxRange !== null &&
      this.wheelpickerWidget.maxRange !== undefined
    ) {
      if (
        control.value < this.wheelpickerWidget.minRange ||
        control.value > this.wheelpickerWidget.maxRange
      ) {
        return { min: { value: control.value } };
      }
    } else {
      return { min: { value: control.value } };
    }

    return null;
  }
}
