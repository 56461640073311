export enum FormControlNames {
  alertOnAnomaly = 'alertOnAnomaly',
  allowBtCreation = 'allowBtCreation',
  allowSrCreation = 'allowSrCreation',
  country = 'country',
  dateFormat = 'dateFormat',
  entity = 'entity',
  id = 'id',
  kpiTemplate = 'kpiTemplate',
  kpiInstance = 'kpiInstance',
  label = 'label',
  maximoSiteId = 'maximoSiteId',
  timezone = 'timezone',
  firstName = 'firstName',
  email = 'email',
  emailForSortedOrFilteredQuery = 'emailForSortedOrFilteredQuery',
  lastName = 'lastName',
  name = 'name',
  profileType = 'profileType',
  sharedQueryPartition = 'sharedQueryPartition',
  status = 'status',
  userNameForFilteredQuery = 'userNameForFilteredQuery',
  sites = 'sites',
  profile = 'profile',
  validateInstance = 'validateInstance',
}
