import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from './model.abstract';

@JsonObject('HistoryItem')
export class HistoryItem extends ModelAbstract {
  @JsonProperty('action', String, true)
  public action: string = '';

  @JsonProperty('datetime', Number, true)
  public datetime = 0;

  @JsonProperty('fieldName', String, true)
  public fieldName: string = '';

  @JsonProperty('Id', String, true)
  public id = '';

  @JsonProperty('newValue', Object, true)
  public newValue: Object = '';

  @JsonProperty('oldValue', Object, true)
  public oldValue: Object = '';

  @JsonProperty('sectionId', String, true)
  public sectionId: string = '';

  @JsonProperty('sectionName', String, true)
  public sectionName: string = '';

  @JsonProperty('templateId', Number, true)
  public templateId: number = 0;

  @JsonProperty('templateName', String, true)
  public templateName: string = '';

  @JsonProperty('type', String, true)
  public type: string = '';

  @JsonProperty('userId', String, true)
  public userId: string = '';

  @JsonProperty('widgetId', String, true)
  public widgetId: string = '';

  @JsonProperty('widgetName', String, true)
  public widgetName: string = '';

  @JsonProperty('widgetType', String, true)
  public widgetType: string = '';
}
