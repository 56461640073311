<div class="al-table-column-header-filtering">
  <div class="title-container">
    <span mat-sort-header class="sort">
      <label for="head-input-{{ title }}" class="title">{{ title }}</label>
    </span>
  </div>

  <form
    [formGroup]="form"
    (submit)="submit()"
    (change)="submit()"
    *ngIf="
      fieldType === 'select' ||
      fieldType === 'input' ||
      fieldType === 'dateRange'
    "
  >
    <div
      class="filter-wrapper"
      *ngIf="
        fieldType === 'select' ||
        fieldType === 'input' ||
        fieldType === 'dateRange'
      "
    >
      <button
        style="width: 10%"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Search"
        type="submit"
      >
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field appearance="outline" class="form-field">
        <input
          id="head-input-{{ title }}"
          *ngIf="!isSelect && fieldType !== 'dateRange'"
          matInput
          formControlName="value"
          type="text"
        />
        <mat-select
          aria-label="{{ title }}"
          *ngIf="isSelect && fieldType === 'input'"
          [(value)]="valeur"
          multiple
          (selectionChange)="submit()"
        >
          <mat-option *ngFor="let value of values" [value]="value.id">
            {{ value.label | translate }}
          </mat-option>
        </mat-select>
        <mat-select
          aria-label="{{ title }}"
          *ngIf="isSelect && fieldType === 'select'"
          [(value)]="valeur"
          (selectionChange)="submit()"
        >
          <mat-option *ngFor="let value of values" [value]="value.id">
            {{ value.label | translate }}
          </mat-option>
        </mat-select>
        <span class="dateRange" *ngIf="fieldType === 'dateRange'">
          <mat-date-range-input #dateRangeInput [rangePicker]="picker">
            <input matStartDate placeholder="Start date" #dateRangeStart/>
            <input
              matEndDate
              placeholder="End date"
              #dateRangeEnd
              (dateChange)="submitDate(dateRangeStart, dateRangeEnd)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </span>
      </mat-form-field>
      <button
        mat-button
        *ngIf="form.get('value')"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        type="button"
        (click)="clearValue()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </form>
  <!--  <div class="filter-wrapper" *ngIf="fieldType === 'label'">-->
  <!--    <span></span>-->
  <!--  </div>-->
</div>
