<mat-expansion-panel class="al-widget al-widget-section" *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="compomentIcon">create</mat-icon>
      <span class="label">{{'WIDGET_NOTE' | translate}} : {{noteWidget.label}}</span>
    </mat-panel-title>
    <mat-panel-description class="icons">

      <mat-icon (click)="delete()">delete</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">
    <div class="full-width-label">
      <mat-form-field class="text-label" appearance="outline" class="full-width" floatLabel="always">
        <mat-label>{{ "NOTE_WIDGET_TITLE" | translate}}
        </mat-label>

        <textarea [formControlName]="formControlNames.label" (change)="setValue(formControlNames.label)"
                  matInput></textarea>
      </mat-form-field>
      <span class="checkbox-container">
        <mat-checkbox [formControlName]="formControlNames.isMandatory"
                      (change)="setValue(formControlNames.isMandatory)">{{"IS_MANDATORY_QUESTION" | translate}}</mat-checkbox>

      </span>

    </div>

    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "NOTE_WIDGET_TOOLTIP" | translate}}
      </mat-label>

      <input type="text" [formControlName]="formControlNames.tooltip" (change)="setValue(formControlNames.tooltip)"
             matInput>
    </mat-form-field>
    <al-asset-input-display (returnable)="getAssets($event)"
                            [equipment]="noteWidget.equipment"
                            [processGroup]="noteWidget.processGroup"
                            [productionUnit]="noteWidget.productionUnit"
    ></al-asset-input-display>

  </div>
</mat-expansion-panel>


<mat-card *ngIf="widgetStatus===widgetStatusEnum.WIDGET" class="al-widget  al-widget-selector"
          [formGroup]="formGroup">
  <mat-icon class="compomentIcon">create</mat-icon>
  <span class="label">  {{'WIDGET_NOTE' | translate}}</span>
</mat-card>


<mat-card *ngIf="widgetStatus===widgetStatusEnum.PREVIEW" class="al-widget-preview">
  <mat-card-header>
    <div class="widget-header">
      <mat-icon class="compomentIcon">create</mat-icon>
      <span class="title">  {{'WIDGET_NOTE' | translate}}</span>
    </div>

  </mat-card-header>
  <mat-card-content>


    <div class="widget-row">
      <div class="widget-label">{{this.noteWidget.label}}</div>
      <div class="answer">{{getValue()}}</div>
    </div>


    <div class="widget-row">
      <div class="widget-label">{{this.noteWidget.tooltip}}</div>
    </div>


    <al-common-preview [widget]="noteWidget"></al-common-preview>

  </mat-card-content>
</mat-card>
