import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { AlJsonConvert } from '@al/json-converter';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class GenericRestService {
  private alJsonConvert: AlJsonConvert;

  private array: any[] = [];

  public constructor(protected http: HttpClient) {
    this.alJsonConvert = new AlJsonConvert();
  }

  public delete<M extends object>(url: string, params: HttpParams) {
    return this.http
      .delete<M>(url, {
        // headers: httpHeaders,
        observe: 'response',
        params: params,
      })
      .pipe(
        map((data: HttpResponse<M>) => {
          // Fix:
          //force conversion to JSON Object before deserialise it with jsonconverter
          var json = JSON.parse(JSON.stringify(data.body));
          return json;
        })
      );
  }

  public get<M extends object>(
    url: string,
    params: HttpParams,
    getClazz: any
  ): Observable<M> {
    return this.http
      .get<M>(url, {
        // headers: httpHeaders,
        observe: 'response',
        params: params,
      })
      .pipe(
        map((data: HttpResponse<M>) => {
          // Fix:
          //force conversion to JSON Object before deserialise it with jsonconverter
          var json = JSON.parse(JSON.stringify(data.body));
          // var m: M = this.alJsonConvert.deserializeObject<M>(json, getClazz);
          var m: M = this.alJsonConvert.deserialize<M>(json, getClazz) as M;
          return m;
        })
      );
  }

  public getList<M extends object>(
    url: string,
    params: HttpParams,
    getClazz: any
  ): Observable<M[]> {
    return this.http
      .get<M[]>(url, {
        observe: 'response',
        params: params,
      })
      .pipe(
        map((data: HttpResponse<M[]>) => {
          // Fix:
          //force conversion to JSON Object before deserialise it with jsonconverter
          var json = JSON.parse(JSON.stringify(data.body));
          return this.alJsonConvert.deserializeArray<M>(json, getClazz);
        })
      );
  }

  public getListDataLake(
    url: string,
    params: HttpParams,
    timestamp: number,
    getClazz: any
  ): Promise<void | any[]> {
    this.array = [];
    return this.getDatalake(url, params, timestamp, getClazz);
  }

  public post<M extends object>(
    url: string,
    params: HttpParams,
    getClazz: any,
    model: M
  ): Observable<M> {
    let jsonConvert: AlJsonConvert = new AlJsonConvert();
    const jsonModel = jsonConvert.serialize(model);
    return this.http.post<M>(url, jsonModel, { params: params }).pipe(
      map((data: M) => {
        return data;
      })
    );
  }

  public postBis<M extends object, D extends Object>(
    url: string,
    params: HttpParams,
    getClazz: any,
    model: M
  ): Observable<D> {
    let jsonConvert: AlJsonConvert = new AlJsonConvert();
    const jsonModel = jsonConvert.serialize(model);
    return this.http.post<D>(url, jsonModel).pipe(
      map((data: D) => {
        var json = JSON.parse(JSON.stringify(data));
        // return this.alJsonConvert.deserializeObject<D>(json, getClazz);
        let value = this.alJsonConvert.deserializeObject<D>(json, getClazz);
        return value;
      })
    );
  }

  public put<M extends object>(
    url: string,
    params: HttpParams,
    getClazz: any,
    model: M
  ): Observable<M> {
    // let headers = new HttpHeaders({
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Headers':
    //     'Origin, X-Requested-With, Content-Type, Accept',
    // });
    let jsonConvert: AlJsonConvert = new AlJsonConvert();
    const jsonModel = jsonConvert.serialize(model);

    return this.http.put<M>(url, jsonModel).pipe(
      map((data: M) => {
        return data;
      })
    );
  }

  public putBis<M extends object, D extends Object>(
    url: string,
    params: HttpParams,
    getClazz: any,
    model: M
  ): Observable<D> {
    // let headers = new HttpHeaders({
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Headers':
    //     'Origin, X-Requested-With, Content-Type, Accept',
    // });
    let jsonConvert: AlJsonConvert = new AlJsonConvert();
    const jsonModel = jsonConvert.serialize(model);

    return this.http.put<D>(url, jsonModel).pipe(
      map((data: D) => {
        var json = JSON.parse(JSON.stringify(data));
        return this.alJsonConvert.deserializeObject<D>(json, getClazz);
      })
    );
  }

  private getDatalake<M extends object>(
    url: string,
    params: HttpParams,
    timestamp: number,
    getClazz: any
  ): Promise<void | any[]> {
    const httpHeaders = new HttpHeaders({
      runtime: 'angular2',
    });
    params = params.set('timestamp', timestamp);

    return firstValueFrom(
      this.http.get<M[]>(url, {
        headers: httpHeaders,
        observe: 'response',
        params: params,
      })
    ).then((response: HttpResponse<M[]>) => {
      var json = JSON.parse(JSON.stringify(response.body));
      if (json.count !== 0 && json.timestamp !== 0) {
        this.array = [
          ...this.array,
          ...this.alJsonConvert.deserializeArray<M>(json.list, getClazz),
        ];
        return this.getDatalake(url, params, json.timestamp, getClazz);
      }

      return this.array;
    });
  }
}
