import { GenericComponentsModule } from '@al/generic-components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { PipeModule } from '@al/pipe';
import { PreviewComponentModule } from '@al/preview-component';
import { RondeListComponent } from './ronde-list/ronde-list.component';
import { RondeManagerComponent } from './ronde-manager/ronde-manager.component';

@NgModule({
  declarations: [RondeListComponent, RondeManagerComponent],
  imports: [
    GenericComponentsModule,
    PipeModule,
    PreviewComponentModule,
    MatProgressSpinnerModule,
  ],
  exports: [RondeListComponent, RondeManagerComponent],
})
export class RondeComponentsModule {}
