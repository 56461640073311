import {
  ProcessGroupState,
  ProcessGroupsStore,
} from '../store/process-group.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class ProcessGroupsQuery extends QueryEntity<ProcessGroupState> {
  public productionUnit = this.selectAll();

  public constructor(protected override store: ProcessGroupsStore) {
    super(store);
  }
}
