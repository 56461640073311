<p mat-dialog-title class="title">{{"CREATE_TEMPLATE_TITLE" | translate}}</p>
<mat-dialog-content>

  <p *ngIf="!pycoso">{{'CREATE_TEMPLATE_CONTENT' | translate }}</p>

  <div *ngIf="pycoso" [formGroup]="pycosoFormGroup">
    <p>{{'CREATE_PYCOSO_TEMPLATE_CONTENT' | translate }}</p>
    <mat-form-field *ngIf="filteredOptions | async; else noFurnace" appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field">{{ "TEMPLATE_FORM_PYCOSO_FURNACE" | translate}}
      </mat-label>
      <input type="text"
             matInput
             formControlName="furnace"
             required
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFurnace">
        <mat-option *ngFor="let furnace of filteredOptions | async" [value]="furnace">
          {{furnace.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <ng-template #noFurnace><p>{{'CREATE_PYCOSO_TEMPLATE_FORM_NO_FURNACE' | translate}}</p></ng-template>

  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" type="button" (click)="createTemplate()" *ngIf="!pycoso">
    {{ "CREATE_TEMPLATE_STANDARD_BUTTON" | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="showPycosoForm()" *ngIf="!pycoso">
    {{ "CREATE_TEMPLATE_PYCOSO_BUTTON" | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="createTemplate()" [disabled]="pycosoFormGroup.invalid" *ngIf="pycoso">
    {{"CREATE_PYCOSO_TEMPLATE_BUTTON_CREATE" | translate}}
  </button>
</mat-dialog-actions>
