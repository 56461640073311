<div class="al-site-list">


  <div class="header">
    <h1 translate class="parent-component-title">SITE_LIST_TITLE</h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->


  </div>

  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.id.valueOf())"
          (OnSearch)="filterId($event,formControlNames.id.valueOf())"
          [field]="formControlNames.id.valueOf()"
          fieldType="input"
          storageNameSpace="site-list-filters"
          title="{{'SITE_ID' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'SITE_ID' | translate }}" class="id">
        {{ element.id }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="maximoSiteId">
      <mat-header-cell *matHeaderCellDef class="maximo-site-id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.maximoSiteId.valueOf())"
          (OnSearch)="filterMaximoSiteId($event,formControlNames.maximoSiteId.valueOf())"
          [field]="formControlNames.maximoSiteId.valueOf()"
          fieldType="input"
          storageNameSpace="site-list-filters"
          title="{{'SITE_MAXIMO_CODE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'SITE_MAXIMO_CODE' | translate }}" class="maximo-site-id">
        {{ element.maximoSiteId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef class="label">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.label.valueOf())"
          (OnSearch)="filterLabel($event,formControlNames.label.valueOf())"
          [field]="formControlNames.label.valueOf()"
          fieldType="input"
          storageNameSpace="site-list-filters"
          title="{{'SITE_LABEL' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'SITE_LABEL' | translate }}" class="label">
        {{ element.label }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="actions" translate>

        <button mat-button (click)="create()" color="primary">
          {{'CREATE_SITE' | translate}}
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="" class="actions">
        <button mat-icon-button (click)="edit(element.id)" [matTooltip]="'EDIT' | translate">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: displayedColumns"

    ></mat-row>
  </mat-table>

  <mat-paginator #paginator [pageSize]="15" [pageSizeOptions]="[15, 25, 50,  100]"></mat-paginator>

</div>




