import { JsonObject, JsonProperty } from 'json2typescript';
import { InstanceFull } from './ronde-full.instance.model';
import { Owner } from '../template/owner.model';
import { TemplateFull } from '../template-full.model';

@JsonObject('RondeFull')
export class RondeFull {
  @JsonProperty('instance', InstanceFull, true)
  public instance: InstanceFull | null = null;

  @JsonProperty('author', Owner, true)
  public owner: Owner | null = null;

  @JsonProperty('template', TemplateFull, true)
  public template: TemplateFull | null = null;
}
