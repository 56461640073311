import { JsonObject, JsonProperty } from 'json2typescript';
import { BooleanConverter } from './converter/boolean.converter';
import { Category } from './category.model';
import { CategoryConverter } from './converter/category.converter';
import { DatetimeConverter } from './converter/datetime.converter';
import { ModelAbstract } from './model.abstract';
import { Status } from './status.model';
import { StatusConverter } from './converter/status.converter';

@JsonObject('TemplateLight')
export class TemplateLight extends ModelAbstract {
  @JsonProperty('categoryId', CategoryConverter, true)
  public category: Category | null = null;

  @JsonProperty('changeDate', DatetimeConverter, true)
  public changeDate: Date | null = null;

  @JsonProperty('id', Number, true)
  public id = 0;

  @JsonProperty('lastInstanceValidation', DatetimeConverter, true)
  public lastInstanceValidation: Date | null = null;

  @JsonProperty('location', String, true)
  public location: string | null = null;

  @JsonProperty('siteId', String, true)
  public siteId: string | null = null;

  @JsonProperty('status', StatusConverter, true)
  public status: Status | null = null;

  @JsonProperty('title', String, true)
  public title: string | null = null;

  @JsonProperty('visible', BooleanConverter, true)
  public visible: boolean = false;
}
