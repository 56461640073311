import { AccessToken } from './access-token';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { IdTokenCognito } from './id-token';
import { Injectable } from '@angular/core';
import { LocalStorageKey } from '@al/local-cache';
import { Observable } from 'rxjs';
import { Paths } from '@al/angular-route';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from './user-info.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private connected = new BehaviorSubject(false);

  private connectedByUserpool = new BehaviorSubject(false);

  public constructor(
    protected userService: UserInfoService,
    protected router: Router,
    protected translateService: TranslateService
  ) {}

  public checkSession(): Promise<CognitoUserSession> {
    var promise = Auth.currentSession();
    promise.then((session) => {
      this.connected.next(true);
      localStorage.setItem(
        LocalStorageKey.ID_TOKEN,
        JSON.stringify(session.getIdToken())
      );
      const idTokenCognitoDecoded = jwt_decode<IdTokenCognito>(
        session.getIdToken().getJwtToken()
      );

      if (idTokenCognitoDecoded.identities) {
        if (
          idTokenCognitoDecoded.identities.length > 0 &&
          idTokenCognitoDecoded.identities[0].providerName === 'Google'
        ) {
          this.connectedByUserpool.next(false);
        } else {
          this.connectedByUserpool.next(true);
        }
      } else {
        this.connectedByUserpool.next(true);
      }
      this.userService.loadIdToken(idTokenCognitoDecoded);
      this.saveSessionToLocalStorage(session, idTokenCognitoDecoded);
    });
    return promise;
  }

  public getConnected(): Observable<boolean> {
    return this.connected.asObservable();
  }

  public getConnectedByUserPool(): Observable<boolean> {
    return this.connectedByUserpool.asObservable();
  }

  public isConnected(): boolean {
    return this.connected.value;
  }

  public login(): void {
    Auth.federatedSignIn();
  }

  public logout(): void {
    Auth.signOut().then(() => {
      this.router.navigate([Paths.LOGIN]);
      this.connected.next(false);
    });
  }

  private saveSessionToLocalStorage(
    session: CognitoUserSession,
    idTokenCognitoDecoded: IdTokenCognito
  ) {
    localStorage.setItem(
      LocalStorageKey.ID_TOKEN,
      JSON.stringify(session.getIdToken())
    );
    localStorage.setItem(
      LocalStorageKey.ID_TOKEN_DECODED,
      JSON.stringify(idTokenCognitoDecoded)
    );

    localStorage.setItem(
      LocalStorageKey.ACCESS_TOKEN,
      JSON.stringify(session.getAccessToken())
    );

    const accessTokenDecoded = jwt_decode<AccessToken>(
      session.getAccessToken().getJwtToken()
    );

    localStorage.setItem(
      LocalStorageKey.ACCESS_TOKEN_DECODED,
      JSON.stringify(accessTokenDecoded)
    );
    localStorage.setItem(
      LocalStorageKey.REFRESH_TOKEN,
      JSON.stringify(session.getRefreshToken())
    );
  }
}
