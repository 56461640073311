import { SiteState, SiteStore } from '../store/site.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';
import { Site } from '@al/model';

@Injectable({ providedIn: 'root' })
export class SiteQuery extends QueryEntity<SiteState> {
  public site = this.selectAll();

  public constructor(protected override store: SiteStore) {
    super(store);
  }

  public getSite(id: string): Observable<Site | undefined> {
    return this.selectEntity(id);
  }
}
