import {
  IdNumberList,
  PresignedUrl,
  Site,
  TemplateItems,
  TemplateLight,
} from '@al/model';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { TemplateLightQuery, TemplateLightStore } from '@al/akita';
import { AlSpinnerService } from '@al/spinner';
import { GenericService } from './generic.service';
import { HttpResponse } from '@angular/common/http';
import { SiteService } from './site.service';
import { TemplateRestService } from '@al/rest-services';
import { UUID } from 'angular2-uuid';
import { take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TemplateLightService
  extends GenericService<TemplateLight>
  implements OnDestroy
{
  private currentSite?: Site;

  private ngUnsubscribe = new Subject();

  public constructor(
    protected override restService: TemplateRestService,
    protected query: TemplateLightQuery,
    protected store: TemplateLightStore,
    protected siteService: SiteService,
    private alSpinnerService: AlSpinnerService
  ) {
    super(restService, query, store);
    this.currentSite = this.siteService.getCurrentSite();
    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: Site) => {
        this.currentSite = response;

        const uuid = UUID.UUID();
        if (window.location.href.includes('template')) {
          this.alSpinnerService.startDataProcess(uuid);
        }
        this.loadStore()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.alSpinnerService.stopDataProcess(uuid);
            },

            error: () => {
              this.alSpinnerService.stopDataProcess(uuid);
              this.alSpinnerService.launchSnackBar('ERR_LOAD_TEMPLATE', 10);
            },
          });
      });
  }

  public copySite(element: TemplateLight, siteId: string) {
    this.restService
      .copySite(element, siteId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          if (this.currentSite?.id == siteId) {
            this.loadStore().subscribe();
          }
        },
        error: (error) => {
          console.error('copy template', error);
        },
      });
  }

  public delete(template: TemplateLight) {
    return this.restService.deleteTemplate(template).pipe(
      take(1),
      map((data) => {
        this.store.remove(template.id);
        return data;
      })
    );
  }

  public exportPdf(id: number): Observable<HttpResponse<any>> {
    const stringID = id.toString();
    // const stringDate = date.toString();
    return this.restService.exportPdf(stringID);
  }

  public getGmpPdf(ids: IdNumberList): Observable<string> {
    return this.restService.getGmpPdf(ids).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: PresignedUrl) => {
        return data.presignedUrl;
      })
    );
  }

  public loadStore(): Observable<void> {
    if (window.location.href.includes('template')) {
      return this.restService.getTemplate().pipe(
        take(1),
        map((data: TemplateItems) => {
          this.store.reset();
          // data.items.forEach((template) => {
          //   this.store.upsert(template.id, template);
          // });
          this.store.set(data.items);
          if (data.items.length > 0) {
            this.store.setActive(data.items[0].id);
          }
        })
      );
    }
    return new Observable();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public update(lightModel: TemplateLight) {
    this.store.update(lightModel.id, lightModel);
  }
}
