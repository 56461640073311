import { Entity, Site, SiteItems } from '@al/model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SiteRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public create(site: Site): Observable<Site> {
    return super.post<Site>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.site.edit}${site.id}`,
      new HttpParams(),
      this.getSiteClazz(),
      site
    );
    // .pipe(
    //   map((event) => {
    //     return event;
    //   })
    // );
  }

  public getAllSites(): Observable<SiteItems> {
    return super.get<SiteItems>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.site.list}`,
      new HttpParams(),
      this.getSiteItemClazz()
    );
  }

  public getEntities(): Observable<Entity[]> {
    return super.getList<Entity>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.entity.list}`,
      new HttpParams(),
      this.getEntityClazz()
    );
  }

  public getFullSite(id: string): Observable<Site> {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('id', id);

    return super.get<Site>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.site.get}${id}`,
      httpParams,
      this.getSiteClazz()
    );
  }

  public save(site: Site): Observable<Site> {
    return super.put<Site>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.site.edit}${site.id}`,
      new HttpParams(),
      this.getSiteClazz(),
      site
    );
  }

  protected getEntityClazz(): typeof Entity {
    return Entity;
  }

  protected getSiteClazz(): typeof Site {
    return Site;
  }

  protected getSiteItemClazz(): typeof SiteItems {
    return SiteItems;
  }
}
