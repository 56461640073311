<mat-expansion-panel class="al-widget al-widget-info" *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="compomentIcon">library_add_check</mat-icon>
      <span class="label">{{'WIDGET_MCQ' | translate}} : {{mcqWidget.label}}</span>
    </mat-panel-title>
    <mat-panel-description class="icons">

      <mat-icon (click)="delete()">delete</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">

    <div class="full-width mcq-widget-info">
      <mat-form-field appearance="outline" class="text-label" floatLabel="always">
        <mat-label>{{ "MCQ_WIDGET_TITLE" | translate}}
        </mat-label>
        <textarea [formControlName]="formControlNames.label" (change)="setValue(formControlNames.label)"
                  matInput></textarea>
      </mat-form-field>
      <span class="checkbox-container">
        <mat-checkbox [formControlName]="formControlNames.singleChoiceOnly"
                      (change)="setValue(formControlNames.singleChoiceOnly)">{{"MCQ_SINGLE_CHOICE_ONLY" | translate}}</mat-checkbox>
        <mat-checkbox [formControlName]="formControlNames.isMandatory"
                      (change)="setValue(formControlNames.isMandatory)">{{"IS_MANDATORY_QUESTION" | translate}}</mat-checkbox>

      </span>
    </div>
    <ng-container formArrayName="options">
      <ng-container *ngFor="let optionForm of optionForms.controls; let i = index">
        <div class="mcq-option-row" [formGroup]="optionForm | as: FormGroupClass">
          <mat-icon>chevron_right</mat-icon>
          <mat-form-field appearance="outline" class="max-width" floatLabel="always">
            <mat-label>{{"MCQ_OPTION_LABEL" | translate}}</mat-label>
            <span>
              <input type="text" formControlName="label"
                     (change)="setOptionText(i)" matInput>
            </span>
          </mat-form-field>
          <span>
            <mat-checkbox formControlName="canRaiseAnomaly"
                          (change)="setOptionCanRaiseAnomaly(i)">
              {{"MCQ_CAN_RAISE_ANOMALY" | translate}}
            </mat-checkbox>
            <button mat-icon-button (click)="deleteOption(i)">
              <mat-icon>delete</mat-icon>
            </button>

          </span>

        </div>
      </ng-container>
      <div role="listitem" class="mcq-new-option-list-item">
        <button mat-button (click)="addOption()">
          <mat-icon>add</mat-icon>
          <span>{{"MCQ_ADD_NEW_OPTION" | translate}}</span>
        </button>
      </div>
    </ng-container>

    <div class="full-width">
      <mat-form-field appearance="outline" class="text-label" floatLabel="always">
        <mat-label>{{ "MCQ_WIDGET_TOOLTIP" | translate}}
        </mat-label>

        <input type="text" [formControlName]="formControlNames.tooltip" (change)="setValue(formControlNames.tooltip)"
               matInput>
      </mat-form-field>
    </div>

    <al-asset-input-display (returnable)="getAssets($event)"></al-asset-input-display>
  </div>
</mat-expansion-panel>

<mat-card class="al-widget-mcq" *ngIf="widgetStatus===widgetStatusEnum.WIDGET" [formGroup]="formGroup">
  <mat-icon>library_add_check</mat-icon>
  {{'WIDGET_MCQ' | translate}}
</mat-card>


<mat-card *ngIf="widgetStatus===widgetStatusEnum.PREVIEW" class="al-widget-preview"
          [ngClass]="{'hasAnomaly': hasAnomaly()}">
  <mat-card-header>
    <div class="widget-header">
      <mat-icon class="compomentIcon">library_add_check</mat-icon>
      <span class="title">  {{'WIDGET_MCQ' | translate}}</span>
    </div>

  </mat-card-header>
  <mat-card-content>


    <div class="widget-row">
      <div class="widget-label">{{this.mcqWidget.label}}</div>
    </div>


    <div class="widget-row">
      <div class="widget-label">{{this.mcqWidget.tooltip}}</div>
    </div>

    <div class="widget-row" *ngIf="getValue()">
      <span class="widget-tooltip"> {{getComment()}}</span>
    </div>


    <div class="widget-row">

      <ul>
        <li *ngFor="let optionForm of mcqWidget.options; let i = index" class="no-dot">
          <mat-checkbox [checked]="isOptionChecked(optionForm)" [disabled]="true">
            {{optionForm.label}}
          </mat-checkbox>
          <!--          <input type="checkbox" disabled="true" checked="{{isOptionChecked(optionForm)}}">-->

        </li>
      </ul>
    </div>
    <!--    McqOption-->

    <al-common-preview [widget]="mcqWidget"></al-common-preview>
  </mat-card-content>
</mat-card>
