import {
  SiteFormComponent,
  SiteListComponent,
  SiteManagerComponent,
} from '@al/site-components';
import { AuthGuard } from '../auth.guard';
import { Paths } from '../paths';
import { Route } from '@angular/router';

export const siteRoutes: Route = {
  path: Paths.site.DEFAULT,
  canActivate: [AuthGuard],
  component: SiteManagerComponent,
  children: [
    {
      path: Paths.site.EDIT,
      component: SiteFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40'],
      },
    },
    {
      path: Paths.site.CREATE,
      component: SiteFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40'],
      },
    },
    {
      path: Paths.site.LIST,
      component: SiteListComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40'],
      },
    },
  ],
};
