import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('SectionRonde')
export class RondeSection extends ModelAbstract {
  // @JsonProperty('id', String, false)
  // public id: string | null = null;

  @JsonProperty('state', String, false)
  public state: string | null = null;
}
