import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { Widget } from './widget.model';

@JsonObject('note')
export class NoteWidget extends Widget {
  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('tooltip', String, true)
  public tooltip: string | null = null;

  public constructor() {
    super();
    this.componentType = ComponentType.NOTE;
  }
}
