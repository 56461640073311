import { HttpClient, HttpParams } from '@angular/common/http';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from 'projects/rest-services/src/lib/generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TemplateHistoryList } from '@al/model';

@Injectable({
  providedIn: 'root',
})
export class HistoryRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public getHistory(
    templateId: number | string
  ): Observable<TemplateHistoryList> {
    return super.get<TemplateHistoryList>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.template.history}${templateId}`,
      new HttpParams(),
      this.getHistoryItemClazz()
    );
  }

  protected getHistoryItemClazz(): typeof TemplateHistoryList {
    return TemplateHistoryList;
  }
}
