import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Instance, RondeFull, RondeLight } from '@al/model';
import { RondeFullService, RondeLightService } from '@al/services';
import { GenericComponent } from '@al/generic-components';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'al-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
})
export class PdfComponent extends GenericComponent implements OnInit {
  @ViewChild('pdfDialog', { static: true })
  public pdfDialog: TemplateRef<any> | undefined;

  public dateLabel: string | null = null;

  public instanceId: number | null = null;

  public constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    protected rondeLightService: RondeLightService,
    protected rondeFullService: RondeFullService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.pdfDialog) {
      this.dialog.open(this.pdfDialog, {
        height: '200px',
        width: '400px',
      });
    }
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('date')) {
        this.dateLabel = params.get('date')!;
        this.generatePdf();
      }
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('instanceId')) {
        this.instanceId = parseInt(params.get('instanceId')!);
        this.generatePdf();
      }
    });
  }

  private generatePdf(): void {
    if (this.instanceId && this.dateLabel) {
      const rondeLight = new RondeLight();
      rondeLight.instance = new Instance();
      rondeLight.instance.id = this.instanceId;
      this.rondeFullService
        .getRondeFull(rondeLight)
        .subscribe((rondeFull: RondeFull) => {
          this.rondeLightService
            .exportPdf(this.instanceId!, rondeFull.instance?.modificationDate!)
            .subscribe((sucess) => {
              window.location = sucess.body.presignedUrl;
            });
        });
    }
  }
}
