export enum WidgetFormControlNames {
  canRaiseAnomaly = 'canRaiseAnomaly',
  decimalIndex = 'decimalIndex',
  disableControl = 'disableControl',
  id = 'id',
  isMandatory = 'isMandatory',
  label = 'label',
  link = 'link',
  max = 'max',
  maxRange = 'maxRange',
  min = 'min',
  minRange = 'minRange',
  nominalValue = 'nominalValue',
  singleChoiceOnly = 'singleChoiceOnly',
  text = 'text',
  tooltip = 'tooltip',
  unit = 'unit',
}
