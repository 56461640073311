import {
  UsersFormComponent,
  UsersListComponent,
  UsersManagerComponent,
} from '@al/users-components';
import { AuthGuard } from '../auth.guard';
import { Paths } from '../paths';
import { Route } from '@angular/router';

export const usersRoute: Route = {
  path: Paths.users.DEFAULT,
  component: UsersManagerComponent,
  children: [
    {
      path: Paths.users.EDIT,
      component: UsersFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30'],
      },
    },
    {
      path: Paths.users.CREATE,
      component: UsersFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30'],
      },
    },
    {
      path: Paths.users.LIST,
      component: UsersListComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30'],
      },
    },
  ],
};
