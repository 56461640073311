import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';
import { Status, User, UserCreation, UserItems } from '@al/model';
import { UsersQuery, UsersStore } from '@al/akita';
import { GenericService } from './generic.service';
import { LocalStorageKey } from '@al/local-cache';
import { UserInfoService } from '@al/session';
import { UsersRestService } from '@al/rest-services';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends GenericService<User> implements OnDestroy {
  public currentUserSubject = new Subject<User>();

  private currentUser: User | undefined;

  private ngUnsubscribe = new Subject();

  public constructor(
    protected userRestService: UsersRestService,
    protected userInfoService: UserInfoService,
    protected userQuery: UsersQuery,
    protected userStore: UsersStore
  ) {
    super(userRestService, userQuery, userStore);
  }

  public create(user: User): Observable<UserCreation> {
    return this.userRestService.create(user).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: UserCreation) => {
        user.id = parseInt(data.id);
        this.userStore.upsert(user.id, user);
        this.userStore.setActive(user.id);
        return data;
      })
    );
  }

  public delete(user: User) {
    return this.userRestService.deleteUser(user).pipe(
      take(1),
      map((data) => {
        let finalUser = Object.assign({}, user);
        finalUser.status = new Status('30', 'DELETED');
        this.userStore.replace(user.id, finalUser);
        return data;
      })
    );
  }

  public getCurrentUser(): User | undefined {
    return this.currentUser;
  }

  public getCurrentUserObservable(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  public loadStore(): Observable<void> {
    return this.userRestService.getAllUsers().pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: UserItems) => {
        data.items.forEach((user) => {
          this.userStore.add(user);
        });

        if (data.items.length > 0) {
          this.userStore.setActive(data.items[0].id);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public save(user: User): Observable<User> {
    return this.userRestService.save(user).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data) => {
        this.userStore.replace(user.id, user);
        this.userStore.setActive(user.id);
        return data;
      })
    );
  }

  public setCurrentUser(user: User): void {
    this.currentUser = user;
    localStorage.setItem(LocalStorageKey.DEFAULT_SITE, user.id.toString());
    localStorage.setItem(
      LocalStorageKey.DEFAULT_SITE_OBJECT,
      JSON.stringify(user)
    );

    this.currentUserSubject.next(this.currentUser);
  }

  public setDefaultUser(): void {
    if (localStorage.getItem(LocalStorageKey.DEFAULT_SITE)) {
      if (localStorage.getItem(LocalStorageKey.DEFAULT_SITE_OBJECT)) {
        this.currentUser = JSON.parse(
          localStorage.getItem(LocalStorageKey.DEFAULT_SITE_OBJECT)!
        );
      }
      if (this.currentUser) {
        this.currentUserSubject.next(this.currentUser);
      }
    }
  }
}
