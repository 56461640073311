import { JsonObject, JsonProperty } from 'json2typescript';
import { HistoryItem } from './history-item.model';
import { ModelAbstract } from './model.abstract';

@JsonObject('TemplateHistoryList')
export class TemplateHistoryList extends ModelAbstract {
  @JsonProperty('body', [HistoryItem], true)
  public body: HistoryItem[] = [];

  @JsonProperty('headers', [HistoryItem], true)
  public headers: HistoryItem[] = [];

  @JsonProperty('type', String, true)
  public type: string = '';
}
