import { AkitaFilterService, UsersQuery, UsersState } from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Injectable } from '@angular/core';
import { User } from '@al/model';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class UserFilterService extends AkitaFilterService<User, UsersState> {
  public constructor(protected usersQuery: UsersQuery) {
    super(new AkitaFiltersPlugin<UsersState>(usersQuery));
  }
}
