import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Category, RondeLight, Site, Status } from '@al/model';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { ReferentielService, StatusEnum } from '@al/referentiel';
import { RondeLightService, SiteService } from '@al/services';
import { take, takeUntil } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { FormControlNames } from '../form-control-names.enum';
import { GenericListComponent } from '@al/generic-components';
import { GoogleService } from '@al/google';
import { MatDialog } from '@angular/material/dialog';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RondeFilterService } from '../service/ronde-filter.service';
import { SessionService } from '@al/session';

export class MyFormat {
  public value = 'DD/MM/YYYY';

  public get display() {
    return {
      dateInput: this.value,
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
    };
  }

  public get parse() {
    return {
      dateInput: 'LL',
    };
  }
}

@Component({
  selector: 'al-ronde-list',
  templateUrl: './ronde-list.component.html',
  styleUrls: ['./ronde-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useClass: MyFormat },
  ],
})
export class RondeListComponent
  extends GenericListComponent<RondeLight>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('excelDialog', { static: true })
  public excelDialog: TemplateRef<any> | undefined;

  @ViewChild('exportAllDialog', { static: true })
  public exportAllDialog: TemplateRef<any> | undefined;

  @ViewChild('exportAllDialogKo')
  public exportAllDialogKo!: TemplateRef<any>;

  @ViewChild('exportExcelDialogKo')
  public exportExcelDialogKo!: TemplateRef<any>;

  @ViewChild('exportPDFDialogKo')
  public exportPDFDialogKo!: TemplateRef<any>;

  @ViewChild('pdfDialog', { static: true })
  public pdfDialog: TemplateRef<any> | undefined;

  public anomalies = [
    { label: 'NO_ANOMALY', id: false },
    { label: 'ONE_ANOMALY', id: true },
  ];

  public categories: Category[] = [];

  public displayValidation = false;

  public endDate: FormControl;

  public formControlNames = FormControlNames;

  public isConnectedWithUserPool: boolean = false;

  public startDate: FormControl;

  public status: Status[] = [];

  public typeExport: string = '';

  public constructor(
    @Inject(MAT_DATE_FORMATS) public config: MyFormat,
    protected rondeFilterService: RondeFilterService, //
    protected rondeService: RondeLightService, //
    protected override router: Router,
    protected override route: ActivatedRoute,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private siteService: SiteService,
    private sessionService: SessionService,
    private googleService: GoogleService
  ) {
    super(rondeFilterService, rondeService, router, route);
    let referentielService = new ReferentielService();
    this.categories = referentielService.categories;
    this.status = referentielService.statusRonde;
    let currentDate = new Date();
    let twoWeekPastDate = new Date(
      currentDate.getTime() - 15 * 24 * 60 * 60 * 1000
    );

    let endDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    this.endDate = new FormControl(endDate);
    let startDate = this.datePipe.transform(twoWeekPastDate, 'yyyy-MM-dd');
    this.startDate = new FormControl(startDate);
    this.startDateChange();
    this.endDateChange();
    this.displayValidation = siteService.getCurrentSite()
      ? siteService.getCurrentSite()?.validateInstance!
      : false;

    this.siteService.getCurrentSiteObservable().subscribe((site: Site) => {
      this.config.value = site.dateFormat.replace('HH:mm:ss', '');
      this.startDate = new FormControl(this.startDate.value);
      this.endDate = new FormControl(this.endDate.value);
      this.displayValidation = site.validateInstance;
      this.config.value = site ? site.dateFormat.replace('HH:mm:ss', '') : '';
      this.setStatus();
    });
    // const site = this.siteService.getCurrentSite();
    // this.config.value = site ? site.dateFormat.replace('HH:mm:ss', '') : '';
    // this.displayValidation =site.validateInstance;
    this.sessionService
      .getConnectedByUserPool()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((connectedByUserPool) => {
        this.isConnectedWithUserPool = connectedByUserPool;
      });

    // this.siteService
    //   .getCurrentSiteObservable()
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((site) => {
    //     this.displayValidation = site.validateInstance;
    //   });
  }

  public deleteRonde(ronde: RondeLight) {
    this.rondeService.deleteRonde(ronde).subscribe({
      next: () => {
        this.openDialogInfo(this.deleteDialogOk);
      },
      error: () => {
        this.openDialogInfo(this.deleteDialogKo);
      },
    });
  }

  public displayDelete(element: RondeLight): boolean {
    return (
      (element.instance!.status!.id === StatusEnum.ON_HOLD ||
        element.instance!.status!.id === StatusEnum.CANCELED) &&
      element.instance!.category!.id !== '7'
    );
  }

  public displaySignature(ronde: RondeLight): boolean {
    if (this.displayValidation && ronde) {
      if (ronde.instance) {
        if (ronde.instance.status) {
          return ronde.instance.status.id === StatusEnum.VALIDATED;
        }
      }
    }
    return false;
  }

  public endDateChange() {
    let trueEnd = new Date(this.endDate.value);
    trueEnd.setHours(23, 59, 59);
    this.rondeService.changeEndDate(trueEnd.getTime());
  }

  public exportAll() {
    var isPyco = false;
    if (this.typeExport == 'PYCO') {
      isPyco = true;
    }
    if (!this.googleService.isConnected$.value) {
      this.googleService.gapiLoadAndSignIn().then(
        () => {
          this.exportGeneric(isPyco);
        },
        () => {
          console.error('Erreur de connexion');
        }
      );
    } else {
      this.exportGeneric(isPyco);
    }

    // this.rondeService.exportAll(isPyco)?.subscribe({
    //   next: () => {
    //     if (this.exportAllDialog) {
    //       this.dialog.open(this.exportAllDialog);
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Export all', error);
    //     this.openDialogInfo(this.exportAllDialogKo);
    //   },
    // });
  }

  public exportGeneric(isPyco: boolean): void {
    let ids = this.dataSource.data.map((o) => {
      return o.instance!.id;
    });
    this.rondeService.exportAll(ids, isPyco)?.subscribe({
      next: () => {
        if (this.exportAllDialog) {
          this.dialog.open(this.exportAllDialog);
        }
      },
      error: (error) => {
        console.error('Export all', error);
        this.openDialogInfo(this.exportAllDialogKo);
      },
    });
  }

  public exportOneExcel(id: number) {
    if (this.excelDialog) {
      this.dialog.open(this.excelDialog, {
        height: '200px',
        width: '400px',
      });
    }

    this.rondeService
      .exportOneExcel(id)
      .pipe(take(1))
      // .subscribe((response) => {
      // });
      .subscribe({
        next: (response) => {
          this.dialog.closeAll();
          window.open(response.body.url, '_blank');
        },
        error: (error) => {
          console.error('Export excel', error);
          this.openDialogInfo(this.exportExcelDialogKo);
        },
      });
  }

  public exportPdf(id: number, date: Date) {
    if (this.pdfDialog) {
      this.dialog.open(this.pdfDialog, {
        height: '200px',
        width: '400px',
      });
    }
    this.rondeService
      .exportPdf(id, date)
      .pipe(take(1))
      // .subscribe((response) => {
      // });
      .subscribe({
        next: (response) => {
          this.dialog.closeAll();
          window.open(response.body.presignedUrl, '_blank');
        },
        error: (error) => {
          console.error('export pdf', error);
          this.openDialogInfo(this.exportPDFDialogKo);
        },
      });
  }

  public filterAnomalies($event: any, field: string): void {
    this.addFilter(
      field,
      $event,
      (value: RondeLight) => value.instance?.hasAnomaly! === $event
    );
  }

  public filterCategory($event: any, field: string): void {
    if ($event.includes('7') && $event.length <= 1) {
      this.typeExport = 'PYCO';
    } else if ($event.includes('7') && $event.length > 1) {
      this.typeExport = 'NONE';
    } else {
      this.typeExport = 'EXCLU';
    }
    this.addFilter(field, $event, (value: RondeLight) =>
      this.categoryCompare(value, $event)
    );
  }

  public filterDate($event: any, field: string): void {
    this.addFilter(
      field,
      $event,
      (value: RondeLight) => value.instance?.modificationDate! == $event
    );
  }

  public filterId($event: any, field: string): void {
    this.addFilter(field, $event, (value: RondeLight) =>
      value.instance?.id
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  public filterLocation($event: any, field: string): void {
    this.addFilter(field, $event, (value: RondeLight) =>
      value.instance?.location
        ?.toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  public filterStatus($event: any, field: string): void {
    this.addFilter(field, $event, (value: RondeLight) =>
      this.statusCompare(value, $event)
    );
  }

  public filterTitle($event: any, field: string): void {
    this.addFilter(field, $event, (value: RondeLight) =>
      value.instance?.title
        ?.toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  public filterValidator($event: any, field: string): void {
    this.addFilter(field, $event, (value: RondeLight) =>
      value.instance?.ownerName
        ?.toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  public getTypeExport() {
    return this.typeExport;
  }

  public openDialogInfo(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public openDialogWithRef(ref: TemplateRef<any>, ronde: RondeLight) {
    this.dialog.open(ref, {
      data: ronde,
    });
  }

  public setStatus(): void {
    let referentielService = new ReferentielService();
    if (!this.displayValidation) {
      this.status = referentielService.statusRonde.filter((status) => {
        return status.id !== StatusEnum.SIGN_BY_SUPERVISOR;
      });
    } else {
      this.status = referentielService.statusRonde;
    }
  }

  public startDateChange() {
    let trueStart = new Date(this.startDate.value);
    trueStart.setHours(0, 0, 0);
    this.rondeService.changeStartDate(trueStart.getTime());
  }

  // public uploadToDrive(ronde: RondeLight) {

  public uploadToDrive(ronde: RondeLight) {
    if (!this.googleService.isConnected$.value) {
      this.googleService.gapiLoadAndSignIn().then(
        () => {
          this.rondeService.uploadToDrive(ronde);
        },
        () => {
          console.error("Erreur d'upload");
        }
      );
    } else {
      this.rondeService.uploadToDrive(ronde);
    }
  }

  //   this.rondeService.uploadToDrive(ronde);
  // }

  protected initColumnDisplay(): string[] {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Validation date':
          if (item.instance?.modificationDate) {
            return new Date(item.instance?.modificationDate).getTime();
          }
          return item[property as keyof RondeLight] as unknown as string;
        case 'location':
          if (item.instance?.location) {
            return item.instance?.location.toString();
          }
          return item[property as keyof RondeLight] as unknown as string;
        case 'category':
          if (item.instance?.category) {
            return item.instance?.category.label.toString();
          }
          return item[property as keyof RondeLight] as unknown as string;
        case 'title':
          if (item.instance?.title) {
            return item.instance?.title.toString();
          }
          return item[property as keyof RondeLight] as unknown as string;
        case 'Validated By':
          if (item.instance?.ownerName) {
            return item.instance?.ownerName.toString();
          }
          return item[property as keyof RondeLight] as unknown as string;
        case 'Status':
          if (item.instance?.status) {
            return item.instance?.status.label;
          }
          return item[property as keyof RondeLight] as unknown as string;

        default:
          return item[property as keyof RondeLight] as unknown as string;
      }
    };
    return [
      FormControlNames.id,
      FormControlNames.location,
      FormControlNames.category,
      FormControlNames.title,
      FormControlNames.validated,
      FormControlNames.anomalies,
      FormControlNames.status,
      FormControlNames.date,
      'actions',
    ];
  }

  private categoryCompare(testVal: RondeLight, event: Array<string>): boolean {
    if (event && event.length > 0 && testVal.instance!.category) {
      return event.includes(testVal.instance!.category.id!.toString());
    }
    return true;
  }

  private statusCompare(testVal: RondeLight, event: Array<string>): boolean {
    if (event && event.length > 0 && testVal.instance?.status) {
      return event.includes(testVal.instance?.status.id!.toString());
    }
    return true;
  }
}
