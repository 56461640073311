import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from 'projects/model/src/lib/model.abstract';

export class AbstractList<T> {
  @JsonProperty('list', Array<T>)
  public list: Array<T> = new Array<T>();
}

@JsonObject('INumberList')
export class IdNumberList extends ModelAbstract {
  @JsonProperty('ids', [Number], true)
  public ids: Array<Number> = new Array<Number>();
}
