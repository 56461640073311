import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '@al/model';

@JsonObject('Picture')
export class Picture extends ModelAbstract {
  @JsonProperty('author', String, true)
  public author: string | null = null;

  @JsonProperty('date', String)
  public date: string | null = null;

  @JsonProperty('formInstanceId', Number, true)
  public formInstanceId: number = 0;

  @JsonProperty('name', String, true)
  public name: string | null = null;

  @JsonProperty('url', String, true)
  public url: string | null = null;
}
