<div class="al-template-list">
  <div class="header">
    <h1 translate class="parent-component-title">TEMPLATE_LIST_TITLE</h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->
  </div>

  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="{{formControlNames.id}}">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, formControlNames.id.valueOf())"
          (OnSearch)="filterId($event, formControlNames.id.valueOf())"
          [field]="formControlNames.id.valueOf()"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{ 'TEMPLATE_ID' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        attr.data-label="{{ 'TEMPLATE_ID' | translate }}"
        class="id"
      >
        {{ element.id }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{formControlNames.category}}">
      <mat-header-cell *matHeaderCellDef class="category">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, formControlNames.category.valueOf())"
          (OnSearch)="
            filterCategory($event, formControlNames.category.valueOf())
          "
          [field]="formControlNames.category.valueOf()"
          [values]="categories"
          [isSelect]="true"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{ 'CATEGORY' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        attr.data-label="{{ 'CATEGORY' | translate }}"
        class="category"
      >
        {{ element.category?.label | translate }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{formControlNames.title}}">
      <mat-header-cell *matHeaderCellDef class="title">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, formControlNames.title)"
          (OnSearch)="filterTitle($event, formControlNames.title)"
          [field]="formControlNames.title.valueOf()"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{ 'TEMPLATE_TITLE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        attr.data-label="{{ 'TEMPLATE_TITLE' | translate }}"
        class="title"
      >
        {{ element.title }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{formControlNames.location}}">
      <mat-header-cell *matHeaderCellDef class="location">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, formControlNames.location)"
          (OnSearch)="filterLocation($event, formControlNames.location)"
          [field]="formControlNames.location.valueOf()"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{ 'TEMPLATE_LOCATION' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        attr.data-label="{{ 'TEMPLATE_LOCATION' | translate }}"
        class="location"
      >
        {{ element.location }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{formControlNames.visibility}}">
      <mat-header-cell *matHeaderCellDef class="visibility">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, formControlNames.visibility)"
          (OnSearch)="filterVisibility($event, formControlNames.visibility)"
          [field]="formControlNames.visibility.valueOf()"
          [values]="visibility"
          [isSelect]="true"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{ 'TEMPLATE_VISIBILITY' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        attr.data-label="{{ 'TEMPLATE_VISIBILITY' | translate }}"
        class="visibility"
      >
        <p *ngIf="element.visible">{{ "ENABLED" | translate }}</p>
        <p *ngIf="!element.visible">{{ "DISABLED" | translate }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{formControlNames.date}}">
      <mat-header-cell *matHeaderCellDef class="date">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, formControlNames.date)"
          (OnSearch)="filterDate($event, formControlNames.date)"
          [field]="formControlNames.date.valueOf()"
          fieldType="date"
          storageNameSpace="template-list-filters"
          title="{{ 'TEMPLATE_DATE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        attr.data-label="{{ 'TEMPLATE_DATE' | translate }}"
        class="date"
      >
        {{ element.lastInstanceValidation | localizedDate }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{formControlNames.changeDate}}">
      <mat-header-cell *matHeaderCellDef class="date">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, formControlNames.changeDate)"
          (OnSearch)="filterDate($event, formControlNames.changeDate)"
          [field]="formControlNames.date.valueOf()"
          fieldType="date"
          storageNameSpace="template-list-filters"
          title="{{ 'TEMPLATE_MODIFICATION_DATE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        attr.data-label="{{ 'TEMPLATE_MODIFICATION_DATE' | translate }}"
        class="date"
      >
        {{ element.changeDate | localizedDate }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="actions" translate>



        <button
          mat-mini-fab
          [matTooltip]="'CREATE_TEMPLATE' | translate"
          (click)="createTemplate()"
          color="primary"
          [disabled]="
            userProfile &&
            !(
              userProfile.id === '40' ||
              userProfile.id === '30' ||
              userProfile.id === '20'
            )
          "
        >
          <!--          {{ "CREATE_TEMPLATE" | translate }}-->
          <mat-icon>add</mat-icon>

        </button>
        <button [matTooltip]="'TEMPLATE_HISTORIES' | translate"

                mat-mini-fab
                (click)="goToHistories()"
                color="primary"
                [disabled]="
            userProfile &&
            !(
              userProfile.id === '40' ||
              userProfile.id === '30' ||
              userProfile.id === '20'
            )
          "
        >
          <mat-icon>history</mat-icon>
        </button>
        <!--        <button mat-button (click)="createSite()"  color="primary">-->
        <!--          {{'CREATE_SITE' | translate}}-->
        <!--        </button>-->
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="" class="actions">


        <button
          mat-icon-button
          (click)="edit(element.id)"
          [matTooltip]="'EDIT' | translate"
          [disabled]="
            userProfile &&
            !(
              userProfile.id === '40' ||
              userProfile.id === '30' ||
              userProfile.id === '20'
            )
          "
        >
          <mat-icon>edit</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="openDialogWithRef(copyDialog, element, true)"
          [matTooltip]="'COPY' | translate"
          [disabled]="
            userProfile &&
            !(
              userProfile.id === '40' ||
              userProfile.id === '30' ||
              userProfile.id === '20'
            )
          "
        >
          <mat-icon>content_copy</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="openDialogWithRef(deleteDialog, element)"
          [matTooltip]="'DELETE' | translate"
          [disabled]="
            userProfile &&
            !(
              userProfile.id === '40' ||
              userProfile.id === '30' ||
              userProfile.id === '20'
            )
          "
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="goToHistory(element.id)"
          [matTooltip]="'HISTORY' | translate"
          [disabled]="
                    userProfile &&
                    !(
                      userProfile.id === '40' ||
                      userProfile.id === '30' ||
                      userProfile.id === '20'
                    )
                  "
        >
          <mat-icon>history</mat-icon>
        </button>
        <button mat-icon-button
                *ngIf="element.category?.id !== '7'"
                (click)="exportPdf(element.id)"

                [matTooltip]="'EXPORT_PDF_RONDE' | translate"
                matTooltipPosition="above">
          <mat-icon floatLabel="always" appearance="outline"
                    class="material-icons-outlined"
          >picture_as_pdf_outline
          </mat-icon>
        </button>

      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [pageSize]="15"
    [pageSizeOptions]="[15, 25, 50, 100]"
  ></mat-paginator>
</div>
<ng-template #copyDialog let-data>
  <h2 matDialogTitle translate>DUPLICATE_TEMPLATE</h2>
  <mat-dialog-content>
    <p translate>SELECT_DUPLICATION_SITE</p>
    <mat-form-field>
      <mat-select *ngIf="data.category.id !== '7'" [(value)]="selectedItemUuid">
        <mat-option
          *ngFor="let item of (currentUser | async)?.sites"
          [value]="item.id"
        >
          {{ item.label }}
        </mat-option>
      </mat-select>
      <mat-select
        *ngIf="data.category.id === '7'"
        [disabled]="true"
        [(value)]="selectedItemUuid"
      >
        <mat-option
          *ngFor="let item of (currentUser | async)?.sites"
          [value]="item.id"
        >
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      matDialogClose
      (click)="copySite(data)"
      color="primary"
      translate
    >
      COPY
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #deleteDialog let-data>
  <al-confirm-dialog (confirm)="deleteTemplate(data)">
    <div title translate>DELETE</div>
    <div content translate>AGREE_TO_DELETE</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogKo>
  <al-confirm-dialog>
    <div title translate>DELETE_DIALOG_MESSAGE_KO_TITLE_TEMPLATE</div>
    <div content translate>DELETE_DIALOG_MESSAGE_KO_TEMPLATE</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogOk>
  <al-confirm-dialog>
    <div title translate>DELETE_DIALOG_MESSAGE_OK_TITLE_TEMPLATE</div>
    <div content translate>DELETE_DIALOG_MESSAGE_OK_TEMPLATE</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #historyDialog>
  <al-confirm-dialog>
    <div title translate>HISTORY_DIALOG</div>
    <div content translate>HISTORY_DIALOG_MESSAGE on mets des messages
      <al-history [templateId]="templateIdForHistory"></al-history>
    </div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>
<ng-template #pdfDialog>
  <h2 matDialogTitle translate>PDF_GENERATE</h2>
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>
<ng-template #exportPDFDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_PDF_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_PDF_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>
