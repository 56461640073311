import {
  FurnaceLightState,
  FurnaceLightStore,
} from '../store/furnace-light.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class FurnaceLightQuery extends QueryEntity<FurnaceLightState> {
  public constructor(protected override store: FurnaceLightStore) {
    super(store);
  }
}
