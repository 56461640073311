import { JsonObject, JsonProperty } from 'json2typescript';
import { GateDirections } from '@al/model';
import { ModelAbstract } from '../../model.abstract';
import { RondeTube } from './ronde.tube.model';

@JsonObject('rondeGate')
export class RondeGate extends ModelAbstract {
  @JsonProperty('called', String, true)
  public called: string = '';

  @JsonProperty('direction', String, true)
  public direction: string = GateDirections.ltr;

  @JsonProperty('gateId', String, true)
  public gateId: string = '';

  @JsonProperty('id', String, true)
  public id: string = '';

  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('level', String, true)
  public level: string = '';

  @JsonProperty('orientation', String, true)
  public orientation: string = '';

  @JsonProperty('tubeCount', Number, true)
  public tubeCount: number = 0;

  @JsonProperty('tubes', [RondeTube], true)
  public tubes: RondeTube[] = [];
}
