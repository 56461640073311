import { Component, OnInit } from '@angular/core';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { FormGroupModelInterface } from '@al/generic-components';
import { InfoWidget } from '@al/model';
import { InstanceAnswerService } from '@al/preview-component';
import { WidgetFormControlNames } from '../widget-form-control-names.enum';

@Component({
  selector: 'al-info-widget',
  templateUrl: './info-widget.component.html',
  styleUrls: [
    '../template-widget.component.scss',
    './info-widget.component.scss',
  ],
})
export class InfoWidgetComponent
  extends TemplateWidgetComponent
  implements OnInit, FormGroupModelInterface<InfoWidget>
{
  public infoWidget: InfoWidget = new InfoWidget();

  public constructor(
    protected override formBuilder: FormBuilder,
    protected override answerService: InstanceAnswerService,
    protected dragAndDropService: DragAndDropService,
    protected override formGroupDirective: FormGroupDirective,
    protected templateModelService: TemplateModelService
  ) {
    super(formBuilder, answerService, formGroupDirective);
  }

  public buildFormGroup(infoWidget: InfoWidget | null): FormGroup {
    return this.formBuilder.group({
      text: this.formBuilder.control(infoWidget?.text, [Validators.required]),
      link: this.formBuilder.control(infoWidget?.link),
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.infoWidget = this.widget as InfoWidget;
    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroup = this.buildFormGroup(this.infoWidget);
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);
    }
  }

  public setValue(formControlName: WidgetFormControlNames): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === WidgetFormControlNames.text) {
          this.infoWidget.text = formControl.value;
        } else if (formControlName === WidgetFormControlNames.link) {
          this.infoWidget.link = formControl.value;
        }
      }
    }
  }
}
