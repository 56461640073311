import {
  Category,
  Days,
  FrequencyOccurence,
  Pair,
  PairNumeric,
  Profile,
  Status,
} from '@al/model';

export class ReferentielService {
  public categories: Category[] = [];

  public daysList: Days[] = [];

  public frequencyOccurenceList: FrequencyOccurence[] = [];

  public statusRonde: Status[] = [];

  public statusUser: Status[] = [];

  public templateProfile: Profile[] = [];

  public templateStatus: Status[] = [];

  public constructor() {
    this.categories.push(new Category('1', 'CONSTRAINT'));
    this.categories.push(new Category('2', 'MAINTENANCE'));
    this.categories.push(new Category('3', 'PRODUCTION'));
    this.categories.push(new Category('4', 'AUDIT'));
    this.categories.push(new Category('5', 'SECURITY'));
    this.categories.push(new Category('6', 'DIVERS'));
    this.categories.push(new Category('7', 'PYCOSO'));
    this.categories.push(new Category('8', 'SAFETY'));

    this.templateStatus.push(new Status('10', 'ON_HOLD'));
    this.templateStatus.push(new Status('20', 'CANCELED'));
    this.templateStatus.push(new Status('30', 'VALIDATED'));
    this.templateStatus.push(new Status('40', 'DELETED'));

    this.statusRonde.push(new Status('10', 'ON_HOLD'));
    this.statusRonde.push(new Status('20', 'VALIDATED'));
    this.statusRonde.push(new Status('30', 'DELETED'));
    this.statusRonde.push(new Status('40', 'CANCELED'));
    this.statusRonde.push(new Status('60', 'SIGN_BY_SUPERVISOR'));

    this.statusUser.push(new Status('10', 'ACTIVE'));
    this.statusUser.push(new Status('30', 'DELETED'));

    this.templateProfile.push(new Profile('10', 'USER'));
    this.templateProfile.push(new Profile('20', 'CONFIGURATOR'));
    this.templateProfile.push(new Profile('30', 'ADMINISTRATOR'));
    this.templateProfile.push(new Profile('40', 'IT_ADMINISTRATOR'));

    this.frequencyOccurenceList.push(new FrequencyOccurence(1, 'FIRST'));
    this.frequencyOccurenceList.push(new FrequencyOccurence(2, 'SECOND'));
    this.frequencyOccurenceList.push(new FrequencyOccurence(3, 'THIRD'));
    this.frequencyOccurenceList.push(new FrequencyOccurence(4, 'FOURTH'));
    this.frequencyOccurenceList.push(new FrequencyOccurence(5, 'FIFTH'));

    this.daysList.push(new Days(1, 'MONDAY'));
    this.daysList.push(new Days(2, 'TUESDAY'));
    this.daysList.push(new Days(3, 'WEDNESDAY'));
    this.daysList.push(new Days(4, 'THURSDAY'));
    this.daysList.push(new Days(5, 'FRIDAY'));
    this.daysList.push(new Days(6, 'SATURDAY'));
    this.daysList.push(new Days(7, 'SUNDAY'));
  }

  public getCategoryFromId(id: string): Category | null {
    return this.getElementFromId(id, this.categories);
  }

  public getDaysFromId(id: number): Days | null {
    return this.getElementNumericFromId(id, this.daysList);
  }

  public getDefaultTemplateStatus(): Status | null {
    return this.getElementFromId('20', this.templateStatus);
  }

  public getElementFromId(id: string, list: Pair[]): Pair | null {
    const filtered = list.filter((element) => element.id === id);
    if (filtered.length === 1) {
      return filtered[0];
    }
    return null;
  }

  public getElementNumericFromId(
    id: number,
    list: PairNumeric[]
  ): PairNumeric | null {
    const filtered = list.filter((element) => element.id === id);
    if (filtered.length === 1) {
      return filtered[0];
    }
    return null;
  }

  public getFrequencyOccurenceFromId(id: number): FrequencyOccurence | null {
    return this.getElementNumericFromId(id, this.frequencyOccurenceList);
  }

  public getProfileFromId(id: string): Category | null {
    return this.getElementFromId(id, this.templateProfile);
  }

  public getStatusFromId(id: string): Status | null {
    return this.getElementFromId(id, this.templateStatus);
  }

  public getStatusRondeFromId(id: string): Status | null {
    return this.getElementFromId(id, this.statusRonde);
  }

  public getStatusUserFromId(id: string): Status | null {
    return this.getElementFromId(id, this.statusUser);
  }
}
