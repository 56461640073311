import {
  AkitaFilterService,
  FurnaceLightQuery,
  FurnaceLightState,
} from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { FurnaceLight } from '@al/model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FurnaceLightFilterService extends AkitaFilterService<
  FurnaceLight,
  FurnaceLightState
> {
  public constructor(protected query: FurnaceLightQuery) {
    super(new AkitaFiltersPlugin<FurnaceLightState>(query));
  }
}
