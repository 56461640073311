import { AkitaFilterService, UnitQuery, UnitState } from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Injectable } from '@angular/core';
import { Unit } from '@al/model';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class UnitFilterService extends AkitaFilterService<Unit, UnitState> {
  public constructor(protected query: UnitQuery) {
    super(new AkitaFiltersPlugin<UnitState>(query));
  }
}
