import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TemplateLight } from '@al/model';

export interface TemplateLightState
  extends EntityState<TemplateLight>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'template-light', resettable: true })
export class TemplateLightStore extends EntityStore<TemplateLightState> {
  public constructor() {
    super();
  }
}
