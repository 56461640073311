import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { McqWidget, SectionWidget, Widget } from '@al/model';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TemplateModelService } from './template-model.service';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class DragAndDropService {
  public cdkDropLists: CdkDropList[] = [];

  public cdkDropListsSubject: Subject<CdkDropList[]> = new Subject();

  public constructor(protected templateModelService: TemplateModelService) {}

  public clean(): void {
    this.cdkDropLists = [];
    this.notifyCdkList();
    // this.cdkListIdSubject.next(this.cdkListIds);
  }

  public copyArrayItem(
    event: CdkDragDrop<Widget[]>,
    widgetId: string,
    sectionId: string | null = null
  ): void {
    // const clone = structuredClone(
    //   event.previousContainer.data[event.previousIndex]
    // );
    const clone = Object.assign(
      Object.create(
        Object.getPrototypeOf(event.previousContainer.data[event.previousIndex])
      ),
      event.previousContainer.data[event.previousIndex]
    );

    // WARNING, the clone doesn't copy the children array but use reference.
    // So break the link to prevent the share of the same children list above all the sections
    if (clone instanceof SectionWidget) {
      clone.children = [];
    }

    if (clone instanceof McqWidget) {
      clone.options = [];
    }

    clone.id = widgetId;
    this.templateModelService.insertWidget(
      clone,
      sectionId,
      event.currentIndex
    );
  }

  public getCdkDropListObservable(): Observable<CdkDropList[]> {
    return this.cdkDropListsSubject.asObservable();
  }

  public moveItemInArray(
    event: CdkDragDrop<Widget[]>,
    sectionId: string | null = null
  ): void {
    this.templateModelService.moveItemInArray(event, sectionId);
  }

  public notifyCdkList(): void {
    this.cdkDropLists = this.cdkDropLists.sort((a, b) =>
      b.id.localeCompare(a.id)
    );
    this.cdkDropListsSubject.next(this.cdkDropLists);
  }

  public registerForDrop(listId: CdkDropList): void {
    this.cdkDropLists.push(listId);
    // this.cdkDropLists = this.cdkDropLists.sort((a, b) =>
    //   a.id.localeCompare(b.id)
    // );
    // this.cdkListIds = this.cdkListIds.sort((a, b) => a.localeCompare(b));
    this.notifyCdkList();
  }

  public transferArrayItem(
    event: CdkDragDrop<Widget[]>,
    widgetId: string,
    sectionId: string | null = null
  ): void {
    const widget: Widget = event.previousContainer.data[event.previousIndex];
    // widget.id = widgetId;
    event.previousContainer.data.splice(event.previousIndex, 1);
    this.templateModelService.moveWidget(widget, sectionId, event.currentIndex);
  }

  public transferArrayItemFromsection(
    event: CdkDragDrop<Widget[]>,
    widgetId: string,
    sectionId: string | null = null
  ): void {
    const widget: Widget = event.container.data[event.currentIndex];
    // widget.id = widgetId;

    this.templateModelService.moveWidget(
      widget,
      sectionId,
      event.previousIndex
    );
  }

  public unregisterForDrop(listId: string): void {
    // if (this.cdkListIds.includes(listId)) {
    this.cdkDropLists = this.cdkDropLists.filter((id) => id.id !== listId);
    this.notifyCdkList(); // }
  }
}
