import { Component, OnDestroy, OnInit } from '@angular/core';
import { Profile, User } from '@al/model';
import { Paths } from '@al/angular-route';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@al/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-menu-widget',
  templateUrl: 'menu-widget.component.html',
  styleUrls: ['menu-widget.component.scss'],
})
export class MenuWidgetComponent implements OnInit, OnDestroy {
  public menuTitle = '';

  public userProfile: Profile | undefined;

  private ngUnsubscribe = new Subject();

  public constructor(
    protected router: Router,
    protected translateService: TranslateService,
    protected userService: UserService
  ) {}

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.translateService
      .get('MAIN_MENU_TITLE')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((title: string) => {
        this.menuTitle = title;
      });

    this.userService
      .getCurrentObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User | undefined) => {
        this.userProfile = user?.profileType;
      });
  }

  public showDashboard(): void {
    this.router.navigate(['/', Paths.DASHBOARD]);
  }

  public showPyrometre(): void {
    this.router.navigate(['/', Paths.pyrometre.DEFAULT, Paths.pyrometre.LIST]);
  }

  public showRonde(): void {
    this.router.navigate(['/', Paths.ronde.DEFAULT, Paths.ronde.LIST]);
  }

  public showScheduler(): void {
    this.router.navigate(['/', Paths.scheduler.DEFAULT, Paths.scheduler.LIST]);
  }

  public showSiteList(): void {
    this.router.navigate(['/', Paths.site.DEFAULT, Paths.site.LIST]);
  }

  public showTemplate(): void {
    this.router.navigate(['/', Paths.template.DEFAULT, Paths.template.LIST]);
  }

  public showUsersList(): void {
    this.router.navigate(['/', Paths.users.DEFAULT, Paths.users.LIST]);
  }
}
