import { Component, OnInit } from '@angular/core';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { NoteWidget, Returnable } from '@al/model';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { FormGroupModelInterface } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { WidgetFormControlNames } from '../widget-form-control-names.enum';

@Component({
  selector: 'al-note-widget',
  templateUrl: './note-widget.component.html',
  styleUrls: [
    '../template-widget.component.scss',
    './note-widget.component.scss',
  ],
})
export class NoteWidgetComponent
  extends TemplateWidgetComponent
  implements FormGroupModelInterface<NoteWidget>, OnInit
{
  public noteWidget: NoteWidget = new NoteWidget();

  public constructor(
    protected dragAndDropService: DragAndDropService,
    protected override formBuilder: FormBuilder,
    protected override answerService: InstanceAnswerService,
    protected override formGroupDirective: FormGroupDirective,
    protected templateModelService: TemplateModelService
  ) {
    super(formBuilder, answerService, formGroupDirective);
  }

  public buildFormGroup(noteWidget: NoteWidget | null): FormGroup {
    return this.formBuilder.group({
      isMandatory: [noteWidget?.isMandatory, []],
      label: this.formBuilder.control(noteWidget?.label, [Validators.required]),
      tooltip: this.formBuilder.control(noteWidget?.tooltip),
    });
  }

  public getAssets(value: Returnable) {
    this.noteWidget.equipment = value.asset;
    this.noteWidget.processGroup = value.locationPG;
    this.noteWidget.productionUnit = value.locationPU;
  }

  public getEntityToSave(): NoteWidget {
    return new NoteWidget();
  }

  public initModel(): NoteWidget {
    return new NoteWidget();
  }

  public override ngOnInit() {
    super.ngOnInit();

    this.noteWidget = this.widget as NoteWidget;

    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroup = this.buildFormGroup(this.noteWidget);
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);
    }
  }

  public save(): void {}

  public setValue(formControlName: WidgetFormControlNames): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === WidgetFormControlNames.label) {
          this.noteWidget.label = formControl.value;
        } else if (formControlName === WidgetFormControlNames.tooltip) {
          this.noteWidget.tooltip = formControl.value;
        } else if (formControlName === WidgetFormControlNames.isMandatory) {
          this.noteWidget.isMandatory = formControl.value;
        }
      }
    }
  }
}
