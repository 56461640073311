import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { UnitQuery, UnitStore } from '@al/akita';
import { GenericService } from './generic.service';
import { Unit } from '@al/model';
import { UnitRestService } from '@al/rest-services';

@Injectable({
  providedIn: 'root',
})
export class UnitService extends GenericService<Unit> implements OnDestroy {
  private defaultUnit = new Unit();

  private ngUnsubscribe = new Subject();

  public constructor(
    protected unitRestService: UnitRestService,
    protected unitQuery: UnitQuery,
    protected unitStore: UnitStore
  ) {
    super(unitRestService, unitQuery, unitStore);
  }

  public getDefaultUnit(): Unit {
    return this.defaultUnit;
  }

  public loadStore(): Observable<void> {
    return this.unitRestService.getListOfUnit().pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: Unit[]) => {
        this.unitStore.set(data);

        this.unitQuery
          .selectEntity((unit: Unit) => unit.abbreviation === 'NONE')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((unit: Unit | undefined) => {
            if (unit) {
              this.defaultUnit = unit;
            }
          });
      })
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
