import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  HistoryItem,
  IdNumberList,
  TemplateFull,
  TemplateHistoryAction,
  TemplateLight,
} from '@al/model';
import {
  HistoryService,
  TemplateFullService,
  TemplateLightService,
} from '@al/services';
import { Subject, map, takeUntil } from 'rxjs';
import { DefaultPath } from '@al/angular-route';
import { GenericComponent } from '@al/generic-components';
import { HistoryFilterService } from './service/history-filter.service';
import { HistoryQuery } from '@al/akita';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReferentielService } from '@al/referentiel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'al-history',
  templateUrl: 'history.component.html',
  styleUrls: ['history.component.scss'],
})
export class HistoryComponent
  extends GenericComponent
  implements OnInit, AfterViewInit
{
  @Input()
  public embedded: boolean = false;

  @ViewChild('exportPDFDialogKo')
  public exportPDFDialogKo!: TemplateRef<any>;

  @ViewChild('paginator')
  public paginator: MatPaginator | undefined;

  @ViewChild('pdfDialog', { static: true })
  public pdfDialog: TemplateRef<any> | undefined;

  @ViewChild(MatSort)
  public sort: MatSort | undefined;

  @Input()
  public templateId: number | string | undefined = undefined;

  public bodyDataSource = new MatTableDataSource<HistoryItem>([]);

  public displayedColumns: string[] = [
    'userId',
    'type',
    'datetime',
    'sectionName',
    'widgetType',
    'widgetName',
    'fieldName',
    'action',
    'oldValue',
  ];

  public template: TemplateFull | undefined = undefined;

  public widgetPropertySubjectHistory: Subject<{ id: any; label: string }[]> =
    new Subject();

  public widgetTypeSubjectHistory: Subject<{ id: any; label: string }[]> =
    new Subject();

  protected actions: { id: any; label: string }[] = [];

  protected daysOfTheWeek: { id: any; label: string }[] = [
    { id: 1, label: 'MONDAY' },
    { id: 2, label: 'THURSDAY' },
    { id: 3, label: 'WEDNESDAY' },
    { id: 4, label: 'TUESDAY' },
    { id: 5, label: 'FRIDAY' },
    { id: 6, label: 'SATURDAY' },
    { id: 7, label: 'SUNDAY' },
  ];

  protected historyFilterService: HistoryFilterService | undefined = undefined;

  protected occurences: { id: any; label: string }[] = [
    { id: 1, label: 'FIRST' },
    { id: 2, label: 'SECOND' },
    { id: 3, label: 'THIRD' },
    { id: 4, label: 'FOURTH' },
    { id: 5, label: 'FIFTH' },
  ];

  protected status: { id: any; label: string }[] = [
    { id: '20', label: 'create' },
    { id: '30', label: 'delete' },
  ];

  protected translations: { id: any; label: string }[] = [];

  protected types: { id: any; label: string }[] = [
    { id: 'headers', label: 'headers' },
    { id: 'body', label: 'body' },
  ];

  protected widgetHeaderProperties: { id: any; label: string }[] = [
    { id: 'componentType', label: 'HISTORY_WIDGET_TYPE' },
    { id: 'id', label: 'TEMPLATE_ID' },
    { id: 'title', label: 'TEMPLATE_TITLE' },
    { id: 'categoryId', label: 'CATEGORY' },
    { id: 'status', label: 'RONDE_STATUS' },
    { id: 'type', label: 'FREQUENCY_TYPE' },
    { id: 'period', label: 'FREQUENCY_PERIOD' },
    { id: 'occurence', label: 'OCCURENCE' },
    { id: 'startTime', label: 'RONDE_START_DATE' },
    { id: 'dayOfTheWeek', label: 'DAY_OF_THE_WEEK' },
  ];

  protected widgetProperties: { id: any; label: string }[] = [];

  protected widgetPycosoProperties: { id: any; label: string }[] = [
    { id: 'shoot', label: 'SHOOT' },
    { id: 'called', label: 'CALLED' },
    { id: 'direction', label: 'TEMPLATE_WIDGET_GATE_DIRECTION' },
    { id: 'gtOrder', label: 'TEMPLATE_WIDGET_GATE_GT_ORDER' },
    { id: 'gateId', label: 'TEMPLATE_WIDGET_GATE_ID' },
    { id: 'gtCenter', label: 'TEMPLATE_WIDGET_GATE_GT_CENTER' },
    { id: 'orientation', label: 'TEMPLATE_WIDGET_GATE_ORIENTATION' },
    { id: 'level', label: 'TEMPLATE_WIDGET_GATE_LEVEL' },
  ];

  protected widgetPycosoTypes: { id: any; label: string }[] = [
    { id: 'tube', label: 'PREVIEW_TUBELABEL' },
    { id: '', label: 'WIDGET_NONE' },
    { id: 'gate', label: 'WIDGET_GATE' },
  ];

  protected widgetStandardProperties: { id: any; label: string }[] = [
    { id: 'processGroup', label: 'PG_LABEL' },
    { id: 'productionUnit', label: 'PU_LABEL' },
    { id: 'isMandatory', label: 'IS_MANDATORY_QUESTION' },
    { id: 'disableControl', label: 'DISABLED' },
    { id: 'label', label: 'SITE_LABEL' },
    { id: 'options', label: 'MCQ_OPTIONS' },
    { id: 'singleChoiceOnly', label: 'MCQ_SINGLE_CHOICE_ONLY' },
    { id: 'decimalIndex', label: 'WHEELPICKER_WIDGET_DECIMAL_INDEX' },
    { id: 'min', label: 'MEASURE_WIDGET_MIN' },
    { id: 'max', label: 'MEASURE_WIDGET_MAX' },
    { id: 'tooltip', label: 'MCQ_WIDGET_TOOLTIP' },
    { id: 'unit', label: 'UNIT' },
    { id: 'maxRange', label: 'MEASURE_MAX_RANGE_ERROR' },
    { id: 'minRange', label: 'MEASURE_MIN_RANGE_ERROR' },
    { id: 'link', label: 'INFO_WIDGET_TOOLTIP' },
    { id: 'text', label: 'NOTE_WIDGET_TITLE' },
    { id: 'visible', label: 'ENABLE_TEMPLATE' },
    { id: 'nominalValue', label: 'WHEELPICKER_WIDGET_NOMINAL_VALUE' },
  ];

  protected widgetStandardTypes: { id: any; label: string }[] = [
    { id: 'note', label: 'WIDGET_NOTE' },
    { id: 'text', label: 'WIDGET_INFO' },
    { id: 'checkbox', label: 'WIDGET_VALIDATION' },
    { id: 'multiple_checkbox', label: 'WIDGET_MCQ' },
    { id: 'section', label: 'WIDGET_SECTION' },
    { id: 'measure', label: 'WIDGET_MEASURE' },
    { id: 'wheel_picker', label: 'WIDGET_WHEELPICKER' },
    { id: '', label: 'NONE' },
  ];

  protected widgetTypes: { id: any; label: string }[] = [];

  public constructor(
    private route: ActivatedRoute,
    protected historyService: HistoryService,
    protected historyQuery: HistoryQuery,
    protected templateFullService: TemplateFullService,
    protected templateLightService: TemplateLightService,
    protected translateService: TranslateService,
    protected router: Router,
    public dialog: MatDialog
  ) {
    super();

    this.historyFilterService = new HistoryFilterService(historyQuery);
    this.loadTraduction();
    this.initColumnDisplay();
  }

  public addFilter(
    field: string,
    $event: number | string,
    predicate: any
  ): void {
    this.historyFilterService!.addFilter(field, $event, predicate);
  }

  public getPropertyTranslationKey(fieldName: string): string {
    const key = this.widgetProperties.filter((p) => p.id === fieldName);
    if (key.length > 0) {
      return key[0].label;
    } else {
      throw new Error('');
    }
  }

  public initColumnDisplay(): void {
    this.bodyDataSource.sortingDataAccessor = (
      item: HistoryItem,
      property: string
    ): string | any => {
      // @ts-ignore
      let tmp = item[property];
      switch (property) {
        case 'fieldName':
          let prop = this.widgetProperties.filter((p) => p.id === tmp);
          if (prop.length > 0) {
            // let trad = this.translations.filter((t) => t.id === prop[0].label);
            // if (trad.length > 0) {
            //   return trad[0].label;
            // }
            return this.getTranslation(prop[0].label);
          }
          return item[property];

        // return this.translateService.instant(item.fieldName);
        default:
          // @ts-ignore
          return item[property];
      }
    };
  }

  public ngAfterViewInit() {
    if (this.paginator) {
      this.bodyDataSource.paginator = this.paginator;
    }

    if (this.sort) {
      this.bodyDataSource.sort = this.sort;
    }
  }

  public ngOnInit(): void {
    for (const key in TemplateHistoryAction) {
      if (Object.prototype.hasOwnProperty.call(TemplateHistoryAction, key)) {
        const label =
          TemplateHistoryAction[key as keyof typeof TemplateHistoryAction];
        this.actions.push({ id: label, label });
      }
    }
    if (this.templateId !== undefined) {
      this.getTemplate();
      this.filterTemplateId();

      this.historyService
        .loadHistoryTemplate(this.templateId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {});
    } else {
      this.route.paramMap.subscribe((params: ParamMap) => {
        if (params.get('templateId')) {
          this.templateId = parseInt(params.get('templateId')!);
          this.filterTemplateId();
          this.getTemplate();
          this.historyService
            .loadHistoryTemplate(this.templateId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {});
        }
      });
    }

    this.historyFilterService!.getFilter()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: HistoryItem[]) => {
        this.bodyDataSource.data = data;
      });
  }

  protected back(): void {
    this.router.navigate([new DefaultPath().LIST], {
      relativeTo: this.route.parent,
    });
  }

  protected clearFilter($event: any, field: string): void {
    this.historyFilterService!.removeFilter(field);
  }

  protected displayNewValue(historyItem: HistoryItem): string {
    return this.displayValue(historyItem, true);
  }

  protected displayOldValue(historyItem: HistoryItem): string {
    return this.displayValue(historyItem, false);
  }

  protected displayValue(historyItem: HistoryItem, newValue: boolean): string {
    let value: string | Object | null;
    if (newValue) {
      value = historyItem.newValue;
    } else {
      value = historyItem.oldValue;
    }
    const referentielService = new ReferentielService();
    switch (historyItem.fieldName) {
      case 'status':
        return this.getStatus(value as string);

      case 'period':
        return `${(value as number) / (1000 * 60 * 60)}`;
      case 'occurence':
        return this.getOccurence(value as number);
      case 'dayOfTheWeek':
        return this.getDayOfTheWeek(value as number);
      case 'startTime':
        return new Date(value as number).toLocaleString();
      case 'categoryId':
        let category = referentielService.categories.filter((c) => {
          return c.id === value;
        });
        if (category.length > 0) {
          return category[0].label;
        } else {
          return '';
        }

      case 'options':
        // let id = value['id'];
        let result: Array<string> = [];
        if (value !== '') {
          (value as Array<Object>).forEach((option) => {
            let labelKey = 'label' as keyof typeof option;
            let checkedKey = 'isChecked' as keyof typeof option;
            let canRaiseKey = 'canRaiseAnomaly' as keyof typeof option;
            let content = `${option[labelKey]}`;

            let checked = Boolean(option[checkedKey]);
            if (checked) {
              content += ` C`;
            }
            let canRaise = Boolean(option[canRaiseKey]);

            if (canRaise) {
              content += ` A`;
            }
            result.push(content);
          });
        }
        return result.toString();
      // let key = 'label' as keyof typeof value;
    }
    return value.toString();
  }

  protected exportPdf(): void {
    let ids: IdNumberList = new IdNumberList();
    ids.ids.push(this.templateId! as number);
    if (this.pdfDialog) {
      this.dialog.open(this.pdfDialog, {
        height: '200px',
        width: '400px',
      });
    }
    this.templateLightService
      .getGmpPdf(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (url: string) => {
          this.dialog.closeAll();
          window.open(url, '_blank');
        },
        () => {
          this.dialog.open(this.exportPDFDialogKo);
        }
      );
  }

  protected filterAction($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      $event.includes(value.action)
    );
  }

  protected filterDate($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      this.isDateBetween(
        new Date($event[0]),
        new Date($event[1]),
        new Date(value.datetime)
      )
    );
  }

  protected filterFieldName($event: any, field: string): void {
    // this.addFilter(field, $event, (value: HistoryItem) =>
    //   value.fieldName
    //     .toString()
    //     .toLowerCase()
    //     .includes($event.toString().toLowerCase())
    // );
    this.addFilter(field, $event, (value: HistoryItem) =>
      $event.includes(value.fieldName)
    );
  }

  protected filterNewValue($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      value.newValue
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  protected filterOldValue($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      value.oldValue
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  protected filterSectionId($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      value.sectionId
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  protected filterSectionName($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      value.sectionName
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  protected filterTemplateId(): void {
    this.addFilter(
      'templateId',
      this.templateId!,
      (value: HistoryItem) => value.templateId === this.templateId
    );
  }

  protected filterType($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      // value.type
      //   .toString()
      //   .toLowerCase()
      //   .includes($event.toString().toLowerCase())
      this.typeCompare(value, $event)
    );
  }

  protected filterUserId($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      value.userId
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  protected filterWidgetId($event: any, field: string): void {
    this.addFilter(field, $event, (value: HistoryItem) =>
      value.widgetId
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  protected filterWidgetName($event: any, field: string): void {
    this.addFilter(
      field,
      $event,
      (value: HistoryItem) =>
        value.widgetName
          .toString()
          .toLowerCase()
          .includes($event.toString().toLowerCase()) ||
        value.widgetId.includes($event.toString())
    );
  }

  protected filterWidgetType($event: any, field: string): void {
    // this.addFilter(field, $event, (value: TemplateHistoryStandard) =>
    //   value.widgetType
    //     .toString()
    //     .toLowerCase()
    //     .includes($event.toString().toLowerCase())
    // );
    this.addFilter(field, $event, (value: HistoryItem) =>
      $event.includes(value.widgetType)
    );
  }

  protected getTranslation(key: string): string {
    let trad = this.translations.filter((t) => t.id === key);
    if (trad.length > 0) {
      return trad[0].label;
    } else {
      return key;
    }
  }

  private getDayOfTheWeek(value: number) {
    const days = this.daysOfTheWeek.filter((d) => d.id === value);
    if (days.length > 0) {
      const day = days[0];
      return this.getTranslation(day.label);
    } else {
      return `${value}`;
    }
  }

  private getOccurence(value: number) {
    const occurences = this.occurences.filter((d) => d.id === value);
    if (occurences.length > 0) {
      const occurence = occurences[0];
      return this.getTranslation(occurence.label);
    } else {
      return `${value}`;
    }
  }

  private getStatus(value: string) {
    const statuts = this.status.filter((d) => d.id === value);
    if (statuts.length > 0) {
      const statut = statuts[0];
      return this.getTranslation(statut.label);
    } else {
      return `${value}`;
    }
  }

  private getTemplate(): void {
    const templateLight = new TemplateLight();
    templateLight.id = this.templateId as number;
    this.templateFullService
      .getFullTemplate(templateLight)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((template) => {
        this.template = template;
        if (this.template) {
          if (this.template.category?.id === '7') {
            this.widgetPycosoTypes.forEach((value) => {
              this.widgetTypes.push(value);
            });
            this.widgetPycosoProperties.forEach((value) => {
              this.widgetProperties.push(value);
            });
          } else {
            this.widgetStandardTypes.forEach((value) => {
              this.widgetTypes.push(value);
            });
            this.widgetStandardProperties.forEach((value) => {
              this.widgetProperties.push(value);
            });
          }

          this.widgetHeaderProperties.forEach((value) => {
            this.widgetProperties.push(value);
          });
          this.loadTraduction();
          this.widgetTypeSubjectHistory.next(this.widgetTypes);
          this.widgetPropertySubjectHistory.next(this.widgetProperties);
        }
      });
  }

  private isDateBetween(date1: Date, date2: Date, dateToCheck: Date): boolean {
    const startDate = new Date(Math.min(date1.getTime(), date2.getTime()));
    const endDate = new Date(Math.max(date1.getTime(), date2.getTime()));
    return dateToCheck >= startDate && dateToCheck <= endDate;
  }

  private loadTraduction() {
    this.widgetProperties.forEach((p) => {
      this.translateService
        .get(p.label)
        .pipe(
          // takeUntil(this.ngUnsubscribe),
          map((data) => {
            this.translations.push({ id: p.label, label: data });
          })
        )
        .subscribe();
    });

    this.daysOfTheWeek.forEach((p) => {
      this.translateService
        .get(p.label)
        .pipe(
          // takeUntil(this.ngUnsubscribe),
          map((data) => {
            this.translations.push({ id: p.label, label: data });
          })
        )
        .subscribe();
    });
    this.occurences.forEach((p) => {
      this.translateService
        .get(p.label)
        .pipe(
          // takeUntil(this.ngUnsubscribe),
          map((data) => {
            this.translations.push({ id: p.label, label: data });
          })
        )
        .subscribe();
    });
  }

  private typeCompare(testVal: HistoryItem, event: Array<string>): boolean {
    if (event && event.length > 0 && testVal.type) {
      return event.includes(testVal.type.toString());
    }
    return true;
  }
}
