<div class="al-template-body" [formGroup]="formGroup">

  <mat-card>


    <mat-card
      cdkDropList
      id="amain-list"
      #dropList
      [cdkDropListConnectedTo]="getCdkListId"
      class="widget-list"
      [cdkDropListData]="this.templateBody"
      (cdkDropListDropped)="drop($event)">
      <mat-card-header>
        <mat-card-sub-title translate>WIDGET_DROP_ZONE</mat-card-sub-title>

      </mat-card-header>

      <div class="example-box" *ngFor="let item of this.templateBody ">

        <al-section-widget *ngIf="isSection(item)" cdkDrag
                           [widget]="item"
                           (OnDelete)="delete($event)"
                           (OnDuplicate)="duplicate($event)" cdkDrag>
        </al-section-widget>
        <al-info-widget *ngIf="isText(item)"
                        cdkDrag
                        [widget]="item"
                        (OnDelete)="delete($event)"
                        (OnDuplicate)="duplicate($event)"
        ></al-info-widget>
        <al-mcq-widget [widget]="item" *ngIf="isMcq(item)" cdkDrag
                       (OnDelete)="delete($event)"
                       (OnDuplicate)="duplicate($event)"></al-mcq-widget>
        <al-note-widget [widget]="item" *ngIf="isNote(item)" cdkDrag
                        (OnDelete)="delete($event)"
                        (OnDuplicate)="duplicate($event)"></al-note-widget>
        <al-validation-widget *ngIf="isValidation(item)" cdkDrag

                              [widget]="item"
                              (OnDelete)="delete($event)"
                              (OnDuplicate)="duplicate($event)"></al-validation-widget>
        <al-wheelpicker-widget (OnDelete)="delete($event)" cdkDrag
                               (OnDuplicate)="duplicate($event)"
                               *ngIf="isWheelpicker(item)"

                               [widget]="item"></al-wheelpicker-widget>
        <al-measure-widget (OnDelete)="delete($event)" cdkDrag
                           (OnDuplicate)="duplicate($event)"
                           *ngIf="isMeasure(item)"
                           [widget]="item"></al-measure-widget>
        <al-gate-widget (OnDelete)="delete($event)" cdkDrag
                        (OnDuplicate)="duplicate($event)"
                        *ngIf="isGate(item)"
                        [widget]="item"></al-gate-widget>

      </div>
     </mat-card>

  </mat-card>
</div>
