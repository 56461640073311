import { PyrometreState, PyrometreStore } from '../store/pyrometre.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class PyrometreQuery extends QueryEntity<PyrometreState> {
  public constructor(protected override store: PyrometreStore) {
    super(store);
  }
}
