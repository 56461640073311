import {
  JsonConvert,
  OperationMode,
  // PropertyConvertingMode,
  // ValueCheckingMode,
} from 'json2typescript';

export class AlJsonConvert extends JsonConvert {
  public constructor() {
    super();
    this.ignorePrimitiveChecks = false;
    this.operationMode = OperationMode.ENABLE;
  }

  public mapper(type: string): void {
    this.useDiscriminator = true;
    this.discriminatorPropertyName = type;
  }
}
