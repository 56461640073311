import {
  FurnaceFullState,
  FurnaceFullStore,
} from '../store/furnace-full.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class FurnaceFullQuery extends QueryEntity<FurnaceFullState> {
  public constructor(protected override store: FurnaceFullStore) {
    super(store);
  }
}
