import { AbstractControl, ValidatorFn } from '@angular/forms';

export const decimalPrecisionValidator: ValidatorFn = (
  control: AbstractControl
) => {
  if (control == null || control.value == null) {
    return null;
  }
  if (![0, 1, 2].includes(control.value)) {
    return { decimalPrecision: true };
  }
  return null;
};
