<div class="al-site-selector">
    <ng-container *ngIf="isSelectable; else elseTemplate">
        <div class="is-selectable">
            <ng-container *ngIf="enabled; else elseTemplate">
                <div class="dropdown">
                    <mat-icon>place</mat-icon>

                    <mat-form-field appearance="outline">
                        <mat-select
                                [(value)]="selectedItemUuid" (valueChange)="onValueChange($event)"
                                [ngClass]="{ isOnline : 'disable' }"
                        >
                            <mat-option *ngFor="let item of items" [value]="item.id">
                                {{item.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <mat-card class="mat-card">
                    <div class="content">
                        <mat-icon>place</mat-icon>
                        <span class="site-name">{{selectedItemName}}cc</span>
                    </div>
                </mat-card>
            </ng-template>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="is-not-selectable">
            <mat-icon>place</mat-icon>
            <span>{{selectedItemName}}cc</span>
        </div>
    </ng-template>
</div>
