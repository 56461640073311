import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Profile, Pyrometre, Site } from '@al/model';
import { PyrometreService, SiteService } from '@al/services';
import { FormControlNames } from '../form-controle-names.enum';
import { GenericFormComponent } from '@al/generic-components';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@al/navigation';
import { Paths } from '@al/angular-route';
import { PyrometreLanguageKey } from '../language-key.enum';
import { ReferentielService } from '@al/referentiel';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'al-pyrometre-form',
  templateUrl: './pyrometre-form.component.html',
  styleUrls: ['./pyrometre-form.component.scss'],
})
export class PyrometreFormComponent
  extends GenericFormComponent<Pyrometre>
  implements OnInit
{
  public aCoeff: number = 0;

  public bCoeff: number = 0;

  public dateIsValid: boolean = true;

  public formControlNames = FormControlNames;

  public profiles: Profile[] = [];

  public pyrometre: Pyrometre = new Pyrometre();

  public sites: Site[] = [];

  public constructor(
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected pyrometreService: PyrometreService,
    protected siteService: SiteService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      pyrometreService,
      translateService,
      router,
      route
    );

    this.creation = route.snapshot.url
      .join('/')
      .endsWith(Paths.pyrometre.CREATE);
    this.form = this.buildFormGroup(this.pyrometre);
    let referentielService = new ReferentielService();
    this.profiles = referentielService.templateProfile;
  }

  public aCoeffChange($event: Event) {
    this.aCoeff = Number(($event.target as HTMLInputElement).value);
  }

  public bCoeffChange($event: Event) {
    this.bCoeff = Number(($event.target as HTMLInputElement).value);
  }

  public buildFormGroup(pyrometre: Pyrometre): FormGroup {
    let tempDate = new Date().toISOString();

    if (pyrometre.calibrationDate) {
      tempDate = new Date(pyrometre.calibrationDate as Date).toISOString();
    }
    this.aCoeff = pyrometre.aCoeff ? pyrometre.aCoeff : 0;
    this.bCoeff = pyrometre.bCoeff ? pyrometre.bCoeff : 0;
    return this.formBuilder.group({
      aCoeff: this.formBuilder.control(pyrometre.aCoeff, [Validators.required]),
      bCoeff: this.formBuilder.control(pyrometre.bCoeff, [Validators.required]),
      label: this.formBuilder.control(pyrometre.label, [Validators.required]),
      address: this.formBuilder.control(pyrometre.macAddress, [
        Validators.required,
        this.macAdressValidator(),
      ]),
      Site: this.formBuilder.control(pyrometre.siteId, []),
      date: this.formBuilder.control(tempDate, [Validators.required]),
    });
  }

  public dateChange() {
    let elementDate = new Date(
      this.form.get(FormControlNames.date.valueOf())?.value
    ).getTime();
    const oneYearDate = new Date();
    oneYearDate.setFullYear(oneYearDate.getFullYear() - 1);

    if (elementDate < oneYearDate.getTime()) {
      this.dateIsValid = false;
    } else {
      this.dateIsValid = true;
    }
  }

  public doSomething(): void {}

  public getEntityToSave(): Pyrometre {
    const pyrometre = new Pyrometre();
    pyrometre.aCoeff = this.form.get(FormControlNames.aCoeff.valueOf())?.value;
    pyrometre.bCoeff = this.form.get(FormControlNames.bCoeff.valueOf())?.value;
    pyrometre.calibrationDate = this.form.get(
      FormControlNames.date.valueOf()
    )?.value;

    pyrometre.label = this.form.get(FormControlNames.label.valueOf())?.value;
    pyrometre.siteId = this.pyrometre.siteId
      ? this.pyrometre.siteId.toString()
      : null;
    let currentSite = this.siteService.getCurrentSite();
    if (currentSite) {
      pyrometre.siteId = currentSite.id.toString();
    }
    pyrometre.macAddress = this.form.get(
      FormControlNames.address.valueOf()
    )?.value;
    pyrometre.id = this.pyrometre.id;
    return pyrometre;
  }

  public initFormGroup(pyrometre: Pyrometre) {
    this.form.reset();
    this.setFormValue(FormControlNames.aCoeff.valueOf(), pyrometre.aCoeff);
    this.setFormValue(FormControlNames.bCoeff.valueOf(), pyrometre.bCoeff);
    this.setFormValue(
      FormControlNames.date.valueOf(),
      pyrometre.calibrationDate
    );
    this.setFormValue(FormControlNames.label.valueOf(), pyrometre.label);
    this.setFormValue(FormControlNames.Site.valueOf(), pyrometre.siteId);
    this.setFormValue(FormControlNames.address.valueOf(), pyrometre.macAddress);
  }

  public initModel(): Pyrometre {
    return new Pyrometre();
  }

  public ngOnInit(): void {
    if (!this.creation) {
      this.pyrometreService
        .getActive()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((pyrometre: Pyrometre | undefined) => {
          if (pyrometre) {
            this.pyrometre = pyrometre;
            this.form = this.buildFormGroup(pyrometre);
          }
        });
    }

    this.siteService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((sites: Site[] | undefined) => {
        if (sites) {
          this.sites = sites;
        }
      });
  }

  public save(): void {
    if (this.form.valid) {
      if (!this.creation) {
        this.pyrometreService
          .save(this.getEntityToSave())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.setDialogTitle(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE
              );
              this.setDialogTextValue(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_OK
              );
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
            error: () => {
              this.setDialogTitle(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_KO_TITLE
              );
              this.setDialogTextValue(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_KO
              );
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
          });
      } else {
        this.pyrometreService
          .create(this.getEntityToSave())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.setDialogTitle(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE
              );
              this.setDialogTextValue(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_OK
              );
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
              this.router.navigate([`${Paths.pyrometre.EDIT}`], {
                relativeTo: this.route.parent,
              });
            },
            error: () => {
              this.setDialogTitle(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_KO_TITLE
              );
              this.setDialogTextValue(
                PyrometreLanguageKey.INFO_DIALOG_MESSAGE_KO
              );
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
          });
      }
    }
  }

  private macAdressValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const isMac = /^([0-9A-Fa-f]{2}[:-]){5}[0-9A-Fa-f]{2}$/.test(value);

      const passwordValid = isMac;

      return !passwordValid ? { isMacAdress: true } : null;
    };
  }
}
