export enum FormControlNames {
  title = 'title',
  id = 'id',
  category = 'category',
  location = 'location',
  validated = 'Validated By',
  date = 'Validation date',
  status = 'Status',
  anomalies = 'Anomalies',
}
