import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentOptions {
  public acgUserPoolClientCallback = '';

  public acgUserPoolClientId = '';

  public acgUserPoolDomain = '';

  public acgUserPoolId = '';

  public acgUserPoolLogoutEndpoint = '';

  public apiUrl = '';

  public apigwUrl = '';

  public apigwUrlDatalake = '';

  public copyright = 'copyright Airliquide 2022';

  public googleClientId = '';

  public production = false;

  public title = '';

  public version = '7.0.1';
}
