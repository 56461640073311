import { SchedulerState, SchedulerStore } from '../store/scheduler.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class SchedulerQuery extends QueryEntity<SchedulerState> {
  public site = this.selectAll();

  public constructor(protected override store: SchedulerStore) {
    super(store);
  }
}
