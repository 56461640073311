import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from './model.abstract';
import { Profile } from './profile.model';
import { ProfileConverter } from './converter/profile.converter';
import { Site } from './site.model';
import { Status } from './status.model';
import { StatusUserConverter } from './converter/status-user.converter';

@JsonObject('User')
export class User extends ModelAbstract {
  @JsonProperty('email', String, true)
  public email: string = '';

  @JsonProperty('emailForSortedOrFilteredQuery', String, true)
  public emailForSortedOrFilteredQuery: String = '';

  @JsonProperty('firstName', String, true)
  public firstName: string = '';

  @JsonProperty('id', Number, true)
  public id = 0;

  @JsonProperty('lastName', String, true)
  public lastName: string = '';

  @JsonProperty('name', String, true)
  public name: string = '';

  @JsonProperty('profileType', ProfileConverter, true)
  public profileType?: Profile = undefined;

  @JsonProperty('sharedQueryPartition', Number, true)
  public sharedQueryPartition: Number = 1;

  @JsonProperty('sites', [Site], true)
  public sites: Site[] = [];

  @JsonProperty('status', StatusUserConverter, true)
  public status?: Status = new Status('10', 'ACTIVE');

  @JsonProperty('supervisor', Boolean, true)
  public supervisor: boolean = false;

  @JsonProperty('userNameForFilteredQuery', String, true)
  public userNameForFilteredQuery: string = '';
}

@JsonObject('UserCreation')
export class UserCreation extends ModelAbstract {
  @JsonProperty('id', String, false)
  public id = '';

  @JsonProperty('unifiedMessage', String, false)
  public unifiedMessage = '';

  @JsonProperty('unifiedStatus', Number, false)
  public unifiedStatus = 0;
}
