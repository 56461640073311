import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Profile, Status, User } from '@al/model';
import { FormControlNames } from '../form-control-names.enum';
import { GenericListComponent } from '@al/generic-components';
import { MatDialog } from '@angular/material/dialog';
import { ReferentielService } from '@al/referentiel';
import { UserFilterService } from '../service/users-filter.service';
import { UsersService } from '@al/services';

@Component({
  selector: 'al-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent
  extends GenericListComponent<User>
  implements OnInit
{
  public formControlNames = FormControlNames;

  public profiles: Profile[] = [];

  public selectedStatus: Status | undefined = undefined;

  public status: Status[] = [];

  public constructor(
    protected userFilterService: UserFilterService,
    protected usersService: UsersService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    super(userFilterService, usersService, router, route);

    let referentielService = new ReferentielService();
    this.profiles = referentielService.templateProfile;
    this.status = referentielService.statusUser;
  }

  public deleteUser(user: User) {
    this.usersService.delete(user).subscribe({
      next: () => {
        this.openDialogInfo(this.deleteDialogOk);
      },
      error: () => {
        this.openDialogInfo(this.deleteDialogKo);
      },
    });
  }

  public filterEmail($event: any, field: string): void {
    this.addFilter(field, $event, (value: User) =>
      value.email.toLowerCase().includes($event.toLowerCase())
    );
  }

  public filterId($event: any, field: string): void {
    this.addFilter(field, $event, (value: User) =>
      value.id.toString().toLowerCase().includes($event.toString())
    );
  }

  public filterName($event: any, field: string): void {
    this.addFilter(field, $event, (value: User) => {
      let truVal =
        value.lastName.toString().toLowerCase() +
        ' ' +
        value.firstName.toString().toLowerCase();

      return truVal.includes($event.toString().toLowerCase());
    });
  }

  public filterProfil($event: any, field: string): void {
    this.addFilter(field, $event, (value: User) =>
      this.profileCompare(value, $event)
    );
  }

  public filterStatus($event: any, field: string): void {
    this.addFilter(field, $event, (value: User) =>
      this.statusCompare(value, $event)
    );
  }

  public openDialogInfo(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public openDialogWithRef(ref: TemplateRef<any>, user: User) {
    this.dialog.open(ref, {
      data: user,
    });
  }

  protected initColumnDisplay(): string[] {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'status':
          if (item.status) {
            return item.status.label;
          }
          return item[property as keyof User] as string;
        case 'profile':
          if (item.profileType) {
            return item.profileType.label;
          }
          return item[property as keyof User] as string;
        default:
          return item[property as keyof User] as string;
      }
    };

    return [
      FormControlNames.id.valueOf(),
      FormControlNames.lastName.valueOf(),
      FormControlNames.email.valueOf(),
      FormControlNames.status.valueOf(),
      FormControlNames.profile.valueOf(),
      FormControlNames.sites.valueOf(),
      'actions',
    ];
  }

  private profileCompare(testVal: User, event: Array<string>): boolean {
    if (event && event.length > 0 && testVal.profileType) {
      return event.includes(testVal.profileType.id!.toString());
    }
    return true;
  }

  private statusCompare(testVal: User, event: Array<string>): boolean {
    if (event && event.length > 0 && testVal.status) {
      return event.includes(testVal.status.id!.toString());
    }
    return true;
  }
}
