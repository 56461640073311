import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageKey } from '@al/local-cache';
import moment from 'moment-timezone';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class DateProxyPipe implements PipeTransform {
  public transform(value: Date): string {
    let pattern = localStorage[LocalStorageKey.DEFAULT_DATEFORMAT];
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    if (value) {
      if (timezone && pattern) {
        return moment(value).format(pattern);
      }
    }
    return '';
  }
}

@Pipe({
  name: 'applySiteTimezonePipe',
  pure: false,
})
export class ApplySiteTimezonePipePipe implements PipeTransform {
  public transform(value: Date): string {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    if (value) {
      if (timezone) {
        return moment(value.getTime()).tz(timezone).format('yyyy-MM-DDTHH:mm');
      }
    }
    return '';
  }
}

@Pipe({
  name: 'toDatePipe',
  pure: false,
})
export class ToDatePipe implements PipeTransform {
  public transform(value: string): Date {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    if (timezone) {
      return moment.tz(value, timezone).toDate();
      // if (this.template.frequency) {
      //   this.template.frequency.startTime = v.toDate();
      // }
    }
    return new Date();
  }
}
