<div class="al-scheduler">


  <div class="header">
    <h1 translate class="parent-component-title">SCHEDULER_TITLE</h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->


  </div>

  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.id.valueOf())"
          (OnSearch)="filterId($event,formControlNames.id.valueOf())"
          [field]="formControlNames.id.valueOf()"
          fieldType="input"
          storageNameSpace="scheduler-list-filters"
          title="{{'TEMPLATE_ID' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="id" class="id">
        {{ element.id }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef class="title">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.title.valueOf())"
          (OnSearch)="filterTitle($event,formControlNames.title.valueOf())"
          [field]="formControlNames.title.valueOf()"
          fieldType="input"
          storageNameSpace="scheduler-list-filters"
          title="{{'TEMPLATE_TITLE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="label" class="title">
        {{ element.title }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastCrDate">
      <mat-header-cell *matHeaderCellDef class="lastCrDate">
        <al-table-column-header-filtering

          fieldType="label"
          storageNameSpace="scheduler-list-filters"
          title="{{'LAST_CR_DATE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="label" class="label">
        {{ element.lastCrDate | localizedDate }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="nextCrDate">
      <mat-header-cell *matHeaderCellDef class="nextCrDate">
        <al-table-column-header-filtering
          fieldType="label"
          storageNameSpace="scheduler-list-filters"
          title="{{'NEXT_CR_DATE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="label" class="nextCrDate">
        {{ element.nextCrDate | localizedDate }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="delay">
      <mat-header-cell *matHeaderCellDef class="delay">
        <al-table-column-header-filtering
          fieldType="label"
          storageNameSpace="scheduler-list-filters"
          title="{{'DELAY' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="label" class="delay">
        <mat-icon *ngIf="diffHours(element.nextCrDate)<0">
          warning
        </mat-icon>
        <span *ngIf="diffHours(element.nextCrDate)<0">
          {{-1 * diffDays(element.nextCrDate)}} {{'DAYS' | translate}}

          {{  -1 * diffHours(element.nextCrDate) % 24}} {{'HOURS' | translate}}

        </span>
        <mat-icon *ngIf="diffHours(element.nextCrDate)>=0">
          check
        </mat-icon>

      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>

  <mat-paginator #paginator [pageSize]="15" [pageSizeOptions]="[15, 25, 50,  100]"></mat-paginator>

</div>




