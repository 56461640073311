import { RondeFullState, RondeFullStore } from '../store/ronde-full.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class RondeFullQuery extends QueryEntity<RondeFullState> {
  public constructor(protected override store: RondeFullStore) {
    super(store);
  }
}
