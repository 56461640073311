<button mat-button *ngIf="isButton" (click)="openDialogWithRef(preview)">
  <mat-icon>preview</mat-icon>
  Preview
</button>

<button mat-icon-button *ngIf="!isButton" (click)="openDialogWithRef(preview)"
        [matTooltip]=" (!this.signedOn ?'SEE_RONDE' : 'VALIDATE_INSTANCE') | translate"
        matTooltipPosition="above">
  <mat-icon *ngIf="!this.signedOn" class="icon-preview" appearance="outline" floatLabel="always"
  >preview
  </mat-icon>

  <mat-icon *ngIf="this.signedOn" class="icon-preview" appearance="outline" floatLabel="always"
  >check
  </mat-icon>
</button>

<ng-template #preview>

  <div class="header">
    <div class="logo">
      <img alt="Air Liquide" src="assets/images/logo_airliquide_header_horizontal.png" class="default">
    </div>
    <div class="template-title">
      {{template.title}}</div>

  </div>
  <div class="info">
    <div class="template-validation" *ngIf="template.lastInstanceValidation"> {{'LAST_CR_DATE' | translate}}
      : {{template.lastInstanceValidation! | localizedDate}}</div>


    <div class="template-category"> {{'CATEGORY' | translate}} : {{getCategory()}}</div>
    <div class="template-creator" *ngIf="getOwner()"> {{'OWNER' | translate}}
      : {{getOwner().firstName}} {{getOwner().lastName}}</div>
    <div class="template-category"> {{'RONDE_STATUS' | translate}} : {{getStatus() | translate}}</div>

    <div class="template-supervisor" *ngIf="getSupervisorValidation()!null"> {{'SUPERVISOR_BY' | translate}}
      : {{getSupervisorValidation()?.validator?.firstName}} {{getSupervisorValidation()?.validator?.lastName}}
      /   {{getSupervisorValidation()?.date! | localizedDate }}
    </div>

    <div class="template-supervisor" *ngIf="getExecutorValidation()!null"> {{'EXECUTED_BY' | translate}}
      : {{getExecutorValidation()?.validator?.firstName}} {{getExecutorValidation()?.validator?.lastName}}
      /   {{getExecutorValidation()?.date! | localizedDate }}
    </div>
    <div class="template-mot" *ngIf="pycoso"> {{'PREVIEW_MOT' | translate}}
      : {{template.smr?.maxTemperatureCelsius || ('PREVIEW_MOT_UNKNOWN' | translate)}} °C
    </div>
    <div class="template-mot" *ngIf="pycoso "> {{'SCADA_GENERATED_FILE' | translate}}
      : {{instance.instance?.scadaFile}}
    </div>

  </div>

  <mat-card>
    <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="viewport" *ngIf="!pycoso; else pycosoPreview">
      <div *cdkVirtualFor="let item of this.template.templateBody ">
        <al-section-widget *ngIf="isSection(item)"
                           [widgetStatus]="widgetStatusEnum.PREVIEW"
                           [widget]="item">
        </al-section-widget>

        <al-info-widget *ngIf="isText(item)"
                        [widgetStatus]="widgetStatusEnum.PREVIEW"
                        [widget]="item">
        </al-info-widget>

        <al-mcq-widget *ngIf="isMcq(item)"
                       [widgetStatus]="widgetStatusEnum.PREVIEW"
                       [widget]="item">
        </al-mcq-widget>

        <al-note-widget *ngIf="isNote(item)"
                        [widgetStatus]="widgetStatusEnum.PREVIEW"
                        [widget]="item">
        </al-note-widget>

        <al-validation-widget *ngIf="isValidation(item)"
                              [widgetStatus]="widgetStatusEnum.PREVIEW"
                              [widget]="item">

        </al-validation-widget>

        <al-wheelpicker-widget
          *ngIf="isWheelpicker(item)"
          [widgetStatus]="widgetStatusEnum.PREVIEW"
          [widget]="item">
        </al-wheelpicker-widget>

        <al-measure-widget
          *ngIf="isMeasure(item)"
          [widgetStatus]="widgetStatusEnum.PREVIEW"
          [widget]="item">
        </al-measure-widget>
      </div>
    </cdk-virtual-scroll-viewport>
    <ng-template #pycosoPreview>
      <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="viewport">
        <mat-table [dataSource]="pycosoDatasource">
          <ng-container matColumnDef="peephole">
            <th mat-header-cell *matHeaderCellDef>{{'WIDGET_GATE' | translate}}</th>
            <td mat-cell *matCellDef="let measure">{{measure.peepholeLabel}}</td>
          </ng-container>
          <ng-container matColumnDef="tube">
            <th mat-header-cell *matHeaderCellDef>{{'PREVIEW_TUBELABEL' | translate}}</th>
            <td mat-cell *matCellDef="let measure">{{measure.tubeLabel}}</td>
          </ng-container>
          <ng-container matColumnDef="rawTemperature">
            <th mat-header-cell *matHeaderCellDef>{{'PREVIEW_TEMPERATURE' | translate}}</th>
            <td mat-cell *matCellDef="let measure">{{measure.rawTemperatureCelsius}} °C</td>
          </ng-container>
          <ng-container matColumnDef="pyrometer">
            <th mat-header-cell *matHeaderCellDef>{{'PREVIEW_PYROMETER' | translate}}</th>
            <td mat-cell *matCellDef="let measure">
              <span *ngIf="measure.pyrometer">{{'YES' | translate}}</span>
              <span *ngIf="!measure.pyrometer">{{'NO' | translate}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>{{'PREVIEW_TIMESTAMP' | translate}}</th>
            <td mat-cell *matCellDef="let measure">{{measure.timestamp | date : 'yyyy-MM-dd HH:mm:ss'}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </ng-template>

    <mat-card-footer class="footer">
      <span>
        {{this.answerService.getAnomalyCount()}} {{'RONDE_ANOMALIES' | translate}}
      </span>
      <span>
        <button class="button" mat-raised-button *ngIf="displayTemplate===false && !pycoso  "
                [style.display]="!displaySignature ? '': 'none'"
                (click)="exportPdf(this.answerService.getInstance().instance?.id!,
        this.answerService.getInstance().instance?.modificationDate!)">
        {{ 'EXPORT_PDF_RONDE' | translate}}
      </button>
      <button class="button" mat-raised-button *ngIf="displayTemplate===false  "

              (click)="exportOneExcel(this.answerService.getInstance().instance?.id!)">
      {{ 'EXPORT_RONDE' | translate}}
    </button>

         <button class="button" mat-raised-button
                 [style.display]="displaySignature ? '': 'none'"
                 (click)="confirmValidation( )">
      {{ 'VALIDATE_INSTANCE_BUTTON' | translate}}
    </button>
      </span>

    </mat-card-footer>
  </mat-card>


</ng-template>


<ng-template #pdfDialog>
  <h2 matDialogTitle translate>PDF_GENERATE</h2>
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>
<ng-template #confirmDialog>
  <al-confirm-dialog (confirm)='validate()'>
    <div title translate>CONFIRM_ROUND</div>
    <div content translate>AGREE_TO_CONFIRM_ROUND</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>
<ng-template #excelDialog>
  <h2 matDialogTitle translate>EXCEL_GENERATE</h2>
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>

<ng-template #exportPDFDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_PDF_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_PDF_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #exportExcelDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_EXCEL_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_EXCEL_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>
