import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Pyrometre } from '@al/model';

export interface PyrometreState extends EntityState<Pyrometre>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'pyrometre', idKey: 'id', resettable: true })
export class PyrometreStore extends EntityStore<PyrometreState> {
  public constructor() {
    super();
  }
}
