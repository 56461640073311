import { JsonObject, JsonProperty } from 'json2typescript';
import { BooleanConverter } from './converter/boolean.converter';
import { ModelAbstract } from './model.abstract';

@JsonObject('Unit')
export class Unit extends ModelAbstract {
  @JsonProperty('abbreviation', String)
  public abbreviation: string | null = null;

  @JsonProperty('description', String)
  public description: string | null = null;

  @JsonProperty('duplicate', BooleanConverter)
  public duplicate: boolean = false;

  @JsonProperty('label', String)
  public label: string | null = null;

  @JsonProperty('uuid', String)
  public uuid: string | null = null;
}
