import { EquipmentState, EquipmentsStore } from '../store/equipment.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class EquipmentsQuery extends QueryEntity<EquipmentState> {
  public productionUnit = this.selectAll();

  public constructor(protected override store: EquipmentsStore) {
    super(store);
  }
}
