<mat-card class="al-template-frequency" [formGroup]="formGroup">
  <mat-card-header>
    <mat-card-title translate>FREQUENCY</mat-card-title>
  </mat-card-header>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label class="required-field">{{
      "FREQUENCY_TYPE" | translate
      }}</mat-label>
    <mat-select
      [formControlName]="formControlNames.type"
      (selectionChange)="setValue(formControlNames.type)"
    >
      <mat-option
        *ngFor="let frequency of frequencyTypeList"
        [value]="frequency"
      >
        {{ frequency | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="this.frequency.type === this.frequencyTypeEnum.NONE">
    {{ "NO_FREQUENCY" | translate }}
  </div>
  <div *ngIf="this.frequency.type !== this.frequencyTypeEnum.NONE">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field"
      >{{ "START_DATE" | translate }}
      </mat-label>
      <input
        type="datetime-local"
        ng-model-options="{timezone : 'utc'}"
        [formControlName]="formControlNames.startTime"
        (change)="setValue(formControlNames.startTime)"
        matInput
      />
    </mat-form-field>
  </div>

  <div *ngIf="this.frequency.type === this.frequencyTypeEnum.HOURLY">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field"
      >{{ "HOURS_DELAY" | translate }}
      </mat-label>
      <input
        type="number"
        [formControlName]="formControlNames.period"
        matInput
        min="1"
        (change)="setValue(formControlNames.period)"
      />
    </mat-form-field>
  </div>

  <div *ngIf="this.frequency.type === this.frequencyTypeEnum.MONTHLY_DAY">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field"
      >{{ "OCCURENCE" | translate }}
      </mat-label>
      <mat-select
        [formControlName]="formControlNames.occurence"
        (selectionChange)="setValue(formControlNames.occurence)"
        [compareWith]="compareFrequencyOccurence"
      >
        <mat-option
          *ngFor="let occurence of frequencyOccurenceList"
          [value]="occurence"
        >
          {{ occurence.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="this.frequency.type === this.frequencyTypeEnum.MONTHLY_DAY">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field"
      >{{ "DAY_OF_THE_WEEK" | translate }}
      </mat-label>
      <mat-select
        [formControlName]="formControlNames.dayOfTheWeek"
        (selectionChange)="setValue(formControlNames.dayOfTheWeek)"
        [compareWith]="compareDays"
      >
        <mat-option *ngFor="let day of daysList" [value]="day">
          {{ day.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card>

<div style="display: none">
  <!-- Trad -->
  <span translate>FIRST</span>
  <span translate>SECOND</span>
  <span translate>THIRD</span>
  <span translate>FOURTH</span>
  <span translate>FIFTH</span>
  <span translate>NONE</span>
  <span translate>HOURLY</span>
  <span translate>DAILY</span>
  <span translate>WEEKLY</span>
  <span translate>MONTHLY_DATE</span>
  <span translate>CUSTOM</span>

  <span translate>MONDAY</span>
  <span translate>TUESDAY</span>
  <span translate>WEDNESDAY</span>
  <span translate>THURSDAY</span>
  <span translate>FRIDAY</span>
  <span translate>SATURDAY</span>
  <span translate>SUNDAY</span>
</div>
