import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, map, startWith, takeUntil } from 'rxjs';
import { Profile, Site, User } from '@al/model';
import { SiteService, UsersService } from '@al/services';
import { FormControlNames } from '../form-control-names.enum';
import { GenericFormComponent } from '@al/generic-components';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@al/navigation';
import { Paths } from '@al/angular-route';
import { ReferentielService } from '@al/referentiel';
import { TranslateService } from '@ngx-translate/core';
import { UserLanguageKey } from '../language-key.enum';

@Component({
  selector: 'al-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
})
export class UsersFormComponent
  extends GenericFormComponent<User>
  implements OnInit
{
  public allSelectedSites: string[] = [];

  public filteredOptions!: Observable<Site[]>;

  public formControlNames = FormControlNames;

  public profiles: Profile[] = [];

  public searchTextboxControl = new FormControl();

  public selectFormControl = new FormControl();

  public selectedProfile?: string;

  public selectedSites: string[] = [];

  public sites: Site[] = [];

  public user: User = new User();

  public constructor(
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected userService: UsersService,
    protected siteService: SiteService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      userService,
      translateService,
      router,
      route
    );
    this.creation = route.snapshot.url.join('/').endsWith(Paths.users.CREATE);
    this.form = this.buildFormGroup(this.user);
    let referentielService = new ReferentielService();
    this.profiles = referentielService.templateProfile;
  }

  public buildFormGroup(user: User): FormGroup {
    return this.formBuilder.group({
      email: this.formBuilder.control(user.email, [
        Validators.required,
        Validators.email,
      ]),
      firstName: this.formBuilder.control(user.firstName, [
        Validators.required,
      ]),
      lastName: this.formBuilder.control(user.lastName, [Validators.required]),
      profileType: this.formBuilder.control(user.profileType, [
        Validators.required,
      ]),
      sites: this.formBuilder.control(user.sites, [Validators.required]),
      status: this.formBuilder.control(user.status, []),
      supervisor: this.formBuilder.control(user.supervisor, []),
    });
  }

  public clearSearch(event: Event | void) {
    if (event) {
      event.stopPropagation();
    }
    this.searchTextboxControl.patchValue('');
  }

  public doSomething(): void {}

  public getEntityToSave(): User {
    const user = new User();
    user.email = this.form.get(FormControlNames.email.valueOf())?.value;
    user.firstName = this.form.get(FormControlNames.firstName.valueOf())?.value;
    user.lastName = this.form.get(FormControlNames.lastName.valueOf())?.value;
    user.supervisor = this.form.get(
      FormControlNames.supervisor.valueOf()
    )?.value;
    user.profileType = this.profiles.find(
      (profile: Profile) => profile.id?.toString() === this.selectedProfile
    );
    user.status = this.form.get(FormControlNames.status.valueOf())?.value;
    user.sites = this.sites.filter((site: Site) =>
      this.selectedSites.includes(site.id.toString())
    );
    user.sharedQueryPartition = this.user.sharedQueryPartition;
    user.id = this.user.id;

    return user;
  }

  public initFormGroup(user: User) {
    this.form.reset();
    this.setFormValue(FormControlNames.email.valueOf(), user.email);
    this.setFormValue(FormControlNames.firstName.valueOf(), user.firstName);
    this.setFormValue(FormControlNames.lastName.valueOf(), user.lastName);
    this.setFormValue(FormControlNames.profileType.valueOf(), user.profileType);
    this.setFormValue(FormControlNames.status.valueOf(), user.status);
    this.setFormValue(FormControlNames.sites.valueOf(), user.sites);
    this.setFormValue(FormControlNames.supervisor.valueOf(), user.supervisor);
  }

  public initModel(): User {
    return new User();
  }

  public ngOnInit(): void {
    if (!this.creation) {
      this.userService
        .getActive()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((user: User | undefined) => {
          if (user) {
            this.user = user;
            this.allSelectedSites = user.sites.map((site: Site) => {
              return site.id;
            });

            this.form = this.buildFormGroup(user);
            this.setFormValue(
              FormControlNames.sites.valueOf(),
              this.user.sites
            );
            if (this.user.profileType) {
              this.selectedProfile = this.user.profileType.id?.toString();
            }
            if (this.user.sites) {
              this.selectedSites = this.user.sites.map((site: Site) =>
                site.id.toString()
              );
            }
            this.form.get('sites')?.setValue(this.selectedSites);
            this.form.get('profileType')?.setValue(this.selectedProfile);
          }
        });
    }

    this.siteService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((sites: Site[] | undefined) => {
        if (sites) {
          this.sites = sites;
          this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
            startWith<string>(''),
            map((name) => this._filter(name))
          );
        }
      });

    this.setDialogTitle(UserLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE);
    this.setDialogTextValue(UserLanguageKey.INFO_DIALOG_MESSAGE_OK);
  }

  //
  // public openDialogWithRef(ref: TemplateRef<any>) {
  //   this.dialog.open(ref);
  // }
  //
  // public previous(): void {
  //   this.userService.activePrevious();
  // }

  public save(): void {
    if (this.form.valid) {
      if (!this.creation) {
        this.userService
          .save(this.getEntityToSave())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.setDialogTitle(UserLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE);
              this.setDialogTextValue(UserLanguageKey.INFO_DIALOG_MESSAGE_OK);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
            error: () => {
              this.setDialogTitle(UserLanguageKey.INFO_DIALOG_MESSAGE_KO_TITLE);
              this.setDialogTextValue(UserLanguageKey.INFO_DIALOG_MESSAGE_KO);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
          });
      } else {
        this.userService
          .create(this.getEntityToSave())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.setDialogTitle(UserLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE);
              this.setDialogTextValue(UserLanguageKey.INFO_DIALOG_MESSAGE_OK);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
              this.router.navigate([`${Paths.users.EDIT}`], {
                relativeTo: this.route.parent,
              });
            },
            error: (error) => {
              console.error(error);
              this.setDialogTitle(UserLanguageKey.INFO_DIALOG_MESSAGE_KO_TITLE);
              this.setDialogTextValue(UserLanguageKey.INFO_DIALOG_MESSAGE_KO);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
          });
      }
    }
  }

  //
  // public setDialogTextValue(key: string): void {
  //   this.translateService
  //     .get(key)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((value: string) => {
  //       this.infoDialogMessage = value;
  //     });
  // }
  //
  // public setDialogTitle(key: string): void {
  //   this.translateService
  //     .get(key)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((value: string) => {
  //       this.infoDialogTitle = value;
  //     });
  // }

  public setAllSelectedSites(currentSelection: string[]) {
    let test = this.allSelectedSites.filter((value: string) => {
      return currentSelection.includes(value) === false;
    });

    if (test.length === 1 && this.allSelectedSites.includes(test[0])) {
      this.allSelectedSites.splice(this.allSelectedSites.indexOf(test[0]), 1);
    } else {
      this.allSelectedSites = [...test, ...currentSelection];
    }
  }

  public setFormDirty() {
    this.form.markAsDirty();
    this.setAllSelectedSites(this.selectedSites);
    this.selectedSites = this.allSelectedSites;

    this.form.get('sites')?.setValue(this.allSelectedSites);
    this.form.get('profileType')?.setValue(this.selectedProfile);
  }

  private _filter(name: string): Site[] {
    const filterValue = name.toLowerCase();

    let filteredList = this.sites.filter((option) =>
      option.maximoSiteId.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }
}
