<div class="al-template-list">
  <div class="header">
    <h1 translate class="parent-component-title">RONDE_LIST_TITLE</h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->

    <!-- <mat-form-field appearance="outline" class="full-width date-input" floatLabel="always">
      <mat-label class="required-field">{{ "RONDE_START_DATE" | translate}}
      </mat-label>
      <input type="date" (change)="startDateChange()" [formControl]="startDate" matInput>
    </mat-form-field> -->

    <mat-form-field appearance="outline" class="full-width date-input" floatLabel="always">
      <mat-label class="required-field">{{ "RONDE_START_DATE" | translate}}
      </mat-label>
      <input matInput [matDatepicker]="dp" (dateChange)="startDateChange()" [formControl]="startDate">
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width date-input" floatLabel="always">
      <mat-label class="required-field">{{ "RONDE_END_DATE" | translate}}
      </mat-label>
      <input matInput [matDatepicker]="picker" (dateChange)="endDateChange()" [formControl]="endDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

    </mat-form-field>


  </div>

  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.id.valueOf())"
          (OnSearch)="filterId($event,formControlNames.id.valueOf())"
          [field]="formControlNames.id.valueOf()"
          fieldType="input"
          storageNameSpace="ronde-list-filters"
          title="{{'RONDE_ID' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'RONDE_ID' | translate }}" class="id">
        {{ element.id }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef class="category">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.category.valueOf())"
          (OnSearch)="filterCategory($event,formControlNames.category.valueOf())"
          [field]="formControlNames.category.valueOf()"
          [values]="categories"
          [isSelect]="true"
          fieldType="input"
          storageNameSpace="ronde-list-filters"
          title="{{'CATEGORY' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'CATEGORY' | translate }}" class="category">
        {{ element.instance.category.label | translate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef class="title">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.title.valueOf())"
          (OnSearch)="filterTitle($event,formControlNames.title.valueOf())"
          [field]="formControlNames.title.valueOf()"
          fieldType="input"
          storageNameSpace="ronde-list-filters"
          title="{{'RONDE_TITLE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'RONDE_TITLE' | translate }}" class="title">
        {{ element.instance.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Validated By">
      <mat-header-cell *matHeaderCellDef class="validated">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.validated.valueOf())"
          (OnSearch)="filterValidator($event,formControlNames.validated.valueOf())"
          [field]="formControlNames.validated.valueOf()"
          fieldType="input"
          storageNameSpace="ronde-list-filters"
          title="{{'RONDE_VALIDATED_BY' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'RONDE_VALIDATED_BY' | translate }}" class="validated">
        {{ element.instance.ownerName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef class="location">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.location.valueOf())"
          (OnSearch)="filterLocation($event,formControlNames.location.valueOf())"
          [field]="formControlNames.location.valueOf()"
          fieldType="input"
          storageNameSpace="ronde-list-filters"
          title="{{'RONDE_LOCATION' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'RONDE_LOCATION' | translate }}" class="location">
        {{ element.instance.location }}
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="Anomalies">
      <mat-header-cell *matHeaderCellDef class="anomalies">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.anomalies.valueOf())"
          (OnSearch)="filterAnomalies($event,formControlNames.anomalies.valueOf())"
          [field]="formControlNames.anomalies.valueOf()"
          [values]="anomalies"
          [isSelect]="true"
          fieldType="select"
          storageNameSpace="ronde-list-filters"
          title="{{'RONDE_ANOMALIES' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'RONDE_ANOMALIES' | translate }}" class="anomalies">
        <p *ngIf="element.instance.hasAnomaly">   {{'ONE_ANOMALY' | translate }}</p>
        <p *ngIf="!element.instance.hasAnomaly">  {{'NO_ANOMALY' | translate }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Validation date">
      <mat-header-cell *matHeaderCellDef class="date">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.date.valueOf())"
          (OnSearch)="filterDate($event,formControlNames.date.valueOf())"
          [field]="formControlNames.date.valueOf()"
          fieldType="date"
          storageNameSpace="ronde-list-filters"
          title="{{'RONDE_DATE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'RONDE_DATE' | translate }}" class="date">
        {{ element.instance.modificationDate | localizedDate}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Status">
      <mat-header-cell *matHeaderCellDef class="maximo-site-id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.status.valueOf())"
          (OnSearch)="filterStatus($event,formControlNames.status.valueOf())"
          [field]="formControlNames.status.valueOf()"
          [values]="status"
          [isSelect]="true"
          fieldType="input"
          storageNameSpace="ronde-list-filters"
          title="{{'RONDE_STATUS' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'RONDE_STATUS' | translate }}" class="maximo-site-id">
        {{ element.instance.status.label | translate }}
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="actions" translate>
        <button mat-raised-button (click)="exportAll()"
                *ngIf="!this.isConnectedWithUserPool" color="primary">
          {{ "EXPORT_DATA" | translate}}
        </button>
        <!--        <button mat-raised-button (click)="exportAll(true)"-->
        <!--                *ngIf="getTypeExport()==='PYCO'  && !this.isConnectedWithUserPool" color="primary">-->
        <!--          {{ "EXPORT_DATA" | translate}}-->
        <!--        </button>-->
        <!--        <button mat-raised-button (click)="exportAll(false)"-->
        <!--                *ngIf="getTypeExport()==='NONE'  && !this.isConnectedWithUserPool" disabled color="primary">-->
        <!--          {{ "EXPORT_DATA" | translate}}-->
        <!--        </button>-->
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="" class="actions">
        <!--        <mat-icon (click)="edit(element.id)"-->
        <!--        [matTooltip]="'SEE_RONDE' | translate"-->
        <!--        matTooltipPosition="above" appearance="outline" floatLabel="always"-->
        <!--        >preview</mat-icon>-->
        <al-preview-component [isButton]="false" [signedOn]="false" [instance]="element"
                              [displayTemplate]="false"></al-preview-component>


        <button mat-icon-button
                (click)="exportOneExcel(element.id)"
                [matTooltip]="'EXPORT_RONDE' | translate"
                matTooltipPosition="above" floatLabel="always">
          <mat-icon appearance="outline" class="material-icons-outlined"

          >file_upload
          </mat-icon>
        </button>

        <button mat-icon-button *ngIf="element.instance.category?.id !== '7'"
                (click)="exportPdf(element.id, element.instance.modificationDate)"

                [matTooltip]="'EXPORT_PDF_RONDE' | translate"
                matTooltipPosition="above">
          <mat-icon floatLabel="always" appearance="outline"
                    class="material-icons-outlined"
          >picture_as_pdf_outline
          </mat-icon>
        </button>


        <button mat-icon-button *ngIf="element.instance.category?.id !== '7' && !this.isConnectedWithUserPool"
                (click)="uploadToDrive(element)"
                [matTooltip]="'UPLOAD_DRIVE_RONDE' | translate"
                matTooltipPosition="above">
          <mat-icon appearance="outline" floatLabel="always"
                    class="material-icons-outlined"
          >cloud_upload
          </mat-icon>
        </button>
        <al-preview-component *ngIf="displaySignature(element)" [signedOn]="true" [isButton]="false"
                              [instance]="element"
                              [displayTemplate]="false"></al-preview-component>
        <button mat-icon-button *ngIf="displayDelete(element)" (click)="openDialogWithRef(deleteDialog, element)"

                [matTooltip]="'DELETE_RONDE' | translate"
                matTooltipPosition="above">
          <mat-icon appearance="outline"
                    floatLabel="always"
                    class="material-icons-outlined"
          >delete
          </mat-icon>
        </button>
      </mat-cell>

    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: displayedColumns"

    ></mat-row>
  </mat-table>

  <mat-paginator #paginator [pageSize]="15" [pageSizeOptions]="[15, 25, 50,  100]"></mat-paginator>

</div>

<ng-template #deleteDialog let-data>
  <al-confirm-dialog (confirm)='deleteRonde(data)'>
    <div title translate>DELETE</div>
    <div content translate>AGREE_TO_DELETE</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #exportAllDialog>
  <mat-dialog-content>
    <p>{{"EXPORT_OF" | translate}} {{dataSource.data.length}} {{"RECIEVE_MAIL" | translate}} </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">
      <mat-icon class="inline-icon">clear</mat-icon>
    </button>
  </mat-dialog-actions>

</ng-template>


<ng-template #pdfDialog>
  <h2 matDialogTitle translate>PDF_GENERATE</h2>
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>

<ng-template #excelDialog>
  <h2 matDialogTitle translate>EXCEL_GENERATE</h2>
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>

<ng-template #exportPDFDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_PDF_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_PDF_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #exportExcelDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_EXCEL_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_EXCEL_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #exportAllDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_ALL_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_ALL_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogKo>
  <al-confirm-dialog>
    <div title translate>DELETE_DIALOG_MESSAGE_KO_TITLE_ROUND</div>
    <div content translate>DELETE_DIALOG_MESSAGE_KO_ROUND</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogOk>
  <al-confirm-dialog>
    <div title translate>DELETE_DIALOG_MESSAGE_OK_TITLE_ROUND</div>
    <div content translate>DELETE_DIALOG_MESSAGE_OK_ROUND</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>
