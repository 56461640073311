import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component } from '@angular/core';
import { FormControlNames } from '../form-control-names.enum';
import { GenericListComponent } from '@al/generic-components';
import { Site } from '@al/model';
import { SiteFilterService } from '../service/site-filter.service';
import { SiteService } from '@al/services';

@Component({
  selector: 'al-site-component',
  templateUrl: 'site-list.component.html',
  styleUrls: ['site-list.component.scss'],
}) /*, OnInit*/ /*, OnDestroy*/
export class SiteListComponent
  extends GenericListComponent<Site>
  implements AfterViewInit
{
  public formControlNames = FormControlNames;

  public constructor(
    protected override akitaFilterService: SiteFilterService, //
    protected siteService: SiteService, //
    protected override router: Router,
    protected override route: ActivatedRoute
  ) {
    super(akitaFilterService, siteService, router, route);
  }

  public filterId($event: number, field: string): void {
    this.addFilter(field, $event, (value: Site) =>
      value.id.toLowerCase().includes($event.toString())
    );
  }

  public filterLabel($event: any, field: string): void {
    this.addFilter(field, $event, (value: Site) =>
      value.label.toLowerCase().includes($event.toLowerCase())
    );
  }

  public filterMaximoSiteId($event: any, field: string): void {
    this.addFilter(field, $event, (value: Site) =>
      value.maximoSiteId.toLowerCase().includes($event.toLowerCase())
    );
  }

  protected initColumnDisplay(): string[] {
    return [
      FormControlNames.id.valueOf(),
      FormControlNames.maximoSiteId.valueOf(),
      FormControlNames.label.valueOf(),
      'actions',
    ];
  }
}
