import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { MeasureWidget } from './measure.widget';

@JsonObject('WheelPicker')
export class WheelPickerWidget extends MeasureWidget {
  @JsonProperty('decimalIndex', Number, true)
  public decimalIndex: number | null = null;

  @JsonProperty('maxRange', Number, true)
  public maxRange: number | null = null;

  @JsonProperty('minRange', Number, true)
  public minRange: number | null = null;

  @JsonProperty('nominalValue', Number, true)
  public nominalValue: number | null = null;

  public constructor() {
    super();
    this.componentType = ComponentType.WHEEL_PICKER;
  }
}
