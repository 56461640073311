import { AkitaFilterService, HistoryQuery, HistoryState } from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { HistoryItem } from '@al/model';
import { Injectable } from '@angular/core';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class HistoryFilterService extends AkitaFilterService<
  HistoryItem,
  HistoryState
> {
  public constructor(protected query: HistoryQuery) {
    super(new AkitaFiltersPlugin<HistoryState>(query));
  }
}
