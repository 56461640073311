import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../../model.abstract';
import { RondeGate } from './ronde.gate.model';

@JsonObject('pycosoBody')
export class PycosoBody extends ModelAbstract {
  @JsonProperty('cardinal', String, true)
  public cardinal: string = '';

  @JsonProperty('comment', String, true)
  public comment: string = '';

  @JsonProperty('gates', [RondeGate], true)
  public gates: RondeGate[] = [];

  @JsonProperty('isHasAnomaly', Boolean, true)
  public hasAnomaly: boolean = false;

  @JsonProperty('pyrometer', String, true)
  public pyrometer: string = '';

  @JsonProperty('region', String, true)
  public region: string = '';

  @JsonProperty('site', String, true)
  public site: string = '';

  @JsonProperty('tubeCount', Number, true)
  public tubeCount: number = 0;
}
