import { Observable, Subject } from 'rxjs';
import { SchedulerItem, SchedulerItems } from '@al/model';
import { SchedulerQuery, SchedulerStore } from '@al/akita';
import { map, takeUntil } from 'rxjs/operators';
import { AlSpinnerService } from '@al/spinner';
import { GenericService } from './generic.service';
import { Injectable } from '@angular/core';
import { SchedulerRestService } from '@al/rest-services';
import { SiteService } from './site.service';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService extends GenericService<SchedulerItem> {
  private ngUnsubscribe = new Subject();

  public constructor(
    protected schedulerRestService: SchedulerRestService,
    protected schedulerQuery: SchedulerQuery,
    protected schedulerStore: SchedulerStore,
    protected siteService: SiteService,
    private alSpinnerService: AlSpinnerService
  ) {
    super(schedulerRestService, schedulerQuery, schedulerStore);
    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        const uuid = UUID.UUID();
        if (window.location.href.includes('scheduler')) {
          this.alSpinnerService.startDataProcess(uuid);
        }
        this.loadStore()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.alSpinnerService.stopDataProcess(uuid);
            },

            error: () => {
              this.alSpinnerService.stopDataProcess(uuid);
              this.alSpinnerService.launchSnackBar('ERR_LOAD_RONDE', 10);
            },
          });
      });
  }

  public loadStore(): Observable<void> {
    if (window.location.href.includes('scheduler')) {
      return this.schedulerRestService.getScheduling().pipe(
        takeUntil(this.ngUnsubscribe),
        map((data: SchedulerItems) => {
          this.schedulerStore.set(data.items);
        })
      );
    }
    return new Observable();
  }
}
