import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { ReferentielService } from '@al/referentiel';
import { Status } from '../status.model';

@JsonConverter
export class StatusConverter implements JsonCustomConvert<Status | null> {
  public deserialize(id: string | number): Status | null {
    const referentielService = new ReferentielService();

    return referentielService.getStatusFromId(id.toString());
  }

  public serialize(status: Status): string | null {
    if (status) {
      return status.id;
    }
    return null;
  }
}
