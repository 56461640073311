import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { LocalStorageKey } from '@al/local-cache';
import moment from 'moment-timezone';

@JsonConverter
export class DatetimeConverter implements JsonCustomConvert<Date | null> {
  public deserialize(datetime: number): Date | null {
    if (datetime) {
      let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
      if (timezone) {
        let value = moment(datetime).tz(timezone);
        return value.toDate();
      } else return new Date(datetime);
    }
    return null;
  }

  public serialize(date: Date): number | null {
    date = new Date(date);
    if (date instanceof Date) {
      return date.getTime();
    }
    return null;
  }
}
