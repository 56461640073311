import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { User } from '@al/model';

export interface UsersState extends EntityState<User>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'users', resettable: true })
export class UsersStore extends EntityStore<UsersState> {
  public constructor() {
    super();
  }
}
