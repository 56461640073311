import { BehaviorSubject, Observable } from 'rxjs';
import { IdTokenCognito } from './id-token';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  private email = new BehaviorSubject('no_email');

  private imageUrl = new BehaviorSubject('');

  private name = new BehaviorSubject('');
  //
  // private imageUrl: string = '';
  //
  // private name: string = '';

  public getEmail(): Observable<string> {
    return this.email.asObservable();
  }

  public getImageUrl(): Observable<string> {
    return this.imageUrl.asObservable();
  }

  public getName(): Observable<string> {
    return this.name.asObservable();
  }

  public loadIdToken(idToken: IdTokenCognito) {
    this.email.next(idToken.email);
    this.imageUrl.next(idToken.picture);

    // = idToken.email;
    // this.imageUrl = idToken.picture;
  }

  // public setEmail(email: string): void {
  //   this.email = email;
  // }
  //
  // public setImageUrl(imageUrl: string): void {
  //   this.imageUrl = imageUrl;
  // }
  //
  // public setName(name: string): void {
  //   this.name = name;
  // }
}
