import { EntityState, EntityStore, QueryEntity } from '@datorama/akita';
import { GenericRestService } from '@al/rest-services';
import { ModelAbstract } from '@al/model';
import { Observable } from 'rxjs';

export abstract class GenericService<MODEL extends ModelAbstract> {
  protected constructor(
    protected restService: GenericRestService,
    protected akitaQuery: QueryEntity<EntityState<MODEL>>,
    protected akitaStore: EntityStore
  ) {}

  public activeNext(): void {
    this.akitaStore.setActive({ next: true });
  }

  public activePrevious(): void {
    this.akitaStore.setActive({ prev: true });
  }

  public clearActive(): void {
    this.akitaStore.setActive(null);
    // this.akitaStore.setActive(undefined);
  }

  public get(): Observable<MODEL[]> {
    return this.akitaQuery.selectAll();
  }

  public getActive(): Observable<MODEL | undefined> {
    return this.akitaQuery.selectActive() as Observable<MODEL | undefined>;
  }

  public getActiveElement(): MODEL | undefined {
    return this.akitaQuery.getActive() as MODEL | undefined;
  }

  public getActives(): Observable<[MODEL] | undefined> {
    return this.akitaQuery.selectActive() as Observable<[MODEL] | undefined>;
  }

  public getById(id: number | string): MODEL | undefined {
    return this.akitaQuery.getEntity(id);
  }

  public setActive(id: string | number): void {
    this.akitaStore.setActive(id);
  }

  public setActives(ids: string[] | number[]): void {
    this.akitaStore.setActive(ids);
  }

  public abstract loadStore(): Observable<void>;
}
