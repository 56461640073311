import { AkitaFilterService, PyrometreQuery, PyrometreState } from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Injectable } from '@angular/core';
import { Pyrometre } from '@al/model';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class PyrometreFilterService extends AkitaFilterService<
  Pyrometre,
  PyrometreState
> {
  public constructor(protected query: PyrometreQuery) {
    super(new AkitaFiltersPlugin<PyrometreState>(query));
  }
}
