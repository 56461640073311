import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Gate, GateDirections, GateWidget, Tube, TubeFull } from '@al/model';
import { GateService, TemplateFullService } from '@al/services';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { DragAndDropService } from '@al/dnd-service';
import { FormGroupModelInterface } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { takeUntil } from 'rxjs/operators';
import { tubeValidator } from './validator/tube-validator.directive';

@Component({
  selector: 'al-gate-widget',
  templateUrl: './gate-widget.component.html',
  styleUrls: ['./gate-widget.component.scss'],
})
export class GateWidgetComponent
  extends TemplateWidgetComponent
  implements OnInit, FormGroupModelInterface<GateWidget>
{
  public availableGates: Gate[] = [];

  public formControlClass = FormControl;

  public gateDirections = GateDirections;

  public gateWidget: GateWidget = new GateWidget();

  public gates: Gate[] = [];

  public selectedTubes: TubeFull[] = [];

  public get tubeArray(): FormArray {
    return this.formGroup.get('tubes') as FormArray;
  }

  public constructor(
    protected override formBuilder: FormBuilder,
    protected dragAndDropService: DragAndDropService,
    protected templateFullService: TemplateFullService,
    protected gateService: GateService,
    protected override formGroupDirective: FormGroupDirective,
    protected override answerService: InstanceAnswerService
  ) {
    super(formBuilder, answerService, formGroupDirective);
    this.gateService.gatesSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((gates: Gate[]) => {
        this.gates = gates;
      });
  }

  public buildFormGroup(widget: GateWidget | null): FormGroup {
    return this.formBuilder.group(
      {
        gate: [
          this.gates.find((gate: Gate) => gate.id === this.gateWidget?.gateId),
          [Validators.required],
        ],
        firingOrder: [
          widget?.direction || GateDirections.ltr,
          [Validators.required],
        ],
        tubes: this.formBuilder.array([], []),
      },
      { validators: [tubeValidator()] }
    );
  }

  public compareTube(option: TubeFull, value: TubeFull): boolean {
    if (option !== null && value !== null) {
      return option.id === value.id;
    }
    return false;
  }

  public displayGate(gate: Gate) {
    return gate && gate.description ? gate.description : '';
  }

  public displayTube(tube: TubeFull) {
    return tube && tube.name ? tube.name : '';
  }

  public getEntityToSave(): GateWidget {
    return new GateWidget();
  }

  public getTubeName(index: number): string {
    return this.gateWidget.tube.at(index)?.name || '';
  }

  public initModel(): GateWidget {
    return new GateWidget();
  }

  public isGtCenter(index: number): boolean {
    const tube = this.gateWidget.tube.at(index);
    return !!tube && this.gateWidget.gtCenter === tube.id;
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.gateWidget = this.widget as GateWidget;

    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroup = this.buildFormGroup(this.gateWidget);
      const selectedGate = this.gates.find(
        (gate: Gate) => gate.id === this.gateWidget?.gateId
      );
      if (selectedGate) {
        this.initTubes(selectedGate);
      }
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);
    }

    this.gateService.reservedSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((reservedGates: Map<string, string>) => {
        this.availableGates = this.gates.filter(
          (gate: Gate) =>
            !reservedGates.has(gate.id) ||
            reservedGates.get(gate.id) === this.gateWidget.id
        );
      });
  }

  public onFiringOrderChange(direction: string): void {
    this.gateWidget.direction = direction;
    if (direction === GateDirections.gtOrder) {
      this.selectedTubes = this.gateWidget.tube.filter(
        (tube: TubeFull) => tube.shoot
      );
      // let gtCenter: string | undefined = undefined;
      if (this.gateWidget.gtCenter === undefined) {
        this.gateWidget.gtCenter = this.selectedTubes[0].id;
      }
      let gtCenterTube: Tube | undefined = undefined;
      if (this.selectedTubes.length > 0) {
        //   gtCenter = this.selectedTubes[0].id;
        //
        gtCenterTube = this.selectedTubes.filter(
          (tube) => tube.id == this.gateWidget.gtCenter
        )[0];
      }

      this.formGroup.addControl(
        'gtCenter',
        this.formBuilder.control(gtCenterTube, Validators.required)
      );
      // this.gateWidget.gtCenter = gtCenter;
    } else {
      this.formGroup.removeControl('gtCenter');
      // this.gateWidget.gtCenter = undefined;
    }
  }

  public onGateSelected(gate: Gate | undefined): void {
    if (gate) {
      this.flattenGate(gate);
      this.initTubes(gate);
      this.gateService.reserveGate(gate?.id || '', this.widget.id || '');
    } else {
      this.gateService.reserveGate('', this.widget.id || '');
    }
  }

  public onGtCenterChange(tube: TubeFull): void {
    this.gateWidget.gtCenter = tube?.id;
  }

  public onTubeToggle(i: number, checked: boolean): void {
    const toggledTube = this.gateWidget.tube.at(i);
    if (toggledTube) {
      toggledTube.shoot = checked;
      this.selectedTubes = this.gateWidget.tube.filter(
        (tube: TubeFull) => tube.shoot
      );
      if (
        !checked &&
        this.gateWidget.gtCenter &&
        this.gateWidget.gtCenter === toggledTube.id
      ) {
        this.gateWidget.gtCenter = undefined;
        this.formGroup.get('gtCenter')?.reset();
      }
    }
  }

  private flattenGate(gate: Gate) {
    this.gateWidget.label = gate?.description || '';
    this.gateWidget.gateId = gate?.id || '';
    this.gateWidget.called = gate?.asCalled || '';
    this.gateWidget.level = gate?.level || '';
    this.gateWidget.orientation = gate?.orientation || '';
    this.gateWidget.type = gate?.type || '';
  }

  private initTubes(gate: Gate) {
    this.gateWidget.tube = (gate?.tubes || []).map(
      (tube: Tube, index: number) =>
        TubeFull.from(tube, this.gateWidget.tube[index])
    );

    if (this.gateWidget.direction === GateDirections.gtOrder) {
      let gtCenterTube: Tube | undefined = undefined;
      this.selectedTubes = this.gateWidget.tube.filter(
        (tube: TubeFull) => tube.shoot
      );
      if (this.selectedTubes.length > 0) {
        //   gtCenter = this.selectedTubes[0].id;
        //
        gtCenterTube = this.selectedTubes.filter(
          (tube) => tube.id == this.gateWidget.gtCenter
        )[0];
      }
      // this.gateWidget.gtCenter = gtCenter;

      this.formGroup.addControl(
        'gtCenter',
        this.formBuilder.control(gtCenterTube, Validators.required)
        // this.formBuilder.control(this.selectedTubes[0], Validators.required)
      );
    }
    const tubesArray = this.formGroup.get('tubes') as FormArray;
    tubesArray.clear();
    this.gateWidget.tube.forEach((tube: TubeFull) => {
      tubesArray.push(this.formBuilder.control(tube.shoot, []));
    });
  }
}
