import { HistoryState, HistoryStore } from '../store/history.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class HistoryQuery extends QueryEntity<HistoryState> {
  public constructor(protected override store: HistoryStore) {
    super(store);
  }
}
