import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { AlJsonConvert } from '@al/json-converter';
import { ComponentType } from '../component-type.enum';
import { GateResponseWidget } from '../widget/gate.response.widget';
import { GateWidget } from '@al/model';
import { InfoWidget } from '../widget/info.widget';
import { McqWidget } from '../widget/mcq.widget';
import { MeasureWidget } from '../widget/measure.widget';
import { NoteWidget } from '../widget/note.widget';
import { SectionWidget } from '../widget/section.widget';
import { ValidationWidget } from '../widget/validation.widget';
import { WheelPickerWidget } from '../widget/wheel-picker.widget';
import { Widget } from '../widget/widget.model';

// to avoid circular dependency the SectionWidget is hand managed

@JsonConverter
export class WidgetArrayConverter implements JsonCustomConvert<Array<Widget>> {
  public deserialize(jsonObjects: object[]): Array<Widget> {
    const alJsonConvert = new AlJsonConvert();

    let widgetList: Array<Widget> = new Array<Widget>();
    jsonObjects.forEach((obj: object) => {
      let widget: Widget = alJsonConvert.deserialize(obj, Widget) as Widget;
      let widgetFound = this.convertToSpecificWidget(
        obj,
        widget.componentType,
        alJsonConvert
      );
      if (widgetFound) {
        widgetList.push(widgetFound);
      } else if (widget.componentType === ComponentType.SECTION) {
        // to avoid circular dependency the SectionWidget is hand managed
        let sectionWidget: SectionWidget = alJsonConvert.deserialize(
          obj,
          SectionWidget
        ) as SectionWidget;

        if (obj.hasOwnProperty('children')) {
          // @ts-ignore
          obj.children.forEach((child: object) => {
            let childrenWidget: Widget = alJsonConvert.deserialize(
              child,
              Widget
            ) as Widget;
            let specificChildrenWidget = this.convertToSpecificWidget(
              child,
              childrenWidget.componentType,
              alJsonConvert
            );
            if (specificChildrenWidget) {
              sectionWidget.children.push(specificChildrenWidget);
            }
          });
        }
        widgetList.push(sectionWidget);
      }
    });
    return widgetList;
  }

  public serialize(widgets: Array<Widget>): object[] | null {
    const alJsonConvert = new AlJsonConvert();
    const jsonChildren: object[] = [];
    if (widgets) {
      widgets.forEach((widget: Widget) => {
        // if (widget.componentType == ComponentType.GATE) {}
        // else
        if (widget.componentType == ComponentType.SECTION) {
          let sectionWidget = widget as SectionWidget;
          let sectionJson = alJsonConvert.serialize(
            sectionWidget,
            SectionWidget
          );
          // to avoid circular dependency the SectionWidget is hand managed
          sectionJson.children = [];
          sectionWidget.children.forEach((child: Widget) => {
            sectionJson.children.push(this.convertToJson(child, alJsonConvert));
          });
          jsonChildren.push(sectionJson);
        } else {
          jsonChildren.push(this.convertToJson(widget, alJsonConvert));
        }
      });

      return jsonChildren;
    }
    return null;
  }

  private convertToJson(widget: Widget, alJsonConvert: AlJsonConvert): object {
    if (widget.componentType == ComponentType.TEXT) {
      return alJsonConvert.serialize(widget, InfoWidget);
    } else if (widget.componentType == ComponentType.NOTE) {
      return alJsonConvert.serialize(widget, NoteWidget);
    } else if (widget.componentType == ComponentType.WHEEL_PICKER) {
      return alJsonConvert.serialize(widget, WheelPickerWidget);
    } else if (widget.componentType == ComponentType.MEASURE) {
      return alJsonConvert.serialize(widget, MeasureWidget);
    } else if (widget.componentType == ComponentType.MULTI_CHECKBOX) {
      return alJsonConvert.serialize(widget, McqWidget);
    } else if (widget.componentType == ComponentType.CHECKBOX) {
      return alJsonConvert.serialize(widget, ValidationWidget);
    } else if (widget.componentType === ComponentType.GATE) {
      let gateConfig = new GateResponseWidget();
      gateConfig.copy(widget as GateWidget);
      return alJsonConvert.serialize(gateConfig, GateResponseWidget);
    }

    return {};
  }

  private convertToSpecificWidget(
    source: object,
    componentType: ComponentType | null,
    alJsonConvert: AlJsonConvert
  ): Widget | null {
    if (componentType === ComponentType.CHECKBOX) {
      return alJsonConvert.deserialize(
        source,
        ValidationWidget
      ) as ValidationWidget;
    }

    if (componentType === ComponentType.MEASURE) {
      return alJsonConvert.deserialize(source, MeasureWidget) as MeasureWidget;
    }

    if (componentType === ComponentType.MULTI_CHECKBOX) {
      return alJsonConvert.deserialize(source, McqWidget) as McqWidget;
    }

    if (componentType === ComponentType.NOTE) {
      return alJsonConvert.deserialize(source, NoteWidget) as NoteWidget;
    }

    if (componentType === ComponentType.TEXT) {
      return alJsonConvert.deserialize(source, InfoWidget) as InfoWidget;
    }
    if (componentType === ComponentType.WHEEL_PICKER) {
      return alJsonConvert.deserialize(
        source,
        WheelPickerWidget
      ) as WheelPickerWidget;
    }
    if (componentType === ComponentType.GATE) {
      let gateConfig = alJsonConvert.deserialize(
        source,
        GateResponseWidget
      ) as GateResponseWidget;
      let gateWidget = new GateWidget();
      gateWidget.copy(gateConfig);
      return gateWidget;
    }
    return null;
  }
}
