import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { FurnaceFull } from '@al/model';
import { Injectable } from '@angular/core';

export interface FurnaceFullState
  extends EntityState<FurnaceFull>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'furnace-full', idKey: 'id', resettable: true })
export class FurnaceFullStore extends EntityStore<FurnaceFullState> {
  public constructor() {
    super();
  }
}
