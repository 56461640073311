import { JsonObject, JsonProperty } from 'json2typescript';
import { FurnaceLight } from './furnace-light.model';
import { Gate } from './gate.model';

@JsonObject('FurnaceFull')
export class FurnaceFull extends FurnaceLight {
  @JsonProperty('furnace_MOT', Number, true)
  public MOT: number = 0;

  @JsonProperty('furnace_VHT', Number, true)
  public VHT: number = 0;

  @JsonProperty('gates', [Gate], true)
  public gates: Gate[] = [];

  @JsonProperty('furnace_minMeasureSequenceCount', Number, true)
  public minMeasureSequenceCount: number = 0;

  @JsonProperty('furnace_MinOT', Number, true)
  public minOT: number = 0;

  @JsonProperty('scada_prefix', String, true)
  public scadaPrefix: string = '';

  @JsonProperty('scada_server', String, true)
  public scadaServer: string = '';

  @JsonProperty('furnace_type', String, true)
  public type: string = '';

  @JsonProperty('furnace_unstableDeltaTemperature', Number, true)
  public unstableDeltaTemperature: number = 0;

  @JsonProperty('furnace_unstableRetryPolicyCount', Number, true)
  public unstableRetryPolicyCount: number = 0;
}
