import { JsonObject, JsonProperty } from 'json2typescript';
import { BooleanConverter } from '../converter/boolean.converter';
import { ModelAbstract } from '../model.abstract';

@JsonObject('Equipment')
export class Equipment extends ModelAbstract {
  @JsonProperty('description', String, true)
  public description: string | null = null;

  @JsonProperty('eiq', Boolean, true)
  public eiq: boolean = false;

  @JsonProperty('eis', Boolean, true)
  public eis: boolean = false;

  @JsonProperty('id', String, true)
  public id: string | null = null;

  @JsonProperty('manufacturer', String, true)
  public manufacturer: string | null = null;

  @JsonProperty('model', String, true)
  public model: string | null = null;

  @JsonProperty('number', String, true)
  public number: string | null = null;

  @JsonProperty('o2Cleaning', BooleanConverter, true)
  public o2Cleaning: boolean = false;

  @JsonProperty('originalEquipmentManufacturer', String, true)
  public originalEquipmentManufacturer: string | null = null;

  @JsonProperty('parentId', String, true)
  public parentId: string | null = null;

  @JsonProperty('processGroupId', String, true)
  public processGroupId: string | null = null;

  @JsonProperty('processTag', String, true)
  public processTag: string | null = null;

  @JsonProperty('serialNumber', String, true)
  public serialNumber: string | null = null;

  @JsonProperty('status', String, true)
  public status: string | null = null;

  @JsonProperty('type', String, true)
  public type: string | null = null;

  @JsonProperty('vendor', String, true)
  public vendor: string | null = null;

  @JsonProperty('yearOfManufacturing', String, true)
  public yearOfManufacturing: string | null = null;
}
