<div class="al-history-list">
  <div class="header">
    <h1 class="parent-component-title">
      {{ 'TEMPLATE_HISTORIES' | translate}}
    </h1>

    <span>
           <button mat-raised-button (click)="back()">
        <mat-icon class="back">keyboard_arrow_left</mat-icon>
             {{ "BACK" | translate }}
      </button>


           <button [matTooltip]="'EXPORT_PDF_GMP'  | translate"
                   mat-mini-fab color="primary"
                   (click)="exportPdf()">
        <mat-icon class="back">picture_as_pdf</mat-icon>
       </button>
    </span>
  </div>

  <div *ngFor=" let template of templates">
    <al-history [templateId]="template.id" [embedded]="true"></al-history>
  </div>


</div>
i
