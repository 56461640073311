import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SiteService, UserService } from '@al/services';
import { LocalStorageKey } from '@al/local-cache';
import { Site } from '@al/model';
import { Subject } from 'rxjs';
import { UserConnectedQuery } from '@al/akita';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss'],
})
export class SiteSelectorComponent implements OnInit, OnDestroy {
  @Input()
  public enabled: boolean = true;

  @Input()
  public isSelectable: boolean = true;

  public items: Site[] = [];

  public get selectedItemName(): string {
    return this.selectedItem ? this.selectedItem.label : '';
  }

  public selectedItem!: Site | undefined;

  public selectedItemUuid: string | null = null;

  private ngUnsubscribe = new Subject();

  public constructor(
    protected userConnectedQuery: UserConnectedQuery,
    protected userService: UserService,
    protected siteService: SiteService
  ) {
    this.enabled = true;
    this.isSelectable = true;
    this.selectedItemUuid = null;
  }

  public compareSite(option: Site, value: Site): boolean {
    if (option !== null && value !== null) {
      return option.id === value.id;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    // bind to get site from user profiles
    this.userService
      .getCurrentObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.siteService
            .getUserSite()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((sites: Site[]) => {
              this.items = sites;
              this.onValueChange(this.items[0].id);
            });
          // this.siteService.getUserSite(user).
          // pipe(takeUntil(this.ngUnsubscribe)).subscribe((sites: Site[]));
          // user?.sites.forEach((site: Site) => {
          //   let siteResult: Site | undefined = this.siteService.getById(
          //     site.id
          //   );
          //   if (siteResult) {
          //     this.items.push(siteResult);
          //   }
          // });
          // this.setDefault();
        },
        error: () => {},
      });
    //bind to load current site
    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((site: Site) => {
        this.selectedItem = site;
        this.selectedItemUuid = site.id;
      });
    this.selectedItem = this.siteService.getCurrentSite();
    this.selectedItemUuid = this.selectedItem?.id!;
  }

  public onValueChange(id: string): void {
    const sites = this.getSiteById(id);
    if (sites.length == 1) {
      this.siteService.setCurrentSite(sites[0]);
    }
  }

  private getSiteById(id: string): Site[] {
    const sites = this.items.filter((site: Site) => site.id === id);
    return sites;
  }

  private setDefault(): void {
    if (!localStorage.getItem(LocalStorageKey.DEFAULT_SITE)) {
    }
  }

  private setSite(id: string): void {
    const sites = this.getSiteById(id);
    if (sites.length == 1) {
      this.siteService.setCurrentSite(sites[0]);
    }
  }
}
