import { AlAssetsTreeModule } from '@al/asset-tree';
import { GenericComponentsModule } from '@al/generic-components';
import { HistoryModule } from '@al/history';
import { NgModule } from '@angular/core';
import { PipeModule } from '@al/pipe';
import { PreviewComponentModule } from '@al/preview-component';
import { TemplateBodyComponent } from './template-body/template-body.component';
import { TemplateCreationDialogComponent } from './template-creation-dialog/template-creation-dialog.component';
import { TemplateFormComponent } from './template-form/template-form.component';
import { TemplateFrequencyComponent } from './template-frequency/template-frequency.component';
import { TemplateHeaderComponent } from './template-header/template-header.component';
// import { TemplateHistoryListComponent } from './template-history-list/template-history-list.component';
import { TemplateInfoComponent } from './template-info/template-info.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplateManagerComponent } from './template-manager/template-manager.component';
import { TemplateTitleComponent } from './template-title/template-title.component';
import { TemplateWidgetSelectorComponent } from './template-widget-selector/template-widget-selector.component';
import { WidgetComponentsModule } from '@al/widget-components';

@NgModule({
  declarations: [
    TemplateBodyComponent,
    TemplateFormComponent,
    TemplateFrequencyComponent,
    TemplateHeaderComponent,
    TemplateInfoComponent,
    TemplateListComponent,
    TemplateManagerComponent,
    TemplateWidgetSelectorComponent,
    TemplateTitleComponent,
    TemplateCreationDialogComponent,
    // TemplateHistoryListComponent,
  ],
  imports: [
    GenericComponentsModule,
    HistoryModule,
    PreviewComponentModule,
    PipeModule,
    AlAssetsTreeModule,
    WidgetComponentsModule,
  ],
  exports: [
    TemplateFormComponent,
    TemplateManagerComponent,
    TemplateListComponent,
    TemplateCreationDialogComponent,
  ],
})
export class TemplateComponentsModule {}
