import { JsonObject, JsonProperty } from 'json2typescript';
import { DatetimeConverter } from './converter/datetime.converter';
import { ModelAbstract } from './model.abstract';

@JsonObject('Pyrometre')
export class Pyrometre extends ModelAbstract {
  @JsonProperty('aCoeff', Number, true)
  public aCoeff: number | null = null;

  @JsonProperty('bCoeff', Number, true)
  public bCoeff: number | null = null;

  @JsonProperty('calibrationDate', DatetimeConverter, true)
  public calibrationDate: Date | null = null;

  @JsonProperty('id', String, true)
  public id = '';

  @JsonProperty('label', String, true)
  public label: string | null = null;

  @JsonProperty('macAddress', String, true)
  public macAddress: string | null = null;

  @JsonProperty('siteId', String, true)
  public siteId: string | null = null;
}
