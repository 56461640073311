<mat-expansion-panel class="al-widget al-widget-info" *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="compomentIcon">check_box</mat-icon>
      <span class="label">{{'WIDGET_VALIDATION' | translate}}
        : {{validationWidget.label}}</span>
    </mat-panel-title>
    <mat-panel-description class="icons">

      <mat-icon (click)="delete()">delete</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">
    <div class="full-width-label">
      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label>{{ "VALIDATION_WIDGET_TITLE" | translate}}
        </mat-label>

        <textarea [formControlName]="formControlNames.label" (change)="setValue(formControlNames.label)"
                  matInput></textarea>
      </mat-form-field>
      <span class="checkbox-container">
        <mat-checkbox [formControlName]="formControlNames.isMandatory"
                      (change)="setValue(formControlNames.isMandatory)">{{"IS_MANDATORY_QUESTION" | translate}}</mat-checkbox>

      </span>

    </div>


    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "VALIDATION_WIDGET_TOOLTIP" | translate}}
      </mat-label>

      <input type="text" [formControlName]="formControlNames.tooltip" (change)="setValue(formControlNames.tooltip)"
             matInput>
    </mat-form-field>

    <al-asset-input-display (returnable)="getAssets($event)"
                            [equipment]="validationWidget.equipment"
                            [processGroup]="validationWidget.processGroup"
                            [productionUnit]="validationWidget.productionUnit"
    ></al-asset-input-display>
  </div>
</mat-expansion-panel>

<mat-card class="al-widget-validation" *ngIf="widgetStatus===widgetStatusEnum.WIDGET" [formGroup]="formGroup">
  <mat-icon>check_box</mat-icon>
  {{'WIDGET_VALIDATION' | translate}}
</mat-card>

<mat-card *ngIf="widgetStatus===widgetStatusEnum.PREVIEW" class="al-widget-preview"
          [ngClass]="{'hasAnomaly': hasAnomaly()}">
  <mat-card-header>
    <div class="widget-header">
      <mat-icon class="compomentIcon">check_box</mat-icon>
      <span class="title">  {{'WIDGET_VALIDATION' | translate}}</span>
    </div>

  </mat-card-header>
  <mat-card-content>


    <div class="widget-row">
      <div class="widget-label">{{this.validationWidget.label}}</div>
      <div class="answer">
        {{ getValue()}}
        <mat-icon [ngClass]="{'higlight-red':   getValue()===false &&  getValue()!==undefined}">

          close
        </mat-icon>
        <mat-icon [ngClass]="{'higlight-green': getValue()===true}">
          check
        </mat-icon>
      </div>

    </div>


    <div class="widget-row">
      <div class="widget-label">{{this.validationWidget.tooltip}}</div>
    </div>

    <div class="widget-row" *ngIf="getComment()">
      <span class="widget-tooltip"> {{getComment()}}</span>
    </div>

    <al-common-preview [widget]="validationWidget"></al-common-preview>

  </mat-card-content>
</mat-card>

<ng-template #title>

</ng-template>
