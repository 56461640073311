/*
 * Public API Surface of angular-route
 */

export * from './lib/paths';
export * from './lib/routes/dashboard.route';
export * from './lib/routes/users.route';
export * from './lib/routes/login.routes';
export * from './lib/routes/pyrometre.route';
export * from './lib/routes/ronde.route';
export * from './lib/routes/pdf.route';
export * from './lib/routes/scheduler.routes';
export * from './lib/routes/site.routes';
export * from './lib/routes/template.routes';
export * from './lib/auth.guard';
