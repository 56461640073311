import { ActivatedRoute, Router } from '@angular/router';
import { Category, TemplateFull } from '@al/model';
import { Component, OnInit } from '@angular/core';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  FormGroupModelInterface,
  GenericFormComponent,
} from '@al/generic-components';
import { TemplateFullService, TemplateLightService } from '@al/services';
import { FormControlNames } from '../form-control-names.enum';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { NavigationService } from '@al/navigation';
import { ReferentielService } from '@al/referentiel';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-template-info',
  templateUrl: './template-info.component.html',
  styleUrls: ['./template-info.component.scss'],
})
export class TemplateInfoComponent
  extends GenericFormComponent<TemplateFull>
  implements FormGroupModelInterface<TemplateFull>, OnInit
{
  public categories: Category[] = [];

  public formControlNames = FormControlNames;

  public formGroup: FormGroup;

  public isNotInitialized = 1;

  private referntielService = new ReferentielService();

  public constructor(
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected templateLightService: TemplateLightService,
    protected templateFullService: TemplateFullService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected templateModelService: TemplateModelService,
    protected dragAndDropService: DragAndDropService,
    protected formGroupDirective: FormGroupDirective
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      templateFullService,
      translateService,
      router,
      route
    );
    this.formGroup = this.formBuilder.group({});
  }

  public buildFormGroup(entity: TemplateFull | null): FormGroup {
    return this.formBuilder.group({
      location: this.formBuilder.control(entity?.location, []),
      category: this.formBuilder.control(entity?.category, [
        Validators.required,
      ]),
      visibility: this.formBuilder.control(entity?.visible, []),
    });
  }

  public buildFormGroupPycoso(entity: TemplateFull | null): FormGroup {
    return this.formBuilder.group({
      furnace: this.formBuilder.control({
        value: entity?.smr?.name,
        disabled: true,
      }),
      category: this.formBuilder.control(entity?.category, [
        Validators.required,
      ]),
      visibility: this.formBuilder.control(entity?.visible, []),
    });
  }

  public compareCategory(option: Category, value: Category): boolean {
    if (option !== null && value !== null) {
      return option.id === value.id;
    }
    return false;
  }

  public getEntityToSave(): TemplateFull {
    return this.templateModelService.getModel();
  }

  public initFormGroup(entity: TemplateFull): void {
    if (entity.smr) {
      this.formGroup = this.buildFormGroupPycoso(entity);
    } else {
      this.formGroup = this.buildFormGroup(entity);
    }
  }

  public initModel(): TemplateFull {
    return new TemplateFull();
  }

  public ngOnInit(): void {
    this.templateModelService
      .getTemplateFullObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      // .pipe(take(this.isNotInitialized))
      .subscribe((templateFull: TemplateFull) => {
        if (this.formGroupDirective) {
          if (templateFull.smr) {
            this.categories = this.referntielService.categories.filter(
              (value: Category) => value.id == '7'
            );
            this.formGroup = this.buildFormGroupPycoso(templateFull);
          } else {
            this.categories = this.referntielService.categories.filter(
              (value: Category) => value.id != '7'
            );
            this.formGroup = this.buildFormGroup(templateFull);
          }

          this.formGroupDirective.form.addControl(
            'template-info',
            this.formGroup
          );
        }
      });
  }

  public save(): void {
    //unused
  }

  public setValue(
    formControlName: FormControlNames,
    event?: MatSelectChange
  ): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === FormControlNames.location) {
          this.templateModelService.setLocation(formControl.value);
        }
        if (formControlName === FormControlNames.category) {
          formControl.setValue(event!.value);
          this.templateModelService.setCategory(formControl.value);
        }
      }
    }
  }

  public setVisibility() {
    //this.visibility = !this.visibility;
    let formControl = this.formGroup.get(FormControlNames.visibility);
    if (formControl !== null) {
      this.templateModelService.setVisibility(formControl.value);
    }
  }
}
