import { ConfirmDialogComponent } from './confirm/confirm-dialog.component';
import { InfoDialogComponent } from './info/info-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ConfirmDialogComponent, InfoDialogComponent],
  imports: [MatDialogModule, MatButtonModule],
  exports: [ConfirmDialogComponent, InfoDialogComponent],
})
export class ConfirmDialogModule {}
