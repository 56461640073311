import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentService } from '@al/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  public code: string = '';

  public isConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private token: string | undefined = undefined;

  public constructor(protected environmentService: EnvironmentService) {}

  public gapiLoadAndSignIn(): Promise<void> {
    const config = {
      access_type: 'offline',
      scope: 'https://www.googleapis.com/auth/drive',
      client_id: this.environmentService.env.googleClientId,
      // plugin_name: 'pluginname',
      // discoveryDocs: [
      //   'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
      // ],
    };
    return new Promise<void>((resolve, error) => {
      if (gapi.client && this.isConnected$.value === true) {
        gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .reloadAuthResponse()
          .then(() => {
            resolve();
          });
      } else {
        gapi.load('client', () => {
          gapi.auth2.init(config);
          if (
            this.isConnected$.value === true &&
            !!gapi.auth2 &&
            gapi.auth2.getAuthInstance().isSignedIn.get()
          ) {
            resolve();
          } else {
            gapi.auth2
              .getAuthInstance()
              .grantOfflineAccess({ prompt: 'consent' })
              .then(() => {
                this.isConnected$.next(true);
                resolve();
              })
              .catch(() => {
                this.isConnected$.next(false);
                error();
              });
          }
        });
      }
    });
  }

  public getConnectedObservable(): Observable<Boolean> {
    return this.isConnected$.asObservable();
  }

  public getToken(): string | undefined {
    this.token = gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .getAuthResponse().access_token;
    return this.token;
  }
}
