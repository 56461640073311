import { HttpClient, HttpParams } from '@angular/common/http';
import { SchedulerItem, SchedulerItems, SchedulerSiteRequest } from '@al/model';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteService } from '@al/services';

@Injectable({
  providedIn: 'root',
})
export class SchedulerRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected siteService: SiteService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public getScheduling(): Observable<SchedulerItems> {
    let httpParams = new HttpParams();
    let schedulerItem = new SchedulerSiteRequest();
    if (this.siteService.getCurrentSite()) {
      const site = this.siteService.getCurrentSite();
      if (site) {
        schedulerItem.site.value = site.id;
      }
    }

    return super.postBis<SchedulerSiteRequest, SchedulerItems>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.scheduler.list}`,
      httpParams,
      this.getSchedulerItemsClazz(),
      schedulerItem
    );
  }

  protected getSchedulerItemClazz(): typeof SchedulerItem {
    return SchedulerItem;
  }

  protected getSchedulerItemsClazz(): typeof SchedulerItems {
    return SchedulerItems;
  }
}
