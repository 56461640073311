import {
  ProductionUnitState,
  ProductionUnitsStore,
} from '../store/production-unit.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class ProductionUnitsQuery extends QueryEntity<ProductionUnitState> {
  public productionUnit = this.selectAll();

  public constructor(protected override store: ProductionUnitsStore) {
    super(store);
  }
}
