import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FurnaceLight, Site } from '@al/model';
import { FurnaceLightService, SiteService } from '@al/services';
import {
  Observable,
  Subject,
  combineLatest,
  map,
  startWith,
  takeUntil,
} from 'rxjs';
import { AlSpinnerService } from '@al/spinner';
import { FurnaceLightFilterService } from './filter/furnace-light-filter.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UUID } from 'angular2-uuid';
import { furnaceLightValidator } from './validators/furnace-light-validator.directive';

@Component({
  selector: 'al-template-creation-dialog',
  templateUrl: './template-creation-dialog.component.html',
  styleUrls: ['./template-creation-dialog.component.css'],
})
export class TemplateCreationDialogComponent implements OnInit, OnDestroy {
  public filteredOptions: Observable<FurnaceLight[]> = new Observable<
    FurnaceLight[]
  >();

  public pycoso: boolean = false;

  public pycosoFormGroup: FormGroup;

  private ngUnsubscribe = new Subject();

  public constructor(
    public dialogRef: MatDialogRef<TemplateCreationDialogComponent>,
    protected formBuilder: FormBuilder,
    protected furnaceLightService: FurnaceLightService,
    protected alSpinnerService: AlSpinnerService,
    protected siteService: SiteService,
    protected furnaceLightFilterService: FurnaceLightFilterService
  ) {
    this.pycosoFormGroup = this.formBuilder.group({
      furnace: ['', [Validators.required, furnaceLightValidator()]],
    });
  }

  public createTemplate() {
    if (this.pycoso && this.pycosoFormGroup.get('furnace')?.value) {
      this.furnaceLightService.setActive(
        this.pycosoFormGroup.get('furnace')?.value.id
      );
    } else {
      this.furnaceLightService.clearActive();
    }
    this.dialogRef.close(true);
  }

  public displayFurnace(furnace: FurnaceLight): string {
    return furnace && furnace.name ? furnace.name : '';
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public ngOnInit() {
    this.siteService.currentSiteSubject.subscribe((site: Site) => {
      this.furnaceLightFilterService.addFilter(
        'site',
        site?.id,
        (furnace: FurnaceLight) => {
          return furnace.site === site.id;
        }
      );
    });
    this.filteredOptions = combineLatest([
      this.furnaceLightFilterService.getFilter(),
      // @ts-ignore furnace formControl is initialized in constructor
      this.pycosoFormGroup.get('furnace').valueChanges.pipe(
        startWith(''),
        map((value: FurnaceLight | string) => {
          return typeof value == 'string' ? value : value.name;
        })
      ),
    ]).pipe(
      takeUntil(this.ngUnsubscribe),
      map(([list, filter]) => {
        return this._filter(list || [], filter || '');
      })
    );
  }

  public showPycosoForm(): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuid);
    this.pycoso = true;
    this.furnaceLightService
      .loadStore()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.alSpinnerService.stopDataProcess(uuid);
        if (this.siteService.getCurrentSite()) {
          this.furnaceLightFilterService.addFilter(
            'site',
            // @ts-ignore
            this.siteService.getCurrentSite().id,
            (furnace: FurnaceLight) => {
              return (
                furnace &&
                // @ts-ignore
                furnace.site.toString() === this.siteService.getCurrentSite().id
              );
            }
          );
        }
        this.filteredOptions = combineLatest([
          this.furnaceLightFilterService.getFilter(),
          // @ts-ignore furnace formControl is initialized in constructor
          this.pycosoFormGroup.get('furnace').valueChanges.pipe(
            startWith(''),
            map((value: FurnaceLight | string) => {
              return typeof value == 'string' ? value : value.name;
            })
          ),
        ]).pipe(
          takeUntil(this.ngUnsubscribe),
          map(([list, filter]) => {
            return this._filter(list || [], filter || '');
          })
        );
      });
  }

  private _filter(list: FurnaceLight[], filter: string): FurnaceLight[] {
    const filterValue = filter.toLowerCase();
    return list.filter((item: FurnaceLight) =>
      item.name.toLowerCase().includes(filterValue)
    );
  }
}
