import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('Owner')
export class Owner extends ModelAbstract {
  @JsonProperty('firstName', String, true)
  public firstName: number | null = null;

  @JsonProperty('id', Number, true)
  public id: number | null = null;

  @JsonProperty('lastName', String, true)
  public lastName: string | null = null;
}
