import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('FurnaceLight')
export class FurnaceLight extends ModelAbstract {
  @JsonProperty('furnace_id', Number, true)
  public id: number = 0;

  @JsonProperty('furnace_name', String, true)
  public name: string = '';

  @JsonProperty('furnace_site', Number, true)
  public site: string = '';
}
