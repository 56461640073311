import { GenericComponentsModule } from '@al/generic-components';
import { HistoryComponent } from './history.component';
import { HistoryListComponent } from './history-list/history-list.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HistoryComponent, HistoryListComponent],
  imports: [GenericComponentsModule, TranslateModule],
  exports: [HistoryComponent],
})
export class HistoryModule {}
