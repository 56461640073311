import { Component, OnInit } from '@angular/core';
import { DatalakeService, UserService } from '@al/services';
import { Kpi, Profile, Site, User } from '@al/model';
import { Observable, take } from 'rxjs';
import { GenericComponent } from '@al/generic-components';
import { KpiService } from 'projects/services/src/lib/kpi.service';
import { MatDialog } from '@angular/material/dialog';
import { Paths } from '@al/angular-route';
import { Router } from '@angular/router';
import { SiteQuery } from '@al/akita';
import { TemplateCreationDialogComponent } from '@al/template-components';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from '@al/session';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'al-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends GenericComponent implements OnInit {
  public assetCount: number = 0;

  public countRondes: number = 0;

  public countTemplate: number = 0;

  public countUsers: number = 0;

  public kpi: Observable<Kpi> = new Observable<Kpi>();

  public obs: Observable<Site[]> = new Observable<Site[]>();

  public pgCount: number = 0;

  public puCount: number = 0;

  public sites: Site[] = [];

  public userProfile: Profile | undefined;

  public values: string[] = ['top', 'tip'];

  public constructor(
    protected siteQuery: SiteQuery,
    protected kpiService: KpiService,
    protected router: Router,
    protected translateService: TranslateService,
    public dialog: MatDialog,
    protected datalakeService: DatalakeService,
    protected userServiceInfo: UserInfoService,
    protected userService: UserService
  ) {
    super();
    userServiceInfo
      .getEmail()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.kpi = this.kpiService.getKpis().pipe(take(1));
      });
  }

  public createTemplate(): void {
    const dialogRef = this.dialog.open(TemplateCreationDialogComponent);
    dialogRef
      .afterClosed()
      .subscribe((result: { furnace: string; pycoso: boolean } | null) => {
        if (result) {
          this.router.navigate([
            '/',
            Paths.template.DEFAULT,
            Paths.template.CREATE,
          ]);
        }
      });
  }

  public createUser(): void {
    this.router.navigate(['/', Paths.users.DEFAULT, Paths.users.CREATE]);
  }

  public ngOnInit(): void {
    this.obs = this.siteQuery.selectAll();

    this.datalakeService
      .getProductionUnitsCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((puCount: number) => {
        this.puCount = puCount;
      });

    this.datalakeService
      .getProcessGroupsCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pgCount: number) => {
        this.pgCount = pgCount;
      });

    this.datalakeService
      .getEquipmentsCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((assetCount: number) => {
        this.assetCount = assetCount;
      });

    this.userService
      .getCurrentObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User | undefined) => {
        this.userProfile = user?.profileType;
      });
  }

  public showPyrometre(): void {
    this.router.navigate(['/', Paths.pyrometre.DEFAULT, Paths.pyrometre.LIST]);
  }

  public showRonde(): void {
    this.router.navigate(['/', Paths.ronde.DEFAULT, Paths.ronde.LIST]);
  }

  public showScheduler(): void {
    this.router.navigate(['/', Paths.scheduler.DEFAULT, Paths.scheduler.LIST]);
  }

  public showSiteList(): void {
    this.router.navigate(['/', Paths.site.DEFAULT, Paths.site.LIST]);
  }

  public showTemplate(): void {
    this.router.navigate(['/', Paths.template.DEFAULT, Paths.template.LIST]);
  }

  public showUsersList(): void {
    this.router.navigate(['/', Paths.users.DEFAULT, Paths.users.LIST]);
  }
}
