import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { McqOption } from './mcq.option';
import { Widget } from './widget.model';

@JsonObject('mcq')
export class McqWidget extends Widget {
  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('options', [McqOption], true)
  public options: McqOption[] = [];

  @JsonProperty('singleChoiceOnly', Boolean, true)
  public singleChoiceOnly: boolean = false;

  @JsonProperty('tooltip', String, true)
  public tooltip: string | null = null;

  public constructor() {
    super();
    this.componentType = ComponentType.MULTI_CHECKBOX;
  }
}
