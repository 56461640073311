import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { SchedulerItem } from '@al/model';

export interface SchedulerState
  extends EntityState<SchedulerItem>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'scheduler', resettable: true })
export class SchedulerStore extends EntityStore<SchedulerState> {
  public constructor() {
    super();
  }
}
