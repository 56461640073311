import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { Category } from '../category.model';
import { ReferentielService } from '@al/referentiel';

@JsonConverter
export class CategoryConverter implements JsonCustomConvert<Category | null> {
  public deserialize(id: string): Category | null {
    const referentielService = new ReferentielService();

    return referentielService.getCategoryFromId(id);
  }

  public serialize(category: Category): string | null {
    if (category) {
      return category.id;
    }
    return null;
  }
}
