<div class="widget-row pictures">

  <mat-icon *ngFor="let picture of widget.pictures" (click)="openImage(picture)">
    image
  </mat-icon>
</div>
<div class="widget-row">
  <span *ngIf="widget.productionUnit">{{widget.productionUnit.name}}</span>
  <span *ngIf="widget.processGroup"> > {{widget.processGroup.name}}</span>
  <span *ngIf="widget.equipment"> > {{widget.equipment.number}}</span>
</div>
