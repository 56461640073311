import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component } from '@angular/core';
import { SchedulerService, TemplateLightService } from '@al/services';
import { FormControlNames } from '../form-control-names.enum';
import { GenericListComponent } from '@al/generic-components';
import { SchedulerFilterService } from '../service/scheduler-filter.service';
import { SchedulerItem } from '@al/model';
import moment from 'moment-timezone';

@Component({
  selector: 'al-scheduler-component',
  templateUrl: 'scheduler-list.component.html',
  styleUrls: ['scheduler-list.component.scss'],
})
export class SchedulerListComponent
  extends GenericListComponent<SchedulerItem>
  implements AfterViewInit
{
  public formControlNames = FormControlNames;

  public constructor(
    protected override akitaFilterService: SchedulerFilterService, //
    protected schedulerService: SchedulerService, //
    protected templateLightService: TemplateLightService,
    protected override router: Router,
    protected override route: ActivatedRoute
  ) {
    super(akitaFilterService, schedulerService, router, route);
  }

  public filterId($event: number, field: string): void {
    this.addFilter(field, $event, (value: SchedulerItem) =>
      value.id.toString().toLowerCase().includes($event.toString())
    );
  }

  public filterTitle($event: any, field: string): void {
    this.addFilter(field, $event, (value: SchedulerItem) =>
      value.title?.toLowerCase().includes($event.toLowerCase())
    );
  }

  protected diffDays(nextCrDate: Date): number {
    return moment(nextCrDate.valueOf()).diff(new Date().valueOf(), 'days');
  }

  protected diffHours(nextCrDate: Date): number {
    return (
      moment(nextCrDate.valueOf()).diff(new Date().valueOf(), 'hours') % 24
    );
  }

  protected initColumnDisplay(): string[] {
    return [
      FormControlNames.id.valueOf(),
      FormControlNames.title.valueOf(),
      FormControlNames.lastCrDate.valueOf(),
      FormControlNames.nextCrDate.valueOf(),
      FormControlNames.delay.valueOf(),
    ];
  }
}
