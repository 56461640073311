import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from './model.abstract';
import { UUID } from 'angular2-uuid';

@JsonObject('Country')
export class Country extends ModelAbstract {
  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('uuid', String, true)
  public uuid: UUID = UUID.UUID();
}
