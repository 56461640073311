import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  selector: 'al-generic-comp',
  template: '',
  styleUrls: [],
})
export abstract class GenericComponent implements OnDestroy {
  protected ngUnsubscribe = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
