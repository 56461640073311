<mat-expansion-panel class="al-widget al-widget-info" *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="compomentIcon">format_list_numbered</mat-icon>
      <span class="label">{{'WIDGET_MEASURE' | translate}} : {{measureWidget.label}}</span>
    </mat-panel-title>
    <mat-panel-description class="icons">
      <mat-icon (click)="delete()">delete</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">

    <div class="full-width-label measure-widget-info">
      <mat-form-field class="text-label" appearance="outline" floatLabel="always">
        <mat-label>{{ "MEASURE_WIDGET_TITLE" | translate}}
        </mat-label>
        <textarea [formControlName]="formControlNames.label" (change)="setValue(formControlNames.label)"
                  matInput></textarea>
        <mat-error *ngIf="formGroup.get(formControlNames.label)?.hasError('required')">
          {{ 'REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <span class="checkbox-container">
        <mat-checkbox [formControlName]="formControlNames.disableControl"
                      (change)="setValue(formControlNames.disableControl)">{{"MEASURE_CONTROL_OFF" | translate}}</mat-checkbox>
        <mat-checkbox [formControlName]="formControlNames.isMandatory"
                      (change)="setValue(formControlNames.isMandatory)">{{"IS_MANDATORY_QUESTION" | translate}}</mat-checkbox>

      </span>
    </div>

    <div class="widget-row">
      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "MEASURE_WIDGET_MIN" | translate}}
          </mat-label>

          <input type="number" [formControlName]="formControlNames.min"
                 (change)="setValue(formControlNames.min)"
                 matInput>
          <mat-error *ngIf="formGroup.get(formControlNames.min)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.min)?.hasError('max')
          && !formGroup.get(formControlNames.min)?.hasError('required')">
            {{ 'MEASURE_MAX_RANGE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>

      <span class="widget-row-component">

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "MEASURE_WIDGET_MAX" | translate}}
          </mat-label>
          <input type="number"
                 [formControlName]="formControlNames.max"
                 (change)="setValue(formControlNames.max)"
                 [textContent]="measureWidget.unit?.label"
                 matInput>
          <mat-error *ngIf="formGroup.get(formControlNames.max)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.max)?.hasError('min')
          && !formGroup.get(formControlNames.max)?.hasError('required')">
            {{ 'MEASURE_MIN_RANGE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>

      <span class="widget-row-component">

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "UNIT" | translate}}
          </mat-label>
          <input type="text"
                 matInput
                 [formControlName]="formControlNames.unit"
                 [matAutocomplete]="auto">
          <mat-autocomplete [displayWith]="displayUnit" #auto="matAutocomplete"
                            (optionSelected)='setValue(formControlNames.unit)'
          >

            <mat-option *ngFor="let unit of this.filteredUnits | async " [value]="unit"
                        selected="unit.uuid === this.measureWidget.unit.uuid"
            >
              {{unit.abbreviation}} - {{unit.description}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="formGroup.get(formControlNames.unit)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

      </span>

    </div>
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "MEASURE_WIDGET_TOOLTIP" | translate}}
      </mat-label>
      <input type="text" [formControlName]="formControlNames.tooltip" (change)="setValue(formControlNames.tooltip)"
             matInput>
      <mat-error *ngIf="formGroup.get(formControlNames.tooltip)?.hasError('required')">
        {{ 'REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <!--    <mat-form-field appearance="outline" class="full-width" floatLabel="always">-->
    <!--      <mat-label>{{ "UNIT" | translate}}-->
    <!--      </mat-label>-->
    <!--      <input type="text"-->
    <!--             matInput-->
    <!--             [formControlName]="formControlNames.unit"-->
    <!--             [matAutocomplete]="auto">-->
    <!--      <mat-autocomplete [displayWith]="displayUnit" #auto="matAutocomplete"-->

    <!--                        (optionSelected)='setValue(formControlNames.unit)'-->

    <!--      >-->

    <!--        <mat-option *ngFor="let unit of this.filteredUnits | async " [value]="unit"-->
    <!--                    selected="unit.uuid === this.measureWidget.unit.uuid"-->
    <!--        >-->
    <!--          {{unit.abbreviation}} - {{unit.description}}-->
    <!--        </mat-option>-->
    <!--      </mat-autocomplete>-->
    <!--      <mat-error *ngIf="formGroup.get(formControlNames.unit)?.hasError('required')">-->
    <!--        {{ 'REQUIRED' | translate }}-->
    <!--      </mat-error>-->
    <!--    </mat-form-field>-->
    <al-asset-input-display (returnable)="getAssets($event)" [equipment]="measureWidget.equipment"
                            [processGroup]="measureWidget.processGroup" [productionUnit]="measureWidget.productionUnit"
    ></al-asset-input-display>

  </div>

</mat-expansion-panel>
<mat-card class="al-widget-mcq" *ngIf="widgetStatus===widgetStatusEnum.WIDGET" [formGroup]="formGroup">
  <mat-icon>format_list_numbered</mat-icon>
  {{'WIDGET_MEASURE' | translate}}
</mat-card>


<mat-card *ngIf="widgetStatus===widgetStatusEnum.PREVIEW" class="al-widget-preview"
          [ngClass]="{'hasAnomaly': hasAnomaly()}">
  <mat-card-header style="margin: 0px">
    <div class="widget-header">
      <mat-icon class="compomentIcon">format_list_numbered</mat-icon>
      <span class="title">  {{'WIDGET_MEASURE' | translate}}</span>
    </div>

  </mat-card-header>
  <mat-card-content>


    <div class="widget-row">
      <div class="widget-label">{{this.measureWidget.label}}</div>
      <div class="answer">{{getValue()}} {{getUnit()}}</div>
    </div>
    <div class="widget-row" *ngIf="this.measureWidget.tooltip">
      <span class="widget-tooltip"> {{measureWidget.tooltip}}</span>
    </div>
    <div class="widget-row" *ngIf="getComment()">
      <span class="widget-tooltip"> {{getComment()}}</span>
    </div>

    <div class="widget-row" *ngIf="measureWidget.min!==null ||  measureWidget.max!==null">
    <span class="widget-constraint" *ngIf="measureWidget.min!==null">    {{'MEASURE_WIDGET_MIN' | translate}}
      :     {{measureWidget.min}}  </span>

      <span class="widget-constraint" *ngIf="measureWidget.max!==null">    {{'MEASURE_WIDGET_MAX' | translate}}
        :     {{measureWidget.max}}</span>

    </div>

    <al-common-preview [widget]="measureWidget"></al-common-preview>

  </mat-card-content>
</mat-card>
