import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';
import { PyrometreQuery, PyrometreStore } from '@al/akita';
import { AlSpinnerService } from '@al/spinner';
import { GenericService } from './generic.service';
import { Pyrometre } from '@al/model';
import { PyrometreRestService } from '@al/rest-services';
import { SiteService } from './site.service';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root',
})
export class PyrometreService
  extends GenericService<Pyrometre>
  implements OnDestroy
{
  private ngUnsubscribe = new Subject();

  public constructor(
    protected override restService: PyrometreRestService,
    protected query: PyrometreQuery,
    protected store: PyrometreStore,
    protected siteService: SiteService,
    private alSpinnerService: AlSpinnerService
  ) {
    super(restService, query, store);
    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        const uuid = UUID.UUID();
        if (window.location.href.includes('pyrometre')) {
          this.alSpinnerService.startDataProcess(uuid);
        }
        this.loadStore()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.alSpinnerService.stopDataProcess(uuid);
            },

            error: () => {
              this.alSpinnerService.stopDataProcess(uuid);
              this.alSpinnerService.launchSnackBar('ERR_LOAD_PYRO', 10);
            },
          });
      });
  }

  public create(pyrometre: Pyrometre): Observable<Pyrometre> {
    return this.restService.create(pyrometre).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data) => {
        pyrometre.id = data.id;
        this.store.upsert(pyrometre.id, pyrometre);
        this.store.setActive(pyrometre.id);
        return data;
      })
    );
  }

  public delete(pyrometre: Pyrometre) {
    return this.restService.deletePyro(pyrometre).pipe(
      take(1),
      map((data) => {
        this.store.remove(pyrometre.id);
        return data;
      })
    );
  }

  public loadStore(): Observable<void> {
    if (window.location.href.includes('pyrometre')) {
      return this.restService.getPyrometre().pipe(
        takeUntil(this.ngUnsubscribe),
        map((data: Pyrometre[]) => {
          // data.forEach((pyrometre) => {
          //   this.store.upsert(pyrometre.id, pyrometre);
          // });
          this.store.reset();
          this.store.set(data);
          if (data.length > 0) {
            this.store.setActive(data[0].id);
          }
        })
      );
    }
    return new Observable();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public save(pyrometre: Pyrometre): Observable<Pyrometre> {
    return this.restService.save(pyrometre).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data) => {
        this.store.replace(pyrometre.id, pyrometre);
        this.store.setActive(pyrometre.id);
        return data;
      })
    );
  }
}
