<p translate mat-dialog-title class="title">SELECT_LOCATION_ASSET_TREE</p>
<mat-dialog-content>

  <mat-form-field>
    <mat-label>{{ "Search" | translate }}
    </mat-label>
    <input matInput type="text" #search (input)="filterNodes($event)">
    <button mat-button matSuffix mat-icon-button aria-label="Clear"
    type="button" (click)="clear()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
      [ngClass]="{ 'background-highlight': activeNode.includes(node) }">

      <ng-container *ngIf="node.expandable; else notExpandableTemplate">
        <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item"
        matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
      </ng-container>
      <ng-template #notExpandableTemplate>
        <button mat-icon-button disabled></button>
      </ng-template>
      <div (click)="select(node)">
        <p *ngIf="isEquipment(node)">
          <span *ngIf="node.item && node.item.processTag">{{node.item.processTag}}:</span>
          <span *ngIf="node.item && node.item.description">{{node.item.description}}</span>
        </p>
        <p *ngIf="isProcessGroup(node)">{{node.item.name}}</p>
        <p *ngIf="isProductionUnit(node)">{{node.item.name}}</p>
      </div>

      <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar">
      </mat-progress-bar>

    </mat-tree-node>

  </mat-tree>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" type="button" (click)="onNoClick()">
    {{ "CANCEL" | translate }}
  </button>
  <!-- <button mat-raised-button color="primary" (click)="sendData()" [disabled]="!this.treeControl.returnable"> -->
  <button mat-raised-button color="primary" (click)="sendData()" [disabled]="false">
    {{ "SUBMIT" | translate }}
  </button>
</mat-dialog-actions>
