import { JsonObject, JsonProperty } from 'json2typescript';
import { UUID } from 'angular2-uuid';

@JsonObject('mcq_option')
export class McqOption {
  @JsonProperty('canRaiseAnomaly', Boolean, true)
  public canRaiseAnomaly = false;

  @JsonProperty('id', String, true)
  public id: UUID = UUID.UUID();

  @JsonProperty('isChecked', Boolean, true)
  public isChecked: boolean = false;

  @JsonProperty('label', String, true)
  public label: string = '';
}
