import {
  Component,
  OnDestroy,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { SessionService, UserInfoService } from '@al/session';
import { AlAccountResetPasswordDialogComponent } from './al-account-reset-password-dialog.component';
import { EnvironmentService } from '@al/environment';
import { LocalStorageKey } from '@al/local-cache';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserConnectedQuery } from '@al/akita';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-account-widget',
  templateUrl: 'account-widget.component.html',
  styleUrls: ['account-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountWidgetComponent implements OnDestroy {
  public buttonStyle = {};

  public email: string = '';

  public fullName: string = '';

  public hasImage!: boolean;

  public isConnectedWithUserPool = false;

  public isDevelopment: boolean;

  public isOnline!: boolean;

  private ngUnsubscribe = new Subject();

  public constructor(
    public dialog: MatDialog,
    private translateService: TranslateService,
    private userInfoService: UserInfoService,
    protected sessionService: SessionService,
    protected userConnectedQuery: UserConnectedQuery,
    protected environmentService: EnvironmentService
  ) {
    userInfoService
      .getImageUrl()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((path: string) => {
        this.buttonStyle = { 'background-image': 'none' };
        // if (path && typeof path === 'string' && path.trim().length > 0) {
        this.buttonStyle = {
          'background-image': `url(${path})`,
        };
        this.hasImage = true;
        // } else {
        //   this.buttonStyle = { 'background-image': 'none' };
        //   this.hasImage = false;
        // }
      });
    userInfoService
      .getEmail()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((name: string) => {
        this.fullName = name;
      });

    this.sessionService
      .getConnectedByUserPool()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((connectedByUserPool) => {
        this.isConnectedWithUserPool = connectedByUserPool;
      });
    this.isDevelopment = !environmentService.env.production;
  }

  public logout(): void {
    this.sessionService.logout();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public openResetPasswordDialog(): void {
    this.dialog.open(AlAccountResetPasswordDialogComponent, {
      data: { email: this.email },
    });
  }

  public setLanguage(lang: string): void {
    localStorage.setItem(LocalStorageKey.LANGUAGE_KEY, lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }
}
