import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { EnvironmentService } from '@al/environment';
import { GenericComponent } from '@al/generic-components';
import { Injectable } from '@angular/core';
import { UserInfoService } from '@al/session';

@Injectable()
export class SecurityHttpInterceptor
  extends GenericComponent
  implements HttpInterceptor
{
  private email: string = 'empty_email';

  public constructor(
    private alEnvironmentService: EnvironmentService,
    protected userServiceInfo: UserInfoService
  ) {
    super();
    userServiceInfo
      .getEmail()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((email: string) => {
        this.email = email;
      });
  }

  public intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (httpRequest.url.includes('datalake')) {
      return from(Auth.currentSession()).pipe(
        takeUntil(this.ngUnsubscribe),
        map((session) => {
          return session ? session.getIdToken().getJwtToken() : '';
        }),
        mergeMap((token: string) =>
          next.handle(
            httpRequest.clone({
              setHeaders: {
                Authorization: `${token}`,
              },
            })
          )
        )
      );
    }
    return from(Auth.currentSession()).pipe(
      takeUntil(this.ngUnsubscribe),
      map((session) => {
        return session ? session.getIdToken().getJwtToken() : '';
      }),
      mergeMap((token: string) => {
        let params = {
          setHeaders: {
            Authorization: `${token}`,
            userid: this.email,
            userId: this.email,
          },
        };
        return next.handle(httpRequest.clone(params));
      })
    );
  }
}
