import {
  SchedulerListComponent,
  SchedulerManagerComponent,
} from '@al/scheduler-component';
import { AuthGuard } from '../auth.guard';
import { Paths } from '../paths';
import { Route } from '@angular/router';

export const schedulerRoutes: Route = {
  path: Paths.scheduler.DEFAULT,
  canActivate: [AuthGuard],
  component: SchedulerManagerComponent,
  children: [
    {
      path: Paths.scheduler.LIST,
      component: SchedulerListComponent,
      canActivate: [AuthGuard],
    },
  ],
};
