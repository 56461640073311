import { Component, OnInit } from '@angular/core';
import { AlSpinnerService } from '@al/spinner';
import { GenericComponent } from '@al/generic-components';
import { NavigationService } from '@al/navigation';
import { PyrometreService } from '@al/services';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-pyrometre-manager',
  templateUrl: './pyrometre-manager.component.html',
  styleUrls: ['./pyrometre-manager.component.scss'],
})
export class PyrometreManagerComponent
  extends GenericComponent
  implements OnInit
{
  public constructor(
    protected router: Router,
    protected navigationService: NavigationService,
    protected pyrometreService: PyrometreService,
    private alSpinnerService: AlSpinnerService
  ) {
    super();
  }

  public ngOnInit(): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuid);

    this.pyrometreService
      .loadStore()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.alSpinnerService.stopDataProcess(uuid);
        },
        error: (error) => {
          console.error('intitial load Error: pyrometer', error);
          this.alSpinnerService.stopDataProcess(uuid);
          this.alSpinnerService.launchSnackBar('ERR_LOAD_PYRO', 10);
        },
      });
  }
}
