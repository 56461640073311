import { BehaviorSubject, Subject } from 'rxjs';
import { FurnaceFull, Gate, GateWidget, TemplateFull, Widget } from '@al/model';
import { Injectable, OnDestroy } from '@angular/core';
import { AlSpinnerService } from '@al/spinner';
import { FurnaceFullService } from '@al/services';
import { UUID } from 'angular2-uuid';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GateService implements OnDestroy {
  public gatesSubject = new BehaviorSubject<Gate[]>([]);

  public reservedSubject = new BehaviorSubject<Map<string, string>>(
    new Map<string, string>()
  );

  private gates: Gate[] = [];

  private ngUnsubscribe = new Subject();

  private reservedGates: Map<string, string> = new Map();

  public constructor(
    protected furnaceFullService: FurnaceFullService,
    protected alSpinnerService: AlSpinnerService
  ) {
    const uuid = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuid);
    this.furnaceFullService
      .getActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((furnace: FurnaceFull | undefined) => {
        if (furnace) {
          this.gates = furnace.gates;
          this.gatesSubject.next(this.gates);
        }
        this.alSpinnerService.stopDataProcess(uuid);
      });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public reserveFromTemplate(
    furnace: FurnaceFull,
    template: TemplateFull
  ): void {
    template.templateBody.forEach((widget: Widget) => {
      const gateWidget = widget as GateWidget;
      const gateToReserve = this.gates.find(
        (gate: Gate) => gate.id === gateWidget.gateId
      );
      if (gateToReserve && gateWidget.id) {
        this.reservedGates.set(gateWidget.gateId, gateWidget.id);
      }
    });
    this.reservedSubject.next(this.reservedGates);
  }

  public reserveGate(gateId: string, widgetId: string): void {
    const oldEntry = Array.from(this.reservedGates.entries()).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) => value === widgetId
    );
    if (oldEntry) {
      this.reservedGates.delete(oldEntry[0]);
    }
    this.reservedGates.set(gateId, widgetId);
    this.reservedSubject.next(this.reservedGates);

    this.gatesSubject.next(this.gates);
  }
}
