import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FormsModule } from '@angular/forms';
import { GenericComponentsModule } from '@al/generic-components';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    GenericComponentsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
