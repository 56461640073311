import { FurnaceFull, FurnaceLight } from '@al/model';
import { FurnaceFullQuery, FurnaceFullStore } from '@al/akita';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { FurnaceRestService } from '@al/rest-services';
import { GenericService } from './generic.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FurnaceFullService
  extends GenericService<FurnaceFull>
  implements OnDestroy
{
  private ngUnsubscribe = new Subject();

  public constructor(
    protected override restService: FurnaceRestService,
    protected query: FurnaceFullQuery,
    protected store: FurnaceFullStore
  ) {
    super(restService, query, store);
  }

  public getByKey(id: number): Observable<FurnaceFull> {
    return this.restService.getFullFurnace(id).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: FurnaceFull) => {
        this.store.reset();
        this.store.set([data]);
        this.store.setActive(data.id);
        return data;
      })
    );
  }

  public getFullFurnace(furnaceLight: FurnaceLight): Observable<FurnaceFull> {
    return this.getByKey(furnaceLight.id);
  }

  public override loadStore(): Observable<void> {
    return new Observable<void>();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
