import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { Country } from './country.model';
import { Entity } from './entity.model';
import { ModelAbstract } from './model.abstract';

@JsonObject('Site')
export class Site extends ModelAbstract {
  @JsonProperty('alertOnAnomaly', Boolean, true)
  public alertOnAnomaly: boolean = false;

  @JsonProperty('allowBtCreation', Boolean, true)
  public allowBtCreation: boolean = false;

  @JsonProperty('allowSrCreation', Boolean, true)
  public allowSrCreation: boolean = false;

  @JsonProperty('country', Country, true)
  public country: Country | null = null;

  @JsonProperty('dateFormat', String, true)
  public dateFormat: string = '';

  @JsonProperty('entity', Entity, true)
  public entity: Entity | null = null;

  @JsonProperty('id', Any, true)
  public id: string = '';

  @JsonProperty('kpiInstance', Number, true)
  public kpiInstance: Number = 0;

  @JsonProperty('kpiTemplate', Number, true)
  public kpiTemplate: Number = 0;

  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('maximoSiteId', String, true)
  public maximoSiteId: string = '';

  @JsonProperty('sharedQueryPartition', Number, true)
  public sharedQueryPartition: Number = 1;

  @JsonProperty('timezone', String, true)
  public timezone: string = '';

  @JsonProperty('validateInstance', Boolean, true)
  public validateInstance: boolean = false;
}
