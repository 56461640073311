import { Equipment, ProcessGroup, ProductionUnit, Site } from '@al/model';
import {
  EquipmentRestService,
  ProcessGroupRestService,
  ProductionUnitRestService,
} from '@al/rest-services';
import {
  EquipmentsQuery,
  EquipmentsStore,
  ProcessGroupsQuery,
  ProcessGroupsStore,
  ProductionUnitsQuery,
  ProductionUnitsStore,
} from '@al/akita';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AlSpinnerService } from '@al/spinner';
import { SiteService } from '@al/services';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root',
})
export class DatalakeService implements OnDestroy {
  public currentEquipmentSubject = new Subject<Equipment>();

  private ngUnsubscribe = new Subject();

  private site: Site | undefined = undefined;

  public constructor(
    protected equipmentRestService: EquipmentRestService,
    protected equipmentQuery: EquipmentsQuery,
    protected equipmentStore: EquipmentsStore,
    protected productionUnitRestService: ProductionUnitRestService,
    protected productionUnitQuery: ProductionUnitsQuery,
    protected productionUnitStore: ProductionUnitsStore,
    protected processGroupRestService: ProcessGroupRestService,
    protected processGroupQuery: ProcessGroupsQuery,
    protected processGroupStore: ProcessGroupsStore,
    protected siteService: SiteService,
    private alSpinnerService: AlSpinnerService
  ) {
    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((site: Site) => {
        if (!this.site || this.site.maximoSiteId !== site.maximoSiteId) {
          this.site = site;
          this.loadStore().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        }
      });
  }

  public getEquipments(): Observable<Equipment[]> {
    return this.equipmentQuery.selectAll();
  }

  public getEquipmentsByPG(pgId: string): Observable<Equipment[]> {
    return this.equipmentQuery.selectAll({
      filterBy: [(entity: Equipment) => entity.processGroupId === pgId],
    });
  }

  public getEquipmentsCount(): Observable<number> {
    return this.equipmentQuery.selectCount();
  }

  public getProcessGroups(): Observable<ProcessGroup[]> {
    return this.processGroupQuery.selectAll();
  }

  public getProcessGroupsByPU(puId: string): Observable<ProcessGroup[]> {
    return this.processGroupQuery.selectAll({
      filterBy: [(entity: ProcessGroup) => entity.productionUnitId === puId],
    });
  }

  public getProcessGroupsCount(): Observable<number> {
    return this.processGroupQuery.selectCount();
  }

  public getProductionUnits(): Observable<ProductionUnit[]> {
    return this.productionUnitQuery.selectAll();
  }

  public getProductionUnitsCount(): Observable<number> {
    return this.productionUnitQuery.selectCount();
  }

  public loadStore(): Observable<void> {
    this.equipmentStore.remove();
    this.processGroupStore.remove();
    this.productionUnitStore.remove();
    const uuidEquip = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuidEquip);
    const uuidPG = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuidPG);
    const uuidPU = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuidPU);

    this.equipmentRestService
      .getAllEquipments()
      .then((equipments: void | Equipment[]) => {
        this.alSpinnerService.stopDataProcess(uuidEquip);
        if (equipments) {
          this.equipmentStore.set(equipments);
        }
      });
    this.processGroupRestService
      .getAllProcessGroups()
      .then((processGroups: void | ProcessGroup[]) => {
        this.alSpinnerService.stopDataProcess(uuidPG);
        if (processGroups) {
          processGroups.forEach((processGroup) =>
            this.processGroupStore.add(processGroup)
          );
        }
      });
    this.productionUnitRestService
      .getAllProductionUnits()
      .then((productionUnits: void | ProductionUnit[]) => {
        this.alSpinnerService.stopDataProcess(uuidPU);
        if (productionUnits) {
          productionUnits.forEach((productionUnit) =>
            this.productionUnitStore.add(productionUnit)
          );
        }
      });

    return new Observable<void>();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
