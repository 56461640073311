import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { Widget } from './widget.model';

@JsonObject('validation')
export class ValidationWidget extends Widget {
  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('tooltip', String, true)
  public tooltip: string = '';

  public constructor() {
    super();
    this.componentType = ComponentType.CHECKBOX;
  }
}
