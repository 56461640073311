export enum LocalStorageKey {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  ACCESS_TOKEN_DECODED = 'ACCESS_TOKEN_DECODED',
  DEFAULT_SITE = 'DEFAULT_SITE',
  DEFAULT_SITE_OBJECT = 'DEFAULT_SITE_OBJECT',
  DEFAULT_TIMEZONE = 'DEFAULT_TIMEZONE',
  ID_TOKEN = 'ID_TOKEN',
  ID_TOKEN_DECODED = 'ID_TOKEN_DECODED',
  LANGUAGE_KEY = 'LANGUAGE_KEY',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  DEFAULT_DATEFORMAT = 'DEFAULT_DATEFORMAT',
}
