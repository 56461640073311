import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { Profile } from '../profile.model';
import { ReferentielService } from '@al/referentiel';

@JsonConverter
export class ProfileConverter implements JsonCustomConvert<Profile | null> {
  public deserialize(id: string | number): Profile | null {
    const referentielService = new ReferentielService();

    return referentielService.getProfileFromId(id.toString());
  }

  public serialize(profile: Profile): string | null {
    if (profile) {
      return profile.id;
    }
    return null;
  }
}
