import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { filter, map, pairwise } from 'rxjs';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private previousUrl?: string;

  public constructor(
    private readonly router: Router,
    private readonly location: Location
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event) => event as RoutesRecognized),
        pairwise()
      )
      .subscribe((events: [RoutesRecognized, RoutesRecognized]) => {
        this.previousUrl = events[0].urlAfterRedirects;
      });
  }

  public back(route: ActivatedRoute): void {
    if (route.parent) {
      this.router.navigate(['..'], { relativeTo: route.parent });
    }
  }
}
