import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { FrequencyOccurence } from '../frequency-occurrence.model';
import { ReferentielService } from '@al/referentiel';

@JsonConverter
export class FrequencyOccurenceConverter
  implements JsonCustomConvert<FrequencyOccurence | null>
{
  public deserialize(id: number): FrequencyOccurence | null {
    const referentielService = new ReferentielService();

    return referentielService.getFrequencyOccurenceFromId(id);
  }

  public serialize(frequency: FrequencyOccurence): number | null {
    if (frequency) {
      return frequency.id;
    }
    return null;
  }
}
