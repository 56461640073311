import { JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

export class SMR extends ModelAbstract {
  @JsonProperty('id', Number, true)
  public id: number = 0;

  @JsonProperty('maxTemperatureCelsius', Number, true)
  public maxTemperatureCelsius: number = 0;

  @JsonProperty('minMeasureSequenceCount', Number, true)
  public minMeasureSequenceCount: number = 0;

  @JsonProperty('minTemperatureCelsius', Number, true)
  public minTemperatureCelsius: number = 0;

  @JsonProperty('name', String, true)
  public name: string = '';

  @JsonProperty('scadaPrefix', String, true)
  public scadaPrefix: string = '';

  @JsonProperty('scadaServer', String, true)
  public scadaServer: string = '';

  @JsonProperty('unstableDeltaTemperature', Number, true)
  public unstableDeltaTemperature: number = 0;

  @JsonProperty('unstableRetryPolicyCount', Number, true)
  public unstableRetryPolicyCount: number = 0;

  @JsonProperty('veryHotTemperature', Number, true)
  public veryHotTemperature: number = 0;
}
