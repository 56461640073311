import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { FrequencyType } from '@al/model';

@JsonConverter
export class FrequencyTypeConverter
  implements JsonCustomConvert<FrequencyType | null>
{
  public deserialize(id: string): FrequencyType | null {
    if (id === FrequencyType.NONE) {
      return FrequencyType.NONE;
    } else if (id === FrequencyType.HOURLY) {
      return FrequencyType.HOURLY;
    } else if (id === FrequencyType.WEEKLY) {
      return FrequencyType.WEEKLY;
    } else if (id === FrequencyType.DAILY) {
      return FrequencyType.DAILY;
    } else if (id === FrequencyType.MONTHLY_DAY) {
      return FrequencyType.MONTHLY_DAY;
    } else if (id === FrequencyType.MONTHLY_DATE) {
      return FrequencyType.MONTHLY_DATE;
    } else if (id === FrequencyType.CUSTOM) {
      return FrequencyType.CUSTOM;
    }
    return null;
  }

  // );
  //   FrequencyType
  // .
  //
  //   forEach(
  //
  // (
  //   value: FrequencyType
  // ) => {
  //   if(value
  //
  // .
  //
  //   valueOf()
  //
  // ===
  //   id
  // ) {
  //   return
  //   value;
  // }
  // })
  //
  // return null;
  // }

  public serialize(frequencyType: FrequencyType): string | null {
    if (frequencyType) {
      return frequencyType.valueOf();
    }
    return null;
  }
}
