import { JsonObject, JsonProperty } from 'json2typescript';
import { DatetimeConverter } from '../converter/datetime.converter';
import { Days } from '../days.model';
import { DaysConverter } from '../converter/days.converter';
import { FrequencyOccurence } from '../frequency-occurrence.model';
import { FrequencyOccurenceConverter } from '../converter/frequency-occurrence.converter';
import { FrequencyTypeConverter } from '../converter/frequency-type.converter';
import { ModelAbstract } from '../model.abstract';

export enum FrequencyType {
  NONE = 'NONE',
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  WEEKLY = 'WEEKLY',
  MONTHLY_DAY = 'MONTHLY_DAY',
  MONTHLY_DATE = 'MONTHLY_DATE',
  CUSTOM = 'CUSTOM',
}

@JsonObject('FrequencyPolicy')
export class FrequencyPolicy extends ModelAbstract {
  @JsonProperty('dayOfTheWeek', DaysConverter, true)
  public dayOfTheWeek: Days | null = null;

  @JsonProperty('occurence', FrequencyOccurenceConverter, false)
  public occurence: FrequencyOccurence | null = null;

  @JsonProperty('period', Number, true)
  public period: number | null = 0;

  @JsonProperty('startTime', DatetimeConverter, true)
  public startTime: Date | null = new Date();

  @JsonProperty('type', FrequencyTypeConverter, true)
  public type: FrequencyType | null = FrequencyType.NONE;
}
