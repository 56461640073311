import { EntityState, ID } from '@datorama/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Injectable } from '@angular/core';
import { ModelAbstract } from '@al/model';
import { Observable } from 'rxjs';

/**
 * Default akita filter service
 *  you must provide the model type MODEL, and the EntityState for the MODEL type
 */
@Injectable({
  providedIn: 'root',
})
export abstract class AkitaFilterService<
  MODEL extends ModelAbstract,
  STATE extends EntityState<MODEL>
> {
  /**
   * Warning : parameter must be instanciated by the children
   * @param akitaFilters
   */
  public constructor(protected akitaFilters: AkitaFiltersPlugin<STATE>) {}

  /**
   * Add a filter to the service
   * @param filter
   */
  public addFilter(
    field: string,
    filteredValue: any,
    predicate: (value: MODEL) => boolean
  ): void {
    const filter = {
      id: field,
      value: filteredValue,
      predicate: predicate,
    };

    if (this.akitaFilters) {
      this.akitaFilters.setFilter(filter);
    }
  }

  /**
   * Clear All filter
   */
  public clear(): void {
    if (this.akitaFilters) {
      this.akitaFilters.clearFilters();
    }
  }

  /**
   * Get Filter result by an observable
   */
  public getFilter(): Observable<MODEL[]> {
    if (this.akitaFilters) {
      const selectAllByFilters = this.akitaFilters.selectAllByFilters();
      return selectAllByFilters as Observable<MODEL[]>;
    }
    return new Observable() as Observable<MODEL[]>;
  }

  /**
   * Get value for specific filter
   * @param id
   */
  public getFilterValue(id: string) {
    if (this.akitaFilters) {
      this.akitaFilters.getFilterValue(id);
    }
  }

  /**
   * Remove specific Filter
   * @param id
   */
  public removeFilter(id: ID): void {
    if (this.akitaFilters) {
      this.akitaFilters.removeFilter(id);
    }
  }

  // public abstract setAkitaFilterPlugins(): void;
}
