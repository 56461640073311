import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('ProcessGroup')
export class ProcessGroup extends ModelAbstract {
  @JsonProperty('id', String, true)
  public id: string | null = null;

  @JsonProperty('name', String, true)
  public name: string | null = null;

  @JsonProperty('number', String, true)
  public number: string | null = null;

  @JsonProperty('productionUnitId', String, true)
  public productionUnitId: string | null = null;

  @JsonProperty('type', String, true)
  public type: string | null = null;
}
