import { AkitaFilterService, SchedulerQuery, SchedulerState } from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Injectable } from '@angular/core';
import { SchedulerItem } from '@al/model';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class SchedulerFilterService extends AkitaFilterService<
  SchedulerItem,
  SchedulerState
> {
  public constructor(protected siteQuery: SchedulerQuery) {
    super(new AkitaFiltersPlugin<SchedulerState>(siteQuery));
  }
}
