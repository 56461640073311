import { AuthGuard, pyrometreRoutes, rondeRoute } from '@al/angular-route';
import {
  Paths,
  dashboardRoute,
  loginRoutes,
  pdfRoute,
  schedulerRoutes,
  siteRoutes,
  templateRoutes,
  usersRoute,
} from '@al/angular-route';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: Paths.CALLBACK,
    redirectTo: '/' + Paths.DASHBOARD,
    pathMatch: 'full',
  },
  {
    path: Paths.DASHBOARD,
    children: dashboardRoute,
    canActivate: [AuthGuard],
  },
  // {
  //   path: Paths.PDF + '/*',
  //   children: pdfRoute,
  // },
  pdfRoute,
  usersRoute,
  siteRoutes,
  templateRoutes,
  pyrometreRoutes,
  rondeRoute,
  schedulerRoutes,
  {
    path: Paths.LOGIN,
    children: loginRoutes,
  },
  {
    path: '**',
    redirectTo: '/' + Paths.DASHBOARD,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
