import { AccountWidgetComponent } from './account-widget.component';
import { AlAccountResetPasswordDialogComponent } from './al-account-reset-password-dialog.component';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from '@al/confirm-dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { ResetPasswordModule } from '@eur/reset-password';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [AccountWidgetComponent, AlAccountResetPasswordDialogComponent],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,
    ResetPasswordModule,
  ],
  exports: [AccountWidgetComponent],
})
export class AccountWidgetModule {}
