import { HttpClient, HttpParams } from '@angular/common/http';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@al/model';

@Injectable({
  providedIn: 'root',
})
export class UserRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public getConnectedUser(): Observable<User> {
    const httpParams = new HttpParams({});

    return super.get<User>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.user.profileDetails}`,
      httpParams,
      this.getUserClazz()
    );
  }

  protected getUserClazz(): typeof User {
    return User;
  }
}
