import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FurnaceLight } from '@al/model';

export function furnaceLightValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value && control.value instanceof FurnaceLight
      ? null
      : { notAnItem: { value: control.value } };
  };
}
