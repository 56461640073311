export enum ComponentType {
  CHECKBOX = 'checkbox',
  MULTI_CHECKBOX = 'multiple_checkbox',
  TEXT = 'text',
  NOTE = 'note',
  SECTION = 'section',
  WHEEL_PICKER = 'wheel_picker',
  MEASURE = 'measure',
  GATE = 'gate',
}
