import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ComponentType,
  GateWidget,
  PycosoMeasure,
  RondeFull,
  RondeGate,
  RondeLight,
  RondeTube,
  TemplateFull,
  TubeFull,
  Validation,
  Widget,
} from '@al/model';
import { RondeFullService, RondeLightService } from '@al/services';
import { take, takeUntil } from 'rxjs';
import { FormGroupDirective } from '@angular/forms';
import { GenericComponent } from '@al/generic-components';
import { InstanceAnswerService } from './services/instance-answer.service';
import { MatDialog } from '@angular/material/dialog';
import { Owner } from 'projects/model/src/lib/template/owner.model';
import { StatusEnum } from '@al/referentiel';
import { TemplateModelService } from '@al/dnd-service';
import { WidgetStatus } from '@al/widget-components';

@Component({
  selector: 'al-preview-component',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  providers: [FormGroupDirective],
})
export class PreviewComponent extends GenericComponent implements OnInit {
  @ViewChild('confirmDialog', { static: true })
  public confirmDialog: TemplateRef<any> | undefined;

  @Input()
  public displayTemplate = true;

  @ViewChild('excelDialog', { static: true })
  public excelDialog: TemplateRef<any> | undefined;

  @ViewChild('exportExcelDialogKo')
  public exportExcelDialogKo!: TemplateRef<any>;

  @ViewChild('exportPDFDialogKo')
  public exportPDFDialogKo!: TemplateRef<any>;

  @Input()
  public instance: RondeLight = new RondeLight();

  @Input()
  public isButton = true;

  @ViewChild('pdfDialog', { static: true })
  public pdfDialog: TemplateRef<any> | undefined;

  @Input()
  public signedOn = false;

  public displayValidation = true;

  public displayedColumns: string[] = [
    'peephole',
    'tube',
    'rawTemperature',
    'pyrometer',
    'timestamp',
  ];

  public pycoso: boolean = false;

  public pycosoDatasource: PycosoMeasure[] = [];

  public ronde: RondeFull = new RondeFull();

  public statusEnum = StatusEnum;

  public template: TemplateFull = new TemplateFull();

  public widgetStatusEnum = WidgetStatus;

  public constructor(
    protected templateModelService: TemplateModelService,
    protected rondeFullService: RondeFullService,
    protected rondeService: RondeLightService,
    protected answerService: InstanceAnswerService,
    public dialog: MatDialog,
    protected formGroupDirective: FormGroupDirective
  ) {
    super();
  }

  public exportOneExcel(id: number) {
    if (this.excelDialog) {
      this.dialog.open(this.excelDialog, {
        height: '200px',
        width: '400px',
      });
    }

    this.rondeService
      .exportOneExcel(id)
      .pipe(take(1))
      // .subscribe((response) => {
      // });
      .subscribe({
        next: (response) => {
          this.dialog.closeAll();
          window.open(response.body.url, '_blank');
        },
        error: (error) => {
          console.error('Export excel', error);
          this.openDialogInfo(this.exportExcelDialogKo);
        },
      });
  }

  public exportPdf(id: number, date: Date) {
    if (this.pdfDialog) {
      this.dialog.open(this.pdfDialog, {
        height: '200px',
        width: '400px',
      });
    }
    this.rondeService
      .exportPdf(id, date)
      .pipe(take(1))
      // .subscribe((response) => {
      // });
      .subscribe({
        next: (response) => {
          this.dialog.closeAll();
          window.open(response.body.presignedUrl, '_blank');
        },
        error: (error) => {
          console.error('export pdf', error);
          this.openDialogInfo(this.exportPDFDialogKo);
        },
      });
  }

  public getCategory(): string {
    if (this.template) {
      if (this.template.category) {
        return this.template.category.label;
      }
    }
    return '';
  }

  public getExecutorValidation(): Validation | null {
    if (!this.displayTemplate) {
      return this.answerService.getExecutorValidation();
    }
    return null;
  }

  public getOwner(): Owner {
    if (this.displayTemplate) {
      return this.answerService.getTemplateCreator();
    } else {
      return this.answerService.getInstanceCreator();
    }
  }

  public getStatus(): string {
    if (this.ronde.instance) {
      if (this.ronde.instance.status) {
        return this.ronde.instance.status.label;
      }
    }
    return '';
  }

  public getSupervisorValidation(): Validation | null {
    if (!this.displayTemplate) {
      return this.answerService.getSuperviserValidation();
    }
    return null;
  }

  public isMcq(widget: Widget): boolean {
    return widget.componentType === ComponentType.MULTI_CHECKBOX;
  }

  public isMeasure(widget: Widget): boolean {
    return widget.componentType === ComponentType.MEASURE;
  }

  public isNote(widget: Widget): boolean {
    return widget.componentType === ComponentType.NOTE;
  }

  public isSection(widget: Widget): boolean {
    return widget.componentType === ComponentType.SECTION;
  }

  public isText(widget: Widget): boolean {
    return widget.componentType === ComponentType.TEXT;
  }

  public get displayExport(): boolean {
    return this.instance.instance?.category?.id !== '7';
  }

  public get displaySignature(): boolean {
    return this.displayValidation && this.signedOn;
  }

  public isValidation(widget: Widget): boolean {
    return widget.componentType === ComponentType.CHECKBOX;
  }

  public isWheelpicker(widget: Widget): boolean {
    return widget.componentType === ComponentType.WHEEL_PICKER;
  }

  public ngOnInit(): void {
    if (this.displayTemplate) {
      this.template = this.templateModelService.getModel();
    } else {
    }
  }

  public openDialogInfo(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public openDialogWithRef(ref: TemplateRef<any>) {
    if (this.displayTemplate) {
      this.template = this.templateModelService.getModel();
      this.answerService.setTemplate(this.template);
      this.pycoso = this.template.category?.id === '7';
      if (this.pycoso) {
        this.initPycoso();
      }
    } else {
      this.rondeFullService
        .getRondeFull(this.instance)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((ronde: RondeFull) => {
          this.ronde = ronde;
          this.answerService.setInstances(this.ronde);
          if (this.ronde.template instanceof TemplateFull) {
            this.template = this.ronde.template;
            this.pycoso = this.template.category?.id === '7';
            if (this.pycoso) {
              this.initPycoso();
            }
          }
        });
    }
    this.dialog.open(ref);
  }

  protected confirmValidation(): void {
    if (this.confirmDialog) {
      this.dialog.open(this.confirmDialog, {
        height: '200px',
        width: '400px',
      });
    }
  }

  protected validate(): void {
    this.rondeService
      .validate(this.ronde.instance!.id)
      // .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((ronde: RondeFull) => {
        this.displayValidation = false;
        this.ronde = ronde;

        this.templateModelService.setSupervisionValidation(ronde);
        this.answerService.setInstances(this.ronde);
        if (this.ronde.template instanceof TemplateFull) {
          this.template = this.ronde.template;
          this.pycoso = this.template.category?.id === '7';
          if (this.pycoso) {
            this.initPycoso();
          }
        }
      });
  }

  private initPycoso(): void {
    const datasource: PycosoMeasure[] = [];
    if (this.displayTemplate) {
      this.template.templateBody.forEach((widget: Widget) => {
        const gateWidget = widget as GateWidget;
        gateWidget.tube.forEach((tube: TubeFull) => {
          if (tube.shoot) {
            const measure = new PycosoMeasure();
            measure.peepholeLabel = gateWidget.label;
            measure.tubeLabel = tube.name;
            datasource.push(measure);
          }
        });
      });
    } else {
      this.ronde.instance?.pycosoBody?.gates.forEach((gate: RondeGate) => {
        gate.tubes.forEach((tube: RondeTube) => {
          const measure = new PycosoMeasure();
          measure.tubeLabel = tube.label;
          measure.peepholeLabel = gate.label;
          measure.rawTemperatureCelsius = tube.rawTemperatureCelsius;
          measure.pyrometer = tube.usePyrometer;
          measure.timestamp = tube.timestamp;
          datasource.push(measure);
        });
      });
    }
    this.pycosoDatasource = datasource.sort(PycosoMeasure.compare);
  }
}
