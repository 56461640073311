import { Observable, map } from 'rxjs';
import { UserConnectedQuery, UserConnectedStore } from '@al/akita';
import { Injectable } from '@angular/core';
import { User } from '@al/model';
import { UserInfoService } from '@al/session';
import { UserRestService } from '@al/rest-services';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUser: User | undefined;

  public constructor(
    protected userRestService: UserRestService,
    protected userInfoService: UserInfoService,
    protected userQuery: UserConnectedQuery,
    protected userStore: UserConnectedStore
  ) {}

  public getConnectedUser(): Observable<User> {
    return this.userRestService.getConnectedUser();
  }

  public getCurrent(): User | undefined {
    return this.currentUser;
  }

  public getCurrentObservable(): Observable<User | undefined> {
    return this.userQuery.selectActive();
  }

  public load(): Observable<void> {
    return this.getConnectedUser().pipe(
      map((data: User) => {
        this.userStore.set([data]);
        this.userStore.setActive(data.id);
        this.currentUser = data;
        // if (
        //   !localStorage.getItem(LocalStorageKey.DEFAULT_SITE_OBJECT) &&
        //   this.currentUser.sites.length > 0
        // ) {
        // }
      })
    );
  }
}
