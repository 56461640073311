import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { GateDirections } from './gate-directions.enum';
import { Widget } from './widget.model';

@JsonObject('GateModelWidget')
export class GateModelWidget extends Widget {
  @JsonProperty('called', String, true)
  public called: string = '';

  @JsonProperty('direction', String, true)
  public direction: string = GateDirections.ltr;

  @JsonProperty('gateId', String, true)
  public gateId: string = '';

  @JsonProperty('gtCenter', String, true)
  public gtCenter: string | undefined = undefined;

  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('level', String, true)
  public level: string = '';

  @JsonProperty('orientation', String, true)
  public orientation: string = '';

  @JsonProperty('type', String, true)
  public type: string = '';

  // public gate: Gate | undefined = undefined;

  public constructor() {
    super();
    this.componentType = ComponentType.GATE;
  }

  public override copy(widget: GateModelWidget) {
    super.copy(widget);
    this.called = widget.called;
    this.direction = widget.direction;
    this.gateId = widget.gateId;
    this.gtCenter = widget.gtCenter;
    this.label = widget.label;
    this.level = widget.level;
    this.orientation = widget.orientation;
    this.type = widget.type;
  }
}
