import { HttpClient, HttpParams } from '@angular/common/http';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Unit } from '@al/model';

@Injectable({
  providedIn: 'root',
})
export class UnitRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public getListOfUnit(): Observable<Unit[]> {
    return super.getList<Unit>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.unit.list}`,
      new HttpParams(),
      this.getUnitClazz()
    );
  }

  protected getUnitClazz(): typeof Unit {
    return Unit;
  }
}
