<mat-expansion-panel class="al-widget al-widget-info" *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="compomentIcon">touch_app</mat-icon>
      <span class="label">{{'WIDGET_WHEELPICKER' | translate}} : {{wheelpickerWidget.label}}</span>
    </mat-panel-title>
    <mat-panel-description class="icons">

      <mat-icon (click)="delete()">delete</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">

    <div class="full-width-label wheelpicker-widget-info">
      <mat-form-field class="text-label" appearance="outline" floatLabel="always">
        <mat-label>{{ "WHEELPICKER_WIDGET_TITLE" | translate}}
        </mat-label>
        <textarea [formControlName]="formControlNames.label" (change)="setValue(formControlNames.label)"
                  matInput></textarea>
      </mat-form-field>
      <span class="checkbox-container">
        <mat-checkbox [formControlName]="formControlNames.disableControl"
                      (change)="setValue(formControlNames.disableControl)">{{"WHEELPICKER_CONTROL_OFF" | translate}}</mat-checkbox>
        <mat-checkbox [formControlName]="formControlNames.isMandatory"
                      (change)="setValue(formControlNames.isMandatory)">{{"IS_MANDATORY_QUESTION" | translate}}</mat-checkbox>

      </span>

    </div>

    <div class="widget-row">
      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "WHEELPICKER_WIDGET_MIN_RANGE" | translate}}
          </mat-label>
          <input type="number" [formControlName]="formControlNames.minRange"
                 (change)="setValue(formControlNames.minRange)"
                 matInput>
          <mat-error *ngIf="formGroup.get(formControlNames.minRange)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.minRange)?.hasError('max')
          && !formGroup.get(formControlNames.minRange)?.hasError('required')">
            {{ 'WHEELPICKER_MAX_RANGE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>

      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "WHEELPICKER_WIDGET_MAX_RANGE" | translate}}
          </mat-label>
          <input type="number" [formControlName]="formControlNames.maxRange"
                 (change)="setValue(formControlNames.maxRange)"
                 matInput>
          <mat-error *ngIf="formGroup.get(formControlNames.maxRange)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.maxRange)?.hasError('min')
          && !formGroup.get(formControlNames.maxRange)?.hasError('required')">
            {{ 'WHEELPICKER_MIN_RANGE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>

      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "WHEELPICKER_WIDGET_DECIMAL_INDEX" | translate}}
          </mat-label>
          <input type="number" min="0" max="2" step="1" [formControlName]="formControlNames.decimalIndex"
                 (change)="setValue(formControlNames.decimalIndex)"
                 matInput>
          <mat-error *ngIf="formGroup.get(formControlNames.decimalIndex)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.decimalIndex)?.hasError('decimalPrecision')
          && !formGroup.get(formControlNames.decimalIndex)?.hasError('required')">
            {{ 'WHEELPICKER_PRECISION_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>

      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "WHEELPICKER_WIDGET_NOMINAL_VALUE" | translate}}
          </mat-label>
          <span matTooltip="{{'WHEELPICKER_SET_RANGE_FIRST' | translate}}"
                [matTooltipDisabled]="formGroup.get(formControlNames.minRange)?.valid &&
              formGroup.get(formControlNames.maxRange)?.valid">
          <input type="number" [formControlName]="formControlNames.nominalValue"
                 (change)="setValue(formControlNames.nominalValue)"
                 matInput>
        </span>
          <mat-error *ngIf="formGroup.get(formControlNames.nominalValue)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.nominalValue)?.hasError('min')
          && !formGroup.get(formControlNames.nominalValue)?.hasError('required')">
            {{ 'WHEELPICKER_MIN_RANGE_ERROR' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.nominalValue)?.hasError('max')
          && !formGroup.get(formControlNames.nominalValue)?.hasError('required')">
            {{ 'WHEELPICKER_MAX_RANGE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>


    </div>
    <div class="widget-row">
      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "WHEELPICKER_WIDGET_MIN" | translate}}
          </mat-label>
          <span matTooltip="{{'WHEELPICKER_SET_RANGE_FIRST' | translate}}"
                [matTooltipDisabled]="formGroup.get(formControlNames.minRange)?.valid &&
              formGroup.get(formControlNames.maxRange)?.valid">
          <input type="number" [formControlName]="formControlNames.min"
                 (change)="setValue(formControlNames.min)"
                 matInput>
        </span>
          <mat-error *ngIf="formGroup.get(formControlNames.min)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.min)?.hasError('min')
          && !formGroup.get(formControlNames.min)?.hasError('required')">
            {{ 'WHEELPICKER_MIN_RANGE_ERROR' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.min)?.hasError('max')
          && !formGroup.get(formControlNames.min)?.hasError('required')">
            {{ 'WHEELPICKER_MAX_RANGE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>
      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "WHEELPICKER_WIDGET_MAX" | translate}}
          </mat-label>
          <span matTooltip="{{'WHEELPICKER_SET_RANGE_FIRST' | translate}}"
                [matTooltipDisabled]="formGroup.get(formControlNames.minRange)?.valid &&
              formGroup.get(formControlNames.maxRange)?.valid">
          <input type="number"
                 [formControlName]="formControlNames.max"
                 (change)="setValue(formControlNames.max)"
                 matInput>
        </span>
          <mat-error *ngIf="formGroup.get(formControlNames.max)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.max)?.hasError('min')
          && !formGroup.get(formControlNames.max)?.hasError('required')">
            {{ 'WHEELPICKER_MIN_RANGE_ERROR' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroup.get(formControlNames.max)?.hasError('max')
          && !formGroup.get(formControlNames.max)?.hasError('required')">
            {{ 'WHEELPICKER_MAX_RANGE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

      </span>
      <span class="widget-row-component">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ "UNIT" | translate}}
          </mat-label>
          <input type="text"
                 matInput
                 [formControlName]="formControlNames.unit"
                 [matAutocomplete]="auto">
          <mat-autocomplete [displayWith]="displayUnit" #auto="matAutocomplete"
                            (optionSelected)='setValue(formControlNames.unit)'>

            <mat-option *ngFor="let unit of this.filteredUnits | async " [value]="unit"
                        selected="unit.uuid === this.wheelpickerWidget.unit.uuid">
              {{unit.abbreviation}} - {{unit.description}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="formGroup.get(formControlNames.unit)?.hasError('required')">
            {{ 'REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

      </span>


      <span class="widget-row-component">

      </span>
    </div>
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "WHEELPICKER_WIDGET_TOOLTIP" | translate}}
      </mat-label>

      <input type="text" [formControlName]="formControlNames.tooltip" (change)="setValue(formControlNames.tooltip)"
             matInput>
    </mat-form-field>
    <!--    <mat-form-field appearance="outline" class="full-width" floatLabel="always">-->
    <!--      <mat-label>{{ "UNIT" | translate}}-->
    <!--      </mat-label>-->
    <!--      <input type="text"-->
    <!--             matInput-->
    <!--             [formControlName]="formControlNames.unit"-->
    <!--             [matAutocomplete]="auto">-->
    <!--      <mat-autocomplete [displayWith]="displayUnit" #auto="matAutocomplete"-->
    <!--                        (optionSelected)='setValue(formControlNames.unit)'>-->

    <!--        <mat-option *ngFor="let unit of this.filteredUnits | async " [value]="unit"-->
    <!--                    selected="unit.uuid === this.wheelpickerWidget.unit.uuid">-->
    <!--          {{unit.abbreviation}} - {{unit.description}}-->
    <!--        </mat-option>-->
    <!--      </mat-autocomplete>-->
    <!--      <mat-error *ngIf="formGroup.get(formControlNames.unit)?.hasError('required')">-->
    <!--        {{ 'REQUIRED' | translate }}-->
    <!--      </mat-error>-->
    <!--    </mat-form-field>-->
    <al-asset-input-display (returnable)="getAssets($event)"></al-asset-input-display>

  </div>
</mat-expansion-panel>
<mat-card class="al-widget-mcq" *ngIf="widgetStatus===widgetStatusEnum.WIDGET" [formGroup]="formGroup">
  <mat-icon>touch_app</mat-icon>
  {{'WIDGET_WHEELPICKER' | translate}}
</mat-card>

<mat-card *ngIf="widgetStatus===widgetStatusEnum.PREVIEW" class="al-widget-preview"
          [ngClass]="{'hasAnomaly': hasAnomaly()}">
  <mat-card-header style="margin: 0px">
    <div class="widget-header">
      <mat-icon class="compomentIcon">touch_app</mat-icon>
      <span class="title">  {{'WIDGET_MEASURE' | translate}}</span>
    </div>

  </mat-card-header>
  <mat-card-content>


    <div class="widget-row">
      <div class="widget-label">{{this.wheelpickerWidget.label}}</div>
      <div class="answer">{{getValue()}} {{getUnit()}}</div>
    </div>
    <div class="widget-row" *ngIf="this.wheelpickerWidget.tooltip">
      <span class="widget-tooltip"> {{wheelpickerWidget.tooltip}}</span>
    </div>
    <div class="widget-row" *ngIf="getComment()">
      <span class="widget-tooltip"> {{getComment()}}</span>
    </div>

    <div class="widget-row" *ngIf="wheelpickerWidget.min!==null ||  wheelpickerWidget.max!==null">
    <span class="widget-constraint" *ngIf="wheelpickerWidget.min!==null">    {{'WHEELPICKER_WIDGET_MIN' | translate}}
      :     {{wheelpickerWidget.min}}  </span>

      <span class="widget-constraint" *ngIf="wheelpickerWidget.max!==null">    {{'WHEELPICKER_WIDGET_MAX' | translate}}
        :     {{wheelpickerWidget.max}}</span>


    </div>
    <al-common-preview [widget]="wheelpickerWidget"></al-common-preview>


  </mat-card-content>
</mat-card>

