export enum FormControlNames {
  label = 'label',
  id = 'id',
  address = 'address',
  Status = 'Status',
  date = 'date',
  aCoeff = 'aCoeff',
  bCoeff = 'bCoeff',
  Site = 'Site',
}
