<!--<mat-card *ngIf="widgetStatus===widgetStatusEnum.EDIT" class="al-widget-info"-->
<!--          [formGroup]="formGroup"-->
<!--&gt;-->
<mat-expansion-panel class="al-widget al-widget-info" *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="compomentIcon">info_outline</mat-icon>
      <span class="label">{{'WIDGET_INFO' | translate}} : {{this.infoWidget.text}}</span>

    </mat-panel-title>
    <mat-panel-description class="icons">

      <mat-icon (click)="delete()">delete</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "INFO_WIDGET_TITLE" | translate}}
      </mat-label>

      <textarea [formControlName]="formControlNames.text" (change)="setValue(formControlNames.text)"
                matInput></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "INFO_WIDGET_TOOLTIP" | translate}}
      </mat-label>

      <input type="text" [formControlName]="formControlNames.link" (change)="setValue(formControlNames.link)" matInput>
    </mat-form-field>
  </div>

</mat-expansion-panel>

<mat-card *ngIf="widgetStatus===widgetStatusEnum.WIDGET" class="al-widget  al-widget-selector"
          [formGroup]="formGroup">
  <mat-icon class="compomentIcon">info_outline</mat-icon>
  <span class="label">  {{'WIDGET_INFO' | translate}}</span>
</mat-card>

<mat-card *ngIf="widgetStatus===widgetStatusEnum.PREVIEW" class="al-widget-preview">
  <mat-card-header>
    <div class="widget-header">
      <mat-icon class="compomentIcon">info_outline</mat-icon>
      <span class="title">  {{'WIDGET_INFO' | translate}}</span>
    </div>

  </mat-card-header>
  <mat-card-content>


    <div class="widget-row">

      <div class="widget-label">{{this.infoWidget.text}}</div>
    </div>


    <div class="widget-row">

      <div class="widget-label">{{this.infoWidget.link}}</div>
    </div>

    <div class="widget-row" *ngIf="getComment()">
     
      <span class="widget-tooltip"> {{getComment()}}</span>
    </div>

  </mat-card-content>
</mat-card>
