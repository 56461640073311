import { Component } from '@angular/core';
import { EnvironmentService } from '@al/environment';
import { SessionService } from '@al/session';

@Component({
  selector: 'al-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public title: string = this.environmentService.env.title;

  public constructor(
    protected environmentService: EnvironmentService,
    protected sessionService: SessionService
  ) {}

  public connect(): void {
    this.sessionService.login();
  }
}
