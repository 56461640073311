import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Equipment } from '@al/model';
import { Injectable } from '@angular/core';

export interface EquipmentState extends EntityState<Equipment>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'equipmentsStore', resettable: true })
export class EquipmentsStore extends EntityStore<EquipmentState> {
  public constructor() {
    super();
  }
}
