import {
  RondeListComponent,
  RondeManagerComponent,
} from '@al/ronde-components';
import { AuthGuard } from '../auth.guard';
import { Paths } from '../paths';
import { Route } from '@angular/router';

export const rondeRoute: Route = {
  path: Paths.ronde.DEFAULT,
  component: RondeManagerComponent,
  children: [
    // {
    //   path: Paths.ronde.EDIT,
    //   component: UsersFormComponent,
    //   canActivate: [AuthGuard],
    // },
    // {
    //   path: Paths.ronde.CREATE,
    //   component: UsersFormComponent,
    //   canActivate: [AuthGuard],
    // },
    {
      path: Paths.ronde.LIST,
      component: RondeListComponent,
      canActivate: [AuthGuard],
    },
  ],
};
