import {
  TemplateLightState,
  TemplateLightStore,
} from '../store/template-light.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class TemplateLightQuery extends QueryEntity<TemplateLightState> {
  public constructor(protected override store: TemplateLightStore) {
    super(store);
  }
}
