import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IdNumberList, TemplateLight } from '@al/model';
import { DefaultPath } from '@al/angular-route';
import { GenericComponent } from '@al/generic-components';
import { MatDialog } from '@angular/material/dialog';
import { TemplateLightService } from '@al/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
})
export class HistoryListComponent extends GenericComponent implements OnInit {
  @ViewChild('exportPDFDialogKo')
  public exportPDFDialogKo!: TemplateRef<any>;

  @ViewChild('pdfDialog', { static: true })
  public pdfDialog: TemplateRef<any> | undefined;

  public templates: TemplateLight[] = [];

  public constructor(
    public dialog: MatDialog,
    protected route: ActivatedRoute,
    protected router: Router,
    protected templateService: TemplateLightService //
  ) {
    super();
  }

  public ngOnInit(): void {
    this.templateService
      .getActives()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.templates = data!;
      });
  }

  protected back(): void {
    this.router.navigate([new DefaultPath().LIST], {
      relativeTo: this.route.parent,
    });
  }

  protected exportPdf(): void {
    let ids: IdNumberList = new IdNumberList();
    this.templates.forEach((template) => {
      ids.ids.push(template.id);
    });

    if (this.pdfDialog) {
      this.dialog.open(this.pdfDialog, {
        height: '200px',
        width: '400px',
      });
    }

    this.templateService
      .getGmpPdf(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (url: string) => {
          this.dialog.closeAll();
          window.open(url, '_blank');
        },
        () => {
          this.dialog.open(this.exportPDFDialogKo);
        }
      );
  }
}
