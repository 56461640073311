import { DashboardComponent } from '@al/dashboard';

import { Routes } from '@angular/router';

export const dashboardRoute: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
];
