import { UsersState, UsersStore } from '../store/users.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';
import { User } from '@al/model';

@Injectable({ providedIn: 'root' })
export class UsersQuery extends QueryEntity<UsersState> {
  public site = this.selectAll();

  public constructor(protected override store: UsersStore) {
    super(store);
  }

  public getUsers(id: string): Observable<User | undefined> {
    return this.selectEntity(id);
  }
}
