import { EnvironmentOptions } from '@al/environment';

export const environment: EnvironmentOptions = {
  production: false,
  version: '7.0.1',
  title: 'eRound (master)',
  copyright: 'copyright Airliquide 2022',

  apiUrl: 'http://localhost:3100/',
  apigwUrl: 'https://qu5dij8xsa.execute-api.eu-west-1.amazonaws.com/develop/',
  apigwUrlDatalake:
    'https://jzvmpbnql1.execute-api.eu-west-1.amazonaws.com/master/',

  acgUserPoolDomain:
    'eapps-auth-develop-users.auth.eu-west-1.amazoncognito.com',
  acgUserPoolLogoutEndpoint: '/logout',
  acgUserPoolClientId: '5jltgeljla5be0rcv60q0ul46o',
  acgUserPoolId: 'eu-west-1_9HRItXMMX',
  acgUserPoolClientCallback: '/callback',
  googleClientId:
    '53352395356-h4g53aelc6qou8aki9787v1khjh8ii6f.apps.googleusercontent.com',
};
