import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('Validator')
export class Validator extends ModelAbstract {
  @JsonProperty('email', String)
  public email: string = '';

  @JsonProperty('firstName', String)
  public firstName: string = '';

  @JsonProperty('id', Number)
  public id: number = 0;

  @JsonProperty('lastName', String)
  public lastName: string = '';
}
