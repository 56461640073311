<div class="al-site-form">
  <div class="header">
    <button mat-raised-button (click)="back()" color="primary">
      <mat-icon class="back" >arrow_back</mat-icon>
      {{ "BACK_USER_LIST" | translate}}
    </button>
    <h1 translate class="parent-component-title" *ngIf="this.creation">CREATION_USER</h1>
    <h1 translate class="parent-component-title" *ngIf="!this.creation">UPDATE_USER</h1>
  </div>


  <mat-card>
    <div class="navigation" *ngIf="!this.creation">
      <mat-icon class="prev" (click)="previous()" matTooltip="{{'USER_FORM_PREVIOUS'|translate}}">skip_previous
      </mat-icon>
      <mat-icon class="next" (click)="next()" matTooltip="{{'USER_FORM_NEXT'|translate}}">skip_next</mat-icon>
    </div>

    <form [formGroup]="form" (submit)="false">

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "USER_FIRSTNAME" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.firstName" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "USER_LASTNAME" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.lastName" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "USER_EMAIL" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.email" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "USER_SITES" | translate}}
        </mat-label>
        <mat-select [formControlName]="formControlNames.sites" [(value)]="selectedSites"
         multiple (selectionChange)="setFormDirty()" (closed)="clearSearch($event)">
          <div class="select-container">
            <mat-optgroup >
              <mat-form-field style="width:100%;">
                <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput [formControl]="searchTextboxControl">
                  <button [disableRipple]="true" *ngIf="search.value"
                  matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
                <mat-icon >close</mat-icon>
                </button>
                </mat-form-field>
              </mat-optgroup>
              <mat-optgroup *ngIf="(filteredOptions | async)?.length === 0">
                <div>No results found!</div>
            </mat-optgroup>

          <mat-option *ngFor="let site of filteredOptions | async" [value]="site.id">{{site.maximoSiteId}}: {{site.label}}</mat-option>
        </div>
        </mat-select>

        <!-- <input type="text" [formControlName]="formControlNames.sites" matInput  > -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "USER_PROFILE" | translate}}
        </mat-label>
        <mat-select [formControlName]="formControlNames.profileType" [(value)]="selectedProfile"
         (selectionChange)="setFormDirty()">
          <mat-option *ngFor="let profile of profiles" [value]="profile.id">{{profile.label}}</mat-option>
        </mat-select>
      </mat-form-field>

<!--      <mat-form-field appearance="outline" class="full-width" floatLabel="always">-->
      <span class="checkbox-container">
        <mat-label class="required-field">{{ "SUPERVISOR" | translate}}
        </mat-label>
        <mat-checkbox [aria-label]="'SUPERVISOR' | translate"
                      [formControlName]="formControlNames.supervisor"></mat-checkbox>
<!--        <mat-select [formControlName]="formControlNames.profileType" [(value)]="selectedProfile"-->
<!--                    (selectionChange)="setFormDirty()">-->
<!--          <mat-option *ngFor="let profile of profiles" [value]="profile.id">{{profile.label}}</mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
      </span>
        <div class="row">
        <div class="col buttons">
          <button mat-button [disabled]="!form.dirty" color="primary" translate (click)="cancel()">CANCEL</button>
          <button mat-button [disabled]="!form.valid || !form.dirty" color="accent" translate (click)="save()">SAVE
          </button>
        </div>
      </div>
    </form>


    <ng-template #infoDialog>
      <al-info-dialog (confirm)='doSomething()'>
        <div title translate>{{infoDialogTitle}}</div>
        <div content translate>{{infoDialogMessage}}</div>
        <div confirm-button translate>OK</div>
      </al-info-dialog>
    </ng-template>
    <ng-template #cancelDialog>
      <al-confirm-dialog (confirm)='clean()'>
        <div title translate>CANCEL</div>
        <div content translate>AGREE_TO_CANCEL</div>
        <div cancel-button translate>NO</div>
        <div confirm-button translate>YES</div>
      </al-confirm-dialog>
    </ng-template>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_OK</div>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_OK_TITLE</div>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_KO</div>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_KO_TITLE</div>

