export class GenericPair<T> {
  public id: T | null = null;

  public label = '';

  public constructor(id: T, label: string) {
    this.id = id;
    this.label = label;
  }
}

export class Pair extends GenericPair<string> {}

export class PairNumeric extends GenericPair<number> {}
