import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { HistoryItem } from '@al/model';
import { Injectable } from '@angular/core';

export interface HistoryState extends EntityState<HistoryItem>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'history', resettable: true, idKey: 'id' })
export class HistoryStore extends EntityStore<HistoryState> {
  public constructor() {
    super();
  }
}
