import { AkitaFilterService, SiteQuery, SiteState } from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Injectable } from '@angular/core';
import { Site } from '@al/model';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class SiteFilterService extends AkitaFilterService<Site, SiteState> {
  public constructor(protected siteQuery: SiteQuery) {
    super(new AkitaFiltersPlugin<SiteState>(siteQuery));
  }
}
