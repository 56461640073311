import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Unit } from '@al/model';

export interface UnitState extends EntityState<Unit>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'unit', idKey: 'uuid', resettable: true })
export class UnitStore extends EntityStore<UnitState> {
  public constructor() {
    super();
  }
}
