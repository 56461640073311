<div class="al-template-list">
  <div class="header">
    <h1 translate class="parent-component-title">PYROMETRE_LIST_TITLE</h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->


  </div>

  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">0

    <!-- <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.id.valueOf())"
          (OnSearch)="filterId($event,formControlNames.id.valueOf())"
          [field]="formControlNames.id.valueOf()"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{'PYROMETRE_ID' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="id" class="id">
        {{ element.id }}
      </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef class="category">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.label.valueOf())"
          (OnSearch)="filterLabel($event,formControlNames.label.valueOf())"
          [field]="formControlNames.label.valueOf()"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{'PYROMETRE_LABEL' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'PYROMETRE_LABEL' | translate }}" class="title">
        {{ element.label | translate }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef class="title">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.address.valueOf())"
          (OnSearch)="filterAddress($event,formControlNames.address.valueOf())"
          [field]="formControlNames.address.valueOf()"
          fieldType="input"
          storageNameSpace="template-list-filters"
          title="{{'PYROMETRE_ADDRESSE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'PYROMETRE_ADDRESSE' | translate }}" class="title">
        {{ element.macAddress }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Status">
      <mat-header-cell *matHeaderCellDef class="status">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.Status.valueOf())"
          (OnSearch)="filterStatus($event,formControlNames.Status.valueOf())"
          [field]="formControlNames.Status.valueOf()"
          [values]="status"
          [isSelect]="true"
          fieldType="select"
          storageNameSpace="template-list-filters"
          title="{{'PYROMETRE_STATUT' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'PYROMETRE_STATUT' | translate }}" class="status">
        <mat-icon *ngIf="!checkDate(element.calibrationDate)">error_outline</mat-icon>
        <mat-icon *ngIf="checkDate(element.calibrationDate)">check_circle_outline</mat-icon>
        <!-- {{ element.macAddress }} -->
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef class="title">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.date.valueOf())"
          (OnSearch)="filterDate($event,formControlNames.date.valueOf())"
          [field]="formControlNames.date.valueOf()"
          fieldType="date"
          storageNameSpace="template-list-filters"
          title="{{'PYROMETRE_DATE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'PYROMETRE_DATE' | translate }}" class="title">
        {{ element.calibrationDate | localizedDate}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="actions" translate>

        <button mat-button (click)="create()" color="primary">
          {{'CREATE_PYROMETRE' | translate}}
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="" class="actions">
        <button mat-icon-button
        (click)="edit(element.id)" [matTooltip]="'EDIT' | translate">
          <mat-icon>edit</mat-icon>

        </button>

        <button mat-icon-button
        (click)="openDialogWithRef(deleteDialog, element)" [matTooltip]="'DELETE' | translate">
          <mat-icon >delete</mat-icon>

        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: displayedColumns"

    ></mat-row>
  </mat-table>

  <mat-paginator #paginator [pageSize]="15" [pageSizeOptions]="[15, 25, 50,  100]"></mat-paginator>

</div>

<ng-template #deleteDialog let-data>
  <al-confirm-dialog (confirm)='deletePyro(data)'>
    <div title translate>DELETE</div>
    <div content translate>AGREE_TO_DELETE</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogKo>
  <al-confirm-dialog >
    <div title translate>DELETE_DIALOG_MESSAGE_KO_TITLE_PYRO</div>
    <div content translate>DELETE_DIALOG_MESSAGE_KO_PYRO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogOk>
  <al-confirm-dialog >
    <div title translate>DELETE_DIALOG_MESSAGE_OK_TITLE_PYRO</div>
    <div content translate>DELETE_DIALOG_MESSAGE_OK_PYRO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>



