class EntityEndpoint {
  public list = 'entity';
}

class TemplateEndpoint {
  public create = 'templates';

  public duplicate = 'templates/duplicate/';

  public get = 'templates/';

  public history = 'templates/change-log/';

  public list = 'templates';

  public pdf = 'templates/history/pdf';

  public save = 'templates/';
}

class RondeEndpoint {
  public get = 'forminstances/';

  public list = 'forminstances';

  public pdf = 'pdf';

  public save = 'forminstances/';

  public supervisor = 'forminstances/supervisor';
}

class DataLakeEndpoint {
  public asset = 'datalake/Asset/All';

  public processGroup = 'datalake/ProcessGroup/All';

  public productionUnit = 'datalake/ProductionUnit/All';
}

class FilesEndpoint {
  public export = 'files/export/';

  public exportAsync = this.export + 'async';
}

class FurnacesEndpoint {
  public get = 'furnace/';

  public list = 'furnaces';
}

class SchedulerEndpoint {
  public list = 'formtemplates/scheduledrounds';
}

class SiteEndpoint {
  public edit = 'site/';

  public get = 'site/';

  public list = 'sites';
}

class UnitEndpoint {
  public list = 'units';
}

class UserEndpoint {
  public profileDetails = 'user/profile-details';
}

class UsersEndpoint {
  public edit = 'users/';

  public list = 'users';
}

class PyrometreEndpoint {
  public edit = 'pyrometers/';

  public list = 'pyrometers';
}

class KpisEndpoint {
  public kpis = 'kpis';
}

export class AwsEndPoint {
  public static datalake: DataLakeEndpoint = new DataLakeEndpoint();

  public static entity: EntityEndpoint = new EntityEndpoint();

  public static files: FilesEndpoint = new FilesEndpoint();

  public static furnaces: FurnacesEndpoint = new FurnacesEndpoint();

  public static kpis: KpisEndpoint = new KpisEndpoint();

  public static pyrometre: PyrometreEndpoint = new PyrometreEndpoint();

  public static ronde: RondeEndpoint = new RondeEndpoint();

  public static scheduler: SchedulerEndpoint = new SchedulerEndpoint();

  public static site: SiteEndpoint = new SiteEndpoint();

  public static template: TemplateEndpoint = new TemplateEndpoint();

  public static unit: UnitEndpoint = new UnitEndpoint();

  public static user: UserEndpoint = new UserEndpoint();

  public static users: UsersEndpoint = new UsersEndpoint();
}
