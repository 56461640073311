import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('TubeConfig')
export class TubeConfig extends ModelAbstract {
  @JsonProperty('id', String, true)
  public id: string = '';

  @JsonProperty('label', String, true)
  public name: string = '';

  @JsonProperty('shoot', Boolean, true)
  public shoot: boolean = true;
}
