import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('Answer')
export class Answer extends ModelAbstract {
  @JsonProperty('comment', String, true)
  public comment: string | null = null;

  @JsonProperty('id', String)
  public id: string | null = null;

  @JsonProperty('measure', Number, true)
  public measure: number = 0;

  @JsonProperty('note', String, true)
  public note: string = '';

  @JsonProperty('options', [String], true)
  public options: string[] = [];

  @JsonProperty('value', Boolean, true)
  public value: boolean | undefined = undefined;

  public hasAnomaly: boolean = false;
}

@JsonObject('ValidAnswer')
export class ValidAnswer extends Answer {
  public override hasAnomaly: boolean = false;
}

@JsonObject('AnomalyAnswer')
export class AnomalyAnswer extends Answer {
  public override hasAnomaly: boolean = true;
}
