<mat-expansion-panel class="al-widget al-widget-section"
                     *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header >
    <mat-panel-title class="full-width">
      <mat-icon class="compomentIcon">web_asset</mat-icon>
      <span class="label">{{'WIDGET_SECTION' | translate}}: {{this.sectionWidget.label}}</span>
    </mat-panel-title>
    <mat-panel-description class="icons">

      <mat-icon (click)="delete()">delete</mat-icon>
      <mat-icon (click)="copy()">content_copy</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">

    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "SECTION_WIDGET_TITLE" | translate}}
      </mat-label>
      <input type="text" [formControlName]="formControlNames.label" (change)="setValue(formControlNames.label)"
             matInput>
    </mat-form-field>
    <div class="all-selectors">
      <div class="all-selector">
        <span>{{'ALL_MANDATORY' | translate}}</span>
        <button mat-raised-button (click)="setAllMAndatory(true)">
          {{'ALL' | translate}}
        </button>
        <button mat-raised-button (click)="setAllMAndatory(false)">
          {{'NONE' | translate}}
        </button>
      </div>
      <div class="all-selector">
        <span>{{'ALL_THRESHOLD' | translate}}</span>
        <button mat-raised-button (click)="setAllThresholds(true)">
          {{'ALL' | translate}}
        </button>
        <button mat-raised-button (click)="setAllThresholds(false)">
          {{'NONE' | translate}}
        </button>
      </div>
    </div>
    <div >
      <mat-card
        cdkDropList

        [id]="getListId"
        [cdkDropListData]="this.sectionWidget.children"
        class="children-list"
        [cdkDropListConnectedTo]="getCdkListId"
        (cdkDropListDropped)="drop($event)"


      >

        <mat-card-header>
          <mat-card-sub-title translate>WIDGET_DROP_ZONE</mat-card-sub-title>

        </mat-card-header>
        <div *ngFor="let item of this.sectionWidget.children" style="margin-bottom: 15px;">
          <al-info-widget *ngIf="isText(item)" cdkDrag
                          [widget]="item"
                          (OnDelete)="deleteChildren($event)"
                          (OnDuplicate)="duplicate($event)"
          ></al-info-widget>


          <al-mcq-widget [widget]="item" *ngIf="isMcq(item)" cdkDrag
                         (OnDelete)="deleteChildren($event)"
                         (OnDuplicate)="duplicate($event)"></al-mcq-widget>
          <al-note-widget [widget]="item" *ngIf="isNote(item)" cdkDrag
                          (OnDelete)="deleteChildren($event)"
                          (OnDuplicate)="duplicate($event)"></al-note-widget>
          <al-validation-widget *ngIf="isValidation(item)" cdkDrag
                                [widget]="item"
                                (OnDelete)="deleteChildren($event)"
                                (OnDuplicate)="duplicate($event)"></al-validation-widget>
          <al-wheelpicker-widget *ngIf="isWheelpicker(item)" cdkDrag
                                 [widget]="item"
                                 (OnDelete)="deleteChildren($event)"
                                 (OnDuplicate)="duplicate($event)"></al-wheelpicker-widget>
          <al-measure-widget *ngIf="isMeasure(item)" cdkDrag
                             [widget]="item"
                             (OnDelete)="deleteChildren($event)"
                             (OnDuplicate)="duplicate($event)"></al-measure-widget>


         </div>
      </mat-card>
    </div>
  </div>


</mat-expansion-panel>


<mat-card *ngIf="widgetStatus===widgetStatusEnum.WIDGET" class="al-widget  al-widget-selector"
          [formGroup]="formGroup">
  <mat-icon class="compomentIcon">web_asset</mat-icon>
  <span class="label">  {{'WIDGET_SECTION' | translate}}</span>
</mat-card>


<mat-card *ngIf="widgetStatus===widgetStatusEnum.PREVIEW" class="al-widget-preview">
  <mat-card-header>
    <div class="widget-header">
      <mat-icon class="compomentIcon">web_asset</mat-icon>
      <span class="title">  {{'WIDGET_SECTION' | translate}}</span>
    </div>

  </mat-card-header>
  <mat-card-content>


    <div class="widget-row">
      <div class="widget-label">{{this.sectionWidget.label}}</div>


    </div>


    <!--    <div class="widget-row">-->

    <div *ngFor="let item of this.sectionWidget.children" style="width:100%">
      <al-info-widget *ngIf="isText(item)"
                      [widget]="item"
                      [widgetStatus]="widgetStatusEnum.PREVIEW"
      ></al-info-widget>


      <al-mcq-widget [widget]="item" *ngIf="isMcq(item)" [widgetStatus]="widgetStatusEnum.PREVIEW"

      ></al-mcq-widget>
      <al-note-widget [widget]="item" *ngIf="isNote(item)" [widgetStatus]="widgetStatusEnum.PREVIEW"


      ></al-note-widget>
      <al-validation-widget *ngIf="isValidation(item)" [widgetStatus]="widgetStatusEnum.PREVIEW"

                            [widget]="item"

      ></al-validation-widget>
      <al-wheelpicker-widget *ngIf="isWheelpicker(item)" [widgetStatus]="widgetStatusEnum.PREVIEW"

                             [widget]="item"

      ></al-wheelpicker-widget>
      <al-measure-widget *ngIf="isMeasure(item)" [widgetStatus]="widgetStatusEnum.PREVIEW"

                         [widget]="item"
      ></al-measure-widget>


    </div>

    <!--    </div>-->

  </mat-card-content>
</mat-card>
