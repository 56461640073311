import {
  ApplySiteTimezonePipePipe,
  DateProxyPipe,
  ToDatePipe,
} from './datePipe/date.pipe';
import { AsPipe } from './asPipe/as.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [DateProxyPipe, ApplySiteTimezonePipePipe, ToDatePipe, AsPipe],
  imports: [],
  exports: [DateProxyPipe, ApplySiteTimezonePipePipe, ToDatePipe, AsPipe],
})
export class PipeModule {}
