import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  Days,
  FrequencyOccurence,
  FrequencyPolicy,
  FrequencyType,
  TemplateFull,
} from '@al/model';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import {
  FormGroupModelInterface,
  GenericFormComponent,
} from '@al/generic-components';
import { TemplateFullService, TemplateLightService } from '@al/services';
import { ApplySiteTimezonePipePipe } from '@al/pipe';
import { FrequencyFormControlNames } from './form-control-names.enum';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@al/navigation';
import { ReferentielService } from '@al/referentiel';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-template-frequency',
  templateUrl: './template-frequency.component.html',
  styleUrls: ['./template-frequency.component.scss'],
})
export class TemplateFrequencyComponent
  extends GenericFormComponent<TemplateFull>
  implements FormGroupModelInterface<TemplateFull>, OnInit
{
  public daysList: Days[] = [];

  public formControlNames = FrequencyFormControlNames;

  public formGroup: FormGroup;

  public frequency: FrequencyPolicy = new FrequencyPolicy();

  public frequencyOccurenceList: FrequencyOccurence[] = [];

  public frequencyTypeEnum = FrequencyType;

  public frequencyTypeList: FrequencyType[] = [
    FrequencyType.NONE,
    FrequencyType.HOURLY,
    FrequencyType.DAILY,
    FrequencyType.WEEKLY,
    FrequencyType.MONTHLY_DATE,
    FrequencyType.MONTHLY_DAY,
    FrequencyType.CUSTOM,
  ];

  public isNotInitialized = 1;

  public templateFull: TemplateFull = new TemplateFull();

  public constructor(
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected templateLightService: TemplateLightService,
    protected templateFullService: TemplateFullService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected templateModelService: TemplateModelService,
    protected dragAndDropService: DragAndDropService,
    protected formGroupDirective: FormGroupDirective
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      templateFullService,
      translateService,
      router,
      route
    );

    this.formGroup = this.buildFormGroup(this.templateFull);
    this.formGroup.markAllAsTouched();
    let referentielService = new ReferentielService();
    this.frequencyOccurenceList = referentielService.frequencyOccurenceList;
    this.daysList = referentielService.daysList;
  }

  public buildFormGroup(entity: TemplateFull | null): FormGroup {
    const datePipe = new ApplySiteTimezonePipePipe();
    if (entity /*&& this.datePipe*/) {
      let group: FormGroup = this.formBuilder.group({
        type: this.formBuilder.control(entity?.frequency?.type, [
          Validators.required,
        ]),
        startTime: this.formBuilder.control(
          // this.datePipe.transform(entity?.frequency?.startTime, 'yyyy-MM-dd'),
          datePipe.transform(entity?.frequency?.startTime!),

          // TODO : fix control on startTime
          []
        ),
      });
      let period = 0;
      if (entity?.frequency?.period) {
        period = entity?.frequency?.period;
      }

      if (entity.frequency?.type === FrequencyType.HOURLY) {
        group.addControl(
          this.formControlNames.period,
          new FormControl(this.periodToHour(period), [
            (control: AbstractControl): ValidationErrors | null => {
              return this.controlPeriod(control);
            },
          ])
        );
      }
      if (entity.frequency?.type === FrequencyType.MONTHLY_DAY) {
        group.addControl(
          this.formControlNames.occurence,
          new FormControl(this.frequency.occurence, [
            (control: AbstractControl): ValidationErrors | null => {
              return this.controlOccurence(control);
            },
          ])
        );
        group.addControl(
          this.formControlNames.dayOfTheWeek,
          new FormControl(this.frequency.dayOfTheWeek, [
            (control: AbstractControl): ValidationErrors | null => {
              return this.controlDayOfTheWeek(control);
            },
          ])
        );
      }
      return group;
    }
    return this.formBuilder.group({});
  }

  public compareDays(option: Days, value: Days): boolean {
    if (option !== null && value !== null) {
      return option.id === value.id;
    }
    return false;
  }

  public compareFrequencyOccurence(
    option: FrequencyOccurence,
    value: FrequencyOccurence
  ): boolean {
    if (option !== null && value !== null) {
      return option.id === value.id;
    }
    return false;
  }

  public getEntityToSave(): TemplateFull {
    return this.templateModelService.getModel();
  }

  public initFormGroup(entity: TemplateFull): void {
    this.formGroup = this.buildFormGroup(entity);
  }

  public initModel(): TemplateFull {
    return new TemplateFull();
  }

  public ngOnInit(): void {
    this.templateModelService
      .getTemplateFullObservable()
      // .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templateFull: TemplateFull) => {
        if (templateFull.frequency) {
          this.frequency = templateFull.frequency;
        }

        if (this.formGroupDirective) {
          this.formGroup = this.buildFormGroup(templateFull);
          this.formGroupDirective.form.addControl(
            'template-frequency',
            this.formGroup
          );
        }
      });
  }

  public save(): void {
    //do nothing
  }

  public setValue(formControlName: FrequencyFormControlNames): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === FrequencyFormControlNames.occurence) {
          this.templateModelService.setOccurence(formControl.value);
          if (this.frequency) {
            this.frequency.occurence = formControl.value;
          }
        } else if (formControlName === FrequencyFormControlNames.dayOfTheWeek) {
          this.templateModelService.setDayOfTheWeek(formControl.value);
          if (this.frequency) {
            this.frequency.dayOfTheWeek = formControl.value;
          }
        } else if (formControlName === FrequencyFormControlNames.type) {
          this.templateModelService.setFrequencyType(formControl.value);
          if (this.frequency) {
            this.frequency.type = formControl.value;

            if (this.frequency.type !== FrequencyType.HOURLY) {
              this.formGroup.removeControl('period');
            } else if (this.frequency.type === FrequencyType.HOURLY) {
              this.formGroup.addControl(
                'period',
                new FormControl(this.periodToHour(this.frequency.period!), [
                  (control: AbstractControl): ValidationErrors | null => {
                    return this.controlPeriod(control);
                  },
                ])
              );
            }

            if (this.frequency?.type === FrequencyType.MONTHLY_DAY) {
              this.formGroup.addControl(
                'occurence',
                new FormControl(this.frequency.occurence, [
                  (control: AbstractControl): ValidationErrors | null => {
                    return this.controlOccurence(control);
                  },
                ])
              );
              this.formGroup.addControl(
                'dayOfTheWeek',
                new FormControl(this.frequency.dayOfTheWeek, [
                  (control: AbstractControl): ValidationErrors | null => {
                    return this.controlDayOfTheWeek(control);
                  },
                ])
              );
            } else {
              this.formGroup.removeControl('occurence');
              this.formGroup.removeControl('dayOfTheWeek');
            }

            this.formGroup.markAllAsTouched();
            // this.formGroup.updateValueAndValidity();
          }
        }
        if (formControlName === FrequencyFormControlNames.period) {
          this.templateModelService.setPeriod(
            formControl.value * 60 * 60 * 1000
          );

          if (this.frequency) {
            this.frequency.period = formControl.value * 60 * 60 * 1000;
          }
        }
        if (formControlName === FrequencyFormControlNames.startTime) {
          let date = this.templateModelService.setAndGetStartTime(
            formControl.value
          );
          if (this.frequency) {
            this.frequency.startTime = date;
          }
        }
      }
    }
  }

  private controlDayOfTheWeek(
    control: AbstractControl
  ): ValidationErrors | null {
    if (this.frequency) {
      if (this.frequency.type === FrequencyType.MONTHLY_DAY) {
        if (
          control.value === undefined ||
          control.value === null ||
          control.value < 1 ||
          control.value > 7
        ) {
          return { period: { value: control.value } };
        }
      }
    }
    return null;
  }

  private controlOccurence(control: AbstractControl): ValidationErrors | null {
    if (this.frequency) {
      if (this.frequency.type === FrequencyType.MONTHLY_DAY) {
        if (
          control.value === undefined ||
          control.value === null ||
          control.value < 1 ||
          control.value > 5
        ) {
          return { period: { value: control.value } };
        }
      }
    }
    return null;
  }

  private controlPeriod(control: AbstractControl): ValidationErrors | null {
    if (this.frequency) {
      if (this.frequency.type === FrequencyType.HOURLY) {
        if (
          control.value === undefined ||
          control.value === null ||
          control.value < 1
        ) {
          return { period: { value: control.value } };
        }
      }
    }
    return null;
  }

  private periodToHour(timestamp: number): number {
    return timestamp / (1000 * 60 * 60);
  }
}
