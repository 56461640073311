import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('GateAbstract')
export class GateModelAbstract extends ModelAbstract {
  @JsonProperty('gate_as_called', String, true)
  public asCalled: string = '';

  @JsonProperty('gate_description', String, true)
  public description: string = '';

  @JsonProperty('gate_id', String, true)
  public id: string = '';

  @JsonProperty('gate_level', String, true)
  public level: string = '';

  @JsonProperty('gate_orientation', String, true)
  public orientation: string = '';

  @JsonProperty('gate_type', String, true)
  public type: string = '';
}
