<div class="al-site-form">


  <div class="header">
    <button mat-raised-button (click)="back()" color="primary">
      <mat-icon class="back" >arrow_back</mat-icon>
      {{ "BACK_PYROMETRE_LIST" | translate}}
    </button>

    <h1 translate class="parent-component-title" *ngIf="this.creation">CREATION_PYROMETRE</h1>
    <h1 translate class="parent-component-title" *ngIf="!this.creation">UPDATE_PYROMETRE</h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->
  </div>


  <mat-card>
    <!--    <span  *ngFor="let entity of entities"  > {{entity.label}}</span>-->
    <div class="navigation" *ngIf="!this.creation">
      <mat-icon class="prev" (click)="previous()" matTooltip="{{'PYROMETRE_FORM_PREVIOUS'|translate}}">skip_previous
      </mat-icon>
      <mat-icon class="next" (click)="next()" matTooltip="{{'PYROMETRE_FORM_NEXT'|translate}}">skip_next</mat-icon>

    </div>

    <form [formGroup]="form" (submit)="false">

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "PYROMETRE_LABEL" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.label" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "PYROMETRE_MAC_ADRESS" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.address" matInput>
      </mat-form-field>
      <div class="datePicker">
        <mat-form-field appearance="outline" class="" floatLabel="always">
          <mat-label class="required-field">{{ "PYROMETRE_CALIBRATION_DATE" | translate}}
          </mat-label>
          <!-- <input
          (change)="dateChange()"
          type="datetime-local"
          [formControlName]="formControlNames.date"
           matInput  > -->
          <input matInput (dateInput)="dateChange()" (dateChange)="dateChange()"
                 [formControlName]="formControlNames.date" [matDatepicker]="picker">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>

          <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
          <mat-datepicker #picker></mat-datepicker>

        </mat-form-field>
        <span class="dateIcon">
          <p *ngIf="!dateIsValid"><mat-icon style="color: red;" >error_outline</mat-icon>
            {{ "PYROMETRE_CALIBRATION_DATE_OLD" | translate}} </p>
          <p *ngIf="dateIsValid"><mat-icon>check_circle_outline</mat-icon>
            {{ "PYROMETRE_CALIBRATION_DATE_VALID" | translate}} </p>
          </span>
      </div>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "PYROMETRE_ACOEFF" | translate}}
        </mat-label>
        <input type="number" (change)="aCoeffChange($event)" [formControlName]="formControlNames.aCoeff" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "PYROMETRE_BCOEFF" | translate}}
        </mat-label>
        <input type="number" (change)="bCoeffChange($event)" [formControlName]="formControlNames.bCoeff" matInput>
      </mat-form-field>

      <div class="formula">
        {{ "DEVIATION_FORMULA" | translate}} {{aCoeff}}*x+ {{bCoeff}}
      </div>

      <!-- <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "PYROMETRE_SITE" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.Site" matInput  >
      </mat-form-field> -->

      <div class="row">
        <div class="col buttons">
          <button mat-button [disabled]="!form.dirty" color="primary" translate (click)="cancel()">CANCEL</button>
          <button mat-button [disabled]="!form.valid || !form.dirty" color="accent" translate (click)="save()">SAVE
          </button>
        </div>
      </div>
    </form>


    <ng-template #infoDialog>
      <al-info-dialog (confirm)='doSomething()'>
        <div title translate>{{infoDialogTitle}}</div>
        <div content translate>{{infoDialogMessage}}</div>
        <div confirm-button translate>OK</div>
      </al-info-dialog>
    </ng-template>
    <ng-template #cancelDialog>
      <al-confirm-dialog (confirm)='clean()'>
        <div title translate>CANCEL</div>
        <div content translate>AGREE_TO_CANCEL</div>
        <div cancel-button translate>NO</div>
        <div confirm-button translate>YES</div>
      </al-confirm-dialog>
    </ng-template>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_OK</div>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_OK_TITLE</div>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_KO</div>
    <div style="display: none" translate>INFO_DIALOG_MESSAGE_KO_TITLE</div>

