import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { RondeFull, RondeItems, RondeLight, Status } from '@al/model';
import { RondeLightQuery, RondeLightStore } from '@al/akita';
import { AlSpinnerService } from '@al/spinner';
import { GenericService } from './generic.service';
import { HttpResponse } from '@angular/common/http';

import { RondeRestService } from '@al/rest-services';
import { SiteService } from '@al/services';
import { UUID } from 'angular2-uuid';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class RondeLightService
  extends GenericService<RondeLight>
  implements OnDestroy
{
  private endDateSubject = new Subject<number>();

  private endDateValue: number = 0;

  private ngUnsubscribe = new Subject();

  private startDateSubject = new Subject<number>();

  private startDateValue: number = 0;

  public constructor(
    protected override restService: RondeRestService,
    protected query: RondeLightQuery,
    protected store: RondeLightStore,
    protected siteService: SiteService,
    private alSpinnerService: AlSpinnerService
  ) {
    super(restService, query, store);
    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        const uuid = UUID.UUID();
        if (window.location.href.includes('reporting')) {
          this.alSpinnerService.startDataProcess(uuid);
        }
        this.loadStore()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.alSpinnerService.stopDataProcess(uuid);
            },

            error: () => {
              this.alSpinnerService.stopDataProcess(uuid);
              this.alSpinnerService.launchSnackBar('ERR_LOAD_RONDE', 10);
            },
          });
      });

    this.getStartDateObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: number) => {
        this.startDateValue = value;
        const uuid = UUID.UUID();
        this.alSpinnerService.startDataProcess(uuid);
        this.loadStore()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.alSpinnerService.stopDataProcess(uuid);
            },
            error: () => {
              this.alSpinnerService.stopDataProcess(uuid);
              this.alSpinnerService.launchSnackBar('ERR_LOAD_RONDE', 10);
            },
          });
      });

    this.getEndDateObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: number) => {
        this.endDateValue = value;
        const uuid = UUID.UUID();
        this.alSpinnerService.startDataProcess(uuid);

        this.loadStore()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.alSpinnerService.stopDataProcess(uuid);
            },
            error: () => {
              this.alSpinnerService.stopDataProcess(uuid);
              this.alSpinnerService.launchSnackBar('ERR_LOAD_RONDE', 10);
            },
          });
      });
  }

  public changeEndDate(newDate: number) {
    this.endDateSubject.next(newDate);
    this.endDateValue = newDate;
  }

  public changeStartDate(newDate: number) {
    this.startDateSubject.next(newDate);
    this.startDateValue = newDate;
  }

  public deleteRonde(ronde: RondeLight) {
    return this.restService.deleteRonde(ronde).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data) => {
        if (ronde.instance) {
          let finalRonde = JSON.parse(JSON.stringify(ronde));

          if (finalRonde.instance) {
            finalRonde.instance.status = new Status('30', 'DELETED');
          }
          this.store.replace(ronde.instance.id, finalRonde);
          this.store.setActive(ronde.instance.id);
        }
        return data;
      })
    );
  }

  public exportAll(ids: number[], isPyco: boolean) {
    const site = this.siteService.getCurrentSite();
    if (site) {
      const startDate = new Date(this.startDateValue);
      const startDateSring = moment(
        this.convertDateToTimezone(startDate, site.timezone)
      )
        .tz(site.timezone)
        .format(site.dateFormat);

      const endDate = new Date(this.endDateValue);

      const endDateString = moment(
        this.convertDateToTimezone(endDate, site.timezone)
      )
        .tz(site.timezone)
        .format(site.dateFormat);
      //
      // const idList = this.query.getAll().map((ronde: RondeLight) => {
      //   return ronde.instance?.id;
      // });

      return this.restService.exportAll(
        ids,
        startDateSring,
        endDateString,
        isPyco
      );
    }
    return this.restService.exportAll([], '', '', isPyco);
  }

  public exportOneExcel(id: number): Observable<HttpResponse<any>> {
    const rondeLight = this.getById(id);
    return this.restService.exportOneExcel(rondeLight!);
  }

  public exportPdf(id: number, date: Date): Observable<HttpResponse<any>> {
    const stringID = id.toString();
    const stringDate = date.toString();
    return this.restService.exportPdf(stringID, stringDate);
  }

  public loadStore(): Observable<void> {
    if (window.location.href.includes('reporting')) {
      return this.restService
        .getRonde(this.startDateValue, this.endDateValue)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          map((data: RondeItems) => {
            this.store.reset();

            data.items.forEach((ronde) => {
              if (ronde.instance) {
                this.store.upsert(ronde.instance.id, ronde);
              }
            });
            // this.store.set(data.items);
            if (data.items.length > 0) {
              if (data.items[0].instance) {
                this.store.setActive(data.items[0].instance.id);
              }
            }
          })
        );
    }
    return new Observable();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public uploadToDrive(ronde: RondeLight) {
    this.restService.uploadToDrive(ronde);
  }

  public validate(id: number): Observable<RondeFull> {
    return this.restService.validate(id).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: RondeFull) => {
        this.store.update(data.instance?.id, {
          instance: data.instance,
        });
        // return this.query.getEntity(data.instance?.id)!;

        return data;
      })
    );

    // return of(id);
  }

  private convertDateToTimezone(date: Date, timezone: string) {
    var utcDate = date.getTime() + -1 * date.getTimezoneOffset() * 60 * 1000;
    var offset = moment.tz(timezone).utcOffset() * 60 * 1000;
    var newUtcDate = utcDate - offset;
    return newUtcDate;
  }

  private getEndDateObservable(): Observable<number> {
    return this.endDateSubject.asObservable();
  }

  private getStartDateObservable(): Observable<number> {
    return this.startDateSubject.asObservable();
  }
}
