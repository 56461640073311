import {
  HttpBackend,
  HttpClient,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RondeFull, RondeItems, RondeLight, Site } from '@al/model';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { GoogleService } from '@al/google';
import { ReferentielService } from '@al/referentiel';
import { SiteService } from '@al/services';
import { take } from 'rxjs';

// import * as console from 'console';

@Injectable({
  providedIn: 'root',
})
export class RondeRestService extends GenericRestService implements OnDestroy {
  private currentSite: Site = new Site();

  private httpClient2: HttpClient;

  private ngUnsubscribe = new Subject();

  public constructor(
    protected environmentService: EnvironmentService,
    protected siteService: SiteService,
    protected httpClient: HttpClient,
    private handler: HttpBackend,
    protected googleService: GoogleService
  ) {
    super(httpClient);

    this.httpClient2 = new HttpClient(handler);

    siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((site: Site) => {
        this.currentSite = site;
      });
  }

  public deleteRonde(ronde: RondeLight) {
    return super.delete<RondeLight>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.ronde.save}${ronde.instance?.id}`,
      new HttpParams()
    );
  }

  public exportAll(
    idList: Array<number | undefined>,
    startDate: string,
    endDate: string,
    isPyco: boolean
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    const site = this.siteService.getCurrentSite();
    if (site) {
      params = params.append('endDate', endDate);
      params = params.append('pycosso', isPyco);
      params = params.append('siteId', site.id);
      params = params.append('siteName', site.label);
      params = params.append('startDate', startDate);
      //TODO: Implement correct Header (kite token)
      params = params.append('token', this.googleService.getToken()!!);
    }

    return this.http.post<any>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.files.exportAsync}`,
      { ids: idList },
      {
        observe: 'response',
        params: params,
      }
    );
  }

  public exportOneExcel(rondeLight: RondeLight): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    const site = this.siteService.getCurrentSite();
    if (site) {
      params = params.append('ids', rondeLight.instance!.id);
      params = params.append('locale', 'en');
      params = params.append('siteId', site.id);
      params = params.append('timestamp', new Date().getTime());
    }
    const pycosCategory = new ReferentielService().categories.find(
      (c) => c.id === '7'
    );
    if (rondeLight.instance?.category?.id === pycosCategory!.id) {
      params = params.append('pycoso', true);
    }
    return this.http.get<any>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.files.export}csv`,
      {
        observe: 'response',
        params: params,
      }
    );
  }

  public exportPdf(id: string, date: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    const site = this.siteService.getCurrentSite();
    if (site) {
      params = params.append('id', id);
      params = params.append('date', date);
    }
    return this.http.get<any>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.ronde.pdf}`,
      {
        observe: 'response',
        params: params,
      }
    );
  }

  public getFullRonde(id: number): Observable<RondeFull> {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('id', id);

    return super.get<RondeFull>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.ronde.get}${id}`,
      httpParams,
      this.getRondeFullItemClazz()
    );
  }

  public getRonde(startDate: number, endDate: number): Observable<RondeItems> {
    let httpParams = new HttpParams();
    if (this.siteService.getCurrentSite()) {
      const site = this.siteService.getCurrentSite();
      if (site) {
        httpParams = httpParams.set('siteId', site.id);
        httpParams = httpParams.append('endDate', endDate);
        httpParams = httpParams.append('startDate', startDate);
        httpParams = httpParams.append('withBody', false);
      }
    }
    return super.get<RondeItems>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.ronde.list}`,
      httpParams,
      this.getRondeItemClazz()
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public uploadToDrive(ronde: RondeLight) {
    let params = new HttpParams();
    const site = this.siteService.getCurrentSite();
    if (site && ronde.instance) {
      params = params.append('id', ronde.instance?.id);
      params = params.append('locale', 'en');
      params = params.append('siteId', site.id);
    }
    return this.http
      .get<any>(
        `${this.environmentService.env.apigwUrl}${AwsEndPoint.files.export}excel`,
        {
          // headers: httpHeaders,
          observe: 'response',
          params: params,
        }
      )
      .pipe(take(1))
      .subscribe((response) => {
        var filename =
          ronde.instance?.title
            ?.replace(/\s/g, '_')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') +
          '_' +
          Date.now();
        var dataBuffer = response.body.replace(/^"|"$/g, '');
        let fileBlob = new Blob([this.s2ab(atob(dataBuffer))], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        this.insertFile(fileBlob, filename);
      });
  }

  public validate(id: number): Observable<RondeFull> {
    let params = new HttpParams();

    return super.get<RondeFull>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.ronde.supervisor}/${id}`,
      params,
      this.getRondeFullItemClazz()
    );

    // );
  }

  protected getRondeFullItemClazz(): typeof RondeFull {
    return RondeFull;
  }

  protected getRondeItemClazz(): typeof RondeItems {
    return RondeItems;
  }

  protected getRondeLightClazz(): typeof RondeLight {
    return RondeLight;
  }

  private insertFile(fileData: Blob, filename: string) {
    var boundary = '-------314159265358979323846';
    var delimiter = '\r\n--' + boundary + '\r\n';
    var closeDelim = '\r\n--' + boundary + '--';

    var reader = new FileReader();
    reader.readAsBinaryString(fileData);
    reader.onload = () => {
      var contentType = fileData.type || 'application/vnd.ms-excel';
      var metadata = {
        title: filename,
        name: filename,
        mimeType: contentType,
      };
      var base64Data = btoa(reader.result as string);
      var multipartRequestBody =
        delimiter +
        'Content-Type: application/json\r\n\r\n' +
        JSON.stringify(metadata) +
        delimiter +
        'Content-Type: ' +
        contentType +
        '\r\n' +
        'Content-Transfer-Encoding: base64\r\n' +
        '\r\n' +
        base64Data +
        closeDelim;

      let params = new HttpParams();
      params = params.append('uploadType', 'multipart');
      params = params.append('convert', true);

      // let jwtToken = JSON.parse(localStorage.getItem('ACCESS_TOKEN') as string);
      let jwtToken = this.googleService.getToken();

      let request = this.httpClient2.post<any>(
        `https://www.googleapis.com/upload/drive/v3/files`,
        multipartRequestBody,
        {
          headers: {
            // TODO: Implement correct Header (kite token)
            'Content-Type': 'multipart/mixed; boundary="' + boundary + '"',
            authorization: `Bearer ${jwtToken}`,
          },
          observe: 'response',
          params: params,
        }
      );

      request.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
        window.open(
          `https://docs.google.com/spreadsheets/d/${res.body.id}`,
          '_blank'
        );
      });
    };
  }

  private s2ab(s: string) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
}
