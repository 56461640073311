import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Site } from '@al/model';

export interface SiteState extends EntityState<Site>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site', resettable: true })
export class SiteStore extends EntityStore<SiteState> {
  public constructor() {
    super();
  }
}
