import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class BooleanConverter implements JsonCustomConvert<Boolean | null> {
  public deserialize(value: number): Boolean | null {
    return value == 1 ? true : false;
  }

  public serialize(bool: Boolean): number | null {
    if (bool) {
      return 1;
    }
    return 0;
  }
}
