import { JsonObject, JsonProperty } from 'json2typescript';
import { GateModelWidget } from './gate.model.widget';
import { GateWidget } from '@al/model';
import { TubeConfig } from 'projects/model/src/lib/template/tube-config.model';

@JsonObject('GateResponseWidget')
export class GateResponseWidget extends GateModelWidget {
  @JsonProperty('tube', [TubeConfig], true)
  public tube: TubeConfig[] = [];

  public override copy(widget: GateWidget): void {
    super.copy(widget);
    widget.tube.forEach((tube) => {
      let tubeConfig = new TubeConfig();
      tubeConfig.id = tube.id;
      tubeConfig.name = tube.name;
      tubeConfig.shoot = tube.shoot;
      this.tube.push(tubeConfig);
    });
  }
}
