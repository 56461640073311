import { AuthGuard } from '../auth.guard';
import { Paths } from '../paths';
import { PdfComponent } from '@al/pdf';
import { Route } from '@angular/router';

export const pdfRoute: Route = {
  path: Paths.PDF + '/:instanceId',
  component: PdfComponent,
  canActivate: [AuthGuard],
};
