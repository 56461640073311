import { JsonObject, JsonProperty } from 'json2typescript';
import { DatetimeConverter } from './converter/datetime.converter';
import { ModelAbstract } from './model.abstract';

@JsonObject('EmptyValue')
export class EmptyValue extends ModelAbstract {}

@JsonObject('SortValue')
export class SortValue extends ModelAbstract {
  @JsonProperty('sort', String)
  public sort: string | null = null;

  public constructor(sort: string) {
    super();
    this.sort = sort;
  }
}

@JsonObject('StringValue')
export class StringValue extends ModelAbstract {
  @JsonProperty('value', String, false)
  public value: string | null = null;

  public constructor(value: string) {
    super();
    this.value = value;
  }
}

@JsonObject('SchedulerItem')
export class SchedulerItem extends ModelAbstract {
  @JsonProperty('id', Number, false)
  public id: number = 0;

  @JsonProperty('lastCrDate', DatetimeConverter, false)
  public lastCrDate: Date | null = null;

  @JsonProperty('nextCrDate', DatetimeConverter, false)
  public nextCrDate: Date | null = null;

  @JsonProperty('siteId', String, false)
  public siteId: string | null = null;

  @JsonProperty('siteLabel', String, false)
  public siteLabel: string | null = null;

  @JsonProperty('title', String, false)
  public title: string | null = null;
}

@JsonObject('SchedulerItems')
export class SchedulerItems extends ModelAbstract {
  @JsonProperty('items', [SchedulerItem], false)
  public items: SchedulerItem[] = [];
}

@JsonObject('SchedulerSiteRequest')
export class SchedulerSiteRequest extends ModelAbstract {
  @JsonProperty('delay', EmptyValue, false)
  public delay = new EmptyValue();

  @JsonProperty('lastCrDate', SortValue, false)
  public lastCrDate = new SortValue('DESC');

  @JsonProperty('nextDate', EmptyValue, false)
  public nextDate = new EmptyValue();

  @JsonProperty('site', StringValue, false)
  public site = new StringValue('');

  @JsonProperty('title', EmptyValue, false)
  public title: EmptyValue = new EmptyValue();
}
