import { HttpClient, HttpParams } from '@angular/common/http';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { ProcessGroup } from '@al/model';
import { SiteService } from '@al/services';

@Injectable({
  providedIn: 'root',
})
export class ProcessGroupRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected httpClient: HttpClient,
    protected siteService: SiteService
  ) {
    super(httpClient);
  }

  public async getAllProcessGroups(): Promise<void | any[]> {
    let httpParams = new HttpParams();

    if (this.siteService.getCurrentSite()) {
      const site = this.siteService.getCurrentSite();
      if (site) {
        httpParams = httpParams.set('siteId', site.maximoSiteId);
      }
    }

    return super.getListDataLake(
      `${this.environmentService.env.apigwUrlDatalake}${AwsEndPoint.datalake.processGroup}`,
      httpParams,
      0,
      this.getProcessGroupClazz()
    );
  }

  protected getProcessGroupClazz(): typeof ProcessGroup {
    return ProcessGroup;
  }
}
