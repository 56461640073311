import { Equipment, ProcessGroup, ProductionUnit, Returnable } from '@al/model';
import { EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, take } from 'rxjs';
import { AlAssetsTreeComponent } from '../al-assets-tree.component';
import { Component } from '@angular/core';

import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'al-asset-input-display',
  templateUrl: './asset-input-display.component.html',
  styleUrls: ['./asset-input-display.component.scss'],
})
export class AssetInputDisplayComponent implements OnDestroy, OnInit {
  @Input() public equipment?: Equipment | null;

  @Input() public processGroup?: ProcessGroup | null;

  @Input() public productionUnit?: ProductionUnit | null;

  @Output() public returnable: EventEmitter<Returnable> = new EventEmitter();

  public asset = new FormControl({ value: '', disabled: true });

  public fullObject: Returnable = new Returnable();

  public pg = new FormControl({ value: '', disabled: true });

  public pu = new FormControl({ value: '', disabled: true });

  private ngUnsubscribe = new Subject();

  public constructor(public dialog: MatDialog) {}

  public clear() {
    this.pu.setValue('');
    this.pg.setValue('');
    this.asset.setValue('');
    this.fullObject = new Returnable();
    this.returnable.emit(this.fullObject);
  }

  public displayTree(): void {
    const dialogRef = this.dialog.open(AlAssetsTreeComponent, {
      width: '75vw',
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: Returnable) => {
        if (result) {
          this.clear();
          this.fullObject = result;
          this.returnable.emit(this.fullObject);

          if (result.locationPU) {
            this.pu.setValue(this.displayProperty('PU', result.locationPU));
          }
          if (result.locationPG) {
            this.pg.setValue(this.displayProperty('PG', result.locationPG));
          }
          if (result.asset) {
            this.asset.setValue(this.displayProperty('Asset', result.asset));
          }
        }
      });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public ngOnInit() {
    if (this.equipment) {
      this.asset.setValue(this.displayProperty('Asset', this.equipment));
      this.fullObject.asset = this.equipment;
    }
    if (this.processGroup) {
      this.pg.setValue(this.displayProperty('PG', this.processGroup));
      this.fullObject.locationPG = this.processGroup;
    }
    if (this.productionUnit) {
      this.pu.setValue(this.displayProperty('PU', this.productionUnit));
      this.fullObject.locationPU = this.productionUnit;
    }
  }

  protected displayProperty(type: string, object: any): string {
    if (object != undefined) {
      if (type === 'Asset') {
        return `${object.number} :  ${object.description}`;
      }
      if (type === 'PG') {
        return `${object.number} :  ${object.name}`;
      }
      if (type === 'PU') {
        return `${object.id} :  ${object.name}`;
      }
    }
    return '';
  }
}
