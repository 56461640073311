<div class="al-history">
  <div class="header">
    <h1 translate class="parent-component-title"
        *ngIf="this.template">{{'TEMPLATE_HISTORY' | translate}} {{this.template.title}} </h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->

    <span>
           <button *ngIf="embedded ===  false" mat-raised-button (click)="back()">
        <mat-icon class="back">keyboard_arrow_left</mat-icon>
             {{ "BACK" | translate }}
      </button>

           <button [matTooltip]="'EXPORT_PDF_GMP'  | translate"
                   mat-mini-fab color="primary"
                   (click)="exportPdf()">
        <mat-icon class="back">picture_as_pdf</mat-icon>
       </button>
    </span>
  </div>


  <mat-table [dataSource]="bodyDataSource" matSort class="mat-elevation-z8">
    <!-- DATE TIME-->

    <!-- <mat-header-cell *matHeaderCellDef>{{
      "DATE" | translate
    }}</mat-header-cell> -->
    <!--    <ng-container matColumnDef="propertySearch">-->
    <!--      <mat-header-cell *matHeaderCellDef class="propertySearch">-->
    <!--        <al-table-column-header-filtering-->
    <!--          (OnClear)="clearFilter($event, 'propertySearch')"-->
    <!--          (OnSearch)="filterDate($event, 'propertySearch')"-->
    <!--          [field]="'propertySearch'"-->
    <!--          fieldType="searchableSelect"-->
    <!--          title="{{ 'HISTORY_DATE' | translate }}"-->
    <!--          storageNameSpace="history-datess-filters-{{templateId}}"-->

    <!--        ></al-table-column-header-filtering>-->
    <!--      </mat-header-cell>-->
    <!--      <mat-cell *matCellDef="let history" class="propertySearch">{{ history.datetime }}-->
    <!--      </mat-cell>-->
    <!--    </ng-container>-->
    <ng-container matColumnDef="datetime">
      <mat-header-cell *matHeaderCellDef class="datetime">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'datetime')"
          (OnSearch)="filterDate($event, 'datetime')"
          [field]="'datetime'"
          fieldType="dateRange"
          title="{{ 'HISTORY_DATE' | translate }}"
          storageNameSpace="history-date-filters-{{templateId}}"

        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="datetime">{{ history.datetime | date: "yyyy-MM-dd HH:mm:ss" }}
      </mat-cell>
    </ng-container>

    <!-- USER ID-->
    <ng-container matColumnDef="userId">
      <!-- <mat-header-cell *matHeaderCellDef>
        {{ "userId" | translate }}
      </mat-header-cell> -->
      <mat-header-cell *matHeaderCellDef class="userId">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'userId')"
          (OnSearch)="filterUserId($event, 'userId')"
          [field]="'userId'"
          fieldType="input"
          title="{{ 'HISTORY_USER' | translate }}"
          storageNameSpace="history-title-filters-{{templateId}}"

        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="userId">{{ history.userId }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="type" class="type">
      <!-- <mat-header-cell *matHeaderCellDef>
        {{ "type" | translate }}
      </mat-header-cell> -->
      <mat-header-cell *matHeaderCellDef>
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'type')"
          (OnSearch)="filterType($event, 'type')"
          [field]="'type'"
          [values]="types"
          [isSelect]="true"
          storageNameSpace="history-type-filters-{{templateId}}"
          fieldType="input"
          title="{{ 'HISTORY_TYPE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let history" class="type">
        {{ history.type | translate}}
      </mat-cell>
    </ng-container>
    <!-- fieldName-->
    <ng-container matColumnDef="fieldName">
      <!-- <mat-header-cell *matHeaderCellDef>
        {{ "fieldName" | translate }}
      </mat-header-cell> -->
      <mat-header-cell *matHeaderCellDef class="fieldName">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'fieldName')"
          (OnSearch)="filterFieldName($event, 'fieldName')"
          [field]="'fieldName'"
          [isSelect]="true"
          [values]="widgetProperties"
          [widgetTypeSubject]="widgetPropertySubjectHistory"

          fieldType="input"
          title="{{ 'HISTORY_FIELD' | translate }}"
          storageNameSpace="history-field-filters-{{templateId}}"

        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="fieldName">
        {{ getPropertyTranslationKey(history.fieldName)| translate}}

        <!--        <span *ngIf="history.fieldName === 'equipment'">-->
        <!--         {{'ASSET_LABEL' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'componentType'">-->
        <!--         {{'HISTORY_WIDGET_TYPE' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'id'">-->
        <!--         {{'TEMPLATE_ID' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'processGroup'">-->
        <!--         {{'PG_LABEL' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'productionUnit'">-->
        <!--         {{'PU_LABEL' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'title'">-->
        <!--         {{'TEMPLATE_TITLE' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'isMandatory'">-->
        <!--         {{'IS_MANDATORY_QUESTION' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'disableControl'">-->
        <!--         {{'DISABLED' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'label'">-->
        <!--         {{'SITE_LABEL' | translate}}-->
        <!--        </span> <span *ngIf="history.fieldName === 'options'">-->
        <!--         {{'MCQ_OPTIONS' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'singleChoiceOnly'">-->
        <!--         {{'MCQ_SINGLE_CHOICE_ONLY' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'decimalIndex'">-->
        <!--         {{'WHEELPICKER_WIDGET_DECIMAL_INDEX' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'min'">-->
        <!--         {{'MEASURE_WIDGET_MIN' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'max'">-->
        <!--         {{'MEASURE_WIDGET_MAX' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'tooltip'">-->
        <!--         {{'MCQ_WIDGET_TOOLTIP' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'unit'">-->
        <!--         {{'UNIT' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'maxRange'">-->
        <!--         {{'MEASURE_MAX_RANGE_ERROR' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'minRange'">-->
        <!--         {{'MEASURE_MIN_RANGE_ERROR' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'link'">-->
        <!--         {{'INFO_WIDGET_TOOLTIP' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'text'">-->
        <!--         {{'NOTE_WIDGET_TITLE' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'visible'">-->
        <!--         {{'ENABLE_TEMPLATE' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'categoryId'">-->
        <!--         {{'CATEGORY' | translate}}-->
        <!--        </span>-->


        <!--        <span *ngIf="history.fieldName === 'status'">-->
        <!--         {{'RONDE_STATUS' | translate}}-->
        <!--        </span>-->


        <!--        <span *ngIf="history.fieldName === 'type'">-->
        <!--         {{'FREQUENCY_TYPE' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'period'">-->
        <!--         {{'FREQUENCY_PERIOD' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'occurence'">-->
        <!--         {{'OCCURENCE' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'nominalValue'">-->
        <!--         {{'WHEELPICKER_WIDGET_NOMINAL_VALUE' | translate}}-->
        <!--        </span>-->
        <!--        &lt;!&ndash; pycoso&ndash;&gt;-->
        <!--        <span *ngIf="history.fieldName === 'shoot'">-->
        <!--         {{'SHOOT' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'called'">-->
        <!--         {{'CALLED' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'direction'">-->
        <!--         {{'TEMPLATE_WIDGET_GATE_DIRECTION' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'gtOrder'">-->
        <!--         {{'TEMPLATE_WIDGET_GATE_GT_ORDER' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'gateId'">-->
        <!--         {{'TEMPLATE_WIDGET_GATE_ID' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'gtCenter'">-->
        <!--         {{'TEMPLATE_WIDGET_GATE_GT_CENTER' | translate}}-->
        <!--        </span>-->
        <!--        <span *ngIf="history.fieldName === 'orientation'">-->
        <!--         {{'TEMPLATE_WIDGET_GATE_GT_CENTER' | translate}}-->
        <!--        </span>-->

        <!--        <span *ngIf="history.fieldName === 'level'">-->
        <!--         {{'TEMPLATE_WIDGET_GATE_LEVEL' | translate}}-->
        <!--        </span>-->
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="oldValue">
      <!-- <mat-header-cell *matHeaderCellDef>
        {{ "oldValue" | translate }}
      </mat-header-cell> -->
      <mat-header-cell *matHeaderCellDef class="oldValue">
        <al-table-column-header-filtering

          title="{{ 'HISTORY_OLD' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="oldValue">
        <mat-icon *ngIf="displayOldValue(history) === 'true'  ">
          check_box
        </mat-icon>
        <mat-icon *ngIf="  displayOldValue(history)==='false'">
          check_box_outline_blank
        </mat-icon>

        <span *ngIf="displayOldValue(history) !== 'true' && displayOldValue(history)!=='false'">
                  {{displayOldValue(history) | translate}}
        </span>

        <mat-icon>chevron_right</mat-icon>

        <mat-icon *ngIf="displayNewValue(history) === 'true'  ">
          check_box
        </mat-icon>
        <mat-icon *ngIf="  displayNewValue(history)==='false'">
          check_box_outline_blank
        </mat-icon>

        <span *ngIf="displayNewValue(history) !== 'true' && displayNewValue(history)!=='false'">
                  {{displayNewValue(history) | translate}}
        </span>
      </mat-cell>
    </ng-container>

    <!--    newValue-->
    <!--    <ng-container matColumnDef="newValue">-->
    <!--      &lt;!&ndash; <mat-header-cell *matHeaderCellDef>-->
    <!--        {{ "newValue" | translate }}-->
    <!--      </mat-header-cell> &ndash;&gt;-->
    <!--      <mat-header-cell *matHeaderCellDef class="newValue">-->
    <!--        <al-table-column-header-filtering-->
    <!--          (OnClear)="clearFilter($event, 'newValue')"-->
    <!--          (OnSearch)="filterNewValue($event, 'newValue')"-->
    <!--          [field]="'newValue'"-->
    <!--          fieldType="input"-->
    <!--          storageNameSpace="template-list-filters"-->
    <!--          title="{{ 'HISTORY_NEW' | translate }}"-->
    <!--        ></al-table-column-header-filtering>-->
    <!--      </mat-header-cell>-->

    <!--      <mat-cell *matCellDef="let history" class="newValue">-->
    <!--        {{ history.newValue }}-->
    <!--      </mat-cell>-->
    <!--    </ng-container>-->

    <!--    ACTION-->
    <ng-container matColumnDef="action">
      <!-- <mat-header-cell *matHeaderCellDef>
        {{ "action" | translate }}
      </mat-header-cell> -->
      <!--      [values]="actions"-->
      <mat-header-cell *matHeaderCellDef class="action">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'action')"
          (OnSearch)="filterAction($event, 'action')"
          [field]="'action'"
          [values]="actions"
          [isSelect]="true"
          fieldType="input"
          title="{{ 'HISTORY_ACTION' | translate }}"
          storageNameSpace="history-action-filters-{{templateId}}"

        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="action">
        {{ history.action | translate }}
      </mat-cell>
    </ng-container>
    <!-- start template standard columns -->
    <ng-container matColumnDef="sectionId">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'sectionId')"
          (OnSearch)="filterSectionId($event, 'sectionId')"
          [field]="'sectionId'"
          fieldType="input"
          title="{{ 'sectionId' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history">
        {{ history.sectionId }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sectionName">
      <mat-header-cell *matHeaderCellDef class="sectionName">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'sectionName')"
          (OnSearch)="filterSectionName($event, 'sectionName')"
          [field]="'sectionName'"
          fieldType="input"
          title="{{ 'HISTORY_SECTION' | translate }}"
          storageNameSpace="history-widget-parent-filters-{{templateId}}"

        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="sectionName">
        {{ history.sectionName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="widgetId">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'widgetId')"
          (OnSearch)="filterWidgetId($event, 'widgetId')"
          [field]="'widgetId'"
          fieldType="input"
          title="{{ 'widgetId' | translate }}"
          storageNameSpace="history-widget-id-filters-{{templateId}}"

        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history">
        {{ history.widgetId }}
      </mat-cell>
    </ng-container>

    <!--widgetName-->
    <ng-container matColumnDef="widgetName">
      <mat-header-cell *matHeaderCellDef class="widgetName">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'widgetName')"
          (OnSearch)="filterWidgetName($event, 'widgetName')"
          [field]="'widgetName'"
          fieldType="input"
          title="{{ 'HISTORY_WIDGET' | translate }}"
          storageNameSpace="history-widget-name-filters-{{templateId}}"

        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="widgetName">
        <span *ngIf="history.widgetId">
          {{ history.widgetName }} ({{ history.widgetId }})
        </span>
        <span *ngIf="!history.widgetId">        {{ history.widgetName }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="widgetType">
      <mat-header-cell *matHeaderCellDef class="widgetType">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event, 'widgetType')"
          (OnSearch)="filterWidgetType($event, 'widgetType')"
          [values]="widgetTypes"
          [widgetTypeSubject]="widgetTypeSubjectHistory"
          [isSelect]="true"
          [field]="'widgetType'"
          fieldType="input"

          title="{{ 'HISTORY_WIDGET_TYPE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>

      <mat-cell *matCellDef="let history" class="widgetType">
         <span *ngIf="history.widgetType === 'note'">
         {{'WIDGET_NOTE' | translate}}
        </span>
        <span *ngIf="history.widgetType === 'measure'">
         {{'WIDGET_MEASURE' | translate}}
        </span>
        <span *ngIf="history.widgetType === 'checkbox'">
         {{'WIDGET_VALIDATION' | translate}}
        </span> <span *ngIf="history.widgetType === 'info'">
         {{'WIDGET_INFO' | translate}}
        </span>
        <span *ngIf="history.widgetType === 'multiple_checkbox'">
         {{'WIDGET_MCQ' | translate}}
        </span>
        <span *ngIf="history.widgetType === 'section'">
         {{'WIDGET_SECTION' | translate}}
        </span> <span *ngIf="history.widgetType === 'wheel_picker'">
         {{'WIDGET_WHEELPICKER' | translate}}
        </span>
        <span *ngIf="history.widgetType === 'gate'">
         {{'WIDGET_GATE' | translate}}
        </span>
        <span *ngIf="history.widgetType === 'tube'">
         {{'PREVIEW_TUBELABEL' | translate}}
        </span>
      </mat-cell>
    </ng-container>

    <!-- end template standard columns -->

    <!-- start template Pycoso columns -->

    <!--    <ng-container matColumnDef="gateName">-->
    <!--      <mat-header-cell *matHeaderCellDef class="id">-->
    <!--        <al-table-column-header-filtering-->
    <!--          (OnClear)="clearFilter($event, 'gateName')"-->
    <!--          (OnSearch)="filterGateName($event, 'gateName')"-->
    <!--          [field]="'gateName'"-->
    <!--          fieldType="input"-->
    <!--          storageNameSpace="template-list-filters"-->
    <!--          title="{{ 'HISTORY_GATE' | translate }}"-->
    <!--        ></al-table-column-header-filtering>-->
    <!--      </mat-header-cell>-->

    <!--      <mat-cell *matCellDef="let history">-->
    <!--        {{ history.gateName }}-->
    <!--      </mat-cell>-->
    <!--    </ng-container>-->

    <!--    <ng-container matColumnDef="tubeName">-->
    <!--      <mat-header-cell *matHeaderCellDef class="id">-->
    <!--        <al-table-column-header-filtering-->
    <!--          (OnClear)="clearFilter($event, 'tubeName')"-->
    <!--          (OnSearch)="filterTubeName($event, 'tubeName')"-->
    <!--          [field]="'tubeName'"-->
    <!--          fieldType="input"-->
    <!--          storageNameSpace="template-list-filters"-->
    <!--          title="{{ 'HISTORY_TUBE' | translate }}"-->
    <!--        ></al-table-column-header-filtering>-->
    <!--      </mat-header-cell>-->

    <!--      <mat-cell *matCellDef="let history">-->
    <!--        {{ history.tubeName }}-->
    <!--      </mat-cell>-->
    <!--    </ng-container>-->

    <!-- end template Pycoso columns -->

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [pageSize]="15"
    [pageSizeOptions]="[15, 25, 50, 100]"
  ></mat-paginator>
</div>


<ng-template #pdfDialog>
  <h2 matDialogTitle translate>PDF_GENERATE</h2>
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>
<ng-template #exportPDFDialogKo>
  <al-confirm-dialog>
    <div title translate>EXPORT_PDF_DIALOG_MESSAGE_KO_TITLE</div>
    <div content translate>EXPORT_PDF_DIALOG_MESSAGE_KO</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>
