import { Equipment } from './equipment.model';
import { ProcessGroup } from './process-group.model';
import { ProductionUnit } from './production-unit.model';

export class Returnable {
  public asset!: Equipment;

  public locationPG!: ProcessGroup;

  public locationPU!: ProductionUnit;
}
