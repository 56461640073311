import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from './model.abstract';

@JsonObject('Kpi')
export class Kpi extends ModelAbstract {
  @JsonProperty('instancesCounter', Number, true)
  public instancesCounter: number = 0;

  @JsonProperty('templatesCounter', Number, true)
  public templatesCounter: number = 0;

  @JsonProperty('usersCounter', Number, true)
  public usersCounter: number = 0;
}
