import { HistoryComponent, HistoryListComponent } from '@al/history';
import {
  TemplateFormComponent,
  TemplateListComponent,
  TemplateManagerComponent,
} from '@al/template-components';
import { AuthGuard } from '../auth.guard';
import { Paths } from '../paths';
import { Route } from '@angular/router';

export const templateRoutes: Route = {
  path: Paths.template.DEFAULT,
  component: TemplateManagerComponent,
  children: [
    {
      path: Paths.template.EDIT,
      component: TemplateFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30', '20'],
      },
    },
    {
      path: Paths.template.CREATE,
      component: TemplateFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30', '20'],
      },
    },
    {
      path: Paths.template.LIST,
      component: TemplateListComponent,
      canActivate: [AuthGuard],
    },
    {
      path: Paths.template.HISTORY + '/:templateId',
      component: HistoryComponent,
      canActivate: [AuthGuard],
    },
    {
      path: Paths.template.HISTORIES,
      component: HistoryListComponent,
      canActivate: [AuthGuard],
    },
  ],
};
