<div class="al-site-list">


  <div class="header">
    <h1 translate class="parent-component-title">USER_LIST_TITLE</h1>
    <!--    <mat-icon class="back" (click)="back()">arrow_back</mat-icon>-->


  </div>

  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">0

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.id.valueOf())"
          (OnSearch)="filterId($event,formControlNames.id.valueOf())"
          [field]="formControlNames.id.valueOf()"
          fieldType="input"
          storageNameSpace="user-list-filters"
          title="{{'USER_ID' | translate }}"
        ></al-table-column-header-filtering>

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'USER_ID' | translate }}" class="id">
        {{ element.id }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef class="maximo-site-id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.lastName.valueOf())"
          (OnSearch)="filterName($event,formControlNames.lastName.valueOf())"
          [field]="formControlNames.lastName.valueOf()"
          fieldType="input"
          storageNameSpace="user-list-filters"
          title="{{'USER_NAME' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'USER_NAME' | translate }}" class="maximo-site-id">
        {{ element.lastName }} {{ element.firstName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef class="maximo-site-id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.email.valueOf())"
          (OnSearch)="filterEmail($event,formControlNames.email.valueOf())"
          [field]="formControlNames.email.valueOf()"
          fieldType="input"
          storageNameSpace="user-list-filters"
          title="{{'USER_EMAIL' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'USER_EMAIL' | translate }}" class="maximo-site-id">
        {{ element.email }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="maximo-site-id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.status.valueOf())"
          (OnSearch)="filterStatus($event,formControlNames.status.valueOf())"
          [field]="formControlNames.status.valueOf()"
          [values]="status"
          [isSelect]="true"
          fieldType="input"
          storageNameSpace="user-list-filters"
          title="{{'USER_STATUS' | translate }}"
        ></al-table-column-header-filtering>

        <!-- <mat-select [(value)]="selectedStatus" (selectionChange)="filterStatus($event,formControlNames.status.valueOf())">
          <mat-option *ngFor="let statu of status" [value]="statu.id">
            {{statu.label}}
          </mat-option>
        </mat-select> -->

      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'USER_STATUS' | translate }}" class="maximo-site-id">
        {{ element.status.label | translate}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="profile">
      <mat-header-cell *matHeaderCellDef class="maximo-site-id">
        <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.profile.valueOf())"
          (OnSearch)="filterProfil($event,formControlNames.profile.valueOf())"
          [field]="formControlNames.profile.valueOf()"
          [values]="profiles"
          [isSelect]="true"
          fieldType="input"
          storageNameSpace="user-list-filters"
          title="{{'USER_PROFILE' | translate }}"
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'USER_PROFILE' | translate }}" class="maximo-site-id">
        {{ element.profileType.label | translate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sites">
      <mat-header-cell *matHeaderCellDef class="maximo-site-id">
        <!-- <al-table-column-header-filtering
          (OnClear)="clearFilter($event,formControlNames.sites.valueOf())"
          (OnSearch)="filterEmail($event,formControlNames.sites.valueOf())"
          [field]="formControlNames.sites.valueOf()"
          fieldType="input"
          storageNameSpace="user-list-filters"
          title="{{'USER_SITES' | translate }}"
        ></al-table-column-header-filtering> -->
      </mat-header-cell>
      <mat-cell *matCellDef="let element" attr.data-label="{{'USER_SITES' | translate}}" class="maximo-site-id">
        <p class="site-text">
          <span *ngFor="let site of element.sites; last as isLast">{{ site.maximoSiteId }}<span
            *ngIf="!isLast">, </span></span>
        </p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="actions" translate>

        <button mat-button (click)="create()" color="primary">
          {{'CREATE_USER' | translate}}
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="" class="actions">
        <button mat-icon-button (click)="edit(element.id)" [matTooltip]="'EDIT' | translate">
          <mat-icon >edit</mat-icon>
        </button>
        <button mat-icon-button  (click)="openDialogWithRef(deleteDialog, element)" [matTooltip]="'DELETE' | translate">
        <mat-icon>delete
        </mat-icon>
      </button>


      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: displayedColumns"

    ></mat-row>
  </mat-table>

  <mat-paginator #paginator [pageSize]="15" [pageSizeOptions]="[15, 25, 50,  100]"></mat-paginator>

</div>


<ng-template #deleteDialog let-data>
  <al-confirm-dialog (confirm)='deleteUser(data)'>
    <div title translate>DELETE</div>
    <div content translate>AGREE_TO_DELETE</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogKo>
  <al-confirm-dialog>
    <div title translate>DELETE_DIALOG_MESSAGE_KO_TITLE_USER</div>
    <div content translate>DELETE_DIALOG_MESSAGE_KO_USER</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

<ng-template #deleteDialogOk>
  <al-confirm-dialog>
    <div title translate>DELETE_DIALOG_MESSAGE_OK_TITLE_USER</div>
    <div content translate>DELETE_DIALOG_MESSAGE_OK_USER</div>
    <div confirm-button translate>OK</div>
  </al-confirm-dialog>
</ng-template>

