import { Component, Input } from '@angular/core';
import { Picture, Widget } from '@al/model';

@Component({
  selector: 'al-common-preview',
  templateUrl: './common-preview.component.html',
  styleUrls: ['./common-preview.component.css'],
})
export class CommonPreviewComponent {
  @Input()
  public widget: Widget = new Widget();

  public openImage(picture: Picture): void {
    if (picture.url) {
      window.open(picture.url!);
    }
  }
}
