import { GenericComponentsModule } from '@al/generic-components';
import { NgModule } from '@angular/core';

import { SiteFormComponent } from './form/site-form.component';
import { SiteListComponent } from './list/site-list.component';
import { SiteManagerComponent } from './site-manager/site-manager.component';

@NgModule({
  declarations: [SiteListComponent, SiteFormComponent, SiteManagerComponent],
  imports: [GenericComponentsModule],
  exports: [SiteListComponent, SiteFormComponent],
})
export class SiteComponentsModule {}
