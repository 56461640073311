import { FormGroup } from '@angular/forms';
import { ModelAbstract } from '@al/model';

export interface FormGroupModelInterface<T extends ModelAbstract> {
  buildFormGroup(entity: T | null): FormGroup;

  // getEntityToSave(): T;
  //
  // // initFormGroup(entity: T): void;
  //
  // initModel(): T;
  //
  // save(): void;
}
