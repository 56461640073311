import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { ComponentTypeConverter } from '../converter/component-type.converter';
import { Equipment } from '../datalake/equipment.model';
import { Picture } from '../ronde/ronde-full.picture.model';
import { ProcessGroup } from '../datalake/process-group.model';
import { ProductionUnit } from '../datalake/production-unit.model';

@JsonObject('Widget')
export class Widget {
  @JsonProperty('componentType', ComponentTypeConverter, true)
  public componentType: ComponentType | null = null;

  @JsonProperty('equipment', Equipment || undefined, true)
  public equipment?: Equipment | null = null;

  @JsonProperty('id', String, true)
  public id: string | null = null;

  @JsonProperty('isMandatory', Boolean, true)
  public isMandatory: boolean = false;

  @JsonProperty('pictures', [Picture], true)
  public pictures: Picture[] = [];

  @JsonProperty('processGroup', ProcessGroup || undefined, true)
  public processGroup?: ProcessGroup | null = null;

  @JsonProperty('productionUnit', ProductionUnit || undefined, true)
  public productionUnit?: ProductionUnit | null = null;

  @JsonProperty('section', String, true)
  public section: string | null = null;

  public copy(widget: Widget) {
    this.id = widget.id;
    this.isMandatory = widget.isMandatory;
    this.equipment = widget.equipment;
    this.processGroup = widget.processGroup;
    this.productionUnit = widget.productionUnit;
    this.section = widget.section;
  }
}
