import { JsonObject, JsonProperty } from 'json2typescript';
import { Category } from '../category.model';
import { CategoryRondeConverter } from '../converter/category-ronde.converter';
import { DatetimeConverter } from '../converter/datetime.converter';
import { ModelAbstract } from '../model.abstract';
import { Status } from '../status.model';
import { StatusRondeConverter } from '../converter/status-ronde.converter';

@JsonObject('instance')
export class Instance extends ModelAbstract {
  @JsonProperty('categoryId', CategoryRondeConverter, true)
  public category: Category | null = null;

  @JsonProperty('hasAnomaly', Boolean, true)
  public hasAnomaly: boolean = false;

  @JsonProperty('id', Number, true)
  public id = 0;

  @JsonProperty('location', String, true)
  public location: string | null = null;

  @JsonProperty('modificationDate', DatetimeConverter, true)
  public modificationDate: Date | null = null;

  @JsonProperty('ownerName', String, true)
  public ownerName: string | null = null;

  @JsonProperty('scadaFile', String, true)
  public scadaFile: string | null = null;

  @JsonProperty('siteId', String, true)
  public siteId: string | null = null;

  @JsonProperty('status', StatusRondeConverter, true)
  public status: Status | null = null;

  @JsonProperty('title', String, true)
  public title: string | null = null;
}
