import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Category, Pyrometre } from '@al/model';
import { FormControlNames } from '../form-controle-names.enum';
import { GenericListComponent } from '@al/generic-components';
import { LocalStorageKey } from '@al/local-cache';
import { MatDialog } from '@angular/material/dialog';
import { PyrometreFilterService } from '../service/pyrometre-filter.service';
import { PyrometreService } from '@al/services';
import { ReferentielService } from '@al/referentiel';

@Component({
  selector: 'al-pyrometre-list',
  templateUrl: './pyrometre-list.component.html',
  styleUrls: ['./pyrometre-list.component.scss'],
})
export class PyrometreListComponent
  extends GenericListComponent<Pyrometre>
  implements OnInit, OnDestroy, AfterViewInit
{
  public categories: Category[] = [];

  public dateFormat: string;

  public formControlNames = FormControlNames;

  public status = [
    { label: 'NOT CALIBRATED', id: false },
    { label: 'CALIBRATED', id: true },
  ];

  public constructor(
    protected pyrometreFilterService: PyrometreFilterService, //
    protected pyrometreService: PyrometreService, //
    protected override router: Router,
    protected override route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    super(pyrometreFilterService, pyrometreService, router, route);
    let referentielService = new ReferentielService();
    this.categories = referentielService.categories;
    this.dateFormat = localStorage[LocalStorageKey.DEFAULT_DATEFORMAT];
  }

  public checkDate(elementDate: number) {
    const oneYearDate = new Date();
    oneYearDate.setFullYear(oneYearDate.getFullYear() - 1);

    if (elementDate < oneYearDate.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  //
  // public createPyrometre() {
  //   this.router.navigate([`${Paths.pyrometre.CREATE}`], {
  //     relativeTo: this.route.parent,
  //   });
  // }

  public deletePyro(pyrometre: Pyrometre) {
    this.pyrometreService.delete(pyrometre).subscribe({
      next: () => {
        this.openDialogInfo(this.deleteDialogOk);
      },
      error: () => {
        this.openDialogInfo(this.deleteDialogKo);
      },
    });
  }

  public filterAddress($event: any, field: string): void {
    this.addFilter(field, $event, (value: Pyrometre) =>
      value.macAddress!.toLowerCase().includes($event.toLowerCase())
    );
  }

  public filterDate($event: any, field: string): void {
    this.addFilter(
      field,
      $event,
      (value: Pyrometre) => value.calibrationDate! == $event
    );
  }

  public filterId($event: any, field: string): void {
    this.addFilter(field, $event, (value: Pyrometre) =>
      value.id
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase())
    );
  }

  public filterLabel($event: any, field: string): void {
    this.addFilter(field, $event, (value: Pyrometre) =>
      value.label!.toLowerCase().includes($event.toLowerCase())
    );
  }

  public filterStatus($event: any, field: string): void {
    this.addFilter(
      field,
      $event,
      (value: Pyrometre) =>
        $event === this.checkDate(value.calibrationDate as any)
    );
  }

  public openDialogInfo(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public openDialogWithRef(ref: TemplateRef<any>, pyrometre: Pyrometre) {
    this.dialog.open(ref, {
      data: pyrometre,
    });
  }

  protected initColumnDisplay(): string[] {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'address':
          if (item.macAddress) {
            return item.macAddress.toString();
          }
          return item[property as keyof Pyrometre] as string;
        case 'date':
          if (item.calibrationDate) {
            return new Date(item.calibrationDate).getTime();
          }
          return item[property as keyof Pyrometre] as string;
        default:
          return item[property as keyof Pyrometre] as string;
      }
    };

    return [
      // FormControlNames.id,
      FormControlNames.label,
      FormControlNames.address,
      FormControlNames.Status,
      FormControlNames.date,
      'actions',
    ];
  }
}
