import { environment } from './environment';

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const currentUrl = `${window.location.protocol}//${window.location.host}`;

const awsMobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_icfr1YwTm',
  aws_user_pools_web_client_id: environment.acgUserPoolClientId,
  oauth: {
    domain: environment.acgUserPoolDomain,
    scope: [],
    redirectSignIn: `${currentUrl}${environment.acgUserPoolClientCallback}`,
    redirectSignOut: `${currentUrl}${environment.acgUserPoolLogoutEndpoint}`,
    responseType: 'code',
  },
};

export default awsMobile;
