<div class="al-site-form">


  <div class="header">
    <button mat-raised-button (click)="back()" color="primary">
      <mat-icon class="back">arrow_back</mat-icon>
      {{ "BACK_SITE_LIST" | translate}}
    </button>

    <h1 translate class="parent-component-title" *ngIf="this.creation">CREATION</h1>
    <h1 translate class="parent-component-title" *ngIf="!this.creation">UPDATE</h1>


    <!-- <mat-icon class="back" (click)="back()">arrow_back</mat-icon> -->
  </div>


  <mat-card>
    <!--    <span  *ngFor="let entity of entities"  > {{entity.label}}</span>-->
    <div class="navigation" *ngIf="!this.creation">
      <mat-icon class="prev" (click)="previous()" matTooltip="{{'SITE_FORM_PREVIOUS'|translate}}">skip_previous
      </mat-icon>
      <mat-icon class="next" (click)="next()" matTooltip="{{'SITE_FORM_NEXT'|translate}}">skip_next</mat-icon>

    </div>

    <form [formGroup]="form" (submit)="false">

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "SITE_LABEL" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.label" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "SITE_MAXIMO_CODE" | translate}}
        </mat-label>
        <input type="text" [formControlName]="formControlNames.maximoSiteId" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label>{{ "SITE_ENTITY" | translate}}
        </mat-label>
        <!--        <input type="text" formControlName="entity" matInput  >-->
        <mat-select [formControlName]="formControlNames.entity"
                    [compareWith]="compareEntity">
          <mat-option *ngFor="let entity of entities " [value]="entity">
            {{entity.label}}
          </mat-option>

        </mat-select>

      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label>{{ "SITE_COUNTRY" | translate}}
        </mat-label>
        <!--        <input type="text" formControlName="entity" matInput  >-->
        <mat-select [formControlName]="formControlNames.country"
                    [compareWith]="compareCountry"
        >
          <mat-option *ngFor="let country of  getCountryList()" [value]="country">
            {{country.label}}
          </mat-option>

        </mat-select>

      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "SITE_GMT" | translate}}
        </mat-label>

        <mat-select [formControlName]="formControlNames.timezone">
          <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezone">
            {{timezone.timezone}} - {{timezone.offset}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width" floatLabel="always">
        <mat-label class="required-field">{{ "SITE_DATEFORMAT" | translate}}
        </mat-label>
        <!--        <input type="text" formControlName="dateFormat" matInput >-->
        <mat-select [formControlName]="formControlNames.dateFormat">
          <mat-option *ngFor="let format of availableDateFormats" [value]="format.format">{{format.format}}
            - {{format.desc}}</mat-option>

        </mat-select>
      </mat-form-field>

      <div class="container">
        <div class="row">
          <div class="col">
            <mat-label class="required-field">{{ "VALIDATE_INSTANCE" | translate}}
            </mat-label>
          </div>
          <div class="col">
            <mat-checkbox [aria-label]="'VALIDATE_INSTANCE' | translate"
                          [formControlName]="formControlNames.validateInstance"

            ></mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-label class="required-field">{{ "SITE_FORM_ACTIVATE_SUPERVISION_BY_SUPERVISER" | translate}}
            </mat-label>
          </div>
          <div class="col">
            <mat-checkbox [aria-label]="'SITE_FORM_ACTIVATE_SUPERVISION_BY_SUPERVISER' | translate"
                          [formControlName]="formControlNames.alertOnAnomaly"

            ></mat-checkbox>
          </div>
        </div>


        <div class="row">
          <div class="col">
            <mat-label class="required-field">{{ "SITE_FORM_ACTIVATE_WORKORDER_CREATION" | translate}}
            </mat-label>
          </div>
          <div class="col">
            <mat-checkbox [aria-label]="'SITE_FORM_ACTIVATE_WORKORDER_CREATION' | translate"
                          [formControlName]="formControlNames.allowBtCreation"></mat-checkbox>
          </div>

        </div>


        <div class="row">
          <div class="col">
            <mat-label class="required-field">{{ "SITE_FORM_ACTIVATE_SERVICE_REQUEST_CREATION" | translate}}
            </mat-label>
          </div>
          <div class="col">
            <mat-checkbox [aria-label]="'SITE_FORM_ACTIVATE_SERVICE_REQUEST_CREATION' | translate"
                          [formControlName]="formControlNames.allowSrCreation"></mat-checkbox>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col buttons">
          <button type="button" mat-button [disabled]="!form.dirty" color="primary" translate (click)="cancel()">
            CANCEL
          </button>
          <button type="button" mat-button [disabled]="!form.valid || !form.dirty" color="accent" translate
                  (click)="save()">SAVE
          </button>
        </div>
      </div>
    </form>
  </mat-card>

</div>


<ng-template #infoDialog>
  <al-info-dialog (confirm)='doSomething()'>
    <div title translate>{{infoDialogTitle}}</div>
    <div content translate>{{infoDialogMessage}}</div>
    <div confirm-button translate>OK</div>
  </al-info-dialog>
</ng-template>
<ng-template #cancelDialog>
  <al-confirm-dialog (confirm)='clean()'>
    <div title translate>CANCEL</div>
    <div content translate>AGREE_TO_CANCEL</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>
<div style="display: none" translate>INFO_DIALOG_MESSAGE_OK</div>
<div style="display: none" translate>INFO_DIALOG_MESSAGE_OK_TITLE</div>
<div style="display: none" translate>INFO_DIALOG_MESSAGE_KO</div>
<div style="display: none" translate>INFO_DIALOG_MESSAGE_KO_TITLE</div>

