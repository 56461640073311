import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { Days } from '../days.model';
import { ReferentielService } from '@al/referentiel';

@JsonConverter
export class DaysConverter implements JsonCustomConvert<Days | null> {
  public deserialize(id: number): Days | null {
    const referentielService = new ReferentielService();

    return referentielService.getDaysFromId(id);
  }

  public serialize(days: Days): number | null {
    if (days) {
      return days.id;
    }
    return null;
  }
}
