import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { GenericComponent } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { Widget } from '@al/model';
import { WidgetFormControlNames } from './widget-form-control-names.enum';

export enum WidgetStatus {
  WIDGET,
  EDIT,
  PREVIEW,
}

@Component({
  selector: 'al-template-widget',
  templateUrl: './template-widget.component.html',
  styleUrls: ['./template-widget.component.scss'],
})
export class TemplateWidgetComponent
  extends GenericComponent
  implements OnInit, OnDestroy
{
  @Output()
  public OnDelete = new EventEmitter();

  @Output()
  public OnDuplicate = new EventEmitter();

  @Input()
  public widget: Widget = new Widget();

  @Input()
  public widgetStatus = WidgetStatus.EDIT;

  public controlId = '';

  public formControlNames = WidgetFormControlNames;

  public formGroup: FormGroup;

  public widgetStatusEnum = WidgetStatus;

  public constructor(
    protected formBuilder: FormBuilder,
    protected answerService: InstanceAnswerService,
    protected formGroupDirective: FormGroupDirective
  ) {
    super();
    this.formGroup = this.formBuilder.group({});
  }

  public copy(): void {
    this.OnDuplicate.emit(this.widget);
  }

  public delete(): void {
    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroupDirective.form.removeControl(this.controlId);
    }
    this.OnDelete.emit(this.widget);
  }

  public getComment(): string | null {
    return this.answerService.getComment(this.widget);
  }

  public getValue(): number | string | string[] | boolean | undefined {
    return this.answerService.getValue(this.widget);
  }

  public isAnomaly(): boolean {
    return this.answerService.isAnomaly(this.widget);
  }

  public isUndefined(): boolean {
    return this.answerService.isValid(this.widget);
  }

  public isValid(): boolean {
    return this.answerService.isValid(this.widget);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroupDirective.form.removeControl(this.controlId);
    }
  }

  public ngOnInit(): void {
    this.controlId = this.widget.constructor.name + '_' + this.widget.id;
  }
}
