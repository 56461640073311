import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function tubeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const tubes: FormArray = <FormArray>control.get('tubes');
    if (!tubes || tubes.length === 0) {
      return { noTubes: { value: tubes.value } };
    } else {
      if (
        tubes.controls.some((tubeControl) => {
          return tubeControl.value;
        })
      ) {
        return null;
      }
      return { noTubeSelected: { value: tubes.value } };
    }
  };
}
