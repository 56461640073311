import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subject, map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-table-column-header-filtering',
  templateUrl: './table-column-header-filtering.component.html',
  styleUrls: ['./table-column-header-filtering.component.scss'],
})
export class TableColumnHeaderFilteringComponent implements OnInit, OnDestroy {
  @Output() public OnClear = new EventEmitter();

  @Output() public OnSearch = new EventEmitter();

  @ViewChild('dateRangeEnd')
  public dateRangeEnd!: ElementRef;

  @ViewChild('dateRangeStart')
  public dateRangeStart!: ElementRef;

  @Input()
  public field!: string;

  @Input()
  public fieldType!: string;

  @Input()
  public isSelect: boolean = false;

  @Input()
  public sortable = true;

  @Input()
  public storageNameSpace: string | null = null;

  @Input()
  public title: string;

  @Input()
  public value!: any;

  @Input()
  public values!: any[];

  @Input()
  public widgetTypeSubject: Subject<{ id: any; label: string }[]> | undefined =
    undefined;

  public form: FormGroup;

  public valeur: any[] = [];

  private localStorageKey!: string;

  private ngUnsubscribe = new Subject();

  private persist = false;

  public constructor(private fb: FormBuilder) {
    this.title = '';
    this.form = this.fb.group({
      value: this.fb.control(''),
    });
  }

  public clearValue(): void {
    if (this.isSelect && this.fieldType === 'input') {
      this.valeur = this.values.map((value) => value.id);
      this.submit();
    } else {
      this.form.get('value')?.setValue('');
      if (this.persist) {
        localStorage.removeItem(this.localStorageKey);
        if (this.isSelect) {
          localStorage.removeItem(this.localStorageKey);
          this.valeur = [];
        }
      }
      if (
        this.fieldType === 'dateRange' &&
        this.dateRangeEnd &&
        this.dateRangeStart
      ) {
        this.dateRangeEnd.nativeElement.value = '';
        this.dateRangeStart.nativeElement.value = '';
      }
      this.OnClear.next(this.field);
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    if (this.widgetTypeSubject) {
      this.widgetTypeSubject
        .pipe(
          takeUntil(this.ngUnsubscribe),
          map((data: { id: any; label: string }[]) => {
            this.values = data;
            return data.map((e) => e.id);
          })
        )

        .subscribe((data) => {
          this.valeur = data;
          this.submit();
        });
    }
    if (this.storageNameSpace != null) {
      this.persist = true;
      this.localStorageKey = `${this.storageNameSpace}-${this.field}-${this.fieldType}`;
    }

    if (this.persist && localStorage.getItem(this.localStorageKey)) {
      this.form
        .get('value')
        ?.setValue(localStorage.getItem(this.localStorageKey));
      if (this.isSelect) {
        this.valeur = JSON.parse(
          localStorage.getItem(this.localStorageKey) as string
        );
        if (this.fieldType === 'input' && this.valeur.length < 1) {
          this.valeur = this.values.map((value) => value.id);
        }
      }
      this.submit();
    } else {
      this.form.get('value')?.setValue('');
      if (this.isSelect && this.fieldType === 'input') {
        this.valeur = this.values.map((value) => value.id);
        this.submit();
      }
    }
  }

  public submit(): void {
    if (this.persist === true) {
      if (this.isSelect) {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.valeur));
        this.OnSearch.next(this.valeur);
      } else {
        localStorage.setItem(
          this.localStorageKey,
          this.form.get('value')?.value
        );
        this.OnSearch.next(this.form.get('value')?.value);
      }
    } else {
      if (this.isSelect) {
        this.OnSearch.next(this.valeur);
      } else {
        this.OnSearch.next(this.form.get('value')?.value);
      }
    }
  }

  public submitDate(
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ): void {
    this.OnSearch.next([dateRangeStart.value, dateRangeEnd.value]);
  }
}
