import { GenericComponentsModule } from '@al/generic-components';
import { NgModule } from '@angular/core';
import { PipeModule } from '@al/pipe';
import { PyrometreFormComponent } from './pyrometre-form/pyrometre-form.component';
import { PyrometreListComponent } from './pyrometre-list/pyrometre-list.component';
import { PyrometreManagerComponent } from './pyrometre-manager/pyrometre-manager.component';

@NgModule({
  declarations: [
    PyrometreListComponent,
    PyrometreManagerComponent,
    PyrometreFormComponent,
  ],
  imports: [GenericComponentsModule, PipeModule],
  exports: [],
})
export class PyrometreComponentsModule {}
