<div>
  <h2 matDialogTitle>
    <ng-content select="[title]"></ng-content>
  </h2>
  <p matDialogContent>
    <ng-content select="[content]"></ng-content>
  </p>
  <mat-dialog-actions align="end">

    <button
      mat-raised-button
      matDialogClose
      mat-button
      (click)="cancelAction()">
      <ng-content select="[cancel-button]"></ng-content>
    </button>

    <button mat-raised-button
            matDialogClose
            mat-button
            (click)="confirmAction()" >
      <ng-content select="[confirm-button]">
      </ng-content>
    </button>

  </mat-dialog-actions>
</div>
