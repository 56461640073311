import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlSpinnerService {
  public isDataProcessing: Observable<boolean>;

  public message: Observable<string>;

  public snackBarIsDisplayed: Observable<{
    duration: number | null;
    text: string;
  }>;

  private $isDataProcessing: BehaviorSubject<boolean>;

  private $message: BehaviorSubject<string>;

  private $snackBarIsDisplayed: BehaviorSubject<{
    duration: number | null;
    text: string;
  }>;

  private dataProcessList: string[] = [];

  public constructor(private translate: TranslateService) {
    this.$isDataProcessing = new BehaviorSubject<boolean>(false);
    this.isDataProcessing = this.$isDataProcessing.asObservable();

    this.$message = new BehaviorSubject<string>('');
    this.message = this.$message.asObservable();

    // INFO: SNACKBAR pour afficher les message d'erreur (et de succès)
    // TODO: A déplacer dans le GlobalLogger (A créer)
    this.$snackBarIsDisplayed = new BehaviorSubject<{
      duration: number | null;
      text: string;
    }>({
      duration: null,
      text: '',
    });
    this.snackBarIsDisplayed = this.$snackBarIsDisplayed.asObservable();
  }

  public launchSnackBar(text: string, duration: number | null): void {
    let truText = this.translate.instant(text);
    this.$snackBarIsDisplayed.next({ text: truText, duration });
  }

  public startDataProcess(processId: string, message?: string): void {
    if (!this.dataProcessList.includes(processId)) {
      this.dataProcessList.push(processId);
      this.$isDataProcessing.next(true);
    }
    this.$message.next(message || '');
  }

  public stopDataProcess(processId: string): void {
    if (this.dataProcessList.includes(processId)) {
      const index = this.dataProcessList.indexOf(processId);
      if (index > -1) {
        this.dataProcessList.splice(index, 1);
      }
    }
    if (this.dataProcessList.length === 0) {
      this.$isDataProcessing.next(false);
    }
  }
}
