import { FurnaceFull, FurnaceItems } from '@al/model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FurnaceRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public getAllFurnaces(): Observable<FurnaceItems> {
    return super.get<FurnaceItems>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.furnaces.list}`,
      new HttpParams(),
      this.getFurnaceItemsClazz()
    );
  }

  public getFullFurnace(id: number): Observable<FurnaceFull> {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('id', id);

    return super.get<FurnaceFull>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.furnaces.get}${id}`,
      httpParams,
      this.getFurnaceFullClazz()
    );
  }

  private getFurnaceFullClazz(): typeof FurnaceFull {
    return FurnaceFull;
  }

  private getFurnaceItemsClazz(): typeof FurnaceItems {
    return FurnaceItems;
  }
}
