<!-- <div cla> -->
<!-- <div class="row" *ngFor=" let s of obs | async">
  {{s.id}} {{s.label}}
</div> -->
<div class="card-container">
  <mat-card class="card-template">
    <mat-card-header>
      <mat-card-title *ngIf="kpi | async as kpis; else elseBlock">{{
        kpis.templatesCounter
      }}</mat-card-title>
      <mat-icon aria-hidden="false" fontIcon="dashboard"></mat-icon>

      <mat-card-subtitle translate>FORM_TEMPLATES</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="showTemplate()">
        {{ "MENU_WIDGET_TEMPLATE_MENU" | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="createTemplate()"
        [disabled]="
          userProfile &&
          !(
            userProfile.id === '40' ||
            userProfile.id === '30' ||
            userProfile.id === '20'
          )
        "
      >
        {{ "CREATE_TEMPLATE" | translate }}
      </button>
    </mat-card-actions>

    <mat-card-content>
      <span> {{ "PU_LABEL" | translate }} : {{ puCount }}</span>
      <span> {{ "PG_LABEL" | translate }} : {{ pgCount }}</span>
      <span> {{ "ASSET_LABEL" | translate }} : {{ assetCount }}</span>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-user">
    <mat-card-header>
      <mat-card-title *ngIf="kpi | async as kpis; else elseBlock">{{
        kpis.usersCounter
      }}</mat-card-title>
      <mat-icon aria-hidden="false" fontIcon="group"></mat-icon>

      <mat-card-subtitle translate>USERS</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        (click)="showUsersList()"
        [disabled]="
          userProfile && !(userProfile.id === '40' || userProfile.id === '30')
        "
      >
        {{ "MANAGE_USERS" | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="createUser()"
        [disabled]="
          userProfile && !(userProfile.id === '40' || userProfile.id === '30')
        "
      >
        {{ "CREATE_USER" | translate }}
      </button>
    </mat-card-actions>
    <mat-card-content> </mat-card-content>
  </mat-card>

  <mat-card class="card-ronde">
    <mat-card-header>
      <mat-card-title *ngIf="kpi | async as kpis; else elseBlock">
        {{ kpis.instancesCounter }}
      </mat-card-title>

      <mat-icon aria-hidden="false" fontIcon="file_upload"></mat-icon>

      <mat-card-subtitle translate>ROUND_REPORTS</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="showRonde()">
        {{ "SEE_REPORTED_ROUNDS" | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="showScheduler()">
        {{ "SEE_SCHEDULER" | translate }}
      </button>
    </mat-card-actions>
    <mat-card-content> </mat-card-content>
  </mat-card>

  <mat-card class="card-admin">
    <mat-card-header>
      <mat-card-title></mat-card-title>
      <mat-card-subtitle translate>ADMINISTRATION</mat-card-subtitle>
    </mat-card-header>

    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        [disabled]="userProfile && userProfile.id !== '40'"
        (click)="showSiteList()"
      >
        {{ "MANAGE_SITES" | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="
          userProfile &&
          !(
            userProfile.id === '40' ||
            userProfile.id === '30' ||
            userProfile.id === '20'
          )
        "
        (click)="showPyrometre()"
      >
        {{ "MANAGE_PYROMETERS" | translate }}
      </button>
    </mat-card-actions>
    <mat-card-content> </mat-card-content>
  </mat-card>
</div>

<ng-template #elseBlock>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>

<!-- </div> -->
