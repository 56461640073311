import { GenericComponentsModule } from '@al/generic-components';
import { NgModule } from '@angular/core';
import { PipeModule } from '@al/pipe';

import { PreviewComponent } from './preview.component';
import { WidgetComponentsModule } from '@al/widget-components';

@NgModule({
  declarations: [PreviewComponent],
  imports: [GenericComponentsModule, WidgetComponentsModule, PipeModule],
  exports: [PreviewComponent],
})
export class PreviewComponentModule {}
