import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { Widget } from './widget.model';

@JsonObject('section')
export class SectionWidget extends Widget {
  @JsonProperty('label', String, true)
  public label: string = '';

  // do not use annotation, serialisation/deserialisation with converter
  public children: Widget[] = [];

  public constructor() {
    super();
    this.componentType = ComponentType.SECTION;
    this.children = [];
  }
}
