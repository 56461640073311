import { Observable, Subject, map, takeUntil } from 'rxjs';
import { RondeFull, RondeLight } from '@al/model';
import { RondeFullQuery, RondeFullStore } from '@al/akita';
import { GenericService } from './generic.service';
import { Injectable } from '@angular/core';
import { RondeRestService } from '@al/rest-services';

@Injectable({
  providedIn: 'root',
})
export class RondeFullService extends GenericService<RondeFull> {
  private ngUnsubscribe = new Subject();

  public constructor(
    protected override restService: RondeRestService,
    protected query: RondeFullQuery,
    protected store: RondeFullStore
  ) {
    super(restService, query, store);
  }

  public getRondeFull(rondeLight: RondeLight): Observable<RondeFull> {
    return this.restService.getFullRonde(rondeLight.instance!.id).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: RondeFull) => {
        // Pas de stockage dans le store
        return data;
      })
    );
  }

  public loadStore(): Observable<void> {
    return new Observable<void>();
  }
}
