import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Returnable, ValidationWidget } from '@al/model';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { DragAndDropService } from '@al/dnd-service';
import { FormGroupModelInterface } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { WidgetFormControlNames } from '../widget-form-control-names.enum';

@Component({
  selector: 'al-validation-widget',
  templateUrl: './validation-widget.component.html',
  styleUrls: [
    '../template-widget.component.scss',
    './validation-widget.component.scss',
  ],
})
export class ValidationWidgetComponent
  extends TemplateWidgetComponent
  implements FormGroupModelInterface<ValidationWidget>, OnInit
{
  public validationWidget: ValidationWidget = new ValidationWidget();

  public constructor(
    protected dragAndDropService: DragAndDropService,
    protected override formBuilder: FormBuilder,
    protected override answerService: InstanceAnswerService,
    protected override formGroupDirective: FormGroupDirective
  ) {
    super(formBuilder, answerService, formGroupDirective);
  }

  public buildFormGroup(validationWidget: ValidationWidget | null): FormGroup {
    return this.formBuilder.group({
      isMandatory: [validationWidget?.isMandatory, []],
      label: this.formBuilder.control(validationWidget?.label, [
        Validators.required,
      ]),
      tooltip: this.formBuilder.control(validationWidget?.tooltip),
    });
  }

  public getAssets(value: Returnable) {
    this.validationWidget.equipment = value.asset;
    this.validationWidget.processGroup = value.locationPG;
    this.validationWidget.productionUnit = value.locationPU;
  }

  public getEntityToSave(): ValidationWidget {
    return new ValidationWidget();
  }

  public hasAnomaly(): boolean {
    return this.answerService.isAnomaly(this.validationWidget);
  }

  public initModel(): ValidationWidget {
    return new ValidationWidget();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.validationWidget = this.widget as ValidationWidget;

    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroup = this.buildFormGroup(this.validationWidget);
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);
    }
  }

  public save(): void {}

  public setValue(formControlName: WidgetFormControlNames): void {
    if (this.formGroup != null && this.formGroup.get(formControlName) != null) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl != null) {
        if (formControlName === WidgetFormControlNames.label) {
          this.validationWidget.label = formControl.value;
        } else if (formControlName === WidgetFormControlNames.tooltip) {
          this.validationWidget.tooltip = formControl.value;
        } else if (formControlName === WidgetFormControlNames.isMandatory) {
          this.validationWidget.isMandatory = formControl.value;
        }
      }
    }
  }
}
