import { HistoryItem, TemplateHistoryList } from '@al/model';
import { HistoryQuery, HistoryStore } from '@al/akita';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map, of, takeUntil } from 'rxjs';
import { GenericService } from './generic.service';
import { HistoryRestService } from '@al/rest-services';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root',
})
export class HistoryService
  extends GenericService<HistoryItem>
  implements OnDestroy
{
  private ngUnsubscribe = new Subject();

  public constructor(
    protected override restService: HistoryRestService,
    protected override akitaQuery: HistoryQuery,
    protected override akitaStore: HistoryStore
  ) {
    super(restService, akitaQuery, akitaStore);
  }

  public loadHistoryTemplate(
    templateId: number | string
  ): Observable<TemplateHistoryList> {
    return this.restService.getHistory(templateId).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: TemplateHistoryList) => {
        // this.akitaStore.destroy();
        let predicate = (t: HistoryItem) => t.templateId === templateId;
        this.akitaStore.remove(predicate);

        data.body.forEach((e) => {
          e.id = UUID.UUID();
          this.akitaStore.add(e);
        });
        data.headers.forEach((e) => {
          e.id = UUID.UUID();
          this.akitaStore.add(e);
        });
        // this.akitaStore.add(data.headers);
        return data;
      })
    );
  }

  public loadStore(): Observable<void> {
    return of();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
