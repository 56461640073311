import { Entity, Pyrometre, PyrometreItems } from '@al/model';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AwsEndPoint } from '../aws.endpoint';

import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteService } from '@al/services';

@Injectable({
  providedIn: 'root',
})
export class PyrometreRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected siteService: SiteService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public create(pyrometre: Pyrometre): Observable<Pyrometre> {
    return super.put<Pyrometre>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.pyrometre.edit}${pyrometre.id}`,
      new HttpParams(),
      this.getPyrometreClazz(),
      pyrometre
    );
    // .pipe(
    //   map((event) => {
    //     return event;
    //   })
    // );
  }

  public deletePyro(pyrometre: Pyrometre) {
    return super.delete<Pyrometre>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.pyrometre.edit}${pyrometre.id}`,
      new HttpParams()
    );
    // .pipe(
    //   map((event) => {
    //     return event;
    //   })
    // );
  }

  public getAllPyrometres(): Observable<PyrometreItems> {
    return super.get<PyrometreItems>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.pyrometre.list}`,
      new HttpParams(),
      this.getPyrometreItemClazz()
    );
  }

  public getEntities(): Observable<Entity[]> {
    return super.getList<Entity>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.entity.list}`,
      new HttpParams(),
      this.getEntityClazz()
    );
  }

  public getPyrometre(): Observable<Array<Pyrometre>> {
    let httpParams = new HttpParams();
    if (this.siteService.getCurrentSite()) {
      const site = this.siteService.getCurrentSite();
      if (site) {
        httpParams = httpParams.set('siteId', site.id);
      }
    }

    return super.getList<Pyrometre>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.pyrometre.list}`,
      httpParams,
      this.getPyrometreClazz()
    );
  }

  public save(pyrometre: Pyrometre): Observable<Pyrometre> {
    return super.post<Pyrometre>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.pyrometre.edit}${pyrometre.id}`,
      new HttpParams(),
      this.getPyrometreClazz(),
      pyrometre
    );
  }

  protected getEntityClazz(): typeof Entity {
    return Entity;
  }

  protected getPyrometreClazz(): typeof Pyrometre {
    return Pyrometre;
  }

  protected getPyrometreItemClazz(): typeof PyrometreItems {
    return PyrometreItems;
  }
}
