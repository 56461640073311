import { AlAssetsTreeModule } from '@al/asset-tree';
import { CommonPreviewComponent } from './common-preview/common-preview.component';
import { GateWidgetComponent } from './gate-widget/gate-widget.component';
import { GenericComponentsModule } from '@al/generic-components';
import { InfoWidgetComponent } from './info-widget/info-widget.component';
import { McqWidgetComponent } from './mcq-widget/mcq-widget.component';
import { MeasureWidgetComponent } from './measure-widget/measure-widget.component';
import { NgModule } from '@angular/core';
import { NoteWidgetComponent } from './note-widget/note-widget.component';
import { PipeModule } from '@al/pipe';
import { SectionWidgetComponent } from './section-widget/section-widget.component';
import { TemplateWidgetComponent } from './template-widget.component';
import { ValidationWidgetComponent } from './validation-widget/validation-widget.component';
import { WheelpickerWidgetComponent } from './wheelpicker-widget/wheelpicker-widget.component';

@NgModule({
  declarations: [
    CommonPreviewComponent,
    GateWidgetComponent,
    InfoWidgetComponent,
    McqWidgetComponent,
    MeasureWidgetComponent,
    NoteWidgetComponent,
    SectionWidgetComponent,
    TemplateWidgetComponent,
    ValidationWidgetComponent,
    WheelpickerWidgetComponent,
  ],
  imports: [AlAssetsTreeModule, GenericComponentsModule, PipeModule],
  exports: [
    GateWidgetComponent,
    InfoWidgetComponent,
    McqWidgetComponent,
    MeasureWidgetComponent,
    NoteWidgetComponent,
    SectionWidgetComponent,
    TemplateWidgetComponent,
    ValidationWidgetComponent,
    WheelpickerWidgetComponent,
  ],
})
export class WidgetComponentsModule {}
