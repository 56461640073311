import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { McqOption, McqWidget, Returnable } from '@al/model';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { DragAndDropService } from '@al/dnd-service';
import { FormGroupModelInterface } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { WidgetFormControlNames } from '../widget-form-control-names.enum';

@Component({
  selector: 'al-mcq-widget',
  templateUrl: './mcq-widget.component.html',
  styleUrls: [
    '../template-widget.component.scss',
    './mcq-widget.component.scss',
  ],
})
export class McqWidgetComponent
  extends TemplateWidgetComponent
  implements FormGroupModelInterface<McqWidget>, OnInit
{
  public FormGroupClass = FormGroup; // used for casting in template

  public mcqWidget: McqWidget = new McqWidget();

  public get optionForms(): FormArray {
    return this.formGroup.get('options') as FormArray;
  }

  public constructor(
    protected override formBuilder: FormBuilder,
    protected override answerService: InstanceAnswerService,
    protected dragAndDropService: DragAndDropService,
    protected override formGroupDirective: FormGroupDirective
  ) {
    super(formBuilder, answerService, formGroupDirective);
  }

  public addOption(): void {
    let newOption = new McqOption();
    this.mcqWidget.options.push(newOption);

    const optionForm = this.formBuilder.group({
      label: [newOption.label, [Validators.required]],
      canRaiseAnomaly: [newOption.canRaiseAnomaly, []],
    });
    this.optionForms.push(optionForm);
  }

  public buildFormGroup(mcqWidget: McqWidget | null): FormGroup {
    let form = this.formBuilder.group({
      label: this.formBuilder.control(mcqWidget?.label, [Validators.required]),
      tooltip: this.formBuilder.control(mcqWidget?.tooltip),
      options: this.formBuilder.array([], [Validators.minLength(2)]), //initialize empty formArray
      singleChoiceOnly: this.formBuilder.control(
        mcqWidget?.singleChoiceOnly,
        []
      ),
      isMandatory: [mcqWidget?.isMandatory, []],
    });
    // add existing options (if any) to formArray
    if (mcqWidget != null && mcqWidget.options != null) {
      for (const option of mcqWidget.options) {
        (form.get('options') as FormArray).push(
          this.formBuilder.group({
            label: [option.label, [Validators.required]],
            canRaiseAnomaly: [option.canRaiseAnomaly, []],
          })
        );
      }
    }

    return form;
  }

  public deleteOption(i: number): void {
    this.mcqWidget.options.splice(i, 1);
    this.optionForms.removeAt(i);
  }

  public getAssets(value: Returnable) {
    this.mcqWidget.equipment = value.asset;
    this.mcqWidget.processGroup = value.locationPG;
    this.mcqWidget.productionUnit = value.locationPU;
  }

  public getEntityToSave(): McqWidget {
    return new McqWidget();
  }

  public hasAnomaly(): boolean {
    return this.answerService.isAnomaly(this.mcqWidget);
  }

  public initModel(): McqWidget {
    return new McqWidget();
  }

  public isOptionChecked(option: McqOption): boolean {
    let checkedOptionsId = this.getValue() as string[];
    if (checkedOptionsId) {
      return checkedOptionsId.includes(option.id.toString());
    }
    return false;
  }

  public override ngOnInit() {
    super.ngOnInit();

    this.mcqWidget = this.widget as McqWidget;

    this.formGroup = this.buildFormGroup(this.mcqWidget);
    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);
    }
  }

  public save(): void {}

  public setOptionCanRaiseAnomaly(index: number): void {
    if (this.optionForms != null) {
      let optionFormGroup = this.optionForms.at(index) as FormGroup;
      let option = this.mcqWidget.options.at(index);
      if (optionFormGroup != null && option != null) {
        option.canRaiseAnomaly = optionFormGroup.get(
          WidgetFormControlNames.canRaiseAnomaly
        )?.value;
      }
    }
  }

  public setOptionText(index: number): void {
    if (this.optionForms != null) {
      let optionFormGroup = this.optionForms.at(index) as FormGroup;
      let option = this.mcqWidget.options.at(index);
      if (optionFormGroup != null && option != null) {
        option.label = optionFormGroup.get(WidgetFormControlNames.label)?.value;
      }
    }
  }

  public setValue(formControlName: WidgetFormControlNames): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === WidgetFormControlNames.label) {
          this.mcqWidget.label = formControl.value;
        } else if (formControlName === WidgetFormControlNames.tooltip) {
          this.mcqWidget.tooltip = formControl.value;
        } else if (
          formControlName === WidgetFormControlNames.singleChoiceOnly
        ) {
          this.mcqWidget.singleChoiceOnly = formControl.value;
        } else if (formControlName === WidgetFormControlNames.isMandatory) {
          this.mcqWidget.isMandatory = formControl.value;
        }
      }
    }
  }
}
