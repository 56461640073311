import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { SiteSelectorComponent } from './site-selector.component';

@NgModule({
  declarations: [SiteSelectorComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, MatSelectModule],
  exports: [SiteSelectorComponent],
})
export class SiteSelectorModule {}
