import { JsonObject, JsonProperty } from 'json2typescript';
import { ComponentType } from '../component-type.enum';
import { Widget } from './widget.model';

@JsonObject('text')
export class InfoWidget extends Widget {
  @JsonProperty('link', String, true)
  public link: string = '';

  @JsonProperty('text', String, true)
  public text: string = '';

  public constructor() {
    super();
    this.componentType = ComponentType.TEXT;
  }
}
