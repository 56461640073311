import { GenericComponentsModule } from '@al/generic-components';
import { MomentModule } from 'ngx-moment';
import { NgModule } from '@angular/core';
import { PipeModule } from '@al/pipe';
import { SchedulerListComponent } from './scheduler-list/scheduler-list.component';
import { SchedulerManagerComponent } from './scheduler-manager/scheduler-manager.component';

@NgModule({
  declarations: [SchedulerListComponent, SchedulerManagerComponent],
  imports: [GenericComponentsModule, MomentModule, PipeModule],
  exports: [SchedulerListComponent, SchedulerManagerComponent],
})
export class SchedulerComponentModule {}
