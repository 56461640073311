<mat-expansion-panel class="al-widget al-widget-info" *ngIf="widgetStatus===widgetStatusEnum.EDIT"
                     [formGroup]="formGroup">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="compomentIcon">door_back_outline</mat-icon>
      <span class="label">{{'TEMPLATE_WIDGET_GATE_TITLE' | translate}} : {{gateWidget.label}}</span>
    </mat-panel-title>
    <mat-panel-description class="icons">
      <mat-icon (click)="delete()">delete</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="al-widget-body" (mousedown)="$event.stopPropagation()">

    <!--    GATE CHOICE    --->
    <div class="full-width-label">
      <mat-form-field appearance="outline" class="text-label" floatLabel="always">
        <mat-label class="required-field">{{"TEMPLATE_WIDGET_GATE_GATE_NAME" | translate}}</mat-label>
        <input type="text"
               matInput
               formControlName="gate"
               required
               [matAutocomplete]="gateAutocomplete">
        <mat-autocomplete #gateAutocomplete="matAutocomplete" [displayWith]="displayGate"
                          (optionSelected)="onGateSelected($event.option.value)">
          <mat-option *ngFor="let gate of availableGates" [value]="gate">
            {{gate.description}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>



    <!--    TUBES -->
    <div formArrayName="tubes">
      <ng-container *ngFor="let tubeControl of tubeArray.controls; let i=index" class="tube-container">
        <div class="tube-row">
          <mat-checkbox [formControlName]="i"
                        (change)="onTubeToggle(i,$event.checked)">{{getTubeName(i)}}</mat-checkbox>
          <mat-icon *ngIf="isGtCenter(i)">center_focus_weak</mat-icon>
        </div>
      </ng-container>
    </div>

    <mat-error *ngIf="formGroup.errors?.['noTubes']">
      {{ 'NO_TUBES_ERROR' | translate }}
    </mat-error>
    <mat-error *ngIf="formGroup.errors?.['noTubeSelected']">
      {{ 'NO_TUBE_SELECTED_ERROR' | translate }}
    </mat-error>


    <!--    SHOOT DIRECTION -->
    <div class="shoot-direction-container">
      <mat-radio-group formControlName="firingOrder" class="radios" (change)="onFiringOrderChange($event.value)">
        <mat-radio-button [value]="gateDirections.ltr">
          {{"TEMPLATE_WIDGET_GATE_DIRECTION_LTR" | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="gateDirections.rtl">
          {{"TEMPLATE_WIDGET_GATE_DIRECTION_RTL" | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="gateDirections.gtOrder">
          {{"TEMPLATE_WIDGET_GATE_GT_ORDER" | translate}}
        </mat-radio-button>
      </mat-radio-group>
      <mat-form-field *ngIf="formGroup.contains('gtCenter')" appearance="fill">
        <mat-label>{{"TEMPLATE_WIDGET_GATE_GT_ORDER_INFO" | translate}}</mat-label>
        <mat-select formControlName="gtCenter" [compareWith]="compareTube"
                    (selectionChange)="onGtCenterChange($event.value)">
          <mat-option *ngFor="let tube of selectedTubes" [value]="tube">{{tube.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


  </div>
</mat-expansion-panel>
<mat-card class="al-widget-gate" *ngIf="widgetStatus===widgetStatusEnum.WIDGET" [formGroup]="formGroup">
  <mat-icon>door_back_outline</mat-icon>
  {{'WIDGET_GATE' | translate}}
</mat-card>
