import { JsonObject, JsonProperty } from 'json2typescript';
import { Category } from '../category.model';
import { CategoryRondeConverter } from '../converter/category-ronde.converter';
import { DatetimeConverter } from '../converter/datetime.converter';
import { InstanceBody } from './ronde-full.instance-body.model';
import { ModelAbstract } from '../model.abstract';
import { PycosoBody } from './pycoso/pycoso-body.model';
import { Status } from '../status.model';
import { StatusRondeConverter } from '../converter/status-ronde.converter';
import { Validation } from './validation.model';

@JsonObject('instanceFull')
export class InstanceFull extends ModelAbstract {
  @JsonProperty('categoryId', CategoryRondeConverter, true)
  public category: Category | null = null;

  @JsonProperty('categoryLabelForSortedQuery', String, true)
  public categoryLabelForSortedQuery: string | null = null;

  @JsonProperty('executorValidation', Validation, true)
  public executorValidation: Validation | null = null;

  @JsonProperty('formTemplateId', String, true)
  public formTemplateId: string | null = null;

  @JsonProperty('generateVHT', Boolean, true)
  public generateVHT: boolean = false;

  @JsonProperty('hasAnomaly', Boolean, true)
  public hasAnomaly: boolean = false;

  @JsonProperty('id', Number, true)
  public id = 0;

  @JsonProperty('instanceBody', InstanceBody, true)
  public instanceBody: InstanceBody | null = null;

  @JsonProperty('location', String, true)
  public location: string | null = null;

  @JsonProperty('modificationDate', DatetimeConverter, true)
  public modificationDate: Date | null = null;

  @JsonProperty('ownerId', String, true)
  public ownerId: string | null = null;

  @JsonProperty('ownerName', String, true)
  public ownerName: string | null = null;

  @JsonProperty('ownerNameForSortedOrFilteredQuery', String, true)
  public ownerNameForSortedOrFilteredQuery: string | null = null;

  @JsonProperty('pycosoBody', PycosoBody, true)
  public pycosoBody: PycosoBody | null = null;

  @JsonProperty('scadaFile', String, true)
  public scadaFile: string | null = null;

  @JsonProperty('sharedQueryPartition', Number, true)
  public sharedQueryPartition = 0;

  @JsonProperty('siteId', String, true)
  public siteId: string | null = null;

  @JsonProperty('siteLabelForSortedQuery', String, true)
  public siteLabelForSortedQuery: string | null = null;

  @JsonProperty('startDate', DatetimeConverter, true)
  public startDate: Date | null = null;

  @JsonProperty('status', StatusRondeConverter, true)
  public status: Status | null = null;

  @JsonProperty('supervisorValidation', Validation, true)
  public supervisorValidation: Validation | null = null;

  @JsonProperty('title', String, true)
  public title: string | null = null;

  @JsonProperty('titleForSortedOrFilteredQuery', String, true)
  public titleForSortedOrFilteredQuery: string | null = null;

  @JsonProperty('validated', Boolean, true)
  public validated: boolean = false;
}
