import { RondeLightState, RondeLightStore } from '../store/ronde-light.store';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class RondeLightQuery extends QueryEntity<RondeLightState> {
  public constructor(protected override store: RondeLightStore) {
    super(store);
  }
}
