import { Injectable } from '@angular/core';
import { KpiRestService } from '@al/rest-services';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  public constructor(protected kpiRestService: KpiRestService) {}

  public getKpis() {
    return this.kpiRestService.getKpis();
  }
}
