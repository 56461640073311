import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TemplateFull } from '@al/model';

export interface TemplateFullState
  extends EntityState<TemplateFull>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'template-full', resettable: true })
export class TemplateFullStore extends EntityStore<TemplateFullState> {
  public constructor() {
    super();
  }
}
