import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, lastValueFrom, mergeMap, of } from 'rxjs';
import { Auth } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { SessionService } from '@al/session';
import { UserService } from '@al/services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  public constructor(
    private router: Router,
    protected userService: UserService,
    protected sessionService: SessionService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const roles = route.data['roles'] as Array<string>;

    const checkAuth = Auth.currentAuthenticatedUser()
      .then(() => {
        return true;
      })
      .catch(() => {
        // return this.sessionService.login();
        return this.router.createUrlTree(['/login']);
      });

    if (this.sessionService.isConnected()) {
      const allChecked: Observable<boolean | UrlTree> = this.userService
        .getConnectedUser()
        .pipe(
          mergeMap((user) => {
            const userProfile = user.profileType?.id;
            if (userProfile && roles && roles.length > 0) {
              if (roles.includes(userProfile)) {
                return of(true);
              }
              return of(this.router.createUrlTree(['/dashboard']));
            }
            return checkAuth;
          })
        );
      return lastValueFrom(allChecked);
    } else {
      return checkAuth;
    }
  }

  protected delay(ms: number): Promise<Number> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
