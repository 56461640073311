import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MeasureWidget, Returnable, Unit } from '@al/model';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { DragAndDropService } from '@al/dnd-service';
import { FormGroupModelInterface } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { Observable } from 'rxjs';
import { UnitFilterService } from '../unit-filter.service';
import { UnitService } from '@al/services';
import { WidgetFormControlNames } from '../widget-form-control-names.enum';

@Component({
  selector: 'al-measure-widget',
  templateUrl: './measure-widget.component.html',
  styleUrls: [
    '../template-widget.component.scss',
    './measure-widget.component.scss',
  ],
})
export class MeasureWidgetComponent
  extends TemplateWidgetComponent
  implements FormGroupModelInterface<MeasureWidget>, OnInit
{
  public filteredUnits: Observable<Unit[]> = new Observable<Unit[]>();

  public measureWidget: MeasureWidget = new MeasureWidget();

  public units: Unit[] = [];

  public constructor(
    protected override formBuilder: FormBuilder,
    protected override answerService: InstanceAnswerService,
    protected dragAndDropService: DragAndDropService,
    protected override formGroupDirective: FormGroupDirective,
    protected unitFilterService: UnitFilterService,
    protected unitService: UnitService
  ) {
    super(formBuilder, answerService, formGroupDirective);
  }

  public buildFormGroup(measureWidget: MeasureWidget | null): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      disableControl: [measureWidget?.disableControl, []],
      label: [measureWidget?.label, Validators.required],
      isMandatory: [measureWidget?.isMandatory, []],

      min: [
        measureWidget?.min,
        [
          // Validators.required,
          (control: AbstractControl) =>
            Validators.max(this.measureWidget.max || Number.MAX_SAFE_INTEGER)(
              control
            ),
        ],
      ],
      max: [
        measureWidget?.max,
        [
          // Validators.required,
          (control: AbstractControl) =>
            Validators.min(this.measureWidget.min || Number.MIN_SAFE_INTEGER)(
              control
            ),
        ],
      ],
      tooltip: [measureWidget?.tooltip],
    });
    form.addControl(
      'unit',
      new FormControl(this.measureWidget.unit, [
        Validators.required,
        (control: AbstractControl): ValidationErrors | null => {
          return this.controlUnit(control);
        },
      ])
    );
    return form;
  }

  public compareUnit(option: Unit, value: Unit): boolean {
    if (option !== null && value !== null) {
      return option.uuid === value.uuid;
    }
    return false;
  }

  public displayUnit(unit: Unit): string {
    if (unit.uuid !== null) {
      return `${unit.abbreviation}-${unit.description}`;
    }
    return '';
  }

  public filterUnit(value: string | Unit): Unit[] {
    // unit foud
    if (value instanceof Unit) {
      return this.units;
    } else {
      //unit not found
      const filterValue = value.toLowerCase();
      let result = this.units.filter(
        (unit) =>
          unit.label!.toLowerCase().includes(filterValue) ||
          unit.description!.toLowerCase().includes(filterValue) ||
          unit.abbreviation!.toLowerCase().includes(filterValue) ||
          this.displayUnit(unit).toLowerCase().includes(filterValue)
      );
      return result;
    }
  }

  public getAssets(value: Returnable) {
    this.measureWidget.equipment = value.asset;
    this.measureWidget.processGroup = value.locationPG;
    this.measureWidget.productionUnit = value.locationPU;
  }

  public getEntityToSave(): MeasureWidget {
    return new MeasureWidget();
  }

  public getUnit(): string {
    if (this.measureWidget !== null && this.measureWidget.unit) {
      return this.measureWidget.unit!.abbreviation!;
    }
    return '';
  }

  public hasAnomaly(): boolean {
    return this.answerService.isAnomaly(this.measureWidget);
  }

  public initModel(): MeasureWidget {
    return new MeasureWidget();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.unitFilterService
      .getFilter()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((units: Unit[] | undefined) => {
        if (units) {
          this.units = units.sort((unit1: Unit, unit2: Unit) =>
            unit1!.label!.localeCompare(unit2!.label!)
          );
          this.units = units.filter(
            (unit1: Unit) => unit1.abbreviation !== 'NONE'
          );
          this.units.splice(0, 0, this.unitService.getDefaultUnit());
        }
      });
    this.measureWidget = this.widget as MeasureWidget;
    if (
      this.measureWidget.unit === null ||
      this.measureWidget.unit.uuid === null
    ) {
      this.measureWidget.unit = this.unitService.getDefaultUnit();
    }
    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.formGroup = this.buildFormGroup(this.measureWidget);
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);

      this.filteredUnits = this.formGroup.get('unit')!.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe),
        startWith(''),
        map((value) => this.filterUnit(value || ''))
      );
    }
  }

  public save(): void {}

  // public setUnit(formControlName: WidgetFormControlNames, unit: Unit): void {
  //   if (
  //     this.formGroup !== null &&
  //     this.formGroup.get(formControlName) !== null
  //   ) {
  //     let formControl = this.formGroup.get(formControlName);
  //
  //     if (
  //       formControl !== null &&
  //       formControlName === WidgetFormControlNames.unit &&
  //       formControl.value instanceof Unit
  //     ) {
  //       this.measureWidget.unit = formControl.value;
  //     }
  //   }
  // }

  public setValue(formControlName: WidgetFormControlNames): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === WidgetFormControlNames.label) {
          this.measureWidget.label = formControl.value;
        } else if (formControlName === WidgetFormControlNames.tooltip) {
          this.measureWidget.tooltip = formControl.value;
        } else if (formControlName === WidgetFormControlNames.max) {
          this.measureWidget.max = formControl.value;
        } else if (formControlName === WidgetFormControlNames.disableControl) {
          this.measureWidget.disableControl = formControl.value;
        } else if (formControlName === WidgetFormControlNames.min) {
          this.measureWidget.min = formControl.value;
        } else if (formControlName === WidgetFormControlNames.isMandatory) {
          this.measureWidget.isMandatory = formControl.value;
        } else if (formControlName === WidgetFormControlNames.unit) {
          if (formControl.value instanceof Unit) {
            this.measureWidget.unit = formControl.value;
          }
        }
      }
    }
  }

  private controlUnit(control: AbstractControl): ValidationErrors | null {
    if (!(control.value instanceof Unit)) {
      return { unit: { value: control.value } };
    }
    let selected = this.filterUnit(control.value);
    if (selected.length === 0) {
      return { required: { value: control.value } };
    }

    return null;
  }
}
