import { JsonObject, JsonProperty } from 'json2typescript';
import { Tube } from '@al/model';

@JsonObject('TubeFull')
export class TubeFull extends Tube {
  @JsonProperty('shoot', Boolean, true)
  public shoot: boolean = true;

  public static from(tube: Tube, tubeFull: TubeFull): TubeFull {
    const result = new TubeFull();
    result.id = tube.id;
    result.name = tube.name;

    if (tubeFull) {
      result.shoot = tubeFull.shoot;
    } else {
      result.shoot = false;
    }
    return result;
  }
}
