import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from '@al/confirm-dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MomentModule } from 'ngx-moment';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TableColumnHeaderFilteringModule } from '@al/table-column-header-filtering';
import { TranslateModule } from '@ngx-translate/core';
import { UsersFormComponent } from './users-form/users-form.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersManagerComponent } from './users-manager/users-manager.component';
// import {NavigationModule} from "@al/navigation";

@NgModule({
  declarations: [UsersFormComponent, UsersListComponent, UsersManagerComponent],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MomentModule,
    // NavigationModule,
    ReactiveFormsModule,
    RouterModule,
    TableColumnHeaderFilteringModule,
    TranslateModule,
  ],
  exports: [UsersFormComponent, UsersListComponent],
})
export class UsersComponentsModule {}
