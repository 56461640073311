<span class="menu" [matMenuTriggerFor]="mainMenu" [matTooltip]="menuTitle">
  <button mat-icon-button attr.aria-label="{{ menuTitle | translate }}">
    <mat-icon>menu</mat-icon>
  </button>
</span>

<mat-menu #mainMenu="matMenu">
  <button mat-menu-item (click)="showDashboard()">
    <mat-icon>home</mat-icon>
    <span translate>MENU_WIDGET_DASHBOARD_MENU</span>
  </button>
  <button mat-menu-item (click)="showTemplate()">
    <mat-icon>list</mat-icon>
    <span translate>MENU_WIDGET_TEMPLATE_MENU</span>
  </button>
  <button mat-menu-item (click)="showRonde()">
    <mat-icon>list</mat-icon>
    <span translate>MENU_WIDGET_INSTANCE_MENU</span>
  </button>

  <button mat-menu-item (click)="showScheduler()">
    <mat-icon>history</mat-icon>
    <span translate>MENU_WIDGET_SHEDULER_MENU</span>
  </button>
  <button
    mat-menu-item
    [disabled]="userProfile && userProfile.id !== '40'"
    (click)="showSiteList()"
  >
    <mat-icon>factory</mat-icon>
    <span translate>MENU_WIDGET_SITE_MENU</span>
  </button>
  <button
    mat-menu-item
    (click)="showUsersList()"
    [disabled]="
      userProfile && !(userProfile.id === '40' || userProfile.id === '30')
    "
  >
    <mat-icon>group</mat-icon>
    <span translate>MENU_WIDGET_USER_MENU</span>
  </button>
  <button
    mat-menu-item
    (click)="showPyrometre()"
    [disabled]="
      userProfile &&
      !(
        userProfile.id === '40' ||
        userProfile.id === '30' ||
        userProfile.id === '20'
      )
    "
  >
    <mat-icon>thermostat</mat-icon>
    <span translate>MENU_WIDGET_PYROMETER_MENU</span>
  </button>
</mat-menu>

<div style="display: none">
  <!--  use to put label in code for i18n extract script-->
  <span translate>MAIN_MENU_TITLE</span>
</div>
