import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { ComponentType, SectionWidget, Widget } from '@al/model';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  TemplateWidgetComponent,
  WidgetStatus,
} from '../template-widget.component';
import { FormGroupModelInterface } from '@al/generic-components';
import { InstanceAnswerService } from '@al/preview-component';
import { WidgetFormControlNames } from '@al/widget-components';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-section-widget',
  templateUrl: './section-widget.component.html',
  styleUrls: [
    '../template-widget.component.scss',
    './section-widget.component.scss',
  ],
})
export class SectionWidgetComponent
  extends TemplateWidgetComponent
  implements
    AfterViewInit,
    FormGroupModelInterface<SectionWidget>,
    OnDestroy,
    OnInit
{
  @ViewChild(CdkDropList)
  public dropList: CdkDropList | undefined;

  public cdkListId: CdkDropList[] = [];

  public children: Widget[] = [];

  public isInit: boolean = false;

  public listId = '';

  public sectionWidget = new SectionWidget();

  public constructor(
    protected override formBuilder: FormBuilder,
    protected dragAndDropService: DragAndDropService,
    protected override answerService: InstanceAnswerService,
    protected templateModelService: TemplateModelService,
    protected override formGroupDirective: FormGroupDirective
  ) {
    super(formBuilder, answerService, formGroupDirective);

    // this.listId = 'child-list-' + this.sectionWidget.id;
  }

  public buildFormGroup(sectionWidget: SectionWidget | null): FormGroup {
    return this.formBuilder.group({
      label: this.formBuilder.control(sectionWidget?.label, [
        Validators.required,
      ]),
    });
  }

  public deleteChildren(widget: Widget): void {
    this.templateModelService.delete(widget);
    this.sectionWidget.children.forEach((child) => {
      let chilId = child.constructor.name + '_' + child.id;
      if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
        this.formGroupDirective.form.removeControl(chilId);
      }
    });
  }

  public drop(event: CdkDragDrop<Widget[]>): void {
    if (event.previousContainer === event.container) {
      this.dragAndDropService.moveItemInArray(event, this.sectionWidget.id);
    } else if (
      event.previousContainer.data[event.previousIndex].componentType !==
      ComponentType.SECTION
    ) {
      let move: boolean = false;
      //
      let widgetId = '';
      // move = event.previousContainer.data[event.currentIndex].id !== null;
      if (event.previousContainer.data[event.previousIndex]) {
        move = event.previousContainer.data[event.previousIndex].id !== null;
      } else {
        move = true;
      }

      if (move) {
        this.dragAndDropService.transferArrayItem(
          event,
          widgetId,
          this.sectionWidget.id
        );
      } else {
        let nextId = this.templateModelService.nextId();
        widgetId = `${this.sectionWidget.id?.split('_')[1]}_${nextId}`;

        this.dragAndDropService.copyArrayItem(
          event,
          widgetId,
          this.sectionWidget.id
        );
      }
    }
  }

  public duplicate($event: Widget): void {
    let index: number = this.sectionWidget.children.indexOf($event);

    const widgetCopy = Object.assign(
      Object.create(Object.getPrototypeOf($event)),
      $event
    );

    const newSectionId = this.templateModelService.nextId();
    widgetCopy.id = `${this.sectionWidget.id?.split('_')[1]}_${newSectionId}`;

    if (widgetCopy instanceof SectionWidget) {
      let section = $event as SectionWidget;
      widgetCopy.children = [];

      section.children.forEach((child: Widget) => {
        let childCopy = Object.assign(
          Object.create(Object.getPrototypeOf(child)),
          child
        );
        childCopy.id =
          newSectionId.split('_')[1] + '_' + this.templateModelService.nextId();
        //TODO ICI
        widgetCopy.children.push(childCopy);
      });
    }

    this.sectionWidget.children.splice(index, 0, widgetCopy);
  }

  public isMcq(widget: Widget): boolean {
    return widget.componentType === ComponentType.MULTI_CHECKBOX;
  }

  public isMeasure(widget: Widget): boolean {
    return widget.componentType === ComponentType.MEASURE;
  }

  public isNote(widget: Widget): boolean {
    return widget.componentType === ComponentType.NOTE;
  }

  public isSection(widget: Widget): boolean {
    return widget.componentType === ComponentType.SECTION;
  }

  public isText(widget: Widget): boolean {
    return widget.componentType === ComponentType.TEXT;
  }

  public isValidation(widget: Widget): boolean {
    return widget.componentType === ComponentType.CHECKBOX;
  }

  public isWheelpicker(widget: Widget): boolean {
    return widget.componentType === ComponentType.WHEEL_PICKER;
  }

  public get getCdkListId(): CdkDropList[] {
    return this.cdkListId;
  }

  public get getListId(): string {
    return this.listId;
  }

  public get getSection(): SectionWidget {
    return this.sectionWidget;
  }

  public ngAfterViewInit() {
    if (this.dropList) {
      this.dragAndDropService.registerForDrop(this.dropList);
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dragAndDropService.unregisterForDrop(this.listId);
  }

  public override ngOnInit() {
    super.ngOnInit();

    this.sectionWidget = this.widget as SectionWidget;
    this.children = this.sectionWidget.children;
    this.listId = 'child-list-' + this.sectionWidget.id;

    if (this.formGroupDirective && this.widgetStatus === WidgetStatus.EDIT) {
      this.dragAndDropService
        .getCdkDropListObservable()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((cdkListId: CdkDropList[]) => {
          this.cdkListId = cdkListId;
        });

      this.formGroup = this.buildFormGroup(this.sectionWidget);
      this.formGroupDirective.form.addControl(this.controlId, this.formGroup);
    }
  }

  public setAllMAndatory(trueFalse: boolean) {
    this.templateModelService.setAllMandatory(this.widget.id, trueFalse);

    Object.keys(this.formGroup.controls).forEach((formgroup) => {
      if (this.formGroup.get(formgroup) instanceof FormGroup) {
        let nestedForm = this.formGroup.get(formgroup) as FormGroup;
        nestedForm.get('isMandatory')?.setValue(trueFalse);
      }
    });
  }

  public setAllThresholds(trueFalse: boolean) {
    this.templateModelService.setEnableAllThreshold(this.widget.id, trueFalse);

    Object.keys(this.formGroup.controls).forEach((formgroup) => {
      if (this.formGroup.get(formgroup) instanceof FormGroup) {
        let nestedForm = this.formGroup.get(formgroup) as FormGroup;
        nestedForm.get('disableControl')?.setValue(trueFalse);
      }
    });
  }

  public setValue(formControlName: WidgetFormControlNames): void {
    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === WidgetFormControlNames.label) {
          this.sectionWidget.label = formControl.value;
        }
      }
    }
  }
}
