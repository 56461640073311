import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('ProductionUnit')
export class ProductionUnit extends ModelAbstract {
  @JsonProperty('id', String, true)
  public id: string | null = null;

  @JsonProperty('name', String, true)
  public name: string | null = null;

  @JsonProperty('type', String, true)
  public type: string | null = null;
}
