import { JsonObject, JsonProperty } from 'json2typescript';
import { Country } from './country.model';
import { ModelAbstract } from './model.abstract';
import { UUID } from 'angular2-uuid';

@JsonObject('Entity')
export class Entity extends ModelAbstract {
  @JsonProperty('countries', [Country], true)
  public countries: Country[] = [];

  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('uuid', String, true)
  public uuid: UUID = '';
}
