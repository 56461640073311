import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../../model.abstract';

@JsonObject('rondeTube')
export class RondeTube extends ModelAbstract {
  @JsonProperty('id', String, true)
  public id: string = '';

  @JsonProperty('label', String, true)
  public label: string = '';

  @JsonProperty('peephole', String, true)
  public peephole: string = '';

  @JsonProperty('raw_temperature_celsius', Number, true)
  public rawTemperatureCelsius: number = 0;

  @JsonProperty('timestamp', Number, true)
  public timestamp: number = 0;

  @JsonProperty('use_pyrometer', Boolean, true)
  public usePyrometer: boolean = true;
}
