import { JsonObject, JsonProperty } from 'json2typescript';
import { DatetimeConverter } from '../converter/datetime.converter';
import { ModelAbstract } from '../model.abstract';
import { Validator } from './validator.model';

@JsonObject('Validation')
export class Validation extends ModelAbstract {
  @JsonProperty('date', DatetimeConverter, true)
  public date: Date | null = null;

  @JsonProperty('validator', Validator, true)
  public validator: Validator | null = null;
}
