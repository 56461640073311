import { ModelAbstract } from '../../model.abstract';

export class PycosoMeasure extends ModelAbstract {
  public peepholeLabel: string = '';

  public pyrometer: boolean = true;

  public rawTemperatureCelsius: number = 0;

  public timestamp: number = 0;

  public tubeLabel: string = '';

  public static compare(a: PycosoMeasure, b: PycosoMeasure): number {
    const gateCompare = a.peepholeLabel.localeCompare(b.peepholeLabel);
    return gateCompare !== 0
      ? gateCompare
      : a.tubeLabel.localeCompare(b.tubeLabel);
  }
}
