import {
  AkitaFilterService,
  RondeLightQuery,
  RondeLightState,
} from '@al/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Injectable } from '@angular/core';
import { RondeLight } from '@al/model';

/**
 * Provide Observable with filter behaviour
 */
@Injectable({
  providedIn: 'root',
})
export class RondeFilterService extends AkitaFilterService<
  RondeLight,
  RondeLightState
> {
  public constructor(protected query: RondeLightQuery) {
    super(new AkitaFiltersPlugin<RondeLightState>(query));
  }
}
