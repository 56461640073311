import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { ComponentType } from '../component-type.enum';

@JsonConverter
export class ComponentTypeConverter
  implements JsonCustomConvert<ComponentType | null>
{
  public deserialize(type: string): ComponentType | null {
    return type as ComponentType;
  }

  public serialize(componentType: ComponentType): string | null {
    if (componentType) {
      return componentType.valueOf();
    }
    return null;
  }
}
