import {
  PyrometreFormComponent,
  PyrometreListComponent,
  PyrometreManagerComponent,
} from '@al/pyrometre-components';
import { AuthGuard } from '../auth.guard';
import { Paths } from '../paths';
import { Route } from '@angular/router';

export const pyrometreRoutes: Route = {
  path: Paths.pyrometre.DEFAULT,
  component: PyrometreManagerComponent,
  children: [
    {
      path: Paths.pyrometre.EDIT,
      component: PyrometreFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30', '20'],
      },
    },
    {
      path: Paths.pyrometre.CREATE,
      component: PyrometreFormComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30', '20'],
      },
    },
    {
      path: Paths.pyrometre.LIST,
      component: PyrometreListComponent,
      canActivate: [AuthGuard],
      data: {
        roles: ['40', '30', '20'],
      },
    },
  ],
};
