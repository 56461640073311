import {
  Answer,
  ComponentType,
  Owner,
  RondeFull,
  RondeLight,
  TemplateFull,
  Validation,
  Widget,
} from '@al/model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InstanceAnswerService {
  private rondeFull: RondeFull = new RondeFull();

  private template: TemplateFull | null = null;

  public getAnomalyCount(): number {
    if (this.rondeFull.instance?.hasAnomaly) {
      return this.rondeFull.instance!.instanceBody!.anomaly.length;
    }
    return 0;
  }

  public getAnomalyValueOfWidget(
    widget: Widget,
    result: Answer
  ): boolean | number | string | string[] | undefined {
    if (widget.componentType === ComponentType.MEASURE) {
      return result.measure;
    } else if (widget.componentType === ComponentType.NOTE) {
      // return result;
    } else if (widget.componentType === ComponentType.CHECKBOX) {
      return result.value;
    } else if (widget.componentType === ComponentType.WHEEL_PICKER) {
      return result.measure;
    } else if (widget.componentType === ComponentType.MULTI_CHECKBOX) {
      return result.options;
    }

    return undefined;
  }

  public getComment(widget: Widget): string | null {
    let resultInAnomaly = this.rondeFull.instance?.instanceBody?.anomaly.filter(
      (r) => r.id === widget.id
    );
    if (resultInAnomaly && resultInAnomaly.length === 1) {
      return resultInAnomaly![0].comment;
    }

    let resultInValid = this.rondeFull.instance?.instanceBody?.valid.filter(
      (r) => r.id === widget.id
    );
    if (resultInValid && resultInValid.length === 1) {
      return resultInValid![0].comment;
    }
    return null;
  }

  public getExecutorValidation(): Validation | null {
    if (this.rondeFull.instance) {
      return this.rondeFull.instance.executorValidation;
    }
    return null;
  }

  public getInstance(): RondeLight {
    return this.rondeFull;
  }

  public getInstanceCreator(): Owner {
    return this.rondeFull.owner!;
  }

  public getSuperviserValidation(): Validation | null {
    if (this.rondeFull.instance) {
      return this.rondeFull.instance.supervisorValidation;
    }
    return null;
  }

  public getTemplateCreator(): Owner {
    return this.template!.owner!;
  }

  public getValidValueOfWidget(
    widget: Widget,
    result: Answer
  ): boolean | number | string | string[] | undefined {
    if (widget.componentType === ComponentType.MEASURE) {
      return result.measure;
    } else if (widget.componentType === ComponentType.NOTE) {
      return result.note;
    } else if (widget.componentType === ComponentType.CHECKBOX) {
      return result.value;
    } else if (widget.componentType === ComponentType.WHEEL_PICKER) {
      return result.measure;
    } else if (widget.componentType === ComponentType.MULTI_CHECKBOX) {
      return result.options;
    }

    return undefined;
  }

  public getValue(
    widget: Widget
  ): boolean | number | string | string[] | undefined {
    let resultInAnomaly = this.rondeFull.instance?.instanceBody?.anomaly.filter(
      (r) => r.id === widget.id
    );
    if (resultInAnomaly && resultInAnomaly.length === 1) {
      return this.getAnomalyValueOfWidget(widget, resultInAnomaly![0]);
    }

    let resultInValid = this.rondeFull.instance?.instanceBody?.valid.filter(
      (r) => r.id === widget.id
    );
    if (resultInValid && resultInValid.length === 1) {
      return this.getValidValueOfWidget(widget, resultInValid![0]);
    }
    return undefined;
  }

  public isAnomaly(widget: Widget): boolean {
    let resultInAnomaly = this.rondeFull.instance?.instanceBody?.anomaly.filter(
      (r) => r.id === widget.id
    );
    if (resultInAnomaly && resultInAnomaly.length === 1) {
      return true;
    }
    return false;
  }

  public isUndefined(widget: Widget): boolean {
    if (!this.isAnomaly(widget) && !this.isValid(widget)) {
      return true;
    }
    return false;
  }

  public isValid(widget: Widget): boolean {
    let resultInValid = this.rondeFull.instance?.instanceBody?.valid.filter(
      (r) => r.id === widget.id
    );
    if (resultInValid && resultInValid.length === 1) {
      return true;
    }
    return false;
  }

  public setInstances(rondeFull: RondeFull): void {
    this.rondeFull = rondeFull;
    this.template = this.rondeFull.template;
  }

  public setTemplate(template: TemplateFull): void {
    this.template = template;
  }
}
