import {
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ComponentType,
  GateWidget,
  InfoWidget,
  McqWidget,
  MeasureWidget,
  NoteWidget,
  SectionWidget,
  TemplateFull,
  ValidationWidget,
  WheelPickerWidget,
  Widget,
} from '@al/model';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import { GenericComponent } from '@al/generic-components';
import { WidgetStatus } from '@al/widget-components';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-template-widget-selector',
  templateUrl: './template-widget-selector.component.html',
  styleUrls: ['./template-widget-selector.component.scss'],
})
export class TemplateWidgetSelectorComponent
  extends GenericComponent
  implements OnInit
{
  @ViewChild('widgetSelectorList')
  public widgetSelectorList: CdkDropList | undefined;

  public cdkListId: CdkDropList[] = [];

  public widgetList: Widget[] = [];

  public widgetStatusEnum = WidgetStatus;

  public constructor(
    protected dragAndDropService: DragAndDropService,
    protected templateModelService: TemplateModelService
  ) {
    super();
  }

  public drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  // public get getCdkListId(): string[] {
  //   return this.cdkListId;
  // }

  public isGate(widget: Widget): boolean {
    return widget.componentType === ComponentType.GATE;
  }

  public isMcq(widget: Widget): boolean {
    return widget.componentType === ComponentType.MULTI_CHECKBOX;
  }

  public isMeasure(widget: Widget): boolean {
    return widget.componentType === ComponentType.MEASURE;
  }

  public isNote(widget: Widget): boolean {
    return widget.componentType === ComponentType.NOTE;
  }

  public isSection(widget: Widget): boolean {
    return widget.componentType === ComponentType.SECTION;
  }

  public isText(widget: Widget): boolean {
    return widget.componentType === ComponentType.TEXT;
  }

  public isValidation(widget: Widget): boolean {
    return widget.componentType === ComponentType.CHECKBOX;
  }

  public isWheelpicker(widget: Widget): boolean {
    return widget.componentType === ComponentType.WHEEL_PICKER;
  }

  public ngOnInit(): void {
    // this.dragAndDropService
    //   .getCdkListIdsObservable()
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((cdkListId: string[]) => {
    //     this.cdkListId = cdkListId;
    //     if (this.widgetSelectorList) {
    //       this.widgetSelectorList.connectedTo = cdkListId;
    //     }
    //   });
    this.dragAndDropService
      .getCdkDropListObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((cdkListId: CdkDropList[]) => {
        this.cdkListId = cdkListId;
        if (this.widgetSelectorList) {
          this.widgetSelectorList.connectedTo = cdkListId;
        }
      });
    this.templateModelService
      .getTemplateFullObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((template: TemplateFull) => {
        this.initWidget(!!template.smr);
      });
  }

  private initWidget(pycoso: boolean) {
    const newWidgetList: Widget[] = [];
    if (pycoso) {
      newWidgetList.push(new GateWidget());
    } else {
      newWidgetList.push(new SectionWidget());
      newWidgetList.push(new McqWidget());
      newWidgetList.push(new ValidationWidget());
      newWidgetList.push(new NoteWidget());
      newWidgetList.push(new InfoWidget());
      newWidgetList.push(new WheelPickerWidget());
      newWidgetList.push(new MeasureWidget());
    }
    this.widgetList = newWidgetList;
  }
}
