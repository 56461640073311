import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { RondeFull } from '@al/model';

export interface RondeFullState extends EntityState<RondeFull>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ronde-full', idKey: 'id', resettable: true })
export class RondeFullStore extends EntityStore<RondeFullState> {
  public constructor() {
    super();
  }
}
