<mat-card class="al-template-widget-selector">
  <mat-card-header>
    <mat-card-title translate>TEMPLATE_WIDGETS</mat-card-title>

  </mat-card-header>
  <div

    cdkDropList
    id="widgetListId"
    #widgetSelectorList="cdkDropList"
    [cdkDropListData]="widgetList"
    [cdkDropListConnectedTo]="this.cdkListId"
  >

      <div *ngFor="let item of widgetList" cdkDrag>
      <al-section-widget class="widget" [widgetStatus]="widgetStatusEnum.WIDGET" [widget]="item"
                         *ngIf="isSection(item)"></al-section-widget>
      <al-info-widget class="widget" [widgetStatus]="widgetStatusEnum.WIDGET" [widget]="item"
                      *ngIf="isText(item)"></al-info-widget>
      <al-mcq-widget class="widget" [widgetStatus]="widgetStatusEnum.WIDGET" [widget]="item"
                     *ngIf="isMcq(item)"></al-mcq-widget>
      <al-note-widget class="widget" [widgetStatus]="widgetStatusEnum.WIDGET" [widget]="item"
                      *ngIf="isNote(item)"></al-note-widget>
      <al-validation-widget class="widget"
                            [widgetStatus]="widgetStatusEnum.WIDGET"
                            [widget]="item"
                            *ngIf="isValidation(item)"></al-validation-widget>
      <al-wheelpicker-widget class="widget" [widgetStatus]="widgetStatusEnum.WIDGET" [widget]="item"
                             *ngIf="isWheelpicker(item)"></al-wheelpicker-widget>
      <al-measure-widget class="widget" [widgetStatus]="widgetStatusEnum.WIDGET" [widget]="item"
                         *ngIf="isMeasure(item)"></al-measure-widget>
      <al-gate-widget class="widget" [widgetStatus]="widgetStatusEnum.WIDGET" [widget]="item"
                      *ngIf="isGate(item)"></al-gate-widget>
    </div>
  </div>
</mat-card>
