import { UnitState, UnitStore } from '../store/unit.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';
import { Unit } from '@al/model';

@Injectable({ providedIn: 'root' })
export class UnitQuery extends QueryEntity<UnitState> {
  public site = this.selectAll();

  public constructor(protected override store: UnitStore) {
    super(store);
  }

  public getUnit(uuid: string): Observable<Unit | undefined> {
    return this.selectEntity(uuid);
  }
}
