import {
  UserConnectedState,
  UserConnectedStore,
} from '../store/user-connected.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';
import { User } from '@al/model';

@Injectable({ providedIn: 'root' })
export class UserConnectedQuery extends QueryEntity<UserConnectedState> {
  public site = this.selectAll();

  public constructor(protected override store: UserConnectedStore) {
    super(store);
  }

  public getUser(id: number): Observable<User | undefined> {
    return this.selectEntity(id);
  }
}
