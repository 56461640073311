import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';

@JsonObject('Tube')
export class Tube extends ModelAbstract {
  @JsonProperty('tube_id', String, true)
  public id: string = '';

  @JsonProperty('tube_name', String, true)
  public name: string = '';
}
