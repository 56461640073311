import { AlSpinnerComponent } from './spinner.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AlSpinnerComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    PortalModule,
    OverlayModule,
    TranslateModule,
    MatCardModule,
  ],
  exports: [AlSpinnerComponent],
})
export class AlSpinnerModule {}
