import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Injectable } from '@angular/core';
import { User } from '@al/model';

export interface UserConnectedState
  extends EntityState<User, number>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-connected', idKey: 'id', resettable: true })
export class UserConnectedStore extends EntityStore<UserConnectedState> {
  public constructor() {
    super();
  }
}
