import { AnomalyAnswer, ValidAnswer } from './ronde-full.answer.model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { ModelAbstract } from '../model.abstract';
import { RondeSection } from './ronde-full.section.model';

@JsonObject('InstanceBody')
export class InstanceBody extends ModelAbstract {
  @JsonProperty('anomaly', [AnomalyAnswer], true)
  public anomaly: AnomalyAnswer[] = [];

  @JsonProperty('mainComment', String, true)
  public mainComment: string | null = null;

  @JsonProperty('section', [RondeSection], true)
  public section: RondeSection[] = [];

  @JsonProperty('valid', [ValidAnswer], true)
  public valid: ValidAnswer[] = [];
}
