<mat-card class="al-template-info" [formGroup]="formGroup">
  <mat-card-header>
    <mat-card-title translate>TEMPLATE_INFO</mat-card-title>

  </mat-card-header>

  <mat-slide-toggle [formControlName]="formControlNames.visibility" (change)="setVisibility()"
                    *ngIf="formGroup.get(formControlNames.visibility)">
    {{'ENABLE_TEMPLATE' | translate}}
  </mat-slide-toggle>

  <mat-form-field appearance="outline" class="full-width" floatLabel="always" *ngIf="formGroup.get('location')">
    <mat-label for="location-input">{{ "LOCATION" | translate}}
    </mat-label>
    <input type="text" id="location-input" [formControlName]="formControlNames.location" matInput
           (change)="setValue(formControlNames.location)">

  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width" floatLabel="always" *ngIf="formGroup.get('furnace')">
    <mat-label for="furnace-input">{{ "PYCOSO_FURNACE" | translate}}
    </mat-label>
    <input type="text" id="furnace-input" [formControlName]="formControlNames.furnace" matInput>

  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width" floatLabel="always"
                  *ngIf="formGroup.get(formControlNames.category)">
    <mat-label for="category-select" class="required-field">{{ "CATEGORY" | translate}}
    </mat-label>
    <mat-select [formControlName]="formControlNames.category.valueOf()" [compareWith]="compareCategory"
                (selectionChange)="setValue(formControlNames.category, $event)"
                id="category-select"
    >

      <mat-option *ngFor="let category of categories " [value]="category">
        {{category.label | translate}}
      </mat-option>

    </mat-select>
  </mat-form-field>


</mat-card>
