import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DragAndDropService, TemplateModelService } from '@al/dnd-service';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import {
  FurnaceLight,
  Profile,
  TemplateFull,
  TemplateLight,
  User,
} from '@al/model';
import {
  SiteService,
  TemplateFullService,
  TemplateLightService,
  UserService,
} from '@al/services';
import { take, takeUntil } from 'rxjs/operators';
import { AlSpinnerService } from '@al/spinner';
import { GenericFormComponent } from '@al/generic-components';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@al/navigation';
import { Paths } from '@al/angular-route';
import { TemplateLanguageKey } from '../language-key.enum';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'al-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
  providers: [FormGroupDirective],
})
export class TemplateFormComponent
  extends GenericFormComponent<TemplateFull>
  implements OnInit, OnDestroy
{
  @ViewChild('exportPDFDialogKo', { static: true })
  public exportPDFDialogKo!: TemplateRef<any>;

  @ViewChild('pdfDialog', { static: true })
  public pdfDialog: TemplateRef<any> | undefined;

  public furnace: FurnaceLight | undefined;

  public userProfile: Profile | undefined;

  public uuid = UUID.UUID();

  public constructor(
    protected override dialog: MatDialog,
    protected siteService: SiteService,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected templateFullService: TemplateFullService,
    protected templateModelService: TemplateModelService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected dragAndDropService: DragAndDropService,
    protected formGroupDirective: FormGroupDirective,
    private alSpinnerService: AlSpinnerService,
    protected userService: UserService,
    protected templateService: TemplateLightService
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      templateFullService,
      translateService,
      router,
      route
    );
    this.form = this.buildFormGroup();
    if (this.formGroupDirective) {
      this.formGroupDirective.form = this.form;
    }
  }

  public buildFormGroup(/*template: Template*/): FormGroup {
    return this.formBuilder.group({});
  }

  public exportPdf() {
    if (this.pdfDialog) {
      this.dialog.open(this.pdfDialog, {
        height: '200px',
        width: '400px',
      });
    }
    this.templateService
      .exportPdf(this.templateModelService.getModel().id)
      .pipe(take(1))
      // .subscribe((response) => {
      // });
      .subscribe({
        next: (response) => {
          this.dialog.closeAll();
          window.open(response.body.presignedUrl, '_blank');
        },
        error: (error) => {
          console.error('export pdf', error);
          this.openDialogInfo(this.exportPDFDialogKo);
        },
      });
  }

  public getEntityToSave(): TemplateFull {
    return new TemplateFull();
  }

  public goToHistory(): void {
    // this.router.navigate([Paths.template.HISTORY], {
    //   relativeTo: this.route.parent,
    // });
    window.open(
      Paths.template.DEFAULT +
        '/' +
        Paths.template.HISTORY +
        '/' +
        this.templateModelService.template.id,
      '_blank'
    );
  }

  public initFormGroup(): void {}

  public initModel(): TemplateFull {
    return new TemplateFull();
  }

  public isPrintable(): boolean {
    return this.templateModelService.getModel().category?.id !== '7';
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.templateModelService.clearModel();
  }

  public ngOnInit(): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuid);
    this.dragAndDropService.clean();

    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.back();
      });

    this.userService
      .getCurrentObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User | undefined) => {
        this.userProfile = user?.profileType;
      });

    this.templateModelService.initTemplate(this.creation, uuid);
    // this.setFormGrouDirectiveLog();
  }

  public onSubmit(quit: boolean = false): void {
    this.uuid = UUID.UUID();
    this.alSpinnerService.startDataProcess(this.uuid);

    this.templateFullService
      .save(this.templateModelService.getModel())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (templatelight: TemplateLight) => {
          if (this.creation) {
            this.templateModelService.updateId(templatelight.id);
            //
            this.creation = false;
          }
          this.setDialogTextValue(TemplateLanguageKey.INFO_DIALOG_MESSAGE_OK);
          this.setDialogTitle(TemplateLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE);
          this.openDialogWithRef(this.infoDialog);
          this.alSpinnerService.stopDataProcess(this.uuid);
          if (quit) {
            this.back();
          }
        },
        error: () => {
          this.setDialogTextValue(TemplateLanguageKey.INFO_DIALOG_MESSAGE_KO);
          this.setDialogTitle(TemplateLanguageKey.INFO_DIALOG_MESSAGE_KO_TITLE);
          this.openDialogWithRef(this.infoDialog);
          this.alSpinnerService.stopDataProcess(this.uuid);
        },
      });
  }

  public openBackDialog(ref: TemplateRef<any>) {
    if (this.form.dirty) {
      this.dialog.open(ref);
    } else {
      this.back();
    }
  }

  public openDialogInfo(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public save(): void {}

  public setFormGrouDirectiveLog(): void {
    if (this.formGroupDirective) {
      this.formGroupDirective.form.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {});
    }
  }

  protected doSomething(): void {}

  protected isValid(): boolean {
    if (
      this.formGroupDirective &&
      (this.userProfile?.id === '30' ||
        this.userProfile?.id === '40' ||
        this.userProfile?.id === '20')
    ) {
      if (this.formGroupDirective.form) {
        return this.formGroupDirective.form.valid;
      }
    }
    return false;
  }

  protected override next(): void {}

  protected override previous(): void {}
}
