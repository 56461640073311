import { Entity, User, UserCreation, UserItems } from '@al/model';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AwsEndPoint } from '../aws.endpoint';
import { EnvironmentService } from '@al/environment';
import { GenericRestService } from '../generic-rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersRestService extends GenericRestService {
  public constructor(
    protected environmentService: EnvironmentService,
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public create(user: User): Observable<UserCreation> {
    return super.postBis<User, UserCreation>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.users.list}`,
      new HttpParams(),
      this.getUserCreationClazz(),
      user
    );
    // .pipe(
    //   map((event) => {
    //     return event;
    //   })
    // );
  }

  public deleteUser(user: User) {
    return super.delete<User>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.users.edit}${user.id}`,
      new HttpParams()
    );
    // .pipe(
    //   map((event) => {
    //     return event;
    //   })
    // );
  }

  public getAllUsers(): Observable<UserItems> {
    return super.get<UserItems>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.users.list}`,
      new HttpParams(),
      this.getUserItemClazz()
    );
  }

  public getEntities(): Observable<Entity[]> {
    return super.getList<Entity>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.entity.list}`,
      new HttpParams(),
      this.getEntityClazz()
    );
  }

  public save(user: User): Observable<User> {
    return super.put<User>(
      `${this.environmentService.env.apigwUrl}${AwsEndPoint.users.edit}${user.id}`,
      new HttpParams(),
      this.getUserClazz(),
      user
    );
    // .pipe(
    //   map((event) => {
    //     return event;
    //   })
    // );
  }

  protected getEntityClazz(): typeof Entity {
    return Entity;
  }

  protected getUserClazz(): typeof User {
    return User;
  }

  protected getUserCreationClazz(): typeof UserCreation {
    return UserCreation;
  }

  protected getUserItemClazz(): typeof UserItems {
    return UserItems;
  }
}
