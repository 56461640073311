import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Country, Entity, Site } from '@al/model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlNames } from '../form-control-names.enum';
import { GenericFormComponent } from '@al/generic-components';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@al/navigation';
import { Paths } from '@al/angular-route';
import { SiteLanguageKey } from '../language-key.enum';
import { SiteService } from '@al/services';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-site',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
})
export class SiteFormComponent
  extends GenericFormComponent<Site>
  implements OnInit
{
  public availableDateFormats: DateFormat[] = [
    { format: 'YYYY-MM-DD HH:mm:ss', desc: '2020-12-24 23:50:12' },
    { format: 'DD-MM-YYYY HH:mm', desc: '24-12-2020 23:50' },
    { format: 'DD-MM-YYYY HH:mm:ss', desc: '24-12-2020 23:50:12' },
    { format: 'YYYY-MM-DD HH:mm', desc: '2020-12-24 23:50' },
    { format: 'YYYY/MM/DD HH:mm', desc: '2020/12/24 23:50' },
    { format: 'YYYY/MM/DD HH:mm:ss', desc: '2020/12/24 23:50:12' },
    { format: 'DD/MM/YYYY HH:mm', desc: '24/12/2020 23:50' },
    { format: 'DD/MM/YYYY HH:mm:ss', desc: '24/12/2020 23:50:12' },
    { format: 'MM/DD/YYYY HH:mm', desc: '12/24/2020 23:50' },
    { format: 'MM/DD/YYYY HH:mm:ss', desc: '12/24/2020 23:50:12' },
  ];

  public entities: Entity[] = [];

  public formControlNames = FormControlNames;

  public timezones: TimeZone[] = [];

  public constructor(
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected siteService: SiteService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      siteService,
      translateService,
      router,
      route
    );
  }

  public buildFormGroup(site: Site): FormGroup {
    return this.formBuilder.group({
      label: this.formBuilder.control(site.label, [Validators.required]),
      maximoSiteId: this.formBuilder.control(site.maximoSiteId, [
        Validators.required,
      ]),
      entity: this.formBuilder.control(site.entity, []),
      country: this.formBuilder.control(site.country, []),
      timezone: this.formBuilder.control(site.timezone, [Validators.required]),
      dateFormat: this.formBuilder.control(site.dateFormat, [
        Validators.required,
      ]),
      alertOnAnomaly: this.formBuilder.control(site.alertOnAnomaly, []),
      allowBtCreation: this.formBuilder.control(site.allowBtCreation, []),
      allowSrCreation: this.formBuilder.control(site.allowSrCreation, []),
      validateInstance: this.formBuilder.control(site.validateInstance, []),
    });
  }

  public compareCountry(option: Country, value: Country): boolean {
    if (option !== null && value !== null) {
      return option.uuid === value.uuid;
    }
    return false;
  }

  public compareEntity(option: Entity, value: Entity): boolean {
    if (option !== null && value !== null) {
      return option.uuid === value.uuid;
    }
    return false;
  }

  public computeOffset(timezone: string): string {
    const zone = moment.tz.zone(timezone);
    if (zone !== null) {
      let hoursOffset = zone.utcOffset(Date.now()) / 60;

      let sign = '';
      if (hoursOffset >= 0) {
        sign = '-';
      } else {
        sign = '+';
        hoursOffset = hoursOffset * -1;
      }

      return '(GMT' + sign + hoursOffset + ')';
    }
    return '';
  }

  public doSomething(): void {}

  public getCountryList(): Country[] {
    if (this.form.get('entity')?.value) {
      return this.siteService.getCountries(this.form.get('entity')?.value);
    }
    return [];
  }

  public getEntityToSave(): Site {
    const site = new Site();
    site.label = this.form.get(FormControlNames.label.valueOf())?.value;
    site.maximoSiteId = this.form.get(
      FormControlNames.maximoSiteId.valueOf()
    )?.value;
    site.dateFormat = this.form.get(
      FormControlNames.dateFormat.valueOf()
    )?.value;
    site.allowSrCreation = this.form.get(
      FormControlNames.allowSrCreation.valueOf()
    )?.value;
    site.allowBtCreation = this.form.get(
      FormControlNames.allowBtCreation.valueOf()
    )?.value;
    site.alertOnAnomaly = this.form.get(
      FormControlNames.alertOnAnomaly.valueOf()
    )?.value;
    site.entity = this.form.get(FormControlNames.entity.valueOf())?.value;
    site.country = this.form.get(FormControlNames.country.valueOf())?.value;
    site.kpiTemplate = this.model.kpiTemplate;
    site.kpiInstance = this.model.kpiInstance;
    site.id = this.model.id;
    site.timezone = this.form.get(FormControlNames.timezone.valueOf())?.value;
    site.validateInstance = this.form.get(
      FormControlNames.validateInstance.valueOf()
    )?.value;
    return site;
  }

  public initFormGroup(site: Site): void {
    this.form.reset();
    this.setFormValue(FormControlNames.label.valueOf(), site.label);
    this.setFormValue(
      FormControlNames.maximoSiteId.valueOf(),
      site.maximoSiteId
    );
    this.setFormValue(FormControlNames.entity.valueOf(), site.entity);
    this.setFormValue(FormControlNames.country.valueOf(), site.country);
    this.setFormValue(FormControlNames.timezone.valueOf(), site.timezone);
    this.setFormValue(FormControlNames.dateFormat.valueOf(), site.dateFormat);
    this.setFormValue(
      FormControlNames.alertOnAnomaly.valueOf(),
      site.alertOnAnomaly
    );
    this.setFormValue(
      FormControlNames.allowBtCreation.valueOf(),
      site.allowBtCreation
    );
    this.setFormValue(
      FormControlNames.allowSrCreation.valueOf(),
      site.allowSrCreation
    );
  }

  public initModel(): Site {
    return new Site();
  }

  public ngOnInit(): void {
    if (!this.creation) {
      this.siteService
        .getActive()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((site: Site | undefined) => {
          if (site) {
            this.siteService
              .getSiteFull(site)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((siteFull: Site) => {
                this.model = siteFull;
                this.form = this.buildFormGroup(siteFull);
              });
          }
        });
    }

    this.entities = this.siteService.getEntities();

    moment.tz.names().forEach((timezone: string) => {
      this.timezones.push({
        timezone: timezone,
        offset: this.computeOffset(timezone),
      });
    });
    this.setDialogTitle(SiteLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE);
    this.setDialogTextValue(SiteLanguageKey.INFO_DIALOG_MESSAGE_OK);
  }

  public save(): void {
    if (this.form.valid) {
      if (!this.creation) {
        this.siteService
          .save(this.getEntityToSave())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.setDialogTitle(SiteLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE);
              this.setDialogTextValue(SiteLanguageKey.INFO_DIALOG_MESSAGE_OK);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
            error: () => {
              this.setDialogTitle(SiteLanguageKey.INFO_DIALOG_MESSAGE_KO_TITLE);
              this.setDialogTextValue(SiteLanguageKey.INFO_DIALOG_MESSAGE_KO);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
          });
      } else {
        this.siteService
          .create(this.getEntityToSave())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.setDialogTitle(SiteLanguageKey.INFO_DIALOG_MESSAGE_OK_TITLE);
              this.setDialogTextValue(SiteLanguageKey.INFO_DIALOG_MESSAGE_OK);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
              this.router.navigate([`${Paths.site.EDIT}`], {
                relativeTo: this.route.parent,
              });
            },
            error: () => {
              this.setDialogTitle(SiteLanguageKey.INFO_DIALOG_MESSAGE_KO_TITLE);
              this.setDialogTextValue(SiteLanguageKey.INFO_DIALOG_MESSAGE_KO);
              this.openDialogWithRef(this.infoDialog);
              this.form.reset(this.form.value);
            },
          });
      }
    }
  }
}

interface DateFormat {
  desc: string;
  format: string;
}

interface TimeZone {
  offset: string;
  timezone: string;
}
