import { JsonObject, JsonProperty } from 'json2typescript';
import { GateModelWidget } from './gate.model.widget';
import { GateResponseWidget } from './gate.response.widget';
import { TubeFull } from '../furnace/tube-full.model';

@JsonObject('gate')
export class GateWidget extends GateModelWidget {
  @JsonProperty('tubes', [TubeFull], true)
  public tube: TubeFull[] = [];

  public override copy(widget: GateResponseWidget): void {
    super.copy(widget as GateModelWidget);
    widget.tube.forEach((tube) => {
      let tubeFull = new TubeFull();
      tubeFull.id = tube.id;
      tubeFull.name = tube.name;
      tubeFull.shoot = tube.shoot;
      this.tube.push(tubeFull);
    });
  }
}
