import {
  CommonModule,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { NgModule } from '@angular/core';
@NgModule({
  declarations: [NavigationComponent],
  imports: [CommonModule],
  exports: [NavigationComponent],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class NavigationModule {}
