import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  FormGroupModelInterface,
  GenericFormComponent,
} from '@al/generic-components';
import { TemplateFullService, TemplateLightService } from '@al/services';
import { FormControlNames } from '../form-control-names.enum';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@al/navigation';
import { TemplateFull } from '@al/model';
import { TemplateModelService } from '@al/dnd-service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-template-title',
  templateUrl: './template-title.component.html',
  styleUrls: ['./template-title.component.scss'],
})
export class TemplateTitleComponent
  extends GenericFormComponent<TemplateFull>
  implements OnInit, OnDestroy, FormGroupModelInterface<TemplateFull>
{
  public formControlNames = FormControlNames;

  public formGroup: FormGroup = new FormGroup({});

  public isNotInitialized = 1;

  public templateFull: TemplateFull = new TemplateFull();

  public constructor(
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected templateLightService: TemplateLightService,
    protected templateFullService: TemplateFullService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected templateModelService: TemplateModelService,
    protected formGroupDirective: FormGroupDirective
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      templateFullService,
      translateService,
      router,
      route
    );
    this.formGroup = this.buildFormGroup(this.templateFull);
  }

  public buildFormGroup(templateFull: TemplateFull | null) {
    return this.formBuilder.group({
      title: this.formBuilder.control(templateFull?.title, [
        Validators.required,
      ]),
    });
  }

  public getEntityToSave(): TemplateFull {
    return this.templateFull;
  }

  public initFormGroup(entity: TemplateFull): void {
    this.formGroup = this.buildFormGroup(entity);
  }

  public initModel(): TemplateFull {
    return new TemplateFull();
  }

  public ngOnInit(): void {
    this.templateModelService
      .getTemplateFullObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((template: TemplateFull) => {
        this.templateFull = template;
        if (this.formGroupDirective) {
          this.formGroup = this.buildFormGroup(this.templateFull);
          this.formGroupDirective.form.addControl(
            'template-title',
            this.formGroup
          );
        }
      });
  }

  public save(): void {}

  public setValue(formControlName: FormControlNames): void {
    //FIX : passer par le service , ne pas modifier le modele ici

    if (
      this.formGroup !== null &&
      this.formGroup.get(formControlName) !== null
    ) {
      let formControl = this.formGroup.get(formControlName);
      if (formControl !== null) {
        if (formControlName === FormControlNames.title) {
          this.templateModelService.setTitle(formControl.value);
          this.templateFull.title = formControl.value;
        }
      }
    }
  }
}
