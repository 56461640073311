import { JsonObject, JsonProperty } from 'json2typescript';
import { Instance } from './ronde.instance.model';
import { ModelAbstract } from '../model.abstract';
import { Owner } from '../template/owner.model';

@JsonObject('RondeLight')
export class RondeLight extends ModelAbstract {
  @JsonProperty('instance', Instance, true)
  public instance: Instance | null = null;

  @JsonProperty('owner', Owner, true)
  public owner: Owner | null = null;
}
