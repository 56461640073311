import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AkitaFilterService } from '@al/akita';
import { DefaultPath } from '@al/angular-route';
import { EntityState } from '@datorama/akita';
import { GenericComponent } from './generic.component';
import { GenericService } from '@al/services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModelAbstract } from '@al/model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-generic-template-list',
  template: '',
  styleUrls: [],
})
export abstract class GenericListComponent<T extends ModelAbstract>
  extends GenericComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('deleteDialogKo')
  public deleteDialogKo!: TemplateRef<any>;

  @ViewChild('deleteDialogOk')
  public deleteDialogOk!: TemplateRef<any>;

  @ViewChild('paginator')
  public paginator: MatPaginator | undefined;

  @ViewChild(MatSort)
  public sort: MatSort | undefined;

  public dataSource = new MatTableDataSource<T>([]);

  public displayedColumns: string[] = [];

  public constructor(
    protected akitaFilterService: AkitaFilterService<T, EntityState<T>>,
    protected genericService: GenericService<T>,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super();
    this.displayedColumns = this.initColumnDisplay();
  }

  public addFilter(
    field: string,
    $event: number | string,
    predicate: any
  ): void {
    this.akitaFilterService.addFilter(field, $event, predicate);
  }

  public ngAfterViewInit() {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }

    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  public ngOnInit(): void {
    this.akitaFilterService
      .getFilter()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: T[]) => {
        this.dataSource.data = data;
      });
  }

  protected clearFilter($event: any, field: string) {
    this.akitaFilterService.removeFilter(field);
  }

  protected create(): void {
    this.router.navigate([new DefaultPath().CREATE], {
      relativeTo: this.route.parent,
    });
  }

  protected delete(): void {
    this.router.navigate([new DefaultPath().CREATE], {
      relativeTo: this.route.parent,
    });
  }

  protected edit(id: string | number) {
    this.genericService.setActive(id);

    this.router.navigate([new DefaultPath().EDIT], {
      relativeTo: this.route.parent,
    });
  }

  protected abstract initColumnDisplay(): string[];
}
