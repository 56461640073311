import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlSpinnerService } from '@al/spinner';
import { GenericComponent } from '@al/generic-components';
import { NavigationService } from '@al/navigation';
import { SiteService } from '@al/services';
import { UUID } from 'angular2-uuid';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-site-manager',
  templateUrl: './site-manager.component.html',
  styleUrls: ['./site-manager.component.scss'],
}) //, AfterContentChecked
export class SiteManagerComponent
  extends GenericComponent
  implements OnInit, OnDestroy
{
  // private ngUnsubscribe = new Subject();

  public constructor(
    // private location: Location,
    private route: ActivatedRoute,
    protected router: Router,
    protected navigationService: NavigationService,
    protected siteService: SiteService,
    private alSpinnerService: AlSpinnerService
  ) {
    super();
  }

  // public ngAfterContentChecked(): void {
  //   this.navigationService.setBack(this.route);
  // }

  public ngOnInit(): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startDataProcess(uuid);

    this.siteService
      .loadEntity()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          console.error('intitial load ok : entity');
          this.alSpinnerService.stopDataProcess(uuid);
        },
        error: (error) => {
          console.error('intitial load Error: entity', error);
          this.alSpinnerService.stopDataProcess(uuid);
          this.alSpinnerService.launchSnackBar('ERR_LOAD_SITES', 10);
        },
      });
  }
}
