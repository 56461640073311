import { BehaviorSubject, Observable, merge } from 'rxjs';
import {
  CollectionViewer,
  DataSource,
  SelectionChange,
} from '@angular/cdk/collections';
import { Equipment, ProcessGroup, ProductionUnit } from '@al/model';
import {
  EquipmentsQuery,
  ProcessGroupsQuery,
  ProductionUnitsQuery,
} from '@al/akita';
import { DynamicFlatNode } from './al-assets-dynamic-flat-node';

import { FlatTreeControl } from '@angular/cdk/tree';

import { map } from 'rxjs/operators';

export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  public dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  public get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  public set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  private equipments: Equipment[] = [];

  private processGroups: ProcessGroup[] = [];

  private productionUnits: ProductionUnit[] = [];

  private searchText: string;

  public constructor(
    private treeControl: FlatTreeControl<DynamicFlatNode>,
    private productionUnitsQuery: ProductionUnitsQuery,
    private processGroupsQuery: ProcessGroupsQuery,
    private equipmentsQuery: EquipmentsQuery
  ) {
    this.searchText = '';
    this.data = this.initializeData();
  }

  public connect(
    collectionViewer: CollectionViewer
  ): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(
      map(() => {
        return this.data;
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public disconnect(_collectionViewer: CollectionViewer): void {}

  public filterNodes(text: string): void {
    this.searchText = text;

    if (text.length === 0) {
      this.data = this.initializeData();
    }

    if (text.length >= 3) {
      this.equipments = [];
      this.processGroups = [];
      this.productionUnits = [];

      this.equipments = this.equipmentsQuery
        .getAll({
          filterBy: (item: Equipment) => {
            let result = false;
            if (
              item &&
              item.processTag &&
              item.processTag.toLowerCase().indexOf(text.toLowerCase()) > -1
            ) {
              result = true;
            }
            if (
              item &&
              item.description &&
              item.description.toLowerCase().indexOf(text.toLowerCase()) > -1
            ) {
              result = true;
            }
            return result;
          },
        })
        .sort((a, b) => this.compareEquipments(a, b));

      this.processGroups = this.processGroupsQuery
        .getAll({
          filterBy: (item: ProcessGroup) =>
            item && item.name
              ? item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
              : false,
        })
        .sort((a, b) => this.compareProcessGroups(a, b));

      this.productionUnits = this.productionUnitsQuery
        .getAll({
          filterBy: (item: ProductionUnit) =>
            item && item.name
              ? item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
              : false,
        })
        .sort((a, b) => this.compareProductionUnits(a, b));

      this.parseEquipments(this.equipments);

      this.parseProcessGroups(this.processGroups);

      const productionUnitNodes = this.mapItemToNode(this.productionUnits);
      this.data = productionUnitNodes;
    }
  }

  /** Handle expand/collapse behaviors */
  public handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  public initializeData(): DynamicFlatNode[] {
    const productionUnits = this.productionUnitsQuery
      .getAll({
        filterBy: (item: ProductionUnit) => {
          if (item.id === null || item.id === '') {
            return false;
          }
          return true;
        },
      })
      .sort((a, b) => this.compareProductionUnits(a, b));
    return productionUnits.map(
      (item) => new DynamicFlatNode(item, 1, this.isExpandable(item))
    );
  }

  public mapItemToNode(
    items: Equipment[] | ProcessGroup[] | ProductionUnit[]
  ): DynamicFlatNode[] {
    const result: DynamicFlatNode[] = [];
    items.forEach((item: Equipment | ProcessGroup | ProductionUnit) =>
      result.push(new DynamicFlatNode(item, 1, this.isExpandable(item)))
    );
    return result;
  }

  /**
   * Toggle the node, remove from display list
   */
  public toggleNode(dynamicFlatNode: DynamicFlatNode, expand: boolean) {
    const node = dynamicFlatNode;
    let children: Equipment[] | ProcessGroup[] | ProductionUnit[] = [];
    if (node.item instanceof Equipment) {
      children = this.equipmentsQuery
        .getAll({
          filterBy: (equipment: Equipment) =>
            equipment.parentId === node.item.id,
        })
        .filter((equipment: Equipment) => {
          if (this.searchText.length === 0) {
            return true;
          }
          return this.equipments.some((item) => item.id === equipment.id);
        })
        .sort((a, b) => this.compareEquipments(a, b));
    }
    if (node.item instanceof ProcessGroup) {
      children = this.equipmentsQuery
        .getAll({
          filterBy: (equipment: Equipment) =>
            equipment.processGroupId === node.item.id &&
            equipment.parentId === null,
        })
        .filter((equipment: Equipment) => {
          if (this.searchText.length === 0) {
            return true;
          }
          return this.equipments.some((item) => item.id === equipment.id);
        })
        .sort((a, b) => this.compareEquipments(a, b));
    }
    if (node.item instanceof ProductionUnit) {
      children = this.processGroupsQuery
        .getAll({
          filterBy: (processGroup: ProcessGroup) =>
            processGroup.productionUnitId === node.item.id,
        })
        .filter((processGroup: ProcessGroup) => {
          if (this.searchText.length === 0) {
            return true;
          }
          return this.processGroups.some((item) => item.id === processGroup.id);
        })
        .sort((a, b) => this.compareProcessGroups(a, b));
    }
    const index = this.data.indexOf(node);
    // If no children, or cannot find the node, no op
    if (children.length === 0 || index < 0) {
      return;
    }

    node.isLoading = true;

    if (expand) {
      const nodes: DynamicFlatNode[] = [];
      children.forEach((child: Equipment | ProductionUnit | ProcessGroup) =>
        nodes.push(
          new DynamicFlatNode(child, node.level + 1, this.isExpandable(child))
        )
      );
      this.data.splice(index + 1, 0, ...nodes);
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i += 1
      ) {
        count += 1;
      }
      this.data.splice(index + 1, count);
    }

    // notify the change
    this.dataChange.next(this.data);
    node.isLoading = false;
  }

  private compareEquipments(a: Equipment, b: Equipment): number {
    const aProcessTag = a.processTag;
    const aDescription = a.description;
    const aName = `${aProcessTag}${aDescription}`;
    const bProcessTag = b.processTag;
    const bDescription = b.description;
    const bName = `${bProcessTag}${bDescription}`;
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  }

  private compareProcessGroups(a: ProcessGroup, b: ProcessGroup): number {
    const aName = a.name || '';
    const bName = b.name || '';
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  }

  private compareProductionUnits(a: ProductionUnit, b: ProductionUnit): number {
    const aName = a.name || '';
    const bName = b.name || '';
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  }

  private getEquipment(id: string): Equipment | null {
    const equipments = this.equipmentsQuery
      .getAll({
        filterBy: (item) => item.id === id,
      })
      .sort((a, b) => this.compareEquipments(a, b));
    return equipments[0] || null;
  }

  private getProcessGroup(id: string): ProcessGroup | null {
    const processGroups = this.processGroupsQuery
      .getAll({
        filterBy: (item) => item.id === id,
      })
      .sort((a, b) => this.compareProcessGroups(a, b));
    return processGroups[0] || null;
  }

  private getProductionUnit(id: string): ProductionUnit | null {
    const productionUnits = this.productionUnitsQuery
      .getAll({
        filterBy: (item) => item.id === id,
      })
      .sort((a, b) => this.compareProductionUnits(a, b));
    return productionUnits[0] || null;
  }

  private isExpandable(
    item: ProductionUnit | ProcessGroup | Equipment
  ): boolean {
    if (item instanceof Equipment) {
      return this.equipmentsQuery.hasEntity(
        (equipment: Equipment) => equipment.parentId === item.id
      );
    }
    if (item instanceof ProcessGroup) {
      return this.equipmentsQuery.hasEntity(
        (equipment: Equipment) =>
          equipment.processGroupId === item.id && equipment.parentId === null
      );
    }
    if (item instanceof ProductionUnit) {
      return this.processGroupsQuery.hasEntity(
        (processGroup: ProcessGroup) =>
          processGroup.productionUnitId === item.id
      );
    }
    return false;
  }

  private merge(a: any[], b: any[], prop: string): any[] {
    const reduced = a.filter(
      (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
    );
    return reduced.concat(b);
  }

  private parseEquipments(items: Equipment[]): void {
    let equipments: Equipment[] = [];
    const processGroups: ProcessGroup[] = [];
    items.forEach((item: Equipment) => {
      if (
        item.processGroupId &&
        !this.processGroups.some(
          (processGroup) => processGroup.id === item.processGroupId
        )
      ) {
        const processGroup = this.getProcessGroup(item.processGroupId);
        if (processGroup) {
          processGroups.push(processGroup);
        }
      }
      if (
        item.parentId &&
        !this.equipments.some((equipment) => equipment.id === item.parentId)
      ) {
        const equipment = this.getEquipment(item.parentId);
        if (equipment) {
          equipments = this.merge(equipments, [equipment], 'id');
        }
      }
    });
    if (equipments.length > 0) {
      this.parseEquipments(equipments);
      this.equipments = this.merge(this.equipments, equipments, 'id');
    }
    if (processGroups.length > 0) {
      this.parseProcessGroups(processGroups);
      this.processGroups = this.merge(this.processGroups, processGroups, 'id');
    }
  }

  private parseProcessGroups(items: ProcessGroup[]): void {
    let productionUnits: ProductionUnit[] = [];
    items.forEach((item: ProcessGroup) => {
      if (item.productionUnitId) {
        const productionUnit = this.getProductionUnit(item.productionUnitId);
        if (productionUnit) {
          productionUnits = this.merge(productionUnits, [productionUnit], 'id');
        }
      }
    });
    this.productionUnits = this.merge(
      this.productionUnits,
      productionUnits,
      'id'
    );
  }
}
