export class DefaultPath {
  public CREATE = 'create';

  public DEFAULT = '';

  public EDIT = 'edit';
  //
  // public HISTORIES = 'histories';
  //
  // public HISTORY = 'history';

  public LIST = 'list';
}

export class PyrometrePath extends DefaultPath {
  public override DEFAULT = 'pyrometre';
}

export class RondePath extends DefaultPath {
  public override DEFAULT = 'reporting';
}

export class SitePath extends DefaultPath {
  public override DEFAULT = 'site';
}

export class TemplatePath extends DefaultPath {
  public override DEFAULT = 'template';

  public HISTORIES = 'histories';

  public HISTORY = 'history';
}

export class UsersPath extends DefaultPath {
  public override DEFAULT = 'utilisateurs';
}

export class SchedulerPath extends DefaultPath {
  public override DEFAULT = 'scheduler';
}

export class Paths {
  public static CALLBACK = 'callback';

  public static DASHBOARD = 'dashboard';

  public static DEFAULT = '**';

  public static LOGIN = 'login';

  public static PDF = 'pdf';

  public static SITE_LIST = 'list';

  public static USERS = 'utilisateurs';

  public static pyrometre: PyrometrePath = new PyrometrePath();

  public static ronde = new RondePath();

  public static scheduler = new SchedulerPath();

  public static site: SitePath = new SitePath();

  public static template = new TemplatePath();

  public static users = new UsersPath();
}
